import axios from 'axios';
import coreAPI, { nextAPI } from '../common/axios';
import {
  buildCustomerPayload,
  transformCustomerToConversationItem,
} from '../common/customerHelper';

let messageCenterSource = null;

export default class MessageCenterService {
  static async getAllInboxsCount(orgId, inboxes, options) {
    const response = await coreAPI.post(`/functions/inboxesV3`, {
      _method: 'POST',
      where: {
        orgId,
        inboxes,
      },
      options,
    });
    return response;
  }

  static async getCustomersV3(params, user, organization, isNeedCancel) {
    try {
      const payload = buildCustomerPayload(params);

      const { CancelToken } = axios;

      if (messageCenterSource && isNeedCancel) {
        messageCenterSource.cancel('getCustomersV3 canceled');
      }

      messageCenterSource = CancelToken.source();

      const { result } = await coreAPI.post(
        '/functions/findCustomerV3',
        payload,
        {
          cancelToken: messageCenterSource.token,
        },
      );

      const newConversationResult = result
        .filter(customer => customer.inbox)
        .map(customer =>
          transformCustomerToConversationItem(customer, user, organization),
        );

      return newConversationResult;
    } catch (e) {
      const errorMessage = e?.response?.data?.error || e?.message || e;

      if (axios.isCancel(e) || errorMessage === 'getCustomersV3 canceled') {
        // eslint-disable-next-line no-console
        console.log(
          'Previous request getCustomersV3 canceled, new request is send',
        );
      } else {
        throw e;
      }
      return [];
    }
  }

  static async getLineSticker(params) {
    let res = { url: '' };

    const { result } = await coreAPI.post('/functions/getLineSticker', params);
    res = result;
    return res;
  }

  static async getOrgKeptMessage(orgId, skip) {
    const query = {
      where: {
        organization: {
          __type: 'Pointer',
          className: 'Organization',
          objectId: orgId,
        },
        message: { $exists: true },
      },
      include: 'message',
      order: '-updatedAt',
      _method: 'GET',
      keys:
        'message.conversation.objectId,message.organization,message.mid,message.platform,message.contentType,message.data,message.labels,message.receipts,message.sender,message.senderType,message.taskId',
      limit: 20,
    };

    if (skip) {
      query.skip = skip;
    }

    const { results } = await coreAPI.post('/classes/KeptMessage', query);

    return results;
  }

  static async getAuditUser(orgId, conversationId) {
    const response = await nextAPI.get(`/audit/${orgId}/${conversationId}`);

    return response.data;
  }

  static async getCustomerByCustomerId(customerId) {
    const query = {
      where: {
        customerId,
      },
      keys: 'customerId,displayName,picture',
      _method: 'GET',
      limit: 1,
    };

    const result = await coreAPI.post('/classes/Customer', query);
    return result;
  }

  static async getOrganizationMembers({
    org,
    keyword,
    memberId,
    memberIds,
    limit,
  }) {
    const mainQuery = {
      order: 'firstName',
      limit,
      _method: 'GET',
    };

    if (org) {
      mainQuery.where = {
        $or: [
          {
            $relatedTo: {
              object: {
                __type: 'Pointer',
                className: 'Membership',
                objectId: org.members.objectId,
              },
              key: 'users',
            },
          },
        ],
      };
    }

    if (keyword) {
      mainQuery.where = {
        $or: [
          {
            $relatedTo: {
              object: {
                __type: 'Pointer',
                className: 'Membership',
                objectId: org.members.objectId,
              },
              key: 'users',
            },
            firstName: {
              $regex: keyword,
              $options: 'i',
            },
          },
          {
            $relatedTo: {
              object: {
                __type: 'Pointer',
                className: 'Membership',
                objectId: org.members.objectId,
              },
              key: 'users',
            },
            lastName: {
              $regex: keyword,
              $options: 'i',
            },
          },
        ],
      };
    }

    if (memberId) {
      mainQuery.where = { objectId: memberId };
    }

    if (memberIds) {
      mainQuery.where = { objectId: { $in: memberIds } };
    }

    if (limit) {
      mainQuery.limit = limit;
    }

    const { results } = await coreAPI.post('/classes/_User', mainQuery);
    return results;
  }

  static async setLastReadByCustomerId(customerId, orgId, badge, lastReadAt) {
    const result = await nextAPI.post('/last_read/conversation/set', {
      id: customerId,
      orgId,
      badge,
      lastReadAt,
    });
    return result;
  }

  static async updateIsMonitoring(customerId, orgId, isMonitoring) {
    const result = await nextAPI.post('/customer/monitoring/update', {
      orgId,
      customerId,
      isMonitoring,
    });
    return result;
  }
}
