import { nextAPI } from '../common/axios';

export default class PermissionSettingService {
  static async getOrgRoleList(orgId) {
    const response = await nextAPI.get(`/org_roles/list?orgId=${orgId}`);

    return response;
  }

  static async getUserOrgFeature(orgId) {
    const response = await nextAPI.get(`/org_roles/user/get?orgId=${orgId}`);

    return response;
  }

  static async createOrgRole({ orgId, name, superiorId, feature }) {
    const response = await nextAPI.post('/org_roles/create', {
      orgId,
      name,
      superiorId,
      feature,
    });

    return response;
  }

  static async updateOrgFeature({ orgId, orgRoleId, feature }) {
    const response = await nextAPI.put('/org_roles/update_feature', {
      orgId,
      orgRoleId,
      feature,
    });

    return response;
  }

  static async deleteOrgRole({ orgId, orgRoleId }) {
    const response = await nextAPI.delete(
      `/org_roles/remove?orgId=${orgId}&orgRoleId=${orgRoleId}`,
    );

    return response;
  }

  static async updateOrgRole({ orgId, orgRoleId, name }) {
    const response = await nextAPI.put(`/org_roles/update`, {
      orgId,
      orgRoleId,
      orgRole: { name },
    });

    return response;
  }
}
