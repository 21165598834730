import coreAPI from '../common/axios';

export default class PermissionService {
  static async getPermission(userId) {
    if (!userId) throw new Error('getPermission: userId is empty');

    const payload = {
      where: {
        owner: {
          __type: 'Pointer',
          className: '_User',
          objectId: userId,
        },
      },
      limit: 1,
      _method: 'GET',
    };

    const response = await coreAPI.post('/classes/Permission', payload);
    if (response.results.length === 1) return response.results[0];
    return null;
  }
}
