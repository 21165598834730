import actionType, {
  SET_BROADCAST,
  BROADCAST_FIND_TAG,
  BROADCAST_FIND_PARTNER_TAG,
  BROADCAST_RESET,
  BROADCAST_ADD_MESSAGE,
  BROADCAST_GET_CUSTOMER_GROUPS,
  SYSTEM_TASK_DELETE_TASK,
  COPY_BROADCAST,
  BROADCAST_GET_CUSTOMERS,
  BROADCAST_SET_DEFAULT_TEMPLATE,
} from '../actions/type';
import { DEFAULT_CONDITION } from '../components/broadcast/create/Options';
import { FacebookTagType, TaskStatusType, WhatsappTagType } from '../types';

const INITIAL_STATE = {
  extension: null,
  list: [],
  customers: [],
  customerGroups: [],
  candidateTags: [],
  ECCandidateTags: [],
  membershipTier: [],
  candidatePartnerTags: null,
  count: 0,
  isLoading: false,
  isCreateLoading: false,
  name: '',
  customerNum: 0,
  conditions: [{ ...DEFAULT_CONDITION.platform }, { ...DEFAULT_CONDITION.tag }],
  fbMessengerTag: FacebookTagType.NO_TAG,
  whatsappTag: WhatsappTagType.NO_TAG,
  timingType: 'now',
  timing: '',
  altText: undefined,
  shareMessageSetting: undefined,
  messages: [
    {
      contentType: 'text/plain',
      data: {
        content: '',
      },
      index: 0,
    },
  ],
  searchName: '',
  defaultTemplate: {
    isShow: false,
    templateType: 'all',
    messageIndex: 0,
  },
  isShowOptionalDialog: false,
  isShowConfirmCardDialog: false,
  isShowImageCardDialog: false,
  isSaveAsDefaultTemplate: false,
  isShowCardDialogTemplateIndex: 0,
  isDetailBroadcastLoading: false,
  isDetailBroadcastTemplateAccountingLoading: false,
  resource: null,
  quickReply: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.BROADCAST_LIST_REQUEST_RESET:
      return INITIAL_STATE;
    case actionType.GET_BROADCAST_LIST_REQUEST:
      return { ...state, isLoading: true };
    case actionType.UPDATE_BROADCAST_LIST:
      const allTaskStatus = Object.values(TaskStatusType);
      return {
        ...state,
        list: state.list.map(o => {
          if (o.objectId === action.payload.objectId) {
            const oldStatusIndex = allTaskStatus.indexOf(o?.status);
            const newStatusIndex = allTaskStatus.indexOf(
              action.payload?.status,
            );
            if (newStatusIndex >= oldStatusIndex) {
              return {
                ...o,
                ...action.payload,
              };
            }
            return o;
          }
          return o;
        }),
      };
    case actionType.GET_BROADCAST_LIST_CONTINUE_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload),
        isLoading: false,
      };
    case actionType.GET_BROADCAST_LIST_SUCCESS:
      return { ...state, list: action.payload, isLoading: false };
    case actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isLoading: false,
        list: [],
      };
    case actionType.GET_BROADCAST_COUNT:
      return { ...state, count: action.payload };
    case actionType.SET_BROADCAST_CREATE_CONDITION:
      return { ...state, conditions: action.payload };
    case actionType.SET_BROADCAST_CREATE_TIMINGTYPE:
      return { ...state, timingType: action.payload };
    case actionType.SET_BROADCAST_CREATE_TIMING:
      return { ...state, timing: action.payload };
    case actionType.SET_BROADCAST_CREATE_NAME:
      return { ...state, name: action.payload };
    case actionType.SET_BROADCAST_CREATE_MESSAGE:
      return { ...state, messages: action.payload };
    case actionType.SET_BROADCAST_CUSTOMER_NUM:
      return { ...state, customerNum: action.payload };
    case actionType.SEND_BROADCAST_REQUEST:
      return { ...state, isCreateLoading: true };
    case actionType.SEND_BROADCAST_SUCCESS:
      return { ...state, isCreateLoading: false };
    case actionType.SEND_BROADCAST_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isCreateLoading: false,
      };
    case SET_BROADCAST:
      return {
        ...state,
        ...action.setting,
      };
    case BROADCAST_FIND_TAG:
      return {
        ...state,
        candidateTags: action.candidateTags,
      };
    case BROADCAST_FIND_PARTNER_TAG:
      return {
        ...state,
        candidatePartnerTags: action.candidatePartnerTags,
      };
    case BROADCAST_RESET:
      return INITIAL_STATE;
    case BROADCAST_ADD_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          { ...action.payload, index: state.messages.length },
        ],
      };
    case BROADCAST_GET_CUSTOMER_GROUPS:
      return {
        ...state,
        customerGroups: [...action.payload],
      };
    case SYSTEM_TASK_DELETE_TASK:
      return {
        ...state,
        list: state.list.filter(d => {
          return d.objectId !== action.payload;
        }),
      };
    case COPY_BROADCAST:
      return {
        ...state,
        ...action.payload,
      };
    case BROADCAST_GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case BROADCAST_SET_DEFAULT_TEMPLATE:
      return {
        ...state,
        defaultTemplate: {
          ...state.defaultTemplate,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
