import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconCheck = ({
  onClick,
  className,
  id,
  width,
  height,
  disableHover,
  iconColor,
}) => {
  const [isHover, setIsHover] = useState(false);

  const random = Math.random();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onMouseEnter={() => {
        if (!disableHover) setIsHover(true);
      }}
      onMouseLeave={() => {
        if (!disableHover) setIsHover(false);
      }}
      onClick={onClick}
    >
      <mask
        id={`inboxCheckMask${id}${random}`}
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="18"
        height="13"
      >
        <path
          d="M8.72951 15.9066L4.49749 11.7068C4.15581 11.3677 3.60082 11.3667 3.25787 11.7046C2.91493 12.0424 2.91391 12.5911 3.2556 12.9302L8.10742 17.745C8.44954 18.0845 9.00542 18.0851 9.34818 17.7462L20.7433 6.47949C21.0856 6.14104 21.0856 5.5923 20.7433 5.25384C20.401 4.91539 19.846 4.91539 19.5036 5.25384L8.72951 15.9066Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#inboxCheckMask${id}${random})`}>
        <rect
          width="24"
          height="24"
          fill={isHover ? hexRgba(iconColor, 40) : iconColor}
        />
      </g>
    </svg>
  );
};

IconCheck.defaultProps = {
  width: 24,
  height: 24,
  iconColor: color.$11,
};

export default IconCheck;
