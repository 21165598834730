import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { color as colorStore } from '../../../themes';

function IconReply({
  className,
  width,
  height,
  id,
  color,
  hoverColor,
  viewBox,
  onClick = () => {},
}) {
  const [mainColor, setMainColor] = useState(color);

  useEffect(() => {
    if (color !== mainColor) {
      setMainColor(color);
    }
  }, [color]);

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={viewBox}
      onMouseEnter={() => setMainColor(hoverColor)}
      onMouseLeave={() => setMainColor(color)}
      onClick={onClick}
    >
      <defs>
        <path
          id={`icon-reply-${id}`}
          d="M10.6786 3.83301C13.9388 3.96634 16.4672 6.56634 16.6668 9.76634V10.0997C16.6668 11.0997 16.4672 12.0997 16.0015 12.9663C14.9369 15.0997 12.7412 16.433 10.3459 16.433C9.41439 16.433 8.54942 16.233 7.75098 15.8997L4.22457 17.0997C4.15803 17.1663 4.0915 17.1663 4.02496 17.1663C3.82535 17.1663 3.69228 17.0997 3.49267 16.9663C3.3596 16.833 3.29306 16.5663 3.3596 16.2997L4.55725 12.7663C4.22457 11.9663 4.02496 11.033 4.02496 10.1663C4.02496 7.76634 5.35568 5.56634 7.55138 4.49967C8.41635 4.03301 9.34785 3.83301 10.3459 3.83301H10.6786ZM14.8038 12.433C15.1365 11.6997 15.3361 10.9663 15.3361 10.1663V9.83301C15.1365 7.29967 13.1404 5.29967 10.612 5.16634H10.3459C9.614 5.16634 8.81556 5.36634 8.1502 5.69967C6.42026 6.56634 5.35568 8.23301 5.35568 10.1663C5.35568 10.8997 5.55529 11.6997 5.88797 12.3663C6.02104 12.5663 6.02104 12.6997 5.95451 12.8997L5.08954 15.433L7.61791 14.5663C7.81752 14.5663 8.01713 14.5663 8.1502 14.633C8.81556 14.9663 9.614 15.1663 10.3459 15.1663C12.2754 15.1663 13.9388 14.1663 14.8038 12.433Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={id} fill="#FFF">
          <use xlinkHref={`#icon-reply-${id}`} />
        </mask>
        <g fill={mainColor} mask={`url(#${id})`}>
          <path d="M0 0H20V20H0z" />
        </g>
      </g>
    </svg>
  );
}

IconReply.defaultProps = {
  className: undefined,
  width: 20,
  height: 20,
  color: colorStore.$10,
  hoverColor: colorStore.$1,
  viewBox: '0,0,20,20',
};

IconReply.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  viewBox: PropTypes.string,
};

export default IconReply;
