import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation, Trans } from 'react-i18next';

import Button from '../../shared/Button';
import Modal from '../../shared/Modal';

const useStyles = makeStyles(
  () => ({
    paper: {
      maxWidth: 960,
      width: 'calc(100vw * 2 / 3)',
      borderRadius: '12px',
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: '#f9f9f9',
    },
    description: {
      fontSize: 15,
      lineHeight: 'normal',
      '& > a': {
        color: '#9fc100',
        textDecoration: 'underline',
      },
    },
    'cancel-button': {
      marginRight: 8,
    },
  }),
  { name: 'ConnectLineBizTipModal' },
);

const ConnectLineBizTipModal = props => {
  const { isOpen, onCancel, onClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      paperClass={classes.paper}
      title={t('organization-setting.linked-app.check-line-biz')}
      content={
        <Typography className={classes.description}>
          <Trans i18nKey="organization-setting.linked-app.connect-line-biz-tip-description">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t('organization-setting.linked-app.helpUrl')}
            >
              {t('organization-setting.linked-app.go-to-line-biz-tutorial')}
            </a>
          </Trans>
        </Typography>
      }
      actions={
        <>
          <Button
            className={classes['cancel-button']}
            topic="primaryBlue"
            onClick={onCancel}
          >
            {t('modal.not-now')}
          </Button>
          <Button topic="primaryBlueHighLight" onClick={onClick}>
            {t('modal.start-connect')}
          </Button>
        </>
      }
    />
  );
};

export default ConnectLineBizTipModal;
