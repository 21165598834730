import { color } from '../../../themes';

const IconAttribute = ({ id, width, height, iconColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <circle
        id={`ic-namea-${id}`}
        cx="10"
        cy="10"
        r="6.7"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        id={`ic-nameb-${id}`}
        cx="10"
        cy="8.5"
        r="2.2"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id={`ic-namec-${id}`}
        d="M6 15v-.5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v.5"
        stroke={iconColor}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconAttribute.defaultProps = {
  width: 20,
  height: 20,
  iconColor: color.$2,
};

export default IconAttribute;
