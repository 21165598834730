import { makeStyles } from '@material-ui/core/styles';
import { platformIconsSrc } from '../iconSrc';
import { PlatformType } from '../../types';

const useStyles = makeStyles(() => ({
  icon: {
    position: 'relative',
    width: 20,
    height: 20,
    marginLeft: '-20px',
    zIndex: 2,
  },
}));

const ChatLivechat = ({ iconStyle }) => {
  const classes = useStyles();

  return (
    <img
      src={platformIconsSrc[PlatformType.LIVECHAT]}
      className={iconStyle || classes.icon}
      alt="ChatLivechat"
    />
  );
};

export default ChatLivechat;
