import actionType from '../actions/type';

const INITIAL_STATE = {
  type: 'enabledApp',
  tabIndex: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_SUPERMARKET_TYPE:
      return { ...state, type: action.payload };
    case actionType.SET_SUPERMARKET_TABINDEX:
      return { ...state, tabIndex: action.payload };
    case actionType.RESET_SUPERMARKET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
