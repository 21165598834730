import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { color as colorStore } from '../../../themes';

function IconMessageUnread({
  className,
  width,
  height,
  color,
  hoverColor,
  viewBox,
  onClick = () => {},
}) {
  const [mainColor, setMainColor] = useState(color);

  useEffect(() => {
    if (color !== mainColor) {
      setMainColor(color);
    }
  }, [color]);

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setMainColor(hoverColor)}
      onMouseLeave={() => setMainColor(color)}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4546 4.66699H4.54547C3.38637 4.66699 2.5 5.56977 2.5 6.75034V15.0837C2.5 16.2643 3.38637 17.1671 4.54547 17.1671H15.4546C16.6137 17.1671 17.5001 16.2643 17.5001 15.0837V6.75034C17.5001 5.56977 16.6137 4.66699 15.4546 4.66699ZM4.54543 6.05585H15.4546C15.7273 6.05585 15.9319 6.19474 16.0682 6.47252L10 10.7781L3.93179 6.47252C4.06816 6.19474 4.27271 6.05585 4.54543 6.05585ZM3.86359 15.0837C3.86359 15.5004 4.13632 15.7782 4.54541 15.7782H15.4546C15.8637 15.7782 16.1364 15.5004 16.1364 15.0837V8.0698L10.4091 12.167C10.2727 12.2365 10.1363 12.3059 9.99998 12.3059C9.86362 12.3059 9.72726 12.2365 9.59089 12.167L3.86359 8.0698V15.0837Z"
        fill={mainColor}
      />
      <circle
        cx="15.4167"
        cy="6.74967"
        r="2.16667"
        fill="white"
        stroke={mainColor}
        strokeWidth="1.5"
      />
    </svg>
  );
}

IconMessageUnread.defaultProps = {
  className: undefined,
  width: 20,
  height: 20,
  color: colorStore.$10,
  hoverColor: colorStore.$1,
  viewBox: '0,0,20,20',
};

IconMessageUnread.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  viewBox: PropTypes.string,
};

export default IconMessageUnread;
