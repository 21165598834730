import actionType from '../actions/type';

const initState = {
  isLoading: false,
  list: [],
  data: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_SURVEYCAKE:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_SURVEYCAKE_FAIL:
      return initState;
    default:
      return state;
  }
};
