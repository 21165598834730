import actionType from '../actions/type';
import { FetchStatus } from '../types';

const INITIAL_STATE = {
  fetchStatus: FetchStatus.INITIAL,
  data: [],
  campaignSelected: null,
  campaignChannelList: null,
  campaignChannelCount: null,
  campaignList: [],
  campaignCount: 0,
  saveResult: null,
  campaignCreateName: '',
  campaignCreateChannels: [],
  lineChannelInfo: null,
  pageSize: 20,
  campaignCurrentPage: 1,
  channelCurrentPage: 1,
  channelSearchName: '',
  resource: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_SUPERLINK_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.data.rows,
        campaignCount: action.data.count,
      };
    case actionType.SET_SUPERLINK_CAMPAIGN_INFO:
      return {
        ...state,
        campaignSelected: action.data,
      };
    case actionType.UPDATE_SUPERLINK_CAMPAIGN_CHANNELS:
      return {
        ...state,
        campaignChannelList: action.channelList,
      };
    case actionType.SET_SUPERLINK_CAMPAIGN_CHANNELS:
      if (action.channelName) {
        return {
          ...state,
          campaignChannelList: action.data.rows,
        };
      }
      return {
        ...state,
        campaignChannelList: action.data.rows,
        campaignChannelCount: action.data.count,
      };
    case actionType.SET_SUPERLINK_CAMPAIGN_SAVE_RESULT:
      return {
        ...state,
        saveResult: action.result,
      };
    case actionType.RESET_SUPERLINK_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      };
    case actionType.RESET_CAMPAIGN_SELECTED:
      return {
        campaignSelected: null,
        campaignChannelList: null,
        campaignChannelCount: null,
        channelCurrentPage: 1,
        pageSize: 20,
        fetchStatus: FetchStatus.INITIAL,
      };
    case actionType.SET_CAMPAIGN_CREATE_NAME:
      return {
        ...state,
        campaignCreateName: action.payload,
      };
    case actionType.SET_CAMPAIGN_CREATE_CHANNELS:
      return {
        ...state,
        campaignCreateChannels: action.payload,
      };
    case actionType.SET_LINE_CHANNEL_INFO:
      return {
        ...state,
        lineChannelInfo: action.payload,
      };
    case actionType.SET_SUPERLINK_FETCH_DATA_FETCHING:
      return {
        ...state,
        fetchStatus: FetchStatus.FETCHING,
      };
    case actionType.SET_SUPERLINK_FETCH_DATA_SUCCESS:
      return {
        ...state,
        fetchStatus: FetchStatus.DONE,
      };
    case actionType.SET_SUPERLINK_CAMPAIGN_PAGE:
      return {
        ...state,
        campaignCurrentPage: action.payload,
      };
    case actionType.SET_SUPERLINK_CHANNEL_PAGE:
      return {
        ...state,
        channelCurrentPage: action.payload,
      };
    case actionType.RESET_SUPERLINK_CAMPAIGN_QUERY:
      return {
        ...state,
        campaignCurrentPage: 1,
        campaignList: [],
        campaignCount: 0,
        pageSize: 20,
        fetchStatus: FetchStatus.INITIAL,
      };
    case actionType.RESET_SUPERLINK_CAMPAIGN_EDIT_DATA:
      return {
        ...state,
        campaignCreateChannels: [],
      };
    case actionType.SET_SUPERLINK_RESOURCE:
      return {
        ...state,
        resource: action.payload,
      };
    case actionType.SET_SUPERLINK_CHANNEL_SEARCH_NAME:
      return {
        ...state,
        channelSearchName: action.payload,
      };
    default:
      return state;
  }
};
