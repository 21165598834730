import actionType from '../actions/type';

const initialState = {
  data: [],
  count: 0,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_WEBVIEW_EDITOR_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
