import makeStyles from '@material-ui/core/styles/makeStyles';
import MUIBadge from '@material-ui/core/Badge';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    standard: {
      '& > .MuiBadge-anchorOriginTopRightRectangle': {
        padding: '0 8px',
        color: color.$7,
        top: '50%',
        minWidth: '30px',
        height: '18px',
        borderRadius: '9.5px',
      },
    },
  }),
  {
    name: 'Badge',
  },
);

const Badge = ({
  badgeContent,
  badgeColor,
  invisible,
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <MUIBadge
      className={badgeContent ? classes.standard : className}
      color={badgeColor}
      variant={badgeContent ? 'standard' : 'dot'}
      badgeContent={badgeContent}
      invisible={invisible}
    >
      {children}
    </MUIBadge>
  );
};

export default Badge;
