import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconFolder = ({ onClick, className, id, infoPanel }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="folder"
          d="M18.545 6.4h-6.954L10.2 4.32c-.164-.16-.41-.32-.655-.32h-4.09C4.064 4 3 5.04 3 6.4v11.2C3 18.96 4.064 20 5.455 20h13.09C19.936 20 21 18.96 21 17.6V8.8c0-1.36-1.064-2.4-2.455-2.4zm.819 11.2c0 .48-.328.8-.819.8H5.455c-.491 0-.819-.32-.819-.8V6.4c0-.48.328-.8.819-.8h3.681l1.391 2.08c.164.16.41.32.655.32h7.363c.491 0 .819.32.819.8v8.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`folderMask${id}`} fill="#fff">
            <use xlinkHref="#folder" />
          </mask>
          <g
            fill={
              infoPanel === 'filesPanel'
                ? color.$3
                : isHover
                ? hexRgba(color.$12, 40)
                : color.$12
            }
            mask={`url(#folderMask${id})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconFolder;
