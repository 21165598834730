import moment from 'moment';
import SystemTaskService from '../services/SystemTaskService';
import {
  SYSTEM_TASK_GET_TASK_LIST_REQUEST,
  SYSTEM_TASK_GET_TASK_LIST_SUCCESS,
  SYSTEM_TASK_GET_TASK_LIST_FAILURE,
  SYSTEM_TASK_SET_TASK_LIST_DATA,
  SYSTEM_TASK_GET_TASK_LIST_CONTINUE_SUCCESS,
  SYSTEM_TASK_INSERT_TASK,
  SYSTEM_TASK_SET_SHOW_BADGE_ICON,
  SYSTEM_TASK_DELETE_TASK,
} from './type';
import { ModalType, SnackBarType } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import i18n from '../i18n';
import exportData from '../common/exportData';

function transfromTaskData(data) {
  const tempData = { ...data };
  if (tempData.scheduleAt) {
    tempData.scheduleAt = moment(tempData.scheduleAt);
  }
  if (tempData.createdAt) {
    tempData.createdAt = moment(tempData.createdAt);
  }
  if (tempData.updateAt) {
    tempData.updateAt = moment(tempData.updateAt);
  }
  return tempData;
}

export const getTaskList = (orgId, isContinue = false) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: SYSTEM_TASK_GET_TASK_LIST_REQUEST });
  try {
    let results = [];
    if (isContinue) {
      const dataLength = getState().systemTask.data.length;
      results = await SystemTaskService.getTaskList(orgId, {
        skip: dataLength,
      });
      dispatch({
        type: SYSTEM_TASK_GET_TASK_LIST_CONTINUE_SUCCESS,
        data: results.map(d => transfromTaskData(d)),
      });
    } else {
      results = await SystemTaskService.getTaskList(orgId);
      dispatch({
        type: SYSTEM_TASK_GET_TASK_LIST_SUCCESS,
        data: results.map(d => transfromTaskData(d)),
      });
    }
  } catch (error) {
    dispatch({ type: SYSTEM_TASK_GET_TASK_LIST_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const setTaskList = task => (dispatch, getState) => {
  const taskData = getState().systemTask.data.map(data => {
    if (data.objectId === task.objectId) {
      return transfromTaskData(task);
    }
    return data;
  });

  dispatch({ type: SYSTEM_TASK_SET_TASK_LIST_DATA, data: taskData });
};

export const tagsOperation = (orgId, tags, other) => async (
  dispatch,
  getState,
) => {
  const query = { ...getState().allCustomers.query };
  const options = { ...getState().allCustomers.options };
  query.orgId = orgId;
  try {
    await SystemTaskService.tagsOperationByTask(tags, query, options, other);
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const tagsOperationByCustomerGroupId = (
  orgId,
  tags,
  groupId,
  other,
) => async dispatch => {
  const query = { orgId };
  try {
    await SystemTaskService.tagsOperationByTaskGroupId(
      tags,
      query,
      groupId,
      other,
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const tagsOperationByCustomerIds = (
  orgId,
  tags,
  options,
  customerIds,
) => async (dispatch, getState) => {
  const query = { ...getState().allCustomers.query };
  query.orgId = orgId;
  query.customerObjectIds = customerIds;
  try {
    await SystemTaskService.tagsOperationByTask(tags, query, {}, options);
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const exportCustomers = (orgId, query, options = {}) => async (
  dispatch,
  getState,
) => {
  const realQuery = { ...getState().allCustomers.query, ...query };
  const realOptions = { ...getState().allCustomers.options, ...options };
  const { displayName } = getState()?.organization?.selectOrg;

  realQuery.orgId = orgId;

  const i18nData = exportData.customers(displayName);

  try {
    await SystemTaskService.exportCustomersByTask(
      realQuery,
      realOptions,
      i18nData,
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const insertTask = task => (dispatch, getState) => {
  const taskLength = getState().systemTask.data.filter(
    d => d.objectId === task.objectId,
  ).length;
  if (taskLength === 0) {
    dispatch({
      type: SYSTEM_TASK_INSERT_TASK,
      task: transfromTaskData(task),
    });
  }
};

/**
 * @param {boolean} isShowIcon
 */
export const setShowSystemTaskBadgeIcon = isShowIcon => (
  dispatch,
  getState,
) => {
  const { isOpen, modalType } = getState().modal;
  if (!isOpen || (isOpen && modalType !== ModalType.SYSTEM_TASK_MODAL)) {
    dispatch({
      type: SYSTEM_TASK_SET_SHOW_BADGE_ICON,
      showSystemTaskBadgeIcon: isShowIcon,
    });
  }
};

export const deleteTask = (taskId, name) => async dispatch => {
  try {
    await SystemTaskService.deleteTask(taskId);
    dispatch({
      type: SYSTEM_TASK_DELETE_TASK,
      payload: taskId,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('broadcast:delete-success', { name }),
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('broadcast:default-error'),
      }),
    );
  }
};
