import actionType from '../actions/type';

export const initState = {
  planName: '測試方案',
  isInitVpc: false,
  data: {
    overview: {
      enabled: true,
      dataAnalysis: { enabled: true },
      tagAnalysis: { enabled: true },
      template: { enabled: true },
      csData: { enabled: true },
    },
    superLink: {
      enabled: true,
    },
    msgCenter: {
      enabled: true,
    },
    messageBot: {
      enabled: true,
      bot: { enabled: true },
      aiBot: { enabled: true },
    },
    marketingAutomation: {
      enabled: true,
    },
    customerMgmt: {
      enabled: true,
      allCustomers: { enabled: true },
      customerGroups: { enabled: true },
      tagManagement: { enabled: true },
      messageKeyword: { enabled: true },
    },
    broadcast: {
      enabled: true,
    },
    superMarketSetting: {
      enabled: true,
      roulette: { enabled: true },
      scratch: { enabled: true },
      slot: { enabled: true },
      coupon: { enabled: true },
      growthTool: { enabled: true },
      eInvoice: { enabled: true },
      lineRoleSetting: { enabled: true },
      offlineSetting: { enabled: true },
      super8api: { enabled: true },
      lineOfficialNotification: { enabled: true },
      shopline: { enabled: true },
      retargetingPermission: { enabled: true },
      mitake: { enabled: true },
      recurringMessage: { enabled: true },
      surveyCake: { enabled: true },
      super8AI: { enabled: true },
      ga4Event: { enabled: true },
      selfBuildOfficial: { enabled: true },
      gssVital: { enabled: true },
      shopify: { enabled: true },
      cyberbiz: { enabled: true },
      app91: { enabled: true },
      retargetingShopline: { enabled: true },
    },
    webviewEditor: {
      enabled: true,
    },
    chatpay: {
      enabled: true,
      order: { enabled: true },
      product: { enabled: true },
      setting: { enabled: true },
      csData: { enabled: true },
    },
    orgSetting: {
      enabled: true,
      lineApp: { enabled: true },
      basicInfo: { enabled: true },
      memberManagement: { enabled: true },
      permissionSetting: { enabled: true },
      richMenuSetting: { enabled: true },
      redirectOptions: { enabled: true },
      orgAnnouenctments: { enabled: true },
      whatsappTemplate: { enabled: true },
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_VPC_FEATURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_VPC_FEATURE_FAIL:
      return initState;
    default:
      return state;
  }
};
