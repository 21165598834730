import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TaskStatusType } from '../../types';
import { color } from '../../themes';
import Tooltip from '../shared/Tooltip';
import IconCheckCircle from '../../assets/images/Icons/IconCheckCircle';
import IconAlertTriangle from '../../assets/images/Icons/IconAlertTriangle';
import { icInLine, icInProgress } from '../../assets/iconSrc';

const useStyles = makeStyles(
  () => ({
    'tooltip-popper': {
      top: '6px !important',
      zIndex: 2001,
    },
    'status-icon': {
      width: 24,
      height: 24,
    },
    'status-icon--pending': {
      animation: '$spin 5s linear infinite',
    },
    'status-icon--working': {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
  {
    name: 'SystemTaskStatusIcon',
  },
);

function SystemTaskStatusIcon({ objectId, taskStatus }) {
  const { i18n } = useTranslation();

  const classes = useStyles();
  let icon = null;
  function translateTooltipTitle(status) {
    switch (status) {
      case TaskStatusType.STARTED:
        return i18n.t('system-task.assign-task.started');
      case TaskStatusType.PENDING:
        return i18n.t('system-task.assign-task.pending');
      case TaskStatusType.WORKING:
        return i18n.t('system-task.assign-task.working');
      case TaskStatusType.DONE:
        return i18n.t('system-task.assign-task.success');
      case TaskStatusType.ERROR:
        return i18n.t('system-task.assign-task.fail');
      default:
        return '';
    }
  }

  switch (taskStatus) {
    case TaskStatusType.STARTED:
      icon = <img src={icInLine} alt="task started" />;
      break;
    case TaskStatusType.PENDING:
      icon = (
        <img
          src={icInProgress}
          alt="task pending"
          className={classes['status-icon--pending']}
        />
      );
      break;
    case TaskStatusType.PREPARING:
    case TaskStatusType.WORKING:
      icon = (
        <img
          src={icInProgress}
          alt="task working"
          className={classes['status-icon--working']}
        />
      );
      break;
    case TaskStatusType.DONE:
      icon = (
        <IconCheckCircle
          key={`Task-icon-${objectId}`}
          color={color.$1}
          id={objectId}
        />
      );
      break;
    case TaskStatusType.ERROR:
      icon = <IconAlertTriangle key={`Task-icon-${objectId}`} />;
      break;
    default:
      return null;
  }

  return (
    <Tooltip
      title={translateTooltipTitle(taskStatus)}
      placement="top"
      detailClasses={{ popper: classes['tooltip-popper'] }}
    >
      <div className={classes['status-icon']}>{icon}</div>
    </Tooltip>
  );
}

SystemTaskStatusIcon.propTypes = {
  objectId: PropTypes.string.isRequired,
  taskStatus: PropTypes.oneOf(Object.values(TaskStatusType)).isRequired,
};

export default SystemTaskStatusIcon;
