import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconFilter = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill={isHover ? hexRgba(color.$13, 40) : color.$13}>
        <path d="M17.92 3.436c-.145-.29-.363-.436-.653-.436H2.733c-.29 0-.508.145-.654.436-.145.218-.072.509.073.727l5.668 6.685v4.506c0 .29.145.508.436.654l2.907 1.453c.072.073.145.073.29.073.146 0 .291 0 .364-.073.218-.145.363-.363.363-.654v-5.959l5.668-6.685c.145-.218.218-.509.073-.727zm-7.048 6.685c-.073.146-.145.291-.145.51v5.013l-1.454-.726V10.63c0-.145-.072-.363-.145-.436l-4.796-5.74H15.74l-4.869 5.667z" />{' '}
      </g>
    </svg>
  );
};

export default IconFilter;
