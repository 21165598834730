import actionType from './type';
import { SnackBarType } from '../types';
import { nextAPI } from '../common/axios';
import { enqueueSnackbar } from './snackbarAction';
import i18n from '../i18n';

const getSuper8apiPermissions = orgId => async dispatch => {
  dispatch({
    type: actionType.GET_SUPER8API_PERMISSIONS_REQUEST,
  });

  try {
    const response = await nextAPI.get(`/api-key/organization/${orgId}`);
    if (response?.permissions.length) {
      dispatch({
        type: actionType.GET_SUPER8API_PERMISSIONS_SUCCESS,
        payload: response,
      });

      return response.permissions;
    }

    dispatch({
      type: actionType.GET_SUPER8API_PERMISSIONS_FAILURE,
      payload: [],
    });

    return [];
  } catch (e) {
    dispatch({
      type: actionType.GET_SUPER8API_PERMISSIONS_FAILURE,
      payload: [],
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: e,
      }),
    );

    return [];
  }
};

const getSuper8apiKey = orgId => async dispatch => {
  dispatch({
    type: actionType.GET_SUPER8API_KEY_REQUEST,
  });

  try {
    const response = await nextAPI.post('/api-key/issue', { orgId });
    if (response?.key) {
      dispatch(getSuper8apiPermissions(orgId));
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('super8api.setting.successIssue'),
        }),
      );
      dispatch({
        type: actionType.GET_SUPER8API_KEY_SUCCESS,
        payload: response.key,
      });
    } else {
      dispatch({
        type: actionType.GET_SUPER8API_KEY_FAILURE,
        payload: '',
      });
    }
  } catch (e) {
    dispatch({
      type: actionType.GET_SUPER8API_KEY_FAILURE,
      payload: '',
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: e,
      }),
    );
  }
};

const getSuper8apiKeyV2 = orgId => async dispatch => {
  dispatch({
    type: actionType.GET_SUPER8API_KEY_REQUEST,
  });

  try {
    const response = await nextAPI.post('/api-key/issue_v2', { orgId });
    if (response?.key) {
      dispatch(getSuper8apiPermissions(orgId));
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('super8api.setting.successIssue'),
        }),
      );
      dispatch({
        type: actionType.GET_SUPER8API_KEY_SUCCESS,
        payload: response.key,
      });
    } else {
      dispatch({
        type: actionType.GET_SUPER8API_KEY_FAILURE,
        payload: '',
      });
    }
  } catch (e) {
    dispatch({
      type: actionType.GET_SUPER8API_KEY_FAILURE,
      payload: '',
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: e,
      }),
    );
  }
};

const setSuper8apiKeyName = data => async dispatch => {
  try {
    await nextAPI.post('/api-key/name', {
      orgId: data.orgId,
      key: data.key,
      name: data.name,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('super8api.setting.successSetKeyName'),
      }),
    );
  } catch (e) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: e,
      }),
    );
  }
};

const deleteSuper8apiKey = data => async dispatch => {
  try {
    await nextAPI.delete('/api-key/delete', {
      data: {
        orgId: data.orgId,
        key: data.key,
      },
    });
    dispatch(getSuper8apiPermissions(data.orgId));
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('super8api.setting.successDelete'),
      }),
    );
  } catch (e) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: e,
      }),
    );
  }
};

export {
  getSuper8apiPermissions,
  getSuper8apiKey,
  getSuper8apiKeyV2,
  setSuper8apiKeyName,
  deleteSuper8apiKey,
};
