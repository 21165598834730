import moment from 'moment';

export const DEFAULT_CONDITION = {
  platform: {
    key: 'platform',
    value: ['line'],
  },
  customer: {
    key: 'customer',
    boolean: 'include',
    value: [],
  },
  gender: {
    key: 'gender',
    boolean: 'eq',
    value: ['male'],
  },
  tag: {
    key: 'tag',
    value: {},
  },
  'partner-tag': {
    key: 'partner-tag',
    boolean: 'include',
    value: [],
  },
  platformValue: 'all',
  'customer-group': {
    key: 'customer-group',
    value: [],
  },
  'broadcast-by-fail': {
    key: 'broadcast-by-fail',
    value: '',
  },
  inbox: {
    key: 'inbox',
    value: [],
  },
  shopline: {
    membershipTier: {
      key: 'shopline',
      type: 'membershipTier',
      value: [],
    },
    pointBalance: {
      key: 'shopline',
      type: 'pointBalance',
      value: {
        start: 0,
      },
    },
    creditBalance: {
      key: 'shopline',
      type: 'creditBalance',
      value: {
        start: 0,
      },
    },
    ordersTotalSum: {
      key: 'shopline',
      type: 'ordersTotalSum',
      value: {
        startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        value: 0,
      },
    },
    orderCount: {
      key: 'shopline',
      type: 'orderCount',
      value: {
        startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        value: 0,
      },
    },
    tags: {
      key: 'shopline',
      type: 'tags',
      value: {
        type: 'include',
        tagsName: [],
      },
    },
  },
  shopify: {
    membershipTier: {
      key: 'shopify',
      type: 'membershipTier',
      value: [],
    },
    pointBalance: {
      key: 'shopify',
      type: 'pointBalance',
      value: {
        start: 0,
      },
    },
    ordersTotalSum: {
      key: 'shopify',
      type: 'ordersTotalSum',
      value: {
        startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        value: 0,
      },
    },
    orderCount: {
      key: 'shopify',
      type: 'orderCount',
      value: {
        startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        value: 0,
      },
    },
    tags: {
      key: 'shopify',
      type: 'tags',
      value: {
        type: 'include',
        tagsName: [],
      },
    },
  },
  cyberbiz: {
    membershipTier: {
      key: 'cyberbiz',
      type: 'membershipTier',
      value: [],
    },
    pointBalance: {
      key: 'cyberbiz',
      type: 'pointBalance',
      value: {
        start: 0,
      },
    },
    creditBalance: {
      key: 'cyberbiz',
      type: 'creditBalance',
      value: {
        start: 0,
      },
    },
    ordersTotalSum: {
      key: 'cyberbiz',
      type: 'ordersTotalSum',
      value: {
        startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        value: 0,
      },
    },
    orderCount: {
      key: 'cyberbiz',
      type: 'orderCount',
      value: {
        startDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        value: 0,
      },
    },
    tags: {
      key: 'cyberbiz',
      type: 'tags',
      value: {
        type: 'include',
        tagsName: [],
      },
    },
  },
  recurring: {
    key: 'recurring',
    value: [{ id: null, element: null }],
  },
};

export default DEFAULT_CONDITION;
