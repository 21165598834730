import classnames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { color } from '../../themes';
import Button from './Button';

const useStyles = makeStyles(
  () => ({
    root: {
      zIndex: '4002 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > h2': {
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: 1,
        color: color.$3,
      },
      padding: 24,
    },
    content: {
      fontSize: 15,
      lineHeight: 1.4,
      color: color.$3,
      padding: '21px 24px 12px',
      backgroundColor: '#f9f9f9',
    },
    actions: {
      padding: '12px 24px 24px',
      backgroundColor: '#f9f9f9',
    },
    errorMessage: {
      fontSize: 13,
      color: color.$17,
      marginRight: 4,
    },
  }),
  {
    name: 'DialogText',
  },
);

function DialogText({
  isOpen,
  title,
  content,
  contentClass,
  contentRef,
  actions,
  actionsClass,
  cancelText,
  submitText,
  onCancel,
  onSubmit,
  isSubmitDisabled,
  isCancelHidden,
  isSubmitHidden,
  errorMessage,
  paperClass,
}) {
  const classes = useStyles();

  function handleOnSubmit() {
    onCancel();
    onSubmit();
  }

  return (
    <Dialog
      className={classes.root}
      classes={{
        paper: classnames(classes.paper, paperClass),
      }}
      open={isOpen}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent
        className={classnames(classes.content, contentClass)}
        ref={contentRef}
      >
        {content}
      </DialogContent>
      <DialogActions className={classnames(classes.actions, actionsClass)}>
        {actions || (
          <>
            {errorMessage !== '' && (
              <div className={classes.errorMessage}>{errorMessage}</div>
            )}
            {!isCancelHidden && (
              <Button topic="primaryBlue" onClick={onCancel}>
                {cancelText || 'cancel'}
              </Button>
            )}
            {!isSubmitHidden && (
              <Button
                topic={
                  !isCancelHidden && !isSubmitHidden
                    ? 'primaryBlueHighLight'
                    : 'primaryBlue'
                }
                onClick={handleOnSubmit}
                isDisable={isSubmitDisabled}
              >
                {submitText || 'submit'}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

DialogText.defaultProps = {
  actions: undefined,
  cancelText: undefined,
  submitText: undefined,
  isSubmitDisabled: false,
  isCancelHidden: false,
  isSubmitHidden: false,
  contentClass: undefined,
  actionsClass: undefined,
  errorMessage: '',
  paperClass: undefined,
  onSubmit: () => {},
  onCancel: () => {},
  contentRef: () => {},
};

DialogText.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  actions: PropTypes.element,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  isCancelHidden: PropTypes.bool,
  isSubmitHidden: PropTypes.bool,
  contentClass: PropTypes.string,
  actionsClass: PropTypes.string,
  errorMessage: PropTypes.string,
  paperClass: PropTypes.string,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default DialogText;
