import imgSrc from '../../assets/iconSrc';
import icInboxSubBind from '../../assets/images/svg/24_ic_inbox_branch_link.svg';
import icInboxBind from '../../assets/images/svg/24_ic_inbox_link.svg';

const INBOXICONTYPE = {
  all: imgSrc.icInboxAll,
  unassigned: imgSrc.icInboxUnassign,
  done: imgSrc.icInboxDone,
  self: imgSrc.icInboxMine,
  private: imgSrc.icInboxAllAssign,
  bot: imgSrc.icInboxBot,
  spam: imgSrc.icInboxSpam,
  unfollow: imgSrc.icInboxUnfollow,
  history: imgSrc.icInboxOnce,
  department: imgSrc.icInboxDept,
  bind: icInboxBind,
  subBind: icInboxSubBind,
};

export default INBOXICONTYPE;
