import qs from 'query-string';
import { nextAPI } from '../common/axios';

export default class VpcFeatureService {
  static async getVpcSetting(orgId) {
    const response = await nextAPI.get(
      `/vpc/setting/get?${qs.stringify(
        {
          orgId,
        },
        {},
      )}`,
    );

    return response?.vpcSetting;
  }
}
