import actionType from '../actions/type';

const INITIAL_STATE = {
  analyzeData: {
    discountTotal: 0,
    spreadPercentage: 0,
    joinFriendsPercentage: 0,
    shareTotalCount: 0,
  },
  sharerArray: [],
  analyzeChart: null,
  campaignList: [],
  campaignCount: 0,
  campaignSelected: null,
  createResult: null,
  channelInfo: null,
  query: {
    searchName: null,
  },
  addOnSettingCount: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_ADDONSETTING_COUNT:
      return {
        ...state,
        addOnSettingCount: action.payload,
      };
    case actionType.GET_CAMPAIGNS:
      return {
        ...state,
        campaignList: action.payload.campaign,
        campaignCount: action.payload.totalItems,
      };
    case actionType.GET_CAMPAIGN_BY_ID:
      return {
        ...state,
        campaignSelected: action.payload.campaign,
      };
    case actionType.RESET_CAMPAIGN_SELECTED:
      return {
        ...state,
        campaignSelected: null,
      };
    case actionType.GET_CAMPAIGN_ANALYZE:
      return {
        ...state,
        analyzeData: action.payload,
      };
    case actionType.GET_SHARER_DATA:
      return {
        ...state,
        sharerArray: action.payload.sharerArray,
        sharerCount: action.payload.totalItems,
      };
    case actionType.GET_CAMPAIGN_CHART:
      return {
        ...state,
        analyzeChart: action.payload,
      };
    case actionType.CREATE_CAMPAIGN:
      return {
        ...state,
        createResult: action.payload,
      };
    case actionType.TOGGLE_FAN:
      return {
        ...state,
      };
    case actionType.EMPTY_CREATE_RESULT:
      return {
        ...state,
        createResult: null,
      };
    case actionType.CREATE_CAMPAIGN_FAILED:
      return {
        ...state,
        createResult: action.payload,
      };
    case actionType.CREATE_CHANNEL_INFO:
      return {
        ...state,
        channelInfo: action.payload,
      };
    case actionType.GET_CHANNEL_INFO:
      return {
        ...state,
        channelInfo: action.payload,
      };
    case actionType.UPDATE_CHANNEL_INFO:
      return {
        ...state,
        channelInfo: action.payload,
      };
    case actionType.SET_DISPLAY_NAME_CAMPAIGN:
      return {
        ...state,
        query: action.query,
      };
    default:
      return state;
  }
};
