import intersection from 'lodash/intersection';
import {
  TAG_MANAGEMENT_QUERY_TAG_REQUEST,
  TAG_MANAGEMENT_QUERY_TAG_SUCCESS,
  TAG_MANAGEMENT_QUERY_TAG_FAILURE,
  TAG_MANAGEMENT_RESET,
  TAG_MANAGEMENT_COUNT_QUERY_TAG_REQUEST,
  TAG_MANAGEMENT_COUNT_QUERY_TAG_SUCCESS,
  TAG_MANAGEMENT_COUNT_QUERY_TAG_FAILURE,
  TAG_MANAGEMENT_SET_DELETE_TAG_LIST,
  TAG_MANAGEMENT_SET_RESOURCE,
} from './type';
import { StatusType, SnackBarType, VendorStore } from '../types';
import TagService from '../services/TagService';
import InspectAddOnService from '../services/InspectAddOnService';
import { enqueueSnackbar } from './snackbarAction';
import config from '../config';
import i18n from '../i18n';

let lastGetDataLength = 0;
let lastSkip = 0;

export const reset = () => dispatch => {
  dispatch({ type: TAG_MANAGEMENT_RESET });
};

export const queryTag = (
  orgId,
  tagName = '',
  isInitial = false,
  options = {},
  bySearch = false,
  actionOptions = {
    useStoreTagName: false,
  },
) => async (dispatch, getState) => {
  const {
    tagName: storeTagName,
    data,
    options: storeOptions,
    deleteTagList,
  } = getState().tagManagement;

  if (lastGetDataLength === 0 && isInitial === false) {
    return;
  }
  if (lastSkip === data.length && isInitial === false) {
    return;
  }

  lastSkip = isInitial ? 0 : data.length;

  const requestOptions = {
    ...storeOptions,
    ...options,
    skip: isInitial ? 0 : data.length,
    limit: isInitial
      ? config.TAG_MANAGEMENT_DEFAULT_QUERY_LIMIT
      : config.TAG_MANAGEMENT_CONTINUE_QUERY_LIMIT,
  };
  const storeData = {
    listStatus: isInitial
      ? !bySearch
        ? StatusType.INITIAL_QUERYING
        : StatusType.QUERYING
      : StatusType.QUERYING,
    isInitial,
    tagName: isInitial
      ? actionOptions.useStoreTagName
        ? storeTagName
        : tagName
      : storeTagName,
    options: requestOptions,
  };
  dispatch({
    type: TAG_MANAGEMENT_QUERY_TAG_REQUEST,
    ...storeData,
  });
  if (isInitial) {
    dispatch({ type: TAG_MANAGEMENT_COUNT_QUERY_TAG_REQUEST });
    try {
      const response = await TagService.findTagV3(
        storeData.tagName,
        orgId,
        undefined,
        {
          ...requestOptions,
          count: true,
        },
        ['name', 'count', 'meta', 'density'],
      );
      dispatch({
        type: TAG_MANAGEMENT_COUNT_QUERY_TAG_SUCCESS,
        count: response.meta.count,
      });
      lastGetDataLength = response.data.length;
      dispatch({
        type: TAG_MANAGEMENT_QUERY_TAG_SUCCESS,
        listStatus: !bySearch
          ? StatusType.INITIAL_QUERY_FINISHED
          : StatusType.QUERY_FINISHED,
        data: response.data.map(tag => ({
          id: tag._id,
          name: tag._id,
          count: tag.count,
          density: tag.density,
        })),
        deleteTagList: intersection(
          deleteTagList,
          data.map(tag => tag.name).concat(response.data.map(tag => tag._id)),
        ),
        tagName: storeData.tagName,
      });
    } catch (error) {
      dispatch({ type: TAG_MANAGEMENT_COUNT_QUERY_TAG_FAILURE });

      dispatch({
        type: TAG_MANAGEMENT_QUERY_TAG_FAILURE,
        listStatus: !bySearch
          ? StatusType.INITIAL_QUERY_FINISHED
          : StatusType.QUERY_FINISHED,
      });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.message,
        }),
      );
    }
  } else {
    try {
      const response = await TagService.findTagV3(
        storeData.tagName,
        orgId,
        undefined,
        requestOptions,
        ['name', 'count', 'meta', 'density'],
      );
      lastGetDataLength = response.data.length;
      dispatch({
        type: TAG_MANAGEMENT_QUERY_TAG_SUCCESS,
        listStatus: StatusType.QUERY_FINISHED,
        data: response.data.map(tag => ({
          id: tag._id,
          name: tag._id,
          count: tag.count,
          density: tag.density,
        })),
        deleteTagList: intersection(
          deleteTagList,
          data.map(tag => tag.name).concat(response.data.map(tag => tag._id)),
        ),
        tagName: storeData.tagName,
      });
    } catch (error) {
      dispatch({
        type: TAG_MANAGEMENT_QUERY_TAG_FAILURE,
        listStatus: StatusType.QUERY_FINISHED,
      });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.message,
        }),
      );
    }
  }
};

export const addOrgTag = (orgId, tags) => async dispatch => {
  try {
    await TagService.addOrgTag(orgId, tags);
    const tagsString = tags.reduce((pre, tag, index) => {
      if (index === 0) {
        return tag;
      }
      return `${pre} ${tag}`;
    }, '');
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(
          'customer-management.tag-management.add-org-tag-success',
          {
            tagsString,
          },
        ),
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const setDeleteTagList = list => ({
  type: TAG_MANAGEMENT_SET_DELETE_TAG_LIST,
  deleteTagList: list,
});

export const deleteOrgTag = (orgId, tags) => async dispatch => {
  try {
    await TagService.deleteOrgTag(orgId, tags);
    dispatch(setDeleteTagList([]));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const getInspectAddonResource = orgId => async dispatch => {
  try {
    const response = await InspectAddOnService.getResource(
      orgId,
      VendorStore.SUPER8,
    );

    dispatch({
      type: TAG_MANAGEMENT_SET_RESOURCE,
      payload: response || null,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.response?.data?.error || error.message);

    dispatch({
      type: TAG_MANAGEMENT_SET_RESOURCE,
      payload: null,
    });
  }
};

export const clearInspectAddonResource = () => async dispatch => {
  try {
    dispatch({
      type: TAG_MANAGEMENT_SET_RESOURCE,
      payload: null,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export default {
  queryTag,
};
