import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import { color } from '../../themes';
import { showText } from '../../common/utils';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      padding: '12px 24px',
      '&:hover': {
        backgroundColor: color.$19,
      },
    },
    contentBlock: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    name: {
      fontSize: 15,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
      color: color.$13,
      marginBottom: 8,
    },
    content: {
      fontSize: 13,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
      wordBreak: 'break-all',
      color: color.$13,
      '& a': {
        color: color.$2,
      },
    },
    createdDate: {
      fontSize: 11,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      color: color.$11,
      marginBottom: 8,
    },
  }),
  {
    name: 'Notifier',
  },
);

function Notifier({ msg }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.picture} />
      <div className={classes.contentBlock}>
        <div className={classes.name}>{msg.name}</div>
        <div className={classes.createdDate}>
          {moment(msg.createdAt).format('YYYY-MM-DD HH:mm')}
        </div>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{
            __html: showText(msg.content),
          }}
        />
      </div>
    </div>
  );
}

Notifier.defaultProps = {};

Notifier.propTypes = {
  msg: PropTypes.shape({}).isRequired,
};

export default Notifier;
