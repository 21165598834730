import { useState } from 'react';
import { color } from '../../../themes';

const IconTemplate = ({ onClick, className, id, iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="template"
          d="M15.892 4a5 5 0 0 1 5 5v4a4.98 4.98 0 0 1-.516 2.214 4.5 4.5 0 0 1-7.573 4.853 1 1 0 1 1-1.772.527L8.946 18H8a5 5 0 0 1-5-5V9a5 5 0 0 1 5-5h7.892zm.518 11.008a.5.5 0 0 0-.41.492V17h-1.5l-.09.008a.5.5 0 0 0-.41.492l.008.09a.5.5 0 0 0 .492.41H16v1.5l.008.09a.5.5 0 0 0 .492.41l.09-.008A.5.5 0 0 0 17 19.5V18h1.5l.09-.008A.5.5 0 0 0 19 17.5l-.008-.09A.5.5 0 0 0 18.5 17H17v-1.5l-.008-.09A.5.5 0 0 0 16.5 15zM15.892 5.6H8A3.4 3.4 0 0 0 4.6 9v4A3.4 3.4 0 0 0 8 16.4h.946a1.6 1.6 0 0 1 1.247.598l.925 1.15-.003-.068a1.6 1.6 0 0 1 .992-1.561 4.503 4.503 0 0 1 7.069-2.637 3.37 3.37 0 0 0 .116-.882V9a3.4 3.4 0 0 0-3.4-3.4zM7.5 10.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4.5 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4.5 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`templateMask${id}`} fill="#fff">
          <use xlinkHref="#template" />
        </mask>
        <g
          fill={isHover ? color.$1 : iconColor}
          mask={`url(#templateMask${id})`}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

export default IconTemplate;
