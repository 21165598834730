import classnames from 'classnames';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { color } from '../../themes';
import Button from './Button';

const useStyles = makeStyles(
  () => ({
    root: { zIndex: '9999 !important' },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: color.$7,
    },
    title: {
      padding: '24px 24px 8px',
      backgroundColor: color.$19,
      '& > h2': {
        fontWeight: 400,
        fontSize: 17,
        lineHeight: 1,
        color: color.$3,
      },
    },
    content: {
      fontSize: 15,
      color: color.$3,
      opacity: 0.6,
      padding: '8px 24px 12px',
      backgroundColor: color.$19,
    },
    actions: {
      padding: '12px 24px 24px',
      backgroundColor: color.$19,
    },
  }),
  {
    name: 'AlertModal',
  },
);

function AlertModal({
  isOpen,
  title,
  titleClass,
  content,
  actions,
  onCancel,
  onClick,
  cancelText,
  submitText,
  isLoading,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tempIsLoading, setTempIsLoading] = useState(isLoading);

  function handlOnClick() {
    setTimeout(() => {
      onClick();
      setTempIsLoading(true);
    }, 200);
  }

  useEffect(() => {
    setTempIsLoading(isLoading);
  }, [isLoading]);

  return (
    <Dialog
      open={isOpen}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classnames(classes.title, titleClass)}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>{content}</DialogContent>
      <DialogActions className={classes.actions}>
        {actions || (
          <>
            <Button topic="primaryBlue" onClick={onCancel} id="cancel">
              {cancelText || t('modal.not-now')}
            </Button>
            <Button
              topic="primaryBlueHighLight"
              onClick={handlOnClick}
              isLoading={tempIsLoading}
              id="submit"
            >
              {submitText || t('modal.submit')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

AlertModal.defaultProps = {
  actions: null,
  cancelText: null,
  submitText: null,
  titleClass: undefined,
  onCancel: () => {},
  onClick: () => {},
  isLoading: false,
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  titleClass: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  actions: PropTypes.element,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default AlertModal;
