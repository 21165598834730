import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconSortDown = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill={isHover ? hexRgba(color.$12, 40) : color.$12}>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill="#BDBDBD"
          fillRule="nonzero"
          d="M9.656 5.9c.297.296.297.776 0 1.072-.269.27-.69.294-.987.073l-.085-.073-1.386-1.385V15.78l-.007.103c-.045.337-.311.603-.648.649l-.103.007-.103-.007c-.336-.046-.602-.312-.648-.649l-.007-.103V5.587L4.296 6.972c-.296.296-.776.296-1.072 0-.269-.269-.293-.69-.073-.987l.073-.085 2.68-2.68c.27-.27.69-.294.987-.073l.085.073 2.68 2.68z"
          transform="translate(-337 -144) translate(80 64) translate(257 80)"
        />
        <path
          fill="#4C4C4C"
          fillRule="nonzero"
          d="M16.776 14.096l-2.68 2.68c-.296.297-.776.297-1.072 0l-2.68-2.68c-.296-.296-.296-.776 0-1.072.296-.296.776-.296 1.072 0l1.386 1.386V4.216c0-.384.285-.701.655-.751l.103-.007c.384 0 .701.285.751.655l.007.103V14.41l1.386-1.386c.296-.296.776-.296 1.072 0 .297.296.297.776 0 1.072z"
          transform="translate(-337 -144) translate(80 64) translate(257 80)"
        />
      </g>
    </svg>
  );
};

export default IconSortDown;
