import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconProducts = ({ onClick, className, id, infoPanel }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
      id={id}
      fill="none"
    >
      <path
        d="M2.8 4C2.8 2.78497 3.78497 1.8 5 1.8H12.2084C12.8368 1.8 13.4352 2.06873 13.8527 2.5384L16.6443 5.679C17.0023 6.08171 17.2 6.60179 17.2 7.1406V16C17.2 17.215 16.215 18.2 15 18.2H5C3.78497 18.2 2.8 17.215 2.8 16V4Z"
        stroke={
          infoPanel === 'productsPanel'
            ? color.$13
            : isHover
            ? hexRgba(color.$13, 40)
            : color.$13
        }
        strokeWidth="1.6"
      />
      <path
        d="M6 11H13.5"
        stroke={
          infoPanel === 'productsPanel'
            ? color.$13
            : isHover
            ? hexRgba(color.$13, 40)
            : color.$13
        }
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 14H13.5"
        stroke={
          infoPanel === 'productsPanel'
            ? color.$12
            : isHover
            ? hexRgba(color.$12, 40)
            : color.$12
        }
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconProducts;
