import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Button from './Button';
import { color } from '../../themes';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '6000 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflow: 'unset',
      backgroundColor: '#f9f9f9',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 17,
      lineHeight: 1,
      borderRadius: 12,
      padding: '24px 24px 8px',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 24px 12px',
      '& span': {
        fontSize: 15,
        color: color.$3,
        opacity: 0.6,
      },
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '12px 24px 24px',
      borderRadius: 12,
    },
    'button--margin-right': {
      marginRight: 8,
    },
    autoCompelete: {
      width: '100%',
      maxWidth: 450,
    },
    submit: {
      borderWidth: 2,
    },
  },
  {
    name: 'DeleteTagModal',
  },
);

function DeleteTagModal({ isOpen, onCancel, onClick, tagName, tagCount }) {
  const classes = useStyles();
  const { t } = useTranslation();

  async function handleOnClick() {
    onCancel();
    setTimeout(() => {
      onClick(tagName);
    }, 200);
  }

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.title}>{t('modal.delete-tag')}</div>
      <div className={classes.content}>
        <span>
          {tagCount > 1
            ? t('modal.delete-tags-description', { tagName, count: tagCount })
            : t('modal.delete-tag-description', { tagName })}
        </span>
      </div>
      <div className={classes.actions}>
        <Button
          topic="primaryBlue"
          className={classes['button--margin-right']}
          onClick={onCancel}
        >
          {t('modal.not-now')}
        </Button>
        <Button
          className={classes.submit}
          topic="primaryBlueHighLight"
          onClick={handleOnClick}
        >
          {t('modal.submit-delete')}
        </Button>
      </div>
    </Dialog>
  );
}

DeleteTagModal.defaultProps = {
  tagCount: 1,
};

DeleteTagModal.propTypes = {
  tagCount: PropTypes.number,
};

export default DeleteTagModal;
