import { PlatformType } from '../../types';
import INBOXTYPE from './inboxType';

const FILTER_TYPE = {
  assignStatus: [
    { value: INBOXTYPE.ALL, text: 'navigation.inbox.all' },
    {
      value: INBOXTYPE.UNASSIGNED,
      text: 'navigation.inbox.unassigned',
    },
    { value: INBOXTYPE.DONE, text: 'navigation.inbox.done' },
    {
      value: INBOXTYPE.PRIVATE,
      text: 'navigation.inbox.private',
    },
    { value: INBOXTYPE.SELF, text: 'navigation.inbox.self' },
    { value: INBOXTYPE.BOT, text: 'navigation.inbox.bot' },
    { value: INBOXTYPE.SPAM, text: 'navigation.inbox.spam' },
    { value: INBOXTYPE.UNFOLLOW, text: 'navigation.inbox.unfollow' },
  ],
  assignPlatform: [
    { value: 'all', text: 'platform.all' },
    { value: PlatformType.LINE, text: 'platform.line' },
    { value: PlatformType.FACEBOOK, text: 'platform.facebook' },
    { value: PlatformType.INSTAGRAM, text: 'platform.instagram' },
    { value: PlatformType.LIVECHAT, text: 'platform.livechat' },
    { value: PlatformType.WHATSAPP, text: 'platform.whatsapp' },
  ],
  assignTimeline: [
    {
      value: 'today',
      text: 'customer-management.all-customers.join-date-filter.today',
    },
    {
      value: 'yesterday',
      text: 'customer-management.all-customers.join-date-filter.yesterday',
    },
    {
      value: 'customTime',
      text: 'customer-management.all-customers.join-date-filter.custom',
    },
  ],
  searchType: [
    {
      value: 'navigation.search.custonmerName',
      placeholder: 'navigation.search.custonmerNamePlaceholder',
    },
    { value: 'navigation.search.message-tab', placeholder: 'chat.inputHint' },
    {
      value: 'chat.search.tag',
      placeholder: 'navigation.search.tagPlaceholder2',
    },
    {
      value: 'navigation.search.cellPhone',
      placeholder: 'navigation.search.cellPhonePlaceholder',
    },
    {
      value: 'navigation.search.email',
      placeholder: 'navigation.search.emailPhonePlaceholder',
    },
  ],
};

export const ASSIGN_INBOX = FILTER_TYPE.assignStatus.filter(
  d =>
    d.value === INBOXTYPE.UNASSIGNED ||
    d.value === INBOXTYPE.DONE ||
    d.value === INBOXTYPE.PRIVATE ||
    d.value === INBOXTYPE.BOT ||
    d.value === INBOXTYPE.SPAM,
);

export default FILTER_TYPE;
