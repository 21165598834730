const senderType = {
  USER: '_User',
  CUSTOMER: 'Customer',
  ORGANIZATION: 'Organization',
  BOT: 'Bot',
  FOREIGN_BOT: 'ForeignBot',
  ADDON: 'AddOn',
  ALL: 'All',
};

export default senderType;
