import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import InspectAddOnService from '../../services/InspectAddOnService';
import { openModal, closeModal } from '../../actions/modalAction';
import { ModalType, VendorStore } from '../../types';
import config from '../../config';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    content: {
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: color.$3,
      '& div': {
        fontSize: '15px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: color.$2,
        textDecoration: 'underline',
        display: 'inline-block',
        cursor: 'pointer',
      },
      '& span': {
        color: color.$17,
      },
    },
  }),
  {
    name: 'CheckTagQuota',
  },
);

let timer;

function CheckTagQuota(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectOrg } = useSelector(store => store.organization);
  const { count } = useSelector(store => store.tagManagement);

  const toTagManagement = () => {
    dispatch(closeModal());
    navigate(`/customer-management/${selectOrg?.objectId}/tag-management`);
  };

  const check = async () => {
    if (selectOrg?.objectId) {
      const resource = await InspectAddOnService.getResource(
        selectOrg?.objectId,
        VendorStore.SUPER8,
      );

      const { used, quota } = resource?.resources?.distinct_tag || {};
      const isOutOfQuota = used >= quota;

      const errorEvent = () => {
        dispatch(
          openModal(ModalType.DIALOG_TEXT, {
            title: i18n.t('tag-management.out-of-quota.title'),
            content: (
              <div className={classes.content}>
                {i18n.t('tag-management.out-of-quota.content1', { quota })}
                <span>{used}</span>
                {i18n.t('tag-management.out-of-quota.content2')}
                <div onClick={toTagManagement}>
                  {i18n.t('tag-management.out-of-quota.to-tag-management-page')}
                </div>
              </div>
            ),
            onSubmit: () => {
              window.open(
                `mailto:${config.ADDON_UNAVAIABLE_MAIL}?subject=${i18n.t(
                  'superMarket:emailSubject',
                  {
                    featureName: i18n.t('tag-management.out-of-quota.head'),
                  },
                )}&body=${encodeURIComponent(
                  i18n.t('superMarket:emailBody', {
                    featureName: i18n.t('tag-management.out-of-quota.head'),
                    orgId: selectOrg?.objectId,
                    displayName: selectOrg?.displayName,
                  }),
                )}`,
              );
            },
            cancelText: i18n.t('tag-management.out-of-quota.cancelText'),
            submitText: i18n.t('tag-management.out-of-quota.submitText'),
          }),
        );
      };

      if (isOutOfQuota) {
        clearInterval(timer);
        errorEvent();
        timer = setInterval(() => {
          dispatch(closeModal());
          errorEvent();
        }, 180000);
      } else {
        clearInterval(timer);
      }
    }
  };

  useEffect(() => {
    if (count > 0) {
      check();
    }
  }, [selectOrg?.objectId, count]);

  return <>{props.children}</>;
}

export default CheckTagQuota;
