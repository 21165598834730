import actionType from '../actions/type';

const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.REMOVE_TAG:
      return state;
    default:
      return state;
  }
};
