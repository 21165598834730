import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconUnassign = ({ onClick, className, id, width, height, iconColor }) => {
  const [isHover, setIsHover] = useState(false);
  const random = Math.random();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="inboxUnassign"
          d="M6.334 3h8.52c.134 0 .26.04.364.107l.005.004.024.016a.67.67 0 0 1 .05.04l.003.004 4.079 3.668c.344.31.585.712.699 1.152a.667.667 0 0 1 .068.4l.004.091.002.092v1.446a.667.667 0 0 1-1.326.099l-.008-.099V8.964h-3.13a1.5 1.5 0 0 1-1.494-1.355l-.006-.145-.001-3.131H6.334a1 1 0 0 0-.994.884l-.006.117v13.303a1 1 0 0 0 .88.993l.117.007 11.485.03c.515 0 .938-.387.996-.884l.006-.117v-.23a.667.667 0 0 1 1.327-.1l.007.1v.23c0 1.235-.96 2.246-2.174 2.329l-.16.005h-.006l-11.484-.03a2.334 2.334 0 0 1-2.323-2.174L4 18.636V5.335c0-1.235.96-2.246 2.174-2.329L6.334 3h8.52zm8.984 6.687a4.63 4.63 0 1 1 0 9.261 4.63 4.63 0 0 1 0-9.261zm.047 1.398a.421.421 0 0 0-.414.345l-.007.076v3.232c0 .207.149.379.345.415l.076.006h2.782a.421.421 0 0 0 .076-.835l-.076-.006h-2.361v-2.812a.421.421 0 0 0-.421-.42zm.156-5.922v2.301c0 .074.048.136.114.158l.053.009 2.576-.001-2.743-2.467z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`inboxUnssignMask${id}${random}`} fill="#fff">
            <use xlinkHref="#inboxUnassign" />
          </mask>
          <g
            fill={isHover ? hexRgba(iconColor, 40) : iconColor}
            mask={`url(#inboxUnssignMask${id}${random})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconUnassign.defaultProps = {
  width: 24,
  height: 24,
  iconColor: color.$12,
};

export default IconUnassign;
