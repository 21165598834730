import PropTypes from 'prop-types';

const IconAlertTriangle = ({
  width,
  height,
  color,
  className,
  onClick,
  ...otherProps
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      width={width}
      height={height}
      onClick={onClick}
      {...otherProps}
    >
      <defs>
        <path
          id="IconAlertTriangle"
          d="M5.374 20c-.398 0-.796-.08-1.194-.318-1.115-.637-1.513-2.15-.876-3.264L10.07 5.114c.16-.318.478-.636.796-.796a2.439 2.439 0 011.831-.238c.637.159 1.114.557 1.512 1.114l6.687 11.224c.239.398.318.796.318 1.194 0 .637-.238 1.274-.716 1.672-.398.477-.955.716-1.592.716H5.374zm6.05-14.01L4.736 17.214c-.24.398-.08.876.318 1.114.08.08.239.08.319.08h13.452c.24 0 .398-.08.558-.239a.723.723 0 00.238-.557c0-.16 0-.239-.08-.398L12.857 5.99c-.239-.398-.716-.478-1.114-.318a.622.622 0 00-.319.318zm1.512 6.846V9.652c0-.478-.319-.796-.796-.796-.478 0-.796.318-.796.796v3.184c0 .477.318.796.796.796.477 0 .796-.319.796-.796zm-.239 3.741a.723.723 0 00.239-.557c0-.08 0-.239-.08-.319 0-.08-.08-.159-.159-.238 0 0-.08-.08-.16-.08-.079 0-.079-.08-.158-.08-.08 0-.08 0-.16-.08-.159 0-.318 0-.477.08-.08 0-.16.08-.24.16-.079.08-.158.159-.158.238 0 .08-.08.16-.08.319 0 .239.08.398.239.557a.72.72 0 00.557.239c.319 0 .478-.08.637-.239z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="IconAlertTriangle-b" fill="#fff">
          <use xlinkHref="#IconAlertTriangle" />
        </mask>
        <g fill={color} mask="url(#IconAlertTriangle-b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

IconAlertTriangle.defaultProps = {
  width: 24,
  height: 24,
  color: '#e95445',
};

IconAlertTriangle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default IconAlertTriangle;
