import coreAPI from '../common/axios';

export default class InspectAddOnService {
  static async getResource(orgId, addonId) {
    if (!orgId) {
      throw new Error('InspectAddOnService.getResource: orgId undefined');
    }

    if (!addonId) {
      throw new Error('InspectAddOnService.getResource: campaignId undefined');
    }

    const response = await coreAPI.post(`/functions/inspectAddonResource`, {
      where: {
        orgId,
        addonId,
      },
    });

    return response?.result;
  }
}
