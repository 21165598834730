import { makeStyles } from '@material-ui/core/styles';
import imgSrc from '../iconSrc';

const useStyles = makeStyles(() => ({
  icon: {
    position: 'relative',
    width: 20,
    height: 20,
    marginLeft: '-20px',
    zIndex: 2,
  },
}));

const ChatInstagram = ({ iconStyle }) => {
  const classes = useStyles();

  return (
    <img
      src={imgSrc.imgInstagram20}
      className={iconStyle || classes.icon}
      alt="ChatInstagram"
    />
  );
};

export default ChatInstagram;
