import Input from './Input';

const TextField = ({
  className,
  style,
  isValid,
  InputProps,
  disabled,
  minRows,
  keyPress,
  keyDown,
  min,
  inputRef,
  select,
  MenuItems,
  SelectProps,
  maxRows,
  multiline,
  wordCount,
  limit,
  topic,
  error,
  onChange,
  ...others
}) => {
  return (
    <Input
      disabled={disabled}
      wordCount={wordCount}
      limit={limit}
      inputRef={inputRef}
      className={className}
      minRows={minRows}
      min={min}
      multiline={multiline}
      maxRows={maxRows}
      style={style}
      topic={topic}
      error={error}
      valid={isValid}
      InputProps={InputProps}
      onKeyPress={keyPress}
      onKeyDown={keyDown}
      select={select}
      MenuItems={MenuItems}
      SelectProps={SelectProps}
      onChange={onChange}
      {...others}
    />
  );
};

TextField.defaultProps = {
  topic: 'default',
};

export default TextField;
