import { useState } from 'react';
import { color } from '../../../themes';

const hexToRgba = require('hex-rgba');

const IconLike = ({ isLiked }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g>
        <path
          fill={isHover || isLiked ? hexToRgba(color.$17, 40) : color.$10}
          d="M3.43 10.865a4.197 4.197 0 0 1 5.935-5.936l.635.636.636-.636a4.197 4.197 0 0 1 5.773-.153l.162.153a4.197 4.197 0 0 1 0 5.936l-6.005 6.005a.8.8 0 0 1-1.131 0l-6.006-6.005zm6.005-3.603L8.233 6.06a2.597 2.597 0 1 0-3.672 3.672l5.44 5.44 5.439-5.44a2.597 2.597 0 0 0 .126-3.536l-.126-.136a2.597 2.597 0 0 0-3.673 0l-1.201 1.2a.8.8 0 0 1-1.131 0z"
        />
      </g>
    </svg>
  );
};

export default IconLike;
