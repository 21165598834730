import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL_PROPS } from './type';
import { ModalType } from '../types';

export const openModal = (modalType, modalProps) => {
  if (!Object.values(ModalType).includes(modalType)) {
    throw new Error('Invalid modalType');
  }

  return {
    type: OPEN_MODAL,
    modalType,
    modalProps,
  };
};

export const updateModalProps = props => {
  return {
    type: UPDATE_MODAL_PROPS,
    modalProps: props,
  };
};

export const closeModal = () => dispatch => {
  dispatch({
    type: CLOSE_MODAL,
  });
};
