import axiosInstance from '../common/axios';
import FileService from '../services/FileService';
import { SnackBarType } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import actionType from './type';

const listFiles = (
  orgnizationId,
  type,
  name,
  uploadedUserObjectId,
  option,
  isLoading = true,
) => async (dispatch, getState) => {
  try {
    const where = {
      organization: {
        __type: 'Pointer',
        className: 'Organization',
        objectId: orgnizationId,
      },
    };

    if (uploadedUserObjectId) {
      where.uploadedBy = {
        __type: 'Pointer',
        className: '_User',
        objectId: uploadedUserObjectId,
      };
    }
    if (type !== 'all') {
      where.mime = { $regex: type, $options: 'i' };
    }

    if (name) {
      where.name = { $regex: name, $options: 'i' };
    }

    const query = {
      where,
      include: 'uploadedBy',
      limit: option.limit,
      order: '-updatedAt',
      _method: 'GET',
    };

    if (option.skip) {
      query.skip = option.skip;
    }
    if (isLoading) {
      dispatch({
        type: actionType.LIST_FILES_REQUEST,
      });
    }

    const { results } = await axiosInstance.post('/classes/File', query);

    dispatch({
      type: actionType.FILELIST_HAS_NEXT_PAGE,
      payload: results.length === option.limit,
    });

    if (option.skip) {
      const { fileList } = getState().files;
      dispatch({
        type: actionType.LIST_FILES,
        payload: [...fileList, ...results],
      });
    } else {
      dispatch({ type: actionType.LIST_FILES, payload: results });
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  } finally {
    if (isLoading) {
      dispatch({
        type: actionType.LIST_FILES_DONE,
      });
    }
  }
};

const listFilesLazy = (
  orgnizationId,
  type,
  name,
  uploadedUserObjectId,
  option,
) => async dispatch => {
  try {
    await dispatch(
      listFiles(orgnizationId, type, name, uploadedUserObjectId, option, false),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

const uploadToBucket = (
  name,
  file,
  orgObjectId,
  authObjectId,
  onUploadProgress,
  duration,
  expireAt,
) => async dispatch => {
  try {
    const response = await FileService.upload(file, onUploadProgress);

    if (file.type.startsWith('video/')) {
      FileService.videoTranscoding(response.url, orgObjectId);
    }

    const query = {
      name,
      mime: file.type,
      url: response.url,
      size: file.size,
      previewUrl: file.previewUrl,
      duration,
      expireAt,
      uploadedBy: {
        __type: 'Pointer',
        className: '_User',
        objectId: authObjectId,
      },
      organization: {
        __type: 'Pointer',
        className: 'Organization',
        objectId: orgObjectId,
      },
    };

    await axiosInstance.post('/classes/File', query);
    dispatch({
      type: actionType.UPLOAD_FILE,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
    return null;
  }
};

const deleteFile = fileId => async dispatch => {
  const query = {
    _method: 'DELETE',
  };
  await axiosInstance.post(`/classes/File/${fileId}`, query);

  dispatch({
    type: actionType.DELETE_FILE,
    payload: true,
  });
};

const emptyBucket = () => ({
  type: actionType.EMPTY_BUCKET,
  payload: null,
});

export { deleteFile, emptyBucket, listFiles, listFilesLazy, uploadToBucket };
