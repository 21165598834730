import coreAPI from '../common/axios';
import { VendorType } from '../types';

export default class ProvisionService {
  static async createAddOn(orgId, vendorId, type) {
    const vendorPayloads = Object.values(VendorType).filter(
      vendor => vendor.type === type,
    );

    if (vendorPayloads.length === 0) {
      throw new Error('Vendor type not found');
    }

    const response = await coreAPI.post('/functions/provision', {
      method: 'POST',
      where: {
        organization: {
          className: 'Organization',
          objectId: orgId,
        },
        addon: {
          className: 'AddOn',
          objectId: vendorId,
        },
      },
      payload: vendorPayloads[0],
    });

    return response.result;
  }

  static async openPlan(orgId, vendorId) {
    const response = await coreAPI.post('/functions/provision', {
      method: 'POST',
      where: {
        organization: {
          className: 'Organization',
          objectId: orgId,
        },
        addon: {
          className: 'AddOn',
          objectId: vendorId,
        },
      },
    });

    return response.result;
  }

  static async editAddOn(orgId, vendorId) {
    const response = await coreAPI.post('/functions/provision', {
      method: 'PUT',
      where: {
        organization: {
          className: 'Organization',
          objectId: orgId,
          __type: 'Pointer',
        },
        setting: {
          className: 'AddOnSetting',
          objectId: vendorId,
          __type: 'Pointer',
        },
      },
    });

    return response.result;
  }
}
