import actionType from '../actions/type';
import { CouponType } from '../types';

const initState = {
  isLoading: false,
  list: [],
  lineChannelInfo: {},
  couponFolder: {
    triggerKeyword: '開啟優惠券夾',
    folderMessageCard: {
      imageType: 'upload',
      imageUrl: '',
      title: '優惠券夾',
      subtitle: '點選下方按鈕開啟優惠券夾😘',
      buttons: [
        {
          type: 'message',
          title: '開啟優惠券夾',
          text: '開啟優惠券夾',
        },
      ],
    },
  },
  coupon: {
    couponMessage: {
      imageType: 'upload',
      imageUrl: '',
      title: '優惠券',
      subtitle: '點選下方按鈕立即領取獎勵😘',
      buttons: [
        {
          type: 'message',
          title: '領取優惠券',
          text: '領取優惠券',
        },
      ],
    },
    triggerKeyword: undefined,
    couponName: '',
    couponType: CouponType.CODE,
    lotteryTags: null,
    drawLimit: 'unlimited',
    needRedeem: false,
    imgUrl: null,
    couponSetS3FileUrl: null,
    couponDesc: '',
    expiredAt: null,
    needUploadCoupon: false,
    awardsBtn: '複製優惠代碼',
    warnMessages: {
      drawLimit: '',
      notSatisfied: 'Sorry 唷，您尚未符合活動資格，請確認後再行操作～',
      insufficient: 'Sorry 唷，優惠券已發放完畢，敬請期待下一次活動～',
    },
  },
  statisticData: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_COUPON:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.CREATE_COUPON:
      return {
        ...state,
        coupon: initState.coupon,
      };
    case actionType.SET_COUPON_FAIL:
      return initState;
    default:
      return state;
  }
};
