import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconSpam = ({ onClick, className, id, width, height, iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  const random = Math.random();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="inboxSpam"
          d="M15.355 21h-6.71a.743.743 0 0 1-.572-.245l-4.828-4.828C3.082 15.845 3 15.6 3 15.355v-6.71c0-.245.082-.409.245-.572l4.828-4.828C8.155 3.082 8.4 3 8.645 3h6.791c.246 0 .41.082.573.245l4.827 4.828c.082.082.164.327.164.572v6.791c0 .246-.082.41-.245.573l-4.828 4.827c-.082.082-.327.164-.572.164zm-6.382-1.636h6.136l4.336-4.337V8.973l-4.418-4.337H8.973L4.636 8.973v6.136l4.337 4.255zM12.818 12V8.727c0-.49-.327-.818-.818-.818-.49 0-.818.327-.818.818V12c0 .49.327.818.818.818.49 0 .818-.327.818-.818zm-.245 3.845c.082-.081.163-.163.163-.245 0-.082.082-.245.082-.327 0-.082 0-.246-.082-.328-.081-.081-.081-.163-.163-.245 0 0-.082-.082-.164-.082s-.082-.082-.164-.082c-.327-.163-.654-.081-.9.164-.081.082-.163.164-.163.245 0 .082-.082.164-.082.328 0 .245.082.409.245.572a.743.743 0 0 0 .573.246c.327 0 .491-.082.655-.246z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`inboxSpamMask${id}${random}`} fill="#fff">
            <use xlinkHref="#inboxSpam" />
          </mask>
          <g
            fill={isHover ? hexRgba(iconColor, 40) : iconColor}
            mask={`url(#inboxSpamMask${id}${random})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconSpam.defaultProps = {
  width: 24,
  height: 24,
  iconColor: color.$11,
};

export default IconSpam;
