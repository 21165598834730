import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconKeepMessage = ({ onClick, className, id, infoPanel }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="keepMessage"
          d="M20.97 9.474c-.082-.327-.327-.49-.653-.572L15.1 8.167l-2.365-4.738c-.245-.572-1.223-.572-1.468 0L8.901 8.085l-5.137.817c-.326 0-.57.245-.733.572-.082.326 0 .653.244.816l3.751 3.676-.897 5.228a.777.777 0 0 0 .326.817c.245.163.571.245.897.082L12 17.643l4.648 2.45h.407c.164 0 .327-.082.49-.164a.777.777 0 0 0 .326-.816l-.897-5.228 3.75-3.676a.66.66 0 0 0 .245-.735zm-5.464 3.594a1.32 1.32 0 0 0-.244.735l.652 4.003-3.588-1.88a.61.61 0 0 0-.734 0l-3.587 1.88.733-4.003c0-.245-.081-.49-.244-.735L5.558 10.29l3.996-.571c.244 0 .489-.245.652-.409L12 5.634l1.794 3.595c.081.245.326.408.652.408l3.996.572-2.936 2.859z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`keepMessageMask${id}`} fill="#fff">
            <use xlinkHref="#keepMessage" />
          </mask>
          <g
            fill={
              infoPanel === 'keepMessagePanel'
                ? color.$3
                : isHover
                ? hexRgba(color.$12, 40)
                : color.$12
            }
            mask={`url(#keepMessageMask${id})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconKeepMessage;
