import { CompositeDecorator } from 'draft-js';
import { WhatsAppPlaceholderSpan } from '../HashtagText';
import { WA_TEMP_PLACEHOLDER_REGEX } from '../../../common/utils';

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const hashtagStrategy = (contentBlock, callback) => {
  findWithRegex(WA_TEMP_PLACEHOLDER_REGEX, contentBlock, callback);
};
const WhatsAppPlaceholderDecorator = new CompositeDecorator([
  {
    strategy: hashtagStrategy,
    component: WhatsAppPlaceholderSpan,
  },
]);
export default WhatsAppPlaceholderDecorator;
