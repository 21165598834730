import { useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { color } from '../../themes';
import IconX from '../../assets/images/Icons/IconX';
import Task from './Task';
import { BatchManagementType, TaskStatusType } from '../../types';
import Circular from '../shared/Circular';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      zIndex: 2000,
      bottom: 24,
      left: 'calc(80px + 24px)',
      width: 368,
      height: 273,
      boxShadow:
        '0 16px 48px 0 rgba(0, 0, 0, 0.07), 0 6px 18px 0 rgba(0, 0, 0, 0.03)',
      backgroundColor: 'white',
      transition: 'left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    'root--navBarOpen': {
      left: 'calc(256px + 24px)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 64,
      padding: '0 16px 0 24px',
      fontSize: 17,
      lineHeight: 1,
      color: color.$13,
    },
    'header-close-button': {
      cursor: 'pointer',
    },
    content: {
      height: 'calc(100% - 64px)',
      overflowY: 'auto',
    },
  }),
  {
    name: 'SystemTaskModal',
  },
);

function SystemTaskModal({
  onCancel,
  data: taskData,
  navBarOpen,
  getTaskList,
  onRetry,
}) {
  const classes = useStyles();
  const [isLazyLoad, setIsLazyLoad] = useState(false);

  const { t } = useTranslation();
  const contentRef = useRef(null);
  const isLazyLoadRef = useRef(null);
  isLazyLoadRef.current = isLazyLoad;

  const tasks = taskData.map(data => {
    if (
      data.type === BatchManagementType.BROADCAST &&
      data.status === TaskStatusType.SCHEDULED
    ) {
      return null;
    }
    return <Task key={`task-${data.objectId}`} data={data} onRetry={onRetry} />;
  });

  const handleScroll = async () => {
    const { scrollHeight, scrollTop, clientHeight } = contentRef.current;
    if (
      !isLazyLoadRef.current &&
      scrollHeight - (scrollTop + clientHeight) < 50
    ) {
      setIsLazyLoad(true);
      await getTaskList();
      setIsLazyLoad(false);
    }
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes['root--navBarOpen']]: navBarOpen,
      })}
    >
      <div className={classes.header}>
        {t('system-task.title')}
        <div
          className={classes['header-close-button']}
          id="div-system-task-modal-icon-x"
          onClick={onCancel}
        >
          <IconX id="system-task-modal-icon-x" />
        </div>
      </div>
      <div ref={contentRef} onScroll={handleScroll} className={classes.content}>
        {tasks}

        {isLazyLoad && (
          <div className="loading" style={{ height: 30 }}>
            <Circular topic="grey" variant="indeterminate" size={20} />
          </div>
        )}
      </div>
    </div>
  );
}

SystemTaskModal.defaultProps = {};

SystemTaskModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  navBarOpen: PropTypes.bool.isRequired,
  getTaskList: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default SystemTaskModal;
