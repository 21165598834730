import {
  SYSTEM_TASK_GET_TASK_LIST_REQUEST,
  SYSTEM_TASK_GET_TASK_LIST_SUCCESS,
  SYSTEM_TASK_GET_TASK_LIST_FAILURE,
  SYSTEM_TASK_SET_TASK_LIST_DATA,
  SYSTEM_TASK_GET_TASK_LIST_CONTINUE_SUCCESS,
  SYSTEM_TASK_INSERT_TASK,
  SYSTEM_TASK_SET_SHOW_BADGE_ICON,
} from '../actions/type';

const INITIAL_STATE = {
  data: [],
  showSystemTaskBadgeIcon: false,
};

export default function systemTaskReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SYSTEM_TASK_INSERT_TASK:
      return {
        ...state,
        data: [action.task, ...state.data],
      };
    case SYSTEM_TASK_GET_TASK_LIST_FAILURE:
      return INITIAL_STATE;
    case SYSTEM_TASK_GET_TASK_LIST_SUCCESS:
    case SYSTEM_TASK_SET_TASK_LIST_DATA:
      return {
        ...state,
        data: action.data,
      };
    case SYSTEM_TASK_GET_TASK_LIST_CONTINUE_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.data),
      };
    case SYSTEM_TASK_SET_SHOW_BADGE_ICON:
      return {
        ...state,
        showSystemTaskBadgeIcon: action.showSystemTaskBadgeIcon,
      };
    case SYSTEM_TASK_GET_TASK_LIST_REQUEST:
    default:
      return state;
  }
}
