import actionType from '../actions/type';

export const initState = {
  isLoading: false,
  storeSettings: {
    name: '', // 商店名称
    logo: '', // 商店logo
    currency: 'TWD',
    contact: {
      phone: {
        value: '', // 商店电话
        visible: false, // 是否用户可见
      },
      email: {
        value: '', // 商店Email
        visible: false, // 是否用户可见
      },
      address: {
        region: '', // 国家/地区
        province: '', // 省/州
        city: '', // 城市
        district: '', // 区
        location: '', // 地址
        room: '', // 门牌号
        postcode: '', // 邮编
      },
    },
    shipping: {
      types: ['uberDirect'],
      vendor: {
        self: {
          // 店家宅配
          price: 0, // 运费金额
          free_amount: null, // 免运费订单金额
          storage: {
            city: '', // 城市
            district: '', // 行政区
            location: '', // 街道路名巷弄门号
            room: '', // 楼层
            postcode: '', // 邮编
            phone: '', // 手机号码
          },
        },
        uberDirect: {
          pickupImmediately: {
            // 出货设置-立即出货
            enable: true,
            open: '10:00', // 出货营业时间
            close: '20:00', // 出货营业时间
          },
          shopLocations: [
            // 出货地址
            {
              name: '',
              city: '', // 城市
              district: '', // 行政区
              location: '', // 街道路名巷弄门号
              room: '', // 楼层
              postcode: '', // 邮编
              phone: '', // 手机号码
            },
          ],
          deliverableAction: 'deliverable_action_leave_at_door', // ['deliverable_action_meet_at_door', 'deliverable_action_leave_at_door'] // 配送详细咨询 meet_at_door-找不到客户，退回 leave_at_door-放在门口
          preparationTime: {
            // 商品准备时间
            enable: false,
            minutes: 10,
          },
        },
      },
    },
    order: {
      expire: 900, // 订单有效期（秒）
    },
    stock: {
      low_count: 10, // 库存预警值
      notify: true, // 库存预警通知
    },
    deliveryStatus: {
      // 开启客户追踪状态通知
      enable: false,
      status: ['unpaid', 'processing', 'confirmed', 'completed', 'cancelled'],
    },
  },
  productsList: [],
  productsListCount: 0,
  products: {
    status: 'draft', // "publish" - 上架，"draft" - 下架
    title: '',
    serial: '',
    desc: '',
    images: [],
    specs: [
      {
        name: '規格',
        items: ['預設'],
      },
    ],
    list: [
      {
        name: '預設',
        sku: '', // 商品編號 SKU
        stock_quantity: 0, // 庫存
        regular_price: 0, // 原價 (NTD)
        sale_price: '', // 特價(NTD)
        currency: 'TWD',
        attributes: [
          {
            name: '規格',
            option: '預設',
          },
        ],
      },
    ],
  },
  orderListCount: 0,
  orderList: [],
  order: null,
  orderFilterQuery: {
    status: {
      order: undefined,
      payment: undefined,
      shipping: undefined,
    },
  },
  statsInfo: {},
  statsCustomerService: [],
  statsRank: {},
  unhandledCount: 0,
  sound: null,
  isPlay: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_CHATPAY:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_CHATPAY_FAIL:
      return initState;
    default:
      return state;
  }
};
