import { useState } from 'react';
import PropTypes from 'prop-types';

function IconSend({
  className,
  width,
  height,
  id,
  color,
  hoverColor,
  viewBox,
}) {
  const [mainColor, setMainColor] = useState(color);
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={viewBox}
      onMouseEnter={() => setMainColor(hoverColor)}
      onMouseLeave={() => setMainColor(color)}
    >
      <defs>
        <path
          id="bhlssw37ja"
          d="M17.52 3.511c.073.186.073.392 0 .578l-5.224 13.475c-.16.412-.623.616-1.035.457-.23-.09-.405-.278-.476-.513L8.984 11.59l-5.917-1.8c-.422-.13-.66-.576-.532-.999.071-.235.247-.424.476-.513l13.475-5.225c.412-.16.875.045 1.035.457zm-3.993 2.405l-7.78 3.017L9.4 10.044l4.128-4.128zm1.13 1.132l-4.127 4.128 1.111 3.65 3.017-7.778z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1044 -204) translate(734 64) translate(0 128) translate(310 12)">
          <mask id={id} fill="#fff">
            <use xlinkHref="#bhlssw37ja" />
          </mask>
          <g fill={mainColor} mask={`url(#${id})`}>
            <path d="M0 0H20V20H0z" />
          </g>
        </g>
      </g>
      Ｆ
    </svg>
  );
}

IconSend.defaultProps = {
  className: undefined,
  width: 20,
  height: 20,
  color: '#BDBDBD',
  hoverColor: '#b5d100',
  viewBox: '0,0,20,20',
};

IconSend.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  viewBox: PropTypes.string,
};

export default IconSend;
