import actionType from '../actions/type';
import config from '../config';
import i18n from '../i18n';

const initState = {
  isLoading: false,
  setting: {
    logo: config.DEFAULT_LIVECHAT_LOGO_EXAMPLE_FILE,
    livechatTextColor: '#f5f5f5',
    livechatBackgroundColor: '#364355',
    title: i18n.t('livechat:title'),
    content: i18n.t('livechat:content'),
    buttonTextColor: '#ffffff',
    buttonColor: '#b5d100',
    position: 'bottom-right', // 插件位置，bottom-left、bottom-right
    contacts: {
      line: { enabled: true },
      facebook: { enabled: true },
      instagram: { enabled: true },
      email: { enabled: true, data: '' },
      mobile: { enabled: true, data: '' },
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_LIVECHAT:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_LIVECHAT_FAIL:
      return {
        ...initState,
        setting: {
          ...initState.setting,
          title: i18n.t('livechat:title'),
          content: i18n.t('livechat:content'),
        },
      };
    default:
      return state;
  }
};
