import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { io } from 'socket.io-client';
import { enqueueSnackbar } from './actions/snackbarAction';
import INBOXTYPE from './common/constants/inboxType';
import SENDER_TYPE from './common/constants/senderType';
import config from './config';
import {
  AuthorityType,
  MessageContentType,
  SOCKETIO_EVENT_TYPE,
  SnackBarType,
} from './types';

import { getUserOrgFeature } from './actions/userAction';

import chatPayNotify from './assets/audios/chatPayNotify.mp3';
import messageNotifySound from './assets/audios/messageNotify.mp3';

import {
  checkEventLeftOrganization,
  setOrgAnnouncement,
} from './actions/organizationAction';
import {
  playSocketioSound,
  setSocketioClient,
  setSocketioClientReady,
  setSocketioIsPlay,
  setSocketioSound,
} from './actions/socketioAction';

import { messageInfoSwitch, setSelectConvCustomer } from './actions';

import { setGotoKeepMsg } from './actions/customerAction';
import webclipPNG from './assets/images/png/webclip.png';
import MessageCenterService from './services/MessageCenterService';

import {
  playSocketioSound as playChatPaySocketioSound,
  setData as setChatPayData,
} from './actions/chatPayAction';

export default function SocketioLayer({ children }) {
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const chatPayAudioRef = useRef(null);
  const unhandledCountRef = useRef(null);

  const { unhandledCount } = useSelector(store => store.chatPay);

  const selectOrg = useSelector(store => store.organization.selectOrg);
  const userData = useSelector(store => store.auth.userData);
  const orgOwnData = useSelector(store => store.auth.orgOwnData);
  const feature = useSelector(store => store.auth.feature);

  const client = useSelector(store => store.socketio.client);
  const clientReady = useSelector(store => store.socketio.ready);
  const preference = useSelector(store => store.preference.preference);

  const navigate = useNavigate();
  const { t } = useTranslation();

  unhandledCountRef.current = unhandledCount;

  const playSound = async () => {
    dispatch(playSocketioSound());
  };

  const messageNofity = message => {
    const { data, contentType, conversation, profile, organization } = message;
    let title = '8| ';

    if (
      preference?.mutedConversation.find(obj => obj === conversation.objectId)
    ) {
      return;
    }

    switch (contentType) {
      case MessageContentType.IMAGE_SET:
      case MessageContentType.TYPE_IMAGE:
        title += t('notify:sentPicture', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.TYPE_AUDIO:
        title += t('notify:sentAudio', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.TYPE_VIDEO:
        title += t('notify:sentVideo', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.TYPE_FILE:
        title += t('notify:sentFile', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.TYPE_TEMPLATE:
        if (data?.templateType === 'text') {
          title += t('notify:sentText', {
            displayName: profile.displayName || '',
            message: data.content,
          });
        } else {
          title += t('notify:sentTemplate', {
            displayName: profile.displayName || '',
          });
        }
        break;
      case MessageContentType.TYPE_LOCATION:
        title += t('notify:sentLocation', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.TYPE_LINE_STICKER:
        title += t('notify:sentSticker', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.TYPE_TEXT_PLAIN:
        title += t('notify:sentText', {
          displayName: profile.displayName || '',
          message: data.content,
        });
        break;
      case MessageContentType.STORY_MENTION:
        title += t('notify:sentStoryMention', {
          displayName: profile.displayName || '',
        });
        break;
      case MessageContentType.START_NOTIFICATIONS:
        title += t('notify:sentStartRecurring', {
          displayName: profile.displayName || '',
          title: data.title || '',
        });
        break;
      case MessageContentType.STOP_NOTIFICATIONS:
        title += t('notify:sentStopRecurring', {
          displayName: profile.displayName || '',
          title: data.title || '',
        });
        break;

      default:
    }

    if (!document.hasFocus() && profile?.objectId) {
      const notification = new Notification(title, {
        icon: profile?.picture || webclipPNG,
        data: {
          type: 'resume',
        },
      });

      playSound();

      notification.onclick = async ev => {
        if (ev?.currentTarget?.data?.type === 'resume') {
          window.focus();

          try {
            const response = await MessageCenterService.getCustomersV3({
              orgId: organization.objectId,
              where: {
                objectId: {
                  $in: [profile.objectId],
                },
              },
              options: { message: true },
            });

            const queryCustomer = response[0];

            navigate(
              `/message-center/${organization.objectId}/conversation/${conversation.objectId}/customer/${queryCustomer.customerId}`,
            );

            dispatch(setSelectConvCustomer(queryCustomer));
            dispatch(setGotoKeepMsg(false));
            dispatch(messageInfoSwitch(''));
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error?.response?.data?.error ?? error.message);
          }
        }
      };
    }
  };

  const browserNotify = event => {
    if (
      !document.hasFocus() &&
      (event.type === SOCKETIO_EVENT_TYPE.ASSIGNED ||
        event.type === SOCKETIO_EVENT_TYPE.MESSAGE_NOTIFY ||
        event.type === SOCKETIO_EVENT_TYPE.EVICT ||
        event.type === SOCKETIO_EVENT_TYPE.INVITAION_ACCEPT)
    ) {
      const { data } = event;

      let message = '8| ';

      let icon = webclipPNG;

      switch (event.type) {
        case SOCKETIO_EVENT_TYPE.MESSAGE_NOTIFY:
          if (
            (feature &&
              feature.msgCenter.inbox.includes(INBOXTYPE.UNASSIGNED)) ||
            (!feature &&
              orgOwnData.authorityName !== AuthorityType.PRIVATE_ONLY)
          ) {
            messageNofity(data);
          }
          return;
        case SOCKETIO_EVENT_TYPE.ASSIGNED:
          message += t('inbox-assign-title', {
            from: `${data.user?.firstName || ''} ${data.user?.lastName || ''}`,
            to: data.customer?.displayName || '',
          });
          icon = selectOrg?.icon || webclipPNG;
          break;
        case SOCKETIO_EVENT_TYPE.EVICT:
          message += t('event-left-title', {
            from: `${data.sender?.firstName || ''} ${
              data.sender?.lastName || ''
            }`,
            to: data.org?.displayName || '',
          });
          icon = webclipPNG;
          break;
        case SOCKETIO_EVENT_TYPE.INVITAION_ACCEPT:
          message += t('event-accept-title', {
            from: `${data.sender?.firstName || ''} ${
              data.sender?.lastName || ''
            }`,
            to: `${data.org?.displayName || ''}`,
          });
          icon = webclipPNG;
          break;
        default:
      }

      playSound();

      const notification = new Notification(message, {
        icon,
        data: {
          type: 'resume',
        },
      });
      notification.onclick = () => {};
    }
  };

  const isMessageCenter = () => {
    return window.location.href.indexOf('message-center') !== -1;
  };

  const isBindSubBindCustomerNotify = message => {
    return (
      feature &&
      !feature.msgCenter.inbox.includes(INBOXTYPE.UNASSIGNED) &&
      (message?.profile?.bind === orgOwnData?.orgRole?._id ||
        message?.profile?.subBind.includes(orgOwnData?.orgRole?._id))
    );
  };

  const imMessageHandle = message => {
    if (
      (message?.senderType === SENDER_TYPE.CUSTOMER &&
        message?.profile?.inbox.includes(userData.objectId)) ||
      isBindSubBindCustomerNotify(message)
    ) {
      messageNofity(message);
    }
  };

  const imEventHandle = event => {
    browserNotify(event);
    // 訊即購
    if (event.type === SOCKETIO_EVENT_TYPE.SHOP8ORDER) {
      const { unhandledDelta } = event?.data || {};

      // 訂單列表提示音（判斷需確認訂單數量）
      if (unhandledDelta !== undefined) {
        // 當有新的一筆需確認訂單，開啟提示音與提示訊息
        if (unhandledDelta >= 1) {
          dispatch(playChatPaySocketioSound());

          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.SUCCESS,
              message: t('chatPay:orderNeedUpdate'),
            }),
          );
        }

        dispatch(
          setChatPayData({
            unhandledCount:
              (unhandledCountRef?.current || 0) + (unhandledDelta || 0),
          }),
        );
      }
    }
    if (event.type === SOCKETIO_EVENT_TYPE.EVICT) {
      dispatch(checkEventLeftOrganization(event.data.org.objectId));
    }
    if (event.type === SOCKETIO_EVENT_TYPE.ANNOUNCEMENT) {
      if (selectOrg) {
        dispatch(setOrgAnnouncement(event.data));
      }
    }
  };

  useEffect(() => {
    dispatch(
      setChatPayData({
        sound: chatPayAudioRef,
      }),
    );
    return () => {
      dispatch(
        setChatPayData({
          sound: null,
          isPlay: false,
        }),
      );
    };
  }, []);

  useEffect(() => {
    dispatch(setSocketioSound(audioRef));
    return () => {
      dispatch(setSocketioSound(null));
      dispatch(setSocketioIsPlay(false));
    };
  }, []);

  useEffect(() => {
    if (userData?.objectId && !client) {
      const socketClient = io(config.SOCKET_SERVER_URL, {
        forceNew: true,
        path: '/im/',
        auth: {
          userId: userData.objectId,
          token: userData.sessionToken,
        },
        transports: ['websocket'],
        autoConnect: false,
      });
      socketClient.on('ready', token => {
        socketClient.token = token;
        dispatch(setSocketioClientReady(true));
      });
      socketClient.on('disconnect', () => {
        dispatch(setSocketioClientReady(false));
        if (isMessageCenter()) {
          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.WARNING,
              message: t('chat.connectError'),
            }),
          );
        }
      });

      socketClient.io.on('reconnect', () => {
        if (isMessageCenter()) {
          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.SUCCESS,
              message: t('chat.connected'),
            }),
          );
        }
      });

      socketClient.connect();
      dispatch(setSocketioClient(socketClient));
    }

    return () => {
      if (userData?.objectId && client) {
        client.off();
        client.io.off();
        dispatch(setSocketioClient(null));
        dispatch(setSocketioClientReady(false));
      }
    };
  }, [userData?.objectId, client]);

  useEffect(() => {
    if (client && selectOrg?.objectId && clientReady) {
      client.emit('subscribe', selectOrg.objectId);
      client.on('message', imMessageHandle);
      client.on('event', imEventHandle);
      client.on('orgRoleUpdated', () => {
        if (selectOrg && orgOwnData) {
          dispatch(getUserOrgFeature(selectOrg.objectId));
        }
      });
    }
    return () => {
      if (client && selectOrg?.objectId && clientReady) {
        client.emit('unsubscribe', selectOrg.objectId);
        client.off('message', imMessageHandle);
        client.off('event', imEventHandle);
      }
    };
  }, [client, selectOrg?.objectId, clientReady, messageNofity, browserNotify]);

  return (
    <>
      <audio
        ref={audioRef}
        src={messageNotifySound}
        controls
        style={{ display: 'none' }}
      >
        <track kind="captions" />
      </audio>
      <audio
        ref={chatPayAudioRef}
        src={chatPayNotify}
        controls
        style={{ display: 'none' }}
      >
        <track kind="captions" />
      </audio>
      {children}
    </>
  );
}
