import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TaskStatusType, SOCKETIO_EVENT_TYPE } from '../../types';
import SystemTaskModal from '../../components/system-task/SystemTaskModal';
import {
  getTaskList,
  setTaskList,
  exportCustomers,
  insertTask,
} from '../../actions/systemTaskAction';

function SystemTaskModalContainer({ isOpen, onCancel, orgId }) {
  if (!isOpen) return null;
  const dispatch = useDispatch();
  const { data } = useSelector(store => store.systemTask);
  const client = useSelector(store => store.socketio.client);
  const clientReady = useSelector(store => store.socketio.ready);

  const navBarOpen = useSelector(store => store.navBarOpen);

  async function getTaskListContinue() {
    await dispatch(getTaskList(orgId, true));
  }

  function retryTask(query, options) {
    dispatch(exportCustomers(orgId, query, options));
  }

  useEffect(() => {
    dispatch(getTaskList(orgId));
  }, []);

  const eventListener = event => {
    if (event.type === SOCKETIO_EVENT_TYPE.TASK) {
      const { data: taskData } = event;
      if (taskData.status === TaskStatusType.STARTED) {
        dispatch(insertTask(taskData));
      } else {
        dispatch(setTaskList(taskData));
      }
    }
  };

  useEffect(() => {
    if (client && clientReady) {
      client.on('event', eventListener);
    }
    return () => {
      if (client && clientReady) {
        client.off('event', eventListener);
      }
    };
  }, [client, clientReady]);

  return (
    <SystemTaskModal
      onCancel={onCancel}
      orgId={orgId}
      data={data}
      navBarOpen={navBarOpen}
      getTaskList={getTaskListContinue}
      onRetry={retryTask}
    />
  );
}

SystemTaskModalContainer.defaultProps = {};

SystemTaskModalContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default SystemTaskModalContainer;
