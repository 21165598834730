import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  deleteIntegration,
  getIntegration,
} from '../../actions/organizationAction';
import { enqueueSnackbar } from '../../actions/snackbarAction';
import Modal from '../shared/Modal';
import Button from '../shared/Button';
import { SnackBarType } from '../../types';

const useStyles = makeStyles(
  () => ({
    paper: {
      maxWidth: 'unset',
      width: 'calc(100vw * 2 / 3)',
      borderRadius: '12px',
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: '#f9f9f9',
    },
    content: {
      fontSize: 15,
      lineHeight: 'normal',
    },
    'cancel-button': {
      marginRight: 8,
    },
  }),
  { name: 'DisconnectPlatformModal' },
);

const I18N_PREFIX = 'organization-setting.linked-app';

const DisconnectPlatformModal = props => {
  const { isOpen, onCancel, integrationId, platformName, description } = props;
  const { isLoading, selectOrg } = useSelector(state => state.organization);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const onClickDisconnectPlatformModal = () => {
    dispatch(deleteIntegration(integrationId))
      .then(() => navigate(-1))
      .then(() => onCancel())
      .then(() => {
        dispatch(getIntegration(selectOrg.objectId));
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.SUCCESS,
            message: t(`${I18N_PREFIX}.disconnected-app`, { platformName }),
          }),
        );
      });
  };

  return (
    <Modal
      action
      open={isOpen}
      paperClass={classes.paper}
      title={t(`${I18N_PREFIX}.disconnect-app`, { platformName })}
      content={
        <Typography className={classes.content} component="span">
          {description}
        </Typography>
      }
      actions={
        <>
          <Button
            className={classes['cancel-button']}
            topic="primaryBlue"
            onClick={onCancel}
          >
            {t('modal.cancel')}
          </Button>
          <Button
            topic="primaryBlueHighLight"
            onClick={onClickDisconnectPlatformModal}
            isLoading={isLoading}
          >
            {t('modal.sure')}
          </Button>
        </>
      }
    />
  );
};

DisconnectPlatformModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  integrationId: PropTypes.string.isRequired,
  platformName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DisconnectPlatformModal;
