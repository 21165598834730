import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconSortChat = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g fill={isHover ? hexRgba(color.$12, 40) : color.$12}>
        <path
          d="M8.36963 6.12483C8.36963 5.73353 8.6305 5.47266 9.0218 5.47266H16.8479C17.2392 5.47266 17.5001 5.73353 17.5001 6.12483C17.5001 6.51613 17.2392 6.777 16.8479 6.777H9.0218C8.6305 6.777 8.36963 6.51613 8.36963 6.12483Z"
          fill="#4C4C4C"
        />
        <path
          d="M15.5435 9.38672H9.0218C8.6305 9.38672 8.36963 9.64759 8.36963 10.0389C8.36963 10.4302 8.6305 10.6911 9.0218 10.6911H15.5435C15.9348 10.6911 16.1957 10.4302 16.1957 10.0389C16.1957 9.64759 15.9348 9.38672 15.5435 9.38672Z"
          fill="#4C4C4C"
        />
        <path
          d="M13.587 13.3008H9.0218C8.6305 13.3008 8.36963 13.5617 8.36963 13.953C8.36963 14.3443 8.6305 14.6051 9.0218 14.6051H13.587C13.9783 14.6051 14.2392 14.3443 14.2392 13.953C14.2392 13.5617 13.9783 13.3008 13.587 13.3008Z"
          fill="#4C4C4C"
        />
        <path
          d="M4.45654 5.47249C4.45654 5.1123 4.74853 4.82031 5.10872 4.82031C5.4689 4.82031 5.76089 5.1123 5.76089 5.47249V9.38553C5.76089 9.74571 5.4689 10.0377 5.10872 10.0377C4.74853 10.0377 4.45654 9.74571 4.45654 9.38553V5.47249Z"
          fill="#4C4C4C"
        />
        <path
          d="M5.1087 5.79206L3.63573 7.41963C3.39404 7.68669 2.98162 7.70725 2.71456 7.46556C2.4475 7.22387 2.42694 6.81145 2.66863 6.54439L4.62515 4.38252C4.88405 4.09645 5.33335 4.09645 5.59225 4.38252L7.54877 6.54439C7.79046 6.81145 7.7699 7.22387 7.50284 7.46556C7.23578 7.70725 6.82336 7.68669 6.58167 7.41963L5.1087 5.79206Z"
          fill="#4C4C4C"
        />
        <path
          d="M4.45654 11.3397C4.45654 10.9795 4.74853 10.6875 5.10872 10.6875C5.4689 10.6875 5.76089 10.9795 5.76089 11.3397V15.2527C5.76089 15.6129 5.4689 15.9049 5.10872 15.9049C4.74853 15.9049 4.45654 15.6129 4.45654 15.2527V11.3397Z"
          fill="#4C4C4C"
        />
        <path
          d="M5.1087 14.4853L3.63573 12.8577C3.39404 12.5907 2.98162 12.5701 2.71456 12.8118C2.4475 13.0535 2.42694 13.4659 2.66863 13.733L4.62515 15.8948C4.88405 16.1809 5.33335 16.1809 5.59225 15.8948L7.54877 13.733C7.79046 13.4659 7.7699 13.0535 7.50284 12.8118C7.23578 12.5701 6.82336 12.5907 6.58167 12.8577L5.1087 14.4853Z"
          fill="#4C4C4C"
        />
      </g>
    </svg>
  );
};

export default IconSortChat;
