import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconArrowRight = ({ onClick, className, id, width, height }) => {
  const [isHover, setIsHover] = useState(false);

  const random = Math.random();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="iconArrowRight"
          d="M11.47 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.763.763 0 0 1 .207.393c.009.044.013.09.013.137l-.004-.077.004.06v.034a.774.774 0 0 1-.074.308.705.705 0 0 1-.146.205l.069-.078a.75.75 0 0 1-.005.006l-.064.072-5.25 5.25a.75.75 0 0 1-1.06-1.06l3.97-3.97H6.75a.75.75 0 1 1 0-1.5h8.69l-3.97-3.97a.75.75 0 0 1 0-1.06z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`iconArrowRightMask${id}${random}`} fill="#fff">
            <use xlinkHref="#iconArrowRight" />
          </mask>
          <g
            fill={isHover ? hexRgba(color.$11, 40) : color.$11}
            mask={`url(#iconArrowRightMask${id}${random})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconArrowRight.defaultProps = {
  width: 24,
  height: 24,
};

export default IconArrowRight;
