import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconArrowLeft = ({ onClick, className, id, width, height }) => {
  const [isHover, setIsHover] = useState(false);

  const random = Math.random();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="iconArrowLeft"
          d="M12.53 6.22a.75.75 0 0 1 0 1.06l-3.97 3.97h8.69a.75.75 0 1 1 0 1.5H8.56l3.97 3.97a.75.75 0 0 1-1.06 1.06l-5.25-5.25-.064-.072a.752.752 0 0 1-.005-.006l.069.078A.754.754 0 0 1 6 12v-.016c0-.02.002-.041.004-.061L6 12a.754.754 0 0 1 .22-.53l5.25-5.25a.75.75 0 0 1 1.06 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`iconArrowLeftMask${id}${random}`} fill="#fff">
            <use xlinkHref="#iconArrowLeft" />
          </mask>
          <g
            fill={isHover ? hexRgba(color.$11, 40) : color.$11}
            mask={`url(#iconArrowLeftMask${id}${random})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconArrowLeft.defaultProps = {
  width: 24,
  height: 24,
};

export default IconArrowLeft;
