import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import { color } from '../../themes';
import NotificationCenterHeader from './NotificationCenterHeader';
import NotificationCenterMsg from './NotificationCenterMsg';

const useStyles = makeStyles(
  () => ({
    '@keyframes ZoomIn': {
      from: {
        right: -368,
      },
      to: {
        right: 0,
      },
    },
    '@keyframes ZoomOut': {
      from: {
        right: 0,
      },
      to: {
        right: -368,
      },
    },
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: 4000,
      width: '100vw',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.5);',
    },
    sidebar: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: 368,
      height: '100vh',
      backgroundColor: color.$7,
      boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.08)',
      animation: '$ZoomIn .5s',
      zIndex: 9999,
    },
    sidebarZoomOut: {
      animation: '$ZoomOut .5s',
    },
  }),
  {
    name: 'NotificationCenterModal',
  },
);

function NotificationCenterModal({ onCancel, systemMsgs, onMsgScroll }) {
  const classes = useStyles();
  const { isModalOpen } = useSelector(store => store.notificationCenter);

  useEffect(() => {
    window.addEventListener('mousedown', onCancel);
    return () => {
      window.removeEventListener('mousedown', onCancel);
    };
  }, []);
  return (
    <div
      className={classnames(classes.sidebar, {
        [classes.sidebarZoomOut]: !isModalOpen,
      })}
      onMouseDown={e => {
        e.stopPropagation();
      }}
    >
      <NotificationCenterHeader onCancel={onCancel} />
      <NotificationCenterMsg
        systemMsgs={systemMsgs}
        onMsgScroll={onMsgScroll}
      />
    </div>
  );
}

NotificationCenterModal.defaultProps = {};

NotificationCenterModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  systemMsgs: PropTypes.array.isRequired,
  onMsgScroll: PropTypes.func.isRequired,
};

export default NotificationCenterModal;
