import { useDispatch } from 'react-redux';
import OrganizationAnnouncementModal from '../../components/organizationAnnouncement/Modal';

import { closeOrganizationAnnoucementModal } from '../../actions/organizationAction';

function OrganizationAnnouncementModalContainer() {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(closeOrganizationAnnoucementModal());
  };

  return <OrganizationAnnouncementModal onCancel={onCancel} />;
}

export default OrganizationAnnouncementModalContainer;
