import { useState } from 'react';
import { color } from '../../../themes';

const IconEye20 = ({
  onClick,
  className,
  id,
  color: iconColor = color.$1,
  hoverColor = color.$10,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id={`ic-eye-20-${id}`}
          d="M17.9498956,9.73333333 C17.8162839,9.46666667 15.0104384,4 10,4 C4.98956159,4 2.18371608,9.46666667 2.05010438,9.73333333 C1.98329854,9.93333333 1.98329854,10.1333333 2.05010438,10.3333333 C2.18371608,10.5333333 4.98956159,16 10,16 C15.0104384,16 17.8162839,10.5333333 17.9498956,10.2666667 C18.0167015,10.1333333 18.0167015,9.86666667 17.9498956,9.73333333 Z M10,14.6666667 C6.39248434,14.6666667 4.05427975,11.0666667 3.38622129,10 C3.9874739,8.93333333 6.39248434,5.33333333 10,5.33333333 C13.6075157,5.33333333 15.9457203,8.93333333 16.6137787,10 C15.9457203,11.0666667 13.6075157,14.6666667 10,14.6666667 Z M10,7.33333333 C8.5302714,7.33333333 7.32776618,8.53333333 7.32776618,10 C7.32776618,11.4666667 8.5302714,12.6666667 10,12.6666667 C11.4697286,12.6666667 12.6722338,11.4666667 12.6722338,10 C12.6722338,8.53333333 11.4697286,7.33333333 10,7.33333333 Z M10,11.3333333 C9.2651357,11.3333333 8.66388309,10.7333333 8.66388309,10 C8.66388309,9.26666667 9.2651357,8.66666667 10,8.66666667 C10.7348643,8.66666667 11.3361169,9.26666667 11.3361169,10 C11.3361169,10.7333333 10.7348643,11.3333333 10,11.3333333 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="20" height="20" />
        <mask id={`eyeMask${id}`} fill="#fff">
          <use xlinkHref={`#ic-eye-20-${id}`} />
        </mask>
        <g fill={isHover ? iconColor : hoverColor} mask={`url(#eyeMask${id})`}>
          <rect width="20" height="20" />
        </g>
      </g>
    </svg>
  );
};

export default IconEye20;
