import actionType from '../actions/type';

export default (
  state = {
    trustRouteList: [],
  },
  action,
) => {
  switch (action.type) {
    case actionType.SET_TRUST_ROUTE_LIST:
      return { ...state, trustRouteList: action.payload };
    case actionType.SET_TRUST_ROUTE_FAIL:
      return { ...state, trustRouteList: [] };
    default:
      return state;
  }
};
