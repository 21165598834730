import * as obp from 'object-path';
import imgSrc from '../assets/iconSrc';
import config from '../config';
import i18n from '../i18n';
import { FollowType, MessageContentType } from '../types';
import CONSTANTS from './constants';

const SEARCH_MODE = {
  FULLMODE: 'fullMode',
  CONTAINMPDE: 'containMode',
};

const buildFindMessagePayload = param => {
  const {
    orgId,
    where = {},
    count = false,
    sort = { _created_at: -1 },
    limit = 20,
    skip,
  } = param;
  if (!orgId) {
    throw new Error('error/no-organization');
  }
  const payload = {
    where: {
      organization: {
        className: 'Organization',
        objectId: orgId,
      },
    },
  };

  const {
    keyword,
    platform,
    inbox,
    tag,
    sender,
    before,
    startAt,
    endAt,
  } = where;

  if (keyword) {
    obp.set(payload, 'where.message.keyword', {
      in: [keyword],
    });
  }

  if (obp.get(platform, 'length', 0) !== 0 && platform[0] !== 'all') {
    obp.set(payload, 'where.message.platform', platform);
  }

  if (obp.get(inbox, 'length', 0) !== 0) {
    payload.where.customer = { inboxes: inbox };
  }

  if (obp.get(tag, 'in.length', 0) !== 0) {
    obp.set(payload, 'where.tags.tag.$in', tag.in);
  }

  if (obp.get(tag, 'nin.length', 0) !== 0) {
    obp.set(payload, 'where.tags.tag.$nin', tag.nin);
  }

  if (startAt && endAt) {
    obp.set(payload, 'where.message.time._created_at.$gte', {
      __type: 'Date',
      iso: new Date(startAt).toISOString(),
    });
    obp.set(payload, 'where.message.time._created_at.$lte', {
      __type: 'Date',
      iso: new Date(endAt).toISOString(),
    });
  }

  if (obp.get(sender, 'length', 0) !== 0) {
    const senderTypes = [];
    const senders = [];
    sender.forEach(s => {
      if (
        s === 'Customer' ||
        s === 'AddOn' ||
        s === 'Organization' ||
        s === '_User' ||
        s === 'ForeignBot'
      ) {
        senderTypes.push(s);
      } else {
        senders.push(s);
      }
    });
    if (senderTypes.length && senders.length) {
      if (senderTypes.filter(d => d !== '_User').length > 0) {
        obp.set(payload, 'where.message.sender.$or', [
          {
            senderType: {
              $in: senderTypes.filter(d => d !== '_User'),
            },
          },
        ]);
        if (
          senderTypes.filter(d => d === '_User').length > 0 ||
          senders.length > 0
        ) {
          payload.where.message.sender.$or.push({
            $and: [
              {
                senderType: '_User',
                sender: {
                  $in: senders,
                },
              },
            ],
          });
        }
      } else {
        obp.set(payload, 'where.message.sender.$or', [
          {
            senderType: '_User',
            sender: {
              $in: senders,
            },
          },
        ]);
      }
    } else if (senderTypes.length) {
      obp.set(payload, 'where.message.sender.senderType', {
        $in: senderTypes,
      });
    } else {
      obp.set(payload, 'where.message.sender.senderType', '_User');
      obp.set(payload, 'where.message.sender.sender', {
        $in: senders,
      });
    }
  }

  if (before) {
    const sortKey = obp.get(Object.keys(sort), 0);
    if (!sortKey) {
      throw new Error('error/sort-param-is-required');
    }
    obp.set(payload, 'where.message.before', {
      [sortKey]: {
        $lt: before,
      },
    });
  }

  if (count) {
    obp.set(payload, 'count', true);
  }

  if (sort) {
    obp.set(payload, 'sort', sort);
  }

  if (limit) {
    obp.set(payload, 'limit', limit);
  }

  if (skip) {
    obp.set(payload, 'skip', skip);
  }

  return payload;
};

const buildCustomerPayload = param => {
  const {
    orgId,
    where = {},
    options = {},
    count = false,
    sort = { lastMessageAt: -1 },
    limit = 20,
    skip = 0,
  } = param;
  let payload = {
    where: {
      organization: {
        className: 'Organization',
        objectId: orgId,
      },
      customer: [],
    },
  };

  if (obp.get(options, 'message')) {
    obp.set(payload, 'options.message', true);
  }

  if (obp.get(options, 'badgeOnly')) {
    obp.set(payload, 'options.badgeOnly', true);
    obp.set(payload, 'options.monitoringOnly', false);
  }

  if (obp.get(options, 'monitoringOnly')) {
    obp.set(payload, 'options.badgeOnly', false);
    obp.set(payload, 'options.monitoringOnly', true);
  }

  if (obp.get(options, 'bindRoleId')) {
    obp.set(payload, 'options.bindRoleId', obp.get(options, 'bindRoleId'));
  }

  const {
    objectId,
    customerId,
    conversationId,
    keyword,
    platform,
    inbox,
    tag,
    before,
    startAt,
    endAt,
    lastInboundAt,
    lastMessageAt,
    assignedAt,
    isReconnect,
    mode,
    cellPhone,
    email,
  } = where;

  if (objectId) {
    payload.where.customer.push({
      _id: objectId,
    });

    if (!isReconnect) {
      return payload;
    }
  }

  if (customerId) {
    payload.where.customer = [{ customerId }];
    return payload;
  }

  if (conversationId) {
    payload.where.customer = [{ conversation: conversationId }];
    return payload;
  }

  if (cellPhone) {
    payload.where.customer.push({ cellPhone });
  }

  if (email) {
    payload.where.customer.push({ email });
  }

  if (obp.get(platform, 'length', 0) !== 0 && platform.indexOf('all') === -1) {
    payload.where.customer.push({ platform: { $in: platform } });
  }

  if (keyword) {
    const sessionSearchMode =
      mode || localStorage.getItem(config.SESSION_SEARCH_MODE);

    if (sessionSearchMode === SEARCH_MODE.CONTAINMPDE) {
      payload.where.customer.push({
        displayName: {
          $regex: keyword,
          $options: 'ig',
        },
      });
    } else {
      payload.where.customer.push({
        nameSearch: keyword,
      });
    }
  }

  if (lastMessageAt) {
    if (sort.lastMessageAt === -1) {
      payload.where.customer.push({
        lastMessageAt: {
          $lt: lastMessageAt,
        },
      });
    } else {
      payload.where.customer.push({
        lastMessageAt: {
          $gt: lastMessageAt,
        },
      });
    }
  }

  if (assignedAt) {
    if (sort.assignedAt === -1) {
      payload.where.customer.push({
        assignedAt: {
          $lt: assignedAt,
        },
      });
    } else {
      payload.where.customer.push({
        assignedAt: {
          $gt: assignedAt,
        },
      });
    }
  }

  if (lastInboundAt) {
    if (sort.lastInboundAt === -1) {
      payload.where.customer.push({
        lastInboundAt: {
          $lt: lastInboundAt,
        },
      });
    } else {
      payload.where.customer.push({
        lastInboundAt: {
          $gt: lastInboundAt,
        },
      });
    }
  }

  if (obp.get(tag, 'in.length', 0) !== 0) {
    obp.set(payload, 'where.tags.tag.$in', tag.in);
  }

  if (obp.get(tag, 'nin.length', 0) !== 0) {
    obp.set(payload, 'where.tags.tag.$nin', tag.nin);
  }

  if (startAt && endAt) {
    payload.where.customer.push({
      lastMessageAt: {
        $gte: { __type: 'Date', iso: new Date(startAt).toISOString() },
        $lte: { __type: 'Date', iso: new Date(endAt).toISOString() },
      },
    });
  }
  if (before) {
    const sortKey = obp.get(Object.keys(sort), '0').toString();
    if (!sortKey) {
      throw new Error('error/sort-param-is-required');
    }
    payload.where.customer.push({
      [sortKey]: {
        $lt: before,
      },
    });
  }

  if (obp.get(inbox, 'length', 0) !== 0) {
    payload.where.customer.push({
      inbox,
    });
  }

  if (count) {
    obp.set(payload, 'count', true);
  }

  if (sort) {
    obp.set(payload, 'sort', sort);
  }

  if (isReconnect) {
    return payload;
  }

  if (limit) {
    obp.set(payload, 'limit', limit);
  }

  if (skip) {
    obp.set(payload, 'skip', skip);
  }

  if (!orgId) {
    payload = {};
  }

  return payload;
};

const getSender = (message, currentUser, currentOrganization) => {
  const { senderType } = message;
  const { sender } = message;
  switch (senderType) {
    default:
      // [x] uncaught senderType
      return '';
    case CONSTANTS.SENDER_TYPE.CUSTOMER:
      return '';
    case CONSTANTS.SENDER_TYPE.USER:
      if (currentUser === sender) {
        return i18n.t('chat.audit.you');
      }
      return obp.get(message, 'profile.firstName', 'User name updating');

    case CONSTANTS.SENDER_TYPE.ORGANIZATION:
      return currentOrganization;
    case CONSTANTS.SENDER_TYPE.BOT:
    case CONSTANTS.SENDER_TYPE.ADDON:
      return i18n.t('navigation.inbox.bot');
    case CONSTANTS.SENDER_TYPE.FOREIGN_BOT:
      const { platform: imPlatform, _p_platform: dbPlatform } = message;
      const platform = imPlatform?.objectId || dbPlatform.split('$')[1];
      if (platform === 'instagram') {
        return 'Instagram';
      }
      return 'Messenger';
  }
};

const getMessageSummary = (message, sender = '') => {
  const { contentType, data } = message;

  switch (contentType) {
    default:
      // console.error('error/not-supported-content-type', contentType, message);
      return '';
    case MessageContentType.TYPE_NOTIFY_EVENT:
      if (
        message.data.event ===
          CONSTANTS.NOTIFY_EVENT.NOTIFY_EVENT_CUSTOMER_FOLLOW ||
        message.data.event === MessageContentType.NOTIFY_EVENT_REFERRAL ||
        message.data.event === MessageContentType.SOURCE_REFERRAL_ADS ||
        message.data.event ===
          MessageContentType.SOURCE_REFERRAL_MESSENGER_CODE ||
        message.data.event ===
          MessageContentType.SOURCE_REFERRAL_DISCOVER_TAB ||
        message.data.event ===
          MessageContentType.SOURCE_REFERRAL_CUSTOMER_CHAT_PLUGIN
      ) {
        return sender
          ? `${i18n.t('chat.customerJoinConversation', {
              displayName: sender,
            })}`
          : i18n.t('chat.customerJoinConversation');
      }
      if (
        message.data.event ===
        CONSTANTS.NOTIFY_EVENT.NOTIFY_EVENT_CUSTOMER_BLOCK
      ) {
        return sender
          ? `${i18n.t('chat.customerBlockConversation', {
              displayName: sender,
            })}`
          : i18n.t('chat.customerBlockConversation');
      }
      if (
        message.data.event === MessageContentType.NOTIFY_EVENT_LOCKMSG_START
      ) {
        return i18n.t('chat.lockedMsg-start');
      }
      if (message.data.event === MessageContentType.NOTIFY_EVENT_LOCKMSG_END) {
        return i18n.t('chat.lockedMsg-end');
      }
      if (
        message.data.event === MessageContentType.NOTIFY_EVENT_CUSTOMER_LOGIN
      ) {
        return i18n.t('chat.customerLoginConversation', {
          displayName: data?.originDisplayName,
        });
      }

      return '';

    case MessageContentType.TYPE_FILE:
      return sender
        ? `${i18n.t('chat.sentFile', { displayName: sender })}`
        : i18n.t('chat.sentFile');
    case MessageContentType.IMAGE_SET:
    case MessageContentType.TYPE_IMAGE:
      return sender
        ? `${i18n.t('chat.sentPicture', { displayName: sender })}`
        : i18n.t('chat.sentPicture');
    case MessageContentType.TYPE_AUDIO:
      return sender
        ? `${i18n.t('chat.sentAudio', { displayName: sender })}`
        : i18n.t('chat.sentVideo');
    case MessageContentType.TYPE_VIDEO:
      return sender
        ? `${i18n.t('chat.sentVideo', { displayName: sender })}`
        : i18n.t('chat.sentVideo');
    case MessageContentType.TYPE_LOCATION:
      return sender
        ? `${i18n.t('chat.sentLocation', { displayName: sender })}`
        : i18n.t('chat.sentLocation');
    case MessageContentType.TYPE_TEMPLATE:
      if (message.data.templateType === 'imagemap') {
        return sender
          ? `${i18n.t('chat.sentImgmap', { displayName: sender })}`
          : i18n.t('chat.sentImgmap');
      }
      if (message.data.templateType === 'text') {
        return sender
          ? `${sender}:${message.data.content}`
          : message.data.content;
      }
      return sender
        ? `${i18n.t('chat.sentTemplate', { displayName: sender })}`
        : i18n.t('chat.sentTemplate');

    case MessageContentType.TYPE_LINE_STICKER:
      return sender
        ? `${i18n.t('chat.sentSticker', { displayName: sender })}`
        : i18n.t('chat.sentSticker');
    case MessageContentType.TYPE_IMAGEMAP:
      return sender
        ? `${i18n.t('chat.sentImgmap', { displayName: sender })}`
        : i18n.t('chat.sentImgmap');
    case MessageContentType.FORM:
      return sender
        ? `${sender}${i18n.t('chat.sentFormForHistory')}`
        : i18n.t('chat.sentFormForHistory');
    case MessageContentType.TYPE_TEXT_PLAIN:
      return sender
        ? `${sender}:${message.data.content}`
        : message.data.content === 'chat.sendReplyTo'
        ? i18n.t('chat.sendReplyTo')
        : message.data.content;
    case MessageContentType.TYPE_SHARE:
      return getMessageSummary(message.data, sender);
    case MessageContentType.STORY_MENTION:
      return i18n.t('chat.sentStoryMention');
    case MessageContentType.LIKE_HEART:
      return sender
        ? `${sender}${i18n.t('chat.sentLike')}`
        : i18n.t('chat.sentLike');
    case MessageContentType.DELETE:
      return i18n.t('chat.sentDelete');
    case MessageContentType.CUSTOMER_SHARE:
      return i18n.t('chat.sentCustomerShare');
    case MessageContentType.UNSUPPORTED:
      return i18n.t('chat.sentUnsupported');
    case MessageContentType.X_RECURRING_NOTIFICATIONS:
      return i18n.t('chat.sentRecurring');
    case MessageContentType.START_NOTIFICATIONS:
      return `${i18n.t('chat.customerStartRecurring', {
        displayName: data.title,
      })}`;
    case MessageContentType.STOP_NOTIFICATIONS:
      return `${i18n.t('chat.customerStopRecurring', {
        displayName: data.title,
      })}`;
  }
};

const transformMessageToConversationItem = message => {
  let conversationItem = {
    objectId: message.objectId,
    offsetTime: obp.get(message, 'createdAt.iso', "1970-01-01'T'00:00:00Z"),
    lastMessageAt: obp.get(message, 'createdAt', {}),
    inboxType: message.inbox,
    title: '',
    content: '',
    customerObjectId: obp.get(message, 'customer.objectId'),
    customerId: obp.get(message, 'customer.customerId'),
    friendship: FollowType.FOLLOW,
  };
  const { senderType } = message;
  switch (senderType) {
    default:
      // console.error('[x] error/uncaught-senderType', senderType);
      return conversationItem;
    case CONSTANTS.SENDER_TYPE.USER:
      conversationItem = {
        ...conversationItem,
        picture: obp.get(message, 'profile.picture') || imgSrc.defaultUser,
        title: obp.get(message, 'profile.firstName'),
        secondTitle: obp.get(message, 'customer.displayName', ''),
        secondPicture:
          obp.get(message, 'customer.picture') || imgSrc.defaultUser,
        secondPlatform: obp.get(message, 'platform.objectId'),
      };
      break;
    case CONSTANTS.SENDER_TYPE.CUSTOMER:
      conversationItem = {
        ...conversationItem,
        picture: obp.get(message, 'profile.picture') || imgSrc.defaultUser,
        platform: obp.get(message, 'platform.objectId', ''),
        title: obp.get(
          message,
          'profile.displayName',
          obp.get(message, 'customer.originalDisplayName'),
        ),
      };
      break;
    case CONSTANTS.SENDER_TYPE.ORGANIZATION:
      conversationItem = {
        ...conversationItem,
        title: obp.get(message, 'profile.displayName'),
        picture: obp.get(message, 'profile.picture') || imgSrc.defaultOrg,
        secondTitle: obp.get(message, 'customer.displayName', ''),
        secondPicture:
          obp.get(message, 'customer.picture') || imgSrc.defaultUser,
        secondPlatform: obp.get(message, 'platform.objectId'),
      };
      break;
    case CONSTANTS.SENDER_TYPE.BOT:
    case CONSTANTS.SENDER_TYPE.FOREIGN_BOT:
    case CONSTANTS.SENDER_TYPE.ADDON:
      conversationItem = {
        ...conversationItem,
        title: i18n.t('navigation.inbox.bot'),
        picture: imgSrc.messagebot,
        secondTitle: obp.get(message, 'customer.displayName', ''),
        secondPicture:
          obp.get(message, 'customer.picture') || imgSrc.defaultUser,
        secondPlatform: obp.get(message, 'platform.objectId'),
      };
      break;
  }
  conversationItem = {
    ...conversationItem,
    content: getMessageSummary(message),
    time: obp.get(message, 'createdAt', 0),
    _created_at: obp.get(message, 'createdAt', 0),
    conversationId: obp.get(message, 'conversation.objectId'),
    customerId: obp.get(message, 'customer.customerId'),
  };
  return conversationItem;
};

const transformCustomerToConversationItem = (customer, user, organization) => {
  const lastMessage = obp.get(customer, 'conversation.lastMessage', {});
  return {
    picture: customer.picture,
    title:
      customer.displayName ||
      customer.originalDisplayName ||
      i18n.t('chat.no-name'),
    platform: obp.get(customer, 'platform.objectId'),
    muted: false, // not known
    time: obp.get(customer, 'lastMessageAt', 0),
    inboxType: customer.inbox,
    badge: obp.get(customer, 'lastRead.badge', 0),
    content:
      lastMessage?.locked === true
        ? i18n.t('chat.lockedMsgConversation')
        : getMessageSummary(
            lastMessage,
            getSender(lastMessage, user, organization),
          ),
    isBotHandle: customer.inbox.indexOf('bot') > -1,
    isChecked: false,
    lastInboundAt: obp.get(customer, 'lastInboundAt', 0),
    lastMessageAt: obp.get(customer, 'lastMessageAt', 0),
    joinedAt: obp.get(customer, 'joinedAt', 0),
    assignedAt: obp.get(customer, 'assignedAt', 0),
    objectId: obp.get(customer, 'objectId'),
    conversationId: obp.get(customer, 'conversation.objectId'),
    customerId: obp.get(customer, 'customerId'),
    friendship: customer.friendship || FollowType.FOLLOW,
    previousInbox: customer.previousInbox,
    isMonitoring: !!customer?.isMonitoring,
  };
};

const prepareAssignPayload = (user, orgId, inbox, customerObjId, member) => {
  const currentUser = user;
  return {
    inbox,
    from: {
      className: '_User',
      objectId: currentUser.objectId,
    },
    customer: {
      className: 'Customer',
      objectId: customerObjId,
    },
    organization: {
      className: 'Organization',
      objectId: orgId,
    },
    ...(member ? { toUser: { className: '_User', objectId: member } } : {}),
  };
};

const permitMessageContentType = contentType => {
  switch (contentType) {
    default:
      return false;
    case MessageContentType.IMAGE_SET:
    case MessageContentType.TYPE_AUDIO:
    case MessageContentType.TYPE_VIDEO:
    case MessageContentType.TYPE_FILE:
    case MessageContentType.FORM:
    case MessageContentType.TYPE_IMAGE:
    case MessageContentType.TYPE_IMAGEMAP:
    case MessageContentType.TYPE_LINE_STICKER:
    case MessageContentType.TYPE_LOCATION:
    case MessageContentType.TYPE_SHARE:
    case MessageContentType.TYPE_TEMPLATE:
    case MessageContentType.TYPE_TEXT_PLAIN:
    case MessageContentType.TYPE_NOTIFY_EVENT:
    case MessageContentType.STORY_MENTION:
    case MessageContentType.LIKE_HEART:
    case MessageContentType.DELETE:
    case MessageContentType.CUSTOMER_SHARE:
    case MessageContentType.UNSUPPORTED:
    case MessageContentType.X_REACT:
    case MessageContentType.X_UNREACT:
    case MessageContentType.START_NOTIFICATIONS:
    case MessageContentType.STOP_NOTIFICATIONS:
    case MessageContentType.X_RECURRING_NOTIFICATIONS:
      return true;
  }
};

const timeKey = inbox => {
  switch (inbox) {
    case CONSTANTS.INBOX_TYPE.BIND:
    case CONSTANTS.INBOX_TYPE.SUBBIND:
      return 'lastInboundAt';
    case CONSTANTS.INBOX_TYPE.UNASSIGNED:
    case CONSTANTS.INBOX_TYPE.ALL:
    case CONSTANTS.INBOX_TYPE.BOT:
    case CONSTANTS.INBOX_TYPE.UNFOLLOW:
    case CONSTANTS.INBOX_TYPE.HISTORY:
    case CONSTANTS.INBOX_TYPE.DEPARTMENT:
      return 'lastMessageAt';
    case CONSTANTS.INBOX_TYPE.PRIVATE:
    case CONSTANTS.INBOX_TYPE.SELF:
    case CONSTANTS.INBOX_TYPE.DONE:
    case CONSTANTS.INBOX_TYPE.SPAM:
    default:
      return 'assignedAt';
  }
};

export {
  buildCustomerPayload,
  buildFindMessagePayload,
  getMessageSummary,
  getSender,
  permitMessageContentType,
  prepareAssignPayload,
  SEARCH_MODE,
  timeKey,
  transformCustomerToConversationItem,
  transformMessageToConversationItem,
};
