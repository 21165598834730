import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import hexRgba from 'hex-rgba';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { color } from '../../themes';
import { SnackBarType } from '../../types';
import IconCheckCircle from '../../assets/images/Icons/IconCheckCircle';
import IconAlertTriangle from '../../assets/images/Icons/IconAlertTriangle';
import IconX from '../../assets/images/Icons/IconX';
import IconRefresh from '../../assets/images/Icons/IconRefresh';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      zIndex: 7000,
      backgroundColor: color.$9,
      width: 'max-content',
      minWidth: 592,
      maxWidth: 700,
      minHeight: 48,
      boxShadow:
        '0 12px 40px 0 rgba(0, 0, 0, 0.08), 0 6px 18px 0 rgba(0, 0, 0, 0.05)',
      borderRadius: 8,
      padding: '12px 84px 12px 56px',
      font: 17,
      lineBreak: 'anywhere',
    },
    smallContent: {
      paddingRight: 124,
    },
    variant: {
      color: color.$14,
    },
    [`variant-${SnackBarType.SUCCESS}`]: {
      color: color.$7,
      backgroundColor: color.$1,
    },
    [`variant-${SnackBarType.ERROR}`]: {
      color: color.$7,
      backgroundColor: color.$17,
    },
    [`variant-${SnackBarType.WARNING}`]: {
      color: color.$7,
      backgroundColor: color.$16,
    },
    [`variant-${SnackBarType.INFO}`]: {
      backgroundColor: color.$9,
    },
    variantIcon: {
      position: 'absolute',
      top: 12,
      left: 24,
      display: 'block',
    },
    refreshIcon: {
      cursor: 'pointer',
      position: 'absolute',
      width: 24,
      height: 24,
      top: 12,
      right: 64,
      display: 'block',
    },
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: 12,
      right: 24,
      display: 'block',
    },
  }),
  {
    name: 'Snackbar',
  },
);

const Snackbar = forwardRef((props, ref) => {
  const { id, variant, message, onRefresh } = props;
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const showRefreshIcon = !!onRefresh;

  let iconComponent = null;
  let iconColor = null;

  switch (variant) {
    case SnackBarType.SUCCESS:
      iconColor = color.$7;
      iconComponent = (
        <IconCheckCircle className={classes.variantIcon} id={`${id}`} />
      );
      break;
    case SnackBarType.ERROR:
    case SnackBarType.WARNING:
      iconColor = color.$7;
      iconComponent = (
        <IconAlertTriangle className={classes.variantIcon} color={color.$7} />
      );
      break;
    default:
      iconColor = color.$14;
  }

  function handleOnClose() {
    closeSnackbar(id);
  }

  return (
    <div
      className={classnames(
        classes.root,
        showRefreshIcon ? classes.smallContent : null,
        classes.variant,
        classes[`variant-${variant}`],
      )}
      ref={ref}
    >
      {iconComponent}
      {message}
      {showRefreshIcon && (
        <div
          onClick={onRefresh}
          className={classes.refreshIcon}
          id="divSnackbarRefresh"
        >
          <IconRefresh
            id="snackbar-icon-refresh"
            width={24}
            height={24}
            color={iconColor}
            hoverColor={hexRgba(iconColor, 70)}
          />
        </div>
      )}
      <div
        onClick={handleOnClose}
        className={classes.closeIcon}
        id="divSnackbarX"
      >
        <IconX
          id="snackbar-icon-x"
          width={24}
          height={24}
          color={iconColor}
          hoverColor={hexRgba(iconColor, 70)}
        />
      </div>
    </div>
  );
});

Snackbar.defaultProps = {
  onRefresh: undefined,
};

Snackbar.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  onRefresh: PropTypes.func,
};

export default Snackbar;
