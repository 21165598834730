import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { color } from '../../themes';
import IconX from '../../assets/images/Icons/IconX';

const useStyles = makeStyles(
  theme => ({
    root: {
      height: 64,
      display: 'flex',
      padding: '0 16px 0 24px',
      fontSize: 17,
      alignItems: 'center',
      lineHeight: 1,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: 17,
      color: color.$13,
      lineHeight: 1,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
    },
    link: {
      ...theme.link.primaryGreen.default,
      display: 'inline-block',
      marginRight: 8,
    },
    close: {
      cursor: 'pointer',
    },
    btn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
  {
    name: 'Header',
  },
);

function Header({ onCancel }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {t('organization-setting.announcement.title')}
      </div>
      <div className={classes.btn}>
        <div className={classes.close} onClick={onCancel}>
          <IconX id="notification-center-modal-icon-x" />
        </div>
      </div>
    </div>
  );
}

export default Header;
