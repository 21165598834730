import { serverApiAxios } from '../common/axios';
import actionType from './type';
import { enqueueSnackbar } from './snackbarAction';
import i18n from '../i18n';
import { SnackBarType, VendorStore } from '../types';
import AddOnSettingService from '../services/AddOnSettingService';
import exportData from '../common/exportData';

const apiUrl = '/growth';

const getCampaigns = (orgId, page = 0, platform) => async dispatch => {
  const response = await serverApiAxios.get(
    `${apiUrl}/campaign/getCampaigns?page=${page}&platform=${platform}&orgId=${orgId}`,
  );
  dispatch({
    type: actionType.GET_CAMPAIGNS,
    payload: response,
  });
};

const getCampaignByID = campaignId => async dispatch => {
  const response = await serverApiAxios.get(
    `${apiUrl}/campaign/getCampaignById/${campaignId}`,
  );
  dispatch({
    type: actionType.GET_CAMPAIGN_BY_ID,
    payload: response,
  });
};

const resetCampaignSelected = () => ({
  type: actionType.RESET_CAMPAIGN_SELECTED,
});

const createCampaign = options => async dispatch => {
  try {
    const response = await serverApiAxios.post(
      `${apiUrl}/campaign/createCampaign`,
      options,
    );
    dispatch({
      type: actionType.CREATE_CAMPAIGN,
      payload: response,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('super-link:create-success', {
          campaignName: response.campaign.name,
        }),
      }),
    );
  } catch {
    dispatch({
      type: actionType.CREATE_CAMPAIGN_FAILED,
      payload: { message: 'failed' },
    });
  }
};

const emptyCreateResult = () => ({
  type: actionType.EMPTY_CREATE_RESULT,
});

const updateCampaign = (campaignId, options) => async dispatch => {
  try {
    const response = await serverApiAxios.put(
      `${apiUrl}/campaign/updateCampaign/${campaignId}`,
      options,
    );
    dispatch({
      type: actionType.UPDATE_CAMPAIGN,
      payload: response.campaign,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('growth-tool.update-success', {
          value: response.campaign.name,
        }),
      }),
    );
  } catch {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('growth-tool.update-failed'),
      }),
    );
  }
};

const toggleSharerFan = (campaignId, isFan) => async dispatch => {
  try {
    await serverApiAxios.put(`${apiUrl}/sharer/updateFan/${campaignId}`, {
      isFan,
    });
    dispatch({
      type: actionType.TOGGLE_FAN,
    });
  } catch {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('growth-tool.update-failed'),
      }),
    );
  }
};

const toggleCampaign = (campaignId, isActive) => async dispatch => {
  try {
    const response = await serverApiAxios.put(
      `${apiUrl}/campaign/toggleCampaign/${campaignId}`,
      {
        isActive,
      },
    );
    dispatch({
      type: actionType.TOGGLE_CAMPAIGN,
      payload: response.campaign,
    });
    if (isActive) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('growth-tool.open-success', {
            value: response.campaign.name,
          }),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('growth-tool.close-success', {
            value: response.campaign.name,
          }),
        }),
      );
    }
  } catch {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('growth-tool.update-failed'),
      }),
    );
  }
};

const deleteCampaign = campaignId => async dispatch => {
  try {
    const response = await serverApiAxios.delete(
      `${apiUrl}/campaign/deleteCampaign/${campaignId}`,
    );
    dispatch({
      type: actionType.DELETE_CAMPAIGN,
      payload: response.campaign,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('growth-tool.delete-success', {
          value: response.campaign.name,
        }),
      }),
    );
  } catch {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('growth-tool.delete-failed'),
      }),
    );
  }
};

const getChannelInfo = orgId => async dispatch => {
  const response = await serverApiAxios.get(
    `${apiUrl}/resource/getChannelInfo/${orgId}`,
  );
  dispatch({
    type: actionType.GET_CHANNEL_INFO,
    payload: response,
  });
};

const getCampaignAnalyze = campaignId => async dispatch => {
  const response = await serverApiAxios.get(
    `${apiUrl}/campaign/getCampaignAnalyze/${campaignId}`,
  );
  dispatch({
    type: actionType.GET_CAMPAIGN_ANALYZE,
    payload: response,
  });
};

const getSharerData = (campaignId, page = 0, sharer = '') => async dispatch => {
  const response = await serverApiAxios.get(
    `${apiUrl}/campaign/getSharerData/${campaignId}?page=${page}&sharer=${encodeURI(
      sharer || '',
    )}`,
  );
  dispatch({
    type: actionType.GET_SHARER_DATA,
    payload: response,
  });
};

const exportShareRank = campaignId => (dispatch, getState) => {
  try {
    const { displayName } = getState()?.organization?.selectOrg;
    const i18nData = exportData.growthTool(displayName);

    serverApiAxios.post(`${apiUrl}/campaign/exportCampaign/${campaignId}`, {
      i18n: i18nData,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

const getCampaignChart = (
  campaignId,
  startTime,
  endTime,
  intervalType = 'day',
) => async dispatch => {
  const offset = new Date().toTimeString().match(/[+-]\d\d\d\d/)[0];
  const response = await serverApiAxios.get(
    // eslint-disable-next-line prefer-template
    apiUrl +
      '/campaign/getCampaignChart/' +
      campaignId +
      '?intervalType=' +
      intervalType +
      '&startTime=' +
      startTime.toISOString() +
      '&endTime=' +
      endTime.toISOString() +
      '&offset=' +
      offset,
  );
  dispatch({
    type: actionType.GET_CAMPAIGN_CHART,
    payload: response,
  });
};

const getGrowthToolAddOnSetting = orgId => async dispatch => {
  try {
    const response = await AddOnSettingService.getAddOnSettingCount(
      orgId,
      VendorStore.GROWTH_TOOL_VENDOR_ID,
    );

    dispatch({
      type: actionType.GET_ADDONSETTING_COUNT,
      payload: response,
    });

    return response;
  } catch {
    dispatch({
      type: actionType.GET_ADDONSETTING_COUNT,
      payload: 0,
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: 'get addOnSetting error',
      }),
    );

    return 0;
  }
};

export {
  getGrowthToolAddOnSetting,
  getCampaigns,
  getCampaignByID,
  getCampaignAnalyze,
  getSharerData,
  getCampaignChart,
  createCampaign,
  resetCampaignSelected,
  emptyCreateResult,
  updateCampaign,
  toggleCampaign,
  deleteCampaign,
  getChannelInfo,
  toggleSharerFan,
  exportShareRank,
};
