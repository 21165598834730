import coreAPI, { nextAPI } from '../common/axios';

export default class NotificationCenterService {
  static async getAnnouncement() {
    const query = {
      where: {
        objectId: 'announcement',
      },
      limit: 1,
      _method: 'GET',
    };

    const response = await coreAPI.post('/classes/Announcement', query);
    return response.results;
  }

  static async getSystemMessage(options) {
    const query = {
      sort: {
        _created_at: -1,
      },
      where: {
        message: {
          endAt: {
            __type: 'Date',
            iso: new Date().toISOString(),
          },
        },
        meta: {
          contentType: 'application/x-notify-event',
        },
      },
      limit: 20,
      ...options,
    };

    const response = await coreAPI.post('/functions/findSystemMessage', query);
    return response.result;
  }

  static async getNotificationLastRead() {
    const response = await nextAPI.get('/last_read/notification');
    return response?.lastRead;
  }

  static async resetNotificationLastRead() {
    await nextAPI.post('/last_read/notification/reset');
  }
}
