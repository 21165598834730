import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconLink = ({
  onClick,
  className,
  width,
  id,
  height,
  hoverColor,
  color: iconColor,
}) => {
  const [isHover, setIsHover] = useState(false);
  const random = Math.random();

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
      viewBox="0 0 20 20"
    >
      <mask id={`inboxLinkMask${id}${random}`} maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.557 4.788l-1.354 1.354c-.299.3-.784.3-1.083 0-.299-.299-.299-.784 0-1.083l1.354-1.353c1.607-1.608 4.213-1.608 5.82 0 1.608 1.607 1.608 4.213 0 5.82l-2.067 2.068c-1.587 1.587-4.16 1.587-5.746 0-.3-.299-.3-.784 0-1.083.299-.299.784-.299 1.083 0 .988.989 2.591.989 3.58 0l2.068-2.068c1.009-1.009 1.009-2.645 0-3.655-1.01-1.009-2.646-1.009-3.655 0zM8.443 15.212l.952-.952c.3-.3.784-.3 1.083 0 .3.299.3.784 0 1.083l-.952.951c-1.607 1.608-4.213 1.608-5.82 0-1.608-1.607-1.608-4.213 0-5.82L5.699 8.48c1.628-1.628 4.267-1.628 5.895 0 .3.299.3.784 0 1.083-.299.299-.784.299-1.083 0-1.03-1.03-2.7-1.03-3.73 0l-1.993 1.993c-1.009 1.009-1.009 2.645 0 3.655 1.01 1.009 2.646 1.009 3.655 0z"
          clipRule="evenodd"
        />
      </mask>
      <g mask={`url(#inboxLinkMask${id}${random})`}>
        <path fill={isHover ? hoverColor : iconColor} d="M0 0H20V20H0z" />
      </g>
    </svg>
  );
};

IconLink.defaultProps = {
  width: 20,
  height: 20,
  color: color.$11,
  hoverColor: hexRgba(color.$11, 40),
};

export default IconLink;
