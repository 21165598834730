import classnames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Logo from './assets/images/svg/super-8-logo-color.svg';
import PlatformPNG from './assets/images/png/img-1024@3x.png';
import { color } from './themes';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 44px',
    },
    logo: {
      display: 'block',
      maxWidth: '100%',
      padding: '0 66px',
      marginBottom: 24,
    },
    'logo--mobile': {
      padding: '0 24px',
    },
    description: {
      fontSize: 17,
      color: color.$12,
      padding: '0 66px',
      marginBottom: 60,
    },
    'description--mobile': {
      fontSize: 15,
      lineHeight: '21px',
      padding: '0 24px',
    },
    platform: {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '60vh',
    },
  }),
  {
    name: 'Block',
  },
);

function Block() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isWidthLessThan428 = useMediaQuery('(max-width:428px)');
  return (
    <div className={classes.root}>
      <img
        className={classnames(classes.logo, {
          [classes['logo--mobile']]: isWidthLessThan428,
        })}
        src={Logo}
        alt="Logo"
      />
      <div
        className={classnames(classes.description, {
          [classes['description--mobile']]: isWidthLessThan428,
        })}
      >
        {t('block-description')}
      </div>
      <img className={classes.platform} src={PlatformPNG} alt="super 8" />
    </div>
  );
}

export default Block;
