import { useState, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Modal from '../components/shared/Modal';
import Button from '../components/shared/Button';
import TextField from '../components/shared/TextField';
import { color } from '../themes';
import { emailValid } from '../common/utils';

const useStyles = makeStyles(
  () => ({
    root: {
      width: 'calc(100vw * 2 / 3)',
      maxWidth: 960,
      borderRadius: '12px',
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: '#f9f9f9',
    },
    content: {
      '& > p': {
        fontSize: 15,
        lineHeight: 1,
        marginBottom: 12,
        color: color.$3,
      },
    },
    'cancel-button': {
      marginRight: 8,
    },
  }),
  { name: 'ChangeMailModal' },
);

const ChangeMailModal = ({ isOpen, onChangeEmail, userData, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSavable, setIsSavable] = useState(false);
  const changeMailRef = useRef(null);

  const handlerChange = () => {
    const email = changeMailRef?.current?.value;
    const isVerify = email && emailValid(email);

    setIsSavable(isVerify);
  };

  const handlerChangeEmail = () => {
    const email = changeMailRef?.current?.value.toLowerCase();
    const newUserData = {
      ...userData,
      email,
      userEmail: email,
      username: email,
    };

    if (email) {
      onChangeEmail(newUserData);
      onCancel();
    }
  };

  return (
    <Modal
      open={isOpen}
      paperClass={classes.root}
      title={t('sign-verify.change-mail-title')}
      content={
        <div className={classes.content}>
          <Typography>{t('sign-verify.change-mail-content')}</Typography>
          <TextField
            placeholder={t('sign-verify.change-mail-placeholder')}
            inputRef={changeMailRef}
            onChange={handlerChange}
          />
        </div>
      }
      actions={
        <>
          <Button
            topic="primaryBlue"
            onClick={onCancel}
            style={{ marginRight: 8 }}
          >
            {t('sign-verify.change-mail-cancel')}
          </Button>
          <Button
            topic="primaryBlueHighLight"
            isDisable={!isSavable}
            onClick={handlerChangeEmail}
          >
            {t('sign-verify.change-mail-btn')}
          </Button>
        </>
      }
      handleClose={onCancel}
    />
  );
};

export default ChangeMailModal;
