import moment from 'moment';
import i18n from '../i18n';
import { MetricsClientName, PlatformType } from '../types';

const getLocale = () => {
  return i18n.language || 'zh-TW';
};

const platform = () => ({
  all: i18n.t(`export:broadcast.all`),
  [PlatformType.FACEBOOK]: i18n.t(`export:broadcast.facebook`),
  [PlatformType.LINE]: i18n.t(`export:broadcast.line`),
  [PlatformType.INSTAGRAM]: i18n.t(`export:broadcast.instagram`),
  [PlatformType.WHATSAPP]: i18n.t(`export:broadcast.whatsapp`),
});

const gender = () => ({
  male: i18n.t(`export:customers.male`),
  female: i18n.t(`export:customers.female`),
  other: i18n.t(`export:customers.other`),
});

// 匯出 - 各渠道客戶及訊息量總數
const overviewMessageAnalysis = periodType => {
  return {
    locale: getLocale(),
    fileName: `${i18n.t(`overview:message-analysis.title`)}_${i18n.t(
      `overview:message-analysis.timeZone.${periodType}`,
    )}_${moment().format('YYYYMMDDHHmmss')}`,
    sheets: {},
    fields: {
      date: i18n.t('chat.search.date'),
      message_from_foreign_number: i18n.t(
        'overview:message-analysis.message_from_foreign_number',
      ),
      message_from_broadcast_number: i18n.t(
        'overview:message-analysis.message_from_broadcast_number',
      ),
      message_from_bot_number: i18n.t(
        'overview:message-analysis.message_from_bot_number',
      ),
      message_from_user_number: i18n.t(
        'overview:message-analysis.message_from_user_number',
      ),
      message_from_customer_number: i18n.t(
        'overview:message-analysis.message_from_customer_number',
      ),
    },
  };
};

// 匯出 - 標籤數量增長曲線
const overviewFindTagAccounting = () => {
  return {
    locale: getLocale(),
    fileName: i18n.t('overview:tag.tag-export-FileName', {
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      date: i18n.t('chat.search.date'),
      all: i18n.t('overview:tag.all'),
    },
  };
};

// 匯出 - 訊息量曲線
const overviewCustomerAnalysis = () => {
  return {
    locale: getLocale(),
    fileName: i18n.t('overview:customer-detail.export-FileName', {
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      date: i18n.t('chat.search.date'),
      [MetricsClientName.FACEBOOK_JOIN_TOTAL]: i18n.t(
        'overview:customer-detail.joinTotal',
      ),
      [MetricsClientName.MESSAGE_FACEBOOK_TOTAL]: i18n.t(
        'overview:customer-detail.messageTotal',
      ),
      [MetricsClientName.MESSAGE_FACEBOOK_INCREMENT]: i18n.t(
        'overview:customer-detail.messageIncrement',
      ),
      [MetricsClientName.INSTAGRAM_JOIN_TOTAL]: i18n.t(
        'overview:customer-detail.joinTotal',
      ),
      [MetricsClientName.MESSAGE_INSTAGRAM_TOTAL]: i18n.t(
        'overview:customer-detail.messageTotal',
      ),
      [MetricsClientName.MESSAGE_INSTAGRAM_INCREMENT]: i18n.t(
        'overview:customer-detail.messageIncrement',
      ),
      [MetricsClientName.LINE_JOIN_TOTAL]: i18n.t(
        'overview:customer-detail.joinTotal',
      ),
      [MetricsClientName.LINE_BLOCK_NUMBER]: i18n.t(
        'overview:customer-detail.blockNumber',
      ),
      [MetricsClientName.MESSAGE_LINE_TOTAL]: i18n.t(
        'overview:customer-detail.messageTotal',
      ),
      [MetricsClientName.MESSAGE_LINE_INCREMENT]: i18n.t(
        'overview:customer-detail.messageIncrement',
      ),
      [MetricsClientName.LIVECHAT_JOIN_TOTAL]: i18n.t(
        'overview:customer-detail.joinTotal',
      ),
      [MetricsClientName.MESSAGE_LIVECHAT_TOTAL]: i18n.t(
        'overview:customer-detail.messageTotal',
      ),
      [MetricsClientName.MESSAGE_LIVECHAT_INCREMENT]: i18n.t(
        'overview:customer-detail.messageIncrement',
      ),
      [MetricsClientName.WHATSAPP_JOIN_TOTAL]: i18n.t(
        'overview:customer-detail.joinTotal',
      ),
      [MetricsClientName.MESSAGE_WHATSAPP_TOTAL]: i18n.t(
        'overview:customer-detail.messageTotal',
      ),
      [MetricsClientName.MESSAGE_WHATSAPP_INCREMENT]: i18n.t(
        'overview:customer-detail.messageIncrement',
      ),
    },
    sheets: {
      [PlatformType.FACEBOOK]: i18n.t(`export:broadcast.facebook`),
      [PlatformType.LINE]: i18n.t(`export:broadcast.line`),
      [PlatformType.INSTAGRAM]: i18n.t(`export:broadcast.instagram`),
      [PlatformType.WHATSAPP]: i18n.t(`export:broadcast.whatsapp`),
      [PlatformType.LIVECHAT]: i18n.t(`export:broadcast.livecaht`),
    },
  };
};

// Super Link
const superLink = campaignId => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:superLink.fileName`, {
      campaignId,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      camapaignName: i18n.t(`export:superLink.name`),
      click: i18n.t(`export:superLink.click`),
      oldJoin: i18n.t(`export:superLink.oldJoin`),
      newJoin: i18n.t(`export:superLink.newJoin`),
      conversionRate: i18n.t(`export:superLink.rate`),
      createdAt: i18n.t(`export:superLink.date`),
      channelName: i18n.t(`export:superLink.channelName`),
      link: i18n.t(`export:superLink.link`),
      tag: i18n.t(`export:superLink.tag`),
      active: i18n.t(`export:superLink.active`),
    },
    sheets: {
      main: i18n.t(`export:superLink.main`),
      channel: i18n.t(`export:superLink.channel`),
    },
    content: {
      active: {
        on: i18n.t(`export:superLink.on`),
        off: i18n.t(`export:superLink.off`),
      },
    },
  };
};

// 所有客戶
const customers = orgName => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:customers.fileName`, {
      orgName,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      displayName: i18n.t(`export:customers.displayName`),
      originalDisplayName: i18n.t(`export:customers.originalDisplayName`),
      joinedAt: i18n.t(`export:customers.joinedAt`),
      friendship: i18n.t(`export:customers.friendship`),
      externalTag: i18n.t(`export:customers.externalTag`),
      tags: i18n.t(`export:customers.tag`),
      email: i18n.t(`export:customers.email`),
      cellPhone: i18n.t(`export:customers.cellPhone`),
      gender: i18n.t(`export:customers.gender`),
      birthday: i18n.t(`export:customers.birthday`),
      nation: i18n.t(`export:customers.nation`),
      address: i18n.t(`export:customers.address`),
      location: i18n.t(`export:customers.location`),
      about: i18n.t(`export:customers.about`),
      objectId: i18n.t(`export:customers.objectId`),
      customerId: i18n.t(`export:customers.customerId`),
      customField1: i18n.t(`export:customers.customField1`),
      customField2: i18n.t(`export:customers.customField2`),
      customField3: i18n.t(`export:customers.customField3`),
      hiddenField1: i18n.t(`export:customers.hiddenField1`),
      hiddenField2: i18n.t(`export:customers.hiddenField2`),
      platform: i18n.t(`export:customers.platform`),
      lastUnfollowAt: i18n.t(`export:customers.lastUnfollowAt`),
    },
    sheets: {
      main: i18n.t(`export:customers.fileName`, {
        orgName,
        date: moment().format('YYYYMMDDHHmmss'),
      }),
    },
    content: {
      default: {
        null: i18n.t(`export:null`),
      },
      platform: platform(),
      friendship: {
        unfollow: i18n.t(`export:customers.ne_friendship`),
        follow: i18n.t(`export:customers.eq_friendship`),
      },
      gender: gender(),
    },
  };
};

// 目標客戶群
const customerGroup = orgName => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:customerGroup.fileName`, {
      orgName,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      displayName: i18n.t(`export:customerGroup.displayName`),
      originalDisplayName: i18n.t(`export:customerGroup.originalDisplayName`),
      joinedAt: i18n.t(`export:customerGroup.joinedAt`),
      friendship: i18n.t(`export:customerGroup.friendship`),
      externalTag: i18n.t(`export:customers.externalTag`),
      tags: i18n.t(`export:customerGroup.tag`),
      email: i18n.t(`export:customerGroup.email`),
      cellPhone: i18n.t(`export:customerGroup.cellPhone`),
      gender: i18n.t(`export:customerGroup.gender`),
      birthday: i18n.t(`export:customerGroup.birthday`),
      nation: i18n.t(`export:customerGroup.nation`),
      address: i18n.t(`export:customerGroup.address`),
      location: i18n.t(`export:customerGroup.location`),
      about: i18n.t(`export:customerGroup.about`),
      objectId: i18n.t(`export:customerGroup.objectId`),
      customerId: i18n.t(`export:customerGroup.customerId`),
      customField1: i18n.t(`export:customerGroup.customField1`),
      customField2: i18n.t(`export:customerGroup.customField2`),
      customField3: i18n.t(`export:customerGroup.customField3`),
      hiddenField1: i18n.t(`export:customerGroup.hiddenField1`),
      hiddenField2: i18n.t(`export:customerGroup.hiddenField2`),
      platform: i18n.t(`export:customerGroup.platform`),
      lastUnfollowAt: i18n.t(`export:customers.lastUnfollowAt`),
    },
    sheets: {
      main: i18n.t(`export:customerGroup.fileName`, {
        orgName,
        date: moment().format('YYYYMMDDHHmmss'),
      }),
    },
    content: {
      default: {
        null: i18n.t(`export:null`),
      },
      platform: platform(),
      friendship: {
        unfollow: i18n.t(`export:customers.ne_friendship`),
        follow: i18n.t(`export:customers.eq_friendship`),
      },
      gender: gender(),
    },
  };
};

// 群發
const broadcast = (orgName, startDate, endDate) => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:broadcast.fileName`, {
      orgName,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      createdAt: i18n.t(`export:broadcast.createdAt`),
      name: i18n.t(`export:broadcast.name`),
      sender: i18n.t(`export:broadcast.sender`),
      platforms: i18n.t(`export:broadcast.platforms`),
      messages: i18n.t(`export:broadcast.messages`),
      status: i18n.t(`export:broadcast.status`),
      success: i18n.t(`export:broadcast.success`),
      fail: i18n.t(`export:broadcast.fail`),
      successRate: i18n.t(`export:broadcast.successRate`),
      readRate: i18n.t(`export:broadcast.readRate`),
      clickRate: i18n.t(`export:broadcast.clickRate`),
      customerObjectIds: i18n.t(`export:broadcast.customerObjectIds`),
      customerGroup: i18n.t(`export:broadcast.customerGroup`),
      gender: i18n.t(`export:broadcast.gender`),
      externalTagInclude: i18n.t(`export:broadcast.externalTagInclude`),
      externalTagExclude: i18n.t(`export:broadcast.externalTagExclude`),
      inboxes: i18n.t(`export:broadcast.inboxes`),
      notRead: i18n.t(`export:broadcast.notRead`),
      read: i18n.t(`export:broadcast.read`),
      notClick: i18n.t(`export:broadcast.notClick`),
      click: i18n.t(`export:broadcast.click`),
      pv: i18n.t(`export:broadcast.pv`, { data: '{{ data }}' }),
      uv: i18n.t(`export:broadcast.uv`, { data: '{{ data }}' }),
      repeatClickRate: i18n.t(`export:broadcast.repeatClickRate`, {
        data: '{{ data }}',
      }),
      uniqueClickRate: i18n.t(`export:broadcast.uniqueClickRate`, {
        data: '{{ data }}',
      }),
      shoplineMembershipTier: i18n.t(`export:broadcast.shoplineMembershipTier`),
      shoplinePointBalance: i18n.t(`export:broadcast.shoplinePointBalance`),
      shoplineCreditBalance: i18n.t(`export:broadcast.shoplineCreditBalance`),
      shoplineOrdersTotalSum: i18n.t(`export:broadcast.shoplineOrdersTotalSum`),
      shoplineOrderCount: i18n.t(`export:broadcast.shoplineOrderCount`),
      shoplineTagInclude: i18n.t(`export:broadcast.shoplineTagInclude`),
      shoplineTagExclude: i18n.t(`export:broadcast.shoplineTagExclude`),
      shopifyOrderCount: i18n.t(`export:broadcast.shopifyOrderCount`),
      shopifyOrdersTotalSum: i18n.t(`export:broadcast.shopifyOrdersTotalSum`),
      shopifyTagInclude: i18n.t(`export:broadcast.shopifyTagInclude`),
      shopifyTagExclude: i18n.t(`export:broadcast.shopifyTagExclude`),
      cyberbizMembershipTier: i18n.t(`export:broadcast.cyberbizMembershipTier`),
      cyberbizPointBalance: i18n.t(`export:broadcast.cyberbizPointBalance`),
      cyberbizOrderCount: i18n.t(`export:broadcast.cyberbizOrderCount`),
      cyberbizOrdersTotalSum: i18n.t(`export:broadcast.cyberbizOrdersTotalSum`),
      cyberbizTagInclude: i18n.t(`export:broadcast.cyberbizTagInclude`),
      cyberbizTagExclude: i18n.t(`export:broadcast.cyberbizTagExclude`),
    },
    sheets: {
      main: `${moment(startDate).format('YYYYMMDD')}_${moment(endDate).format(
        'YYYYMMDD',
      )}`,
    },
    content: {
      btnClick: {
        template: i18n.t(`export:broadcast.pv`, { data: '{{ data }}' }),
      },
      btnUVClick: {
        template: i18n.t(`export:broadcast.uv`, { data: '{{ data }}' }),
      },
      btnPVRate: {
        template: i18n.t(`export:broadcast.repeatClickRate`, {
          data: '{{ data }}',
        }),
      },
      btnUVRate: {
        template: i18n.t(`export:broadcast.uniqueClickRate`, {
          data: '{{ data }}',
        }),
      },
      platform: platform(),
      messages: {
        'application/x-audio': i18n.t(`export:broadcast.file`),
        'application/x-file': i18n.t(`export:broadcast.file`),
        'application/x-image': i18n.t(`export:broadcast.file`),
        'application/x-video': i18n.t(`export:broadcast.file`),
        'application/x-template': {
          card: i18n.t(`export:broadcast.card`),
          confirm: i18n.t(`export:broadcast.confirm`),
          list: i18n.t(`export:broadcast.list`),
          image: i18n.t(`export:broadcast.image`),
          imagemap: i18n.t(`export:broadcast.imagemap`),
          quickReply: i18n.t(`export:broadcast.quickReply`),
          shareMessage: i18n.t(`export:broadcast.shareMessage`),
          richvideo: i18n.t(`export:broadcast.richvideo`),
        },
        'text/plain': i18n.t(`export:broadcast.text/plain`),
        'application/x-imagemap': i18n.t(
          `export:broadcast.application/x-imagemap`,
        ),
        'multiple-message': i18n.t(`export:broadcast.multiple-message`),
      },
      status: {
        future: i18n.t(`export:broadcast.future`),
        done: i18n.t(`export:broadcast.gone`),
        past: i18n.t(`export:broadcast.past`),
        pending: i18n.t(`export:broadcast.pending`),
        working: i18n.t(`export:broadcast.working`),
        dispatching: i18n.t(`export:broadcast.dispatching`),
        preparing: i18n.t(`export:broadcast.preparing`),
        scheduled: i18n.t(`export:broadcast.scheduled`),
        stopScheduled: i18n.t(`export:broadcast.stopScheduled`),
        error: i18n.t(`export:broadcast.error`),
        draft: i18n.t(`export:broadcast.draft`),
        started: i18n.t(`export:broadcast.scheduled`),
      },
      inboxes: {
        unassigned: i18n.t(`export:broadcast.unassigned`),
        private: i18n.t(`export:broadcast.private`),
        done: i18n.t(`export:broadcast.done`),
        spam: i18n.t(`export:broadcast.spam`),
        bot: i18n.t(`export:broadcast.bot`),
      },
      gender: gender(),
    },
  };
};

// 輪盤
const roulette = name => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:game.gameFileName`, {
      name,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      awardsName: i18n.t(`export:game.awardsName`),
      winnerTag: i18n.t(`export:game.winnerTag`),
      awardsCount: i18n.t(`export:game.awardsCount`),
      otherCount: i18n.t(`export:game.otherCount`),
      winnerCount: i18n.t(`export:game.winnerCount`),
      displayName: i18n.t(`export:game.displayName`),
      originalDisplayName: i18n.t(`export:game.originalDisplayName`),
      platform: i18n.t(`export:game.platform`),
      customerId: i18n.t(`export:game.customerId`),
      email: i18n.t(`export:game.email`),
      cellPhone: i18n.t(`export:game.cellPhone`),
      couponCode: i18n.t(`export:game.couponCode`),
      winningAt: i18n.t(`export:game.winningAt`),
      winAwardsName: i18n.t(`export:game.winAwardsName`),
      usedAt: i18n.t(`export:game.usedAt`),
    },
    sheets: {
      main: i18n.t(`export:game.mainSheets`),
      unused: i18n.t(`export:game.pending`),
    },
    content: {
      platform: platform(),
    },
  };
};

// 刮刮卡
const scratch = name => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:game.gameFileName`, {
      name,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      awardsName: i18n.t(`export:game.awardsName`),
      winnerTag: i18n.t(`export:game.winnerTag`),
      awardsCount: i18n.t(`export:game.awardsCount`),
      otherCount: i18n.t(`export:game.otherCount`),
      winnerCount: i18n.t(`export:game.winnerCount`),
      displayName: i18n.t(`export:game.displayName`),
      originalDisplayName: i18n.t(`export:game.originalDisplayName`),
      platform: i18n.t(`export:game.platform`),
      customerId: i18n.t(`export:game.customerId`),
      email: i18n.t(`export:game.email`),
      cellPhone: i18n.t(`export:game.cellPhone`),
      couponCode: i18n.t(`export:game.couponCode`),
      winningAt: i18n.t(`export:game.winningAt`),
      winAwardsName: i18n.t(`export:game.winAwardsName`),
      usedAt: i18n.t(`export:game.usedAt`),
    },
    sheets: {
      main: i18n.t(`export:game.mainSheets`),
      unused: i18n.t(`export:game.pending`),
    },
    content: {
      platform: platform(),
    },
  };
};

// 拉霸
const slotMachine = name => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:game.gameFileName`, {
      name,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      awardsName: i18n.t(`export:game.awardsName`),
      winnerTag: i18n.t(`export:game.winnerTag`),
      awardsCount: i18n.t(`export:game.awardsCount`),
      otherCount: i18n.t(`export:game.otherCount`),
      winnerCount: i18n.t(`export:game.winnerCount`),
      displayName: i18n.t(`export:game.displayName`),
      originalDisplayName: i18n.t(`export:game.originalDisplayName`),
      platform: i18n.t(`export:game.platform`),
      customerId: i18n.t(`export:game.customerId`),
      email: i18n.t(`export:game.email`),
      cellPhone: i18n.t(`export:game.cellPhone`),
      couponCode: i18n.t(`export:game.couponCode`),
      winningAt: i18n.t(`export:game.winningAt`),
      winAwardsName: i18n.t(`export:game.winAwardsName`),
      usedAt: i18n.t(`export:game.usedAt`),
    },
    sheets: {
      main: i18n.t(`export:game.mainSheets`),
      unused: i18n.t(`export:game.pending`),
    },
    content: {
      platform: platform(),
    },
  };
};

// 優惠券
const coupon = name => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:game.couponFileName`, {
      name,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      displayName: i18n.t(`export:game.displayName`),
      originalDisplayName: i18n.t(`export:game.originalDisplayName`),
      platform: i18n.t(`export:game.platform`),
      customerId: i18n.t(`export:game.customerId`),
      email: i18n.t(`export:game.email`),
      cellPhone: i18n.t(`export:game.cellPhone`),
      couponCode: i18n.t(`export:game.couponCode`),
      winningAt: i18n.t(`export:game.winningAt`),
      awardsName: i18n.t(`export:game.winAwardsName`),
      usedAt: i18n.t(`export:game.usedAt`),
    },
    sheets: {
      sent: i18n.t(`export:game.used`),
      unused: i18n.t(`export:game.unused`),
    },
    content: {
      platform: platform(),
    },
  };
};

// 發票
const eInvoice = name => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:game.eInvoiceFileName`, {
      name,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      loginedAt: i18n.t(`export:game.loginAt`),
      customerId: i18n.t(`export:game.customerId`),
      displayName: i18n.t(`export:game.displayName`),
      cellPhone: i18n.t(`export:game.cellPhone`),
      birthday: i18n.t(`export:game.birthday`),
      gender: i18n.t(`export:game.gender`),
      invNum: i18n.t(`export:game.invNum`),
      ramdomNum: i18n.t(`export:game.randomNumber`),
      purchaseTime: i18n.t(`export:game.invDate`),
      sellerBan: i18n.t(`export:game.sellerID`),
      sellerName: i18n.t(`export:game.sellerName`),
      itemName: i18n.t(`export:game.description`),
      itemQuantity: i18n.t(`export:game.quantity`),
      itemAmount: i18n.t(`export:game.unitPrice`),
      totalAmount: i18n.t(`export:game.totalAmount`),
      item: i18n.t(`export:game.item`),
      amount: i18n.t(`export:game.amount`),
      channel: i18n.t(`export:game.channel`),
    },
    sheets: {
      itemRanking: i18n.t(`export:game.itemRanking`),
      channelRanking: i18n.t(`export:game.channelRanking`),
    },
    content: {
      gender: gender(),
    },
  };
};

// 定期通知
const recurringMessage = orgName => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:recurringMessage.fileName`, {
      orgName,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      triggered: i18n.t(`export:recurringMessage.triggered`),
      sent: i18n.t(`export:recurringMessage.sent`),
      subscribed: i18n.t(`export:recurringMessage.subscribed`),
      subscribedRate: i18n.t(`export:recurringMessage.subscribedRate`),
      name: i18n.t(`export:recurringMessage.name`),
      frequency: i18n.t(`export:recurringMessage.frequency`),
      btnSubscribed: i18n.t(`export:recurringMessage.btnSubscribed`),
      efficient: i18n.t(`export:recurringMessage.efficient`),
      canceled: i18n.t(`export:recurringMessage.canceled`),
      reachable: i18n.t(`export:recurringMessage.reachable`),
    },
    sheets: {
      main: i18n.t(`export:recurringMessage.mainSheets`),
      theme: i18n.t(`export:recurringMessage.theme`),
    },
    content: {
      triggered: {
        template: i18n.t(`export:unit.template1`, { data: '{{ data }}' }),
      },
      sent: {
        template: i18n.t(`export:unit.template2`, { data: '{{ data }}' }),
      },
      subscribed: {
        template: i18n.t(`export:unit.template1`, { data: '{{ data }}' }),
      },
      subscribedRate: {
        template: i18n.t(`export:unit.template3`, { data: '{{ data }}' }),
      },
      btnSubscribed: {
        template: i18n.t(`export:unit.template1`, { data: '{{ data }}' }),
      },
      efficient: {
        template: i18n.t(`export:unit.template1`, { data: '{{ data }}' }),
      },
      canceled: {
        template: i18n.t(`export:unit.template1`, { data: '{{ data }}' }),
      },
      reachable: {
        template: i18n.t(`export:unit.template1`, { data: '{{ data }}' }),
      },
      frequency: {
        DAILY: i18n.t(`export:recurringMessage.DAILY`),
        WEEKLY: i18n.t(`export:recurringMessage.WEEKLY`),
        MONTHLY: i18n.t(`export:recurringMessage.MONTHLY`),
      },
    },
  };
};

// 增粉
const growthTool = orgName => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:growthTool.fileName`, {
      orgName,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      campaignId: i18n.t(`export:growthTool.campaignId`),
      customerId: i18n.t(`export:growthTool.customerId`),
      displayName: i18n.t(`export:growthTool.displayName`),
      shareCount: i18n.t(`export:growthTool.shareCount`),
      inviteFriendCount: i18n.t(`export:growthTool.inviteFriendCount`),
      isGetGift: i18n.t(`export:growthTool.isGetGift`),
      isFans: i18n.t(`export:growthTool.isFans`),
      createdAt: i18n.t(`export:growthTool.createdAt`),
      sharerId: i18n.t(`export:growthTool.sharerId`),
    },
    sheets: {
      sharer: i18n.t(`export:growthTool.sharer`),
      participant: i18n.t(`export:growthTool.participant`),
    },
    content: {
      isGetGift: {
        yes: i18n.t(`export:yes`),
        no: i18n.t(`export:no`),
      },
      isFans: {
        yes: i18n.t(`export:yes`),
        no: i18n.t(`export:no`),
      },
    },
  };
};

// 再行銷
const retargeting = campainName => {
  return {
    locale: getLocale(),
    fileName: i18n.t(`export:retargeting.fileName`, {
      campainName,
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      triggerCustomer: i18n.t(`export:retargeting.triggerCustomer`),
      messageCount: i18n.t(`export:retargeting.messageCount`),
      orderCount: i18n.t(`export:retargeting.orderCount`),
      revenue: i18n.t(`export:retargeting.revenue`),
      openRate: i18n.t(`export:retargeting.openRate`),
      unopenRate: i18n.t(`export:retargeting.unopenRate`),
      openClickRate: i18n.t(`export:retargeting.openClickRate`),
      openUnclickRate: i18n.t(`export:retargeting.openUnclickRate`),
      openCheckoutRate: i18n.t(`export:retargeting.openCheckoutRate`),
      openUncheckoutRate: i18n.t(`export:retargeting.openUncheckoutRate`),
      displayName: i18n.t(`export:retargeting.displayName`),
      customerId: i18n.t(`export:retargeting.customerId`),
      platform: i18n.t(`export:retargeting.platform`),
      email: i18n.t(`export:retargeting.email`),
      cellPhone: i18n.t(`export:retargeting.cellPhone`),
      gender: i18n.t(`export:retargeting.gender`),
      orderTotalAmount: i18n.t(`export:retargeting.orderTotalAmount`),
      orderDetail: i18n.t(`export:retargeting.orderDetail`),
      orderCreatedAt: i18n.t(`export:retargeting.orderCreatedAt`),
      itemId: i18n.t(`export:retargeting.itemId`),
      itemName: i18n.t(`export:retargeting.itemName`),
      itemPrice: i18n.t(`export:retargeting.itemPrice`),
      itemQuantity: i18n.t(`export:retargeting.itemQuantity`),
    },
    sheets: {
      main: i18n.t(`export:retargeting.mainSheets`),
      orderDetail: i18n.t(`export:retargeting.orderDetailSheets`),
    },
    content: {
      platform: platform(),
      gender: gender(),
    },
  };
};

const customerServiceData = fileName => {
  return {
    locale: getLocale(),
    fileName,
    sheets: {
      caseStatistics: i18n.t(`export:customerServiceData.caseStatistics`),
      caseEffect: i18n.t(`export:customerServiceData.caseEffect`),
      userEffect: i18n.t(`export:customerServiceData.userEffect`),
      caseSummary: i18n.t(`export:customerServiceData.caseSummary`),
      caseCount: i18n.t(`export:customerServiceData.caseCount`),
      caseAnalysis: i18n.t(`export:customerServiceData.caseAnalysis`),
      caseHeatmapCreate: i18n.t(`export:customerServiceData.caseHeatmapCreate`),
      caseHeatmapStopWait: i18n.t(
        `export:customerServiceData.caseHeatmapStopWait`,
      ),
      caseHeatmapSentMessage: i18n.t(
        `export:customerServiceData.caseHeatmapSentMessage`,
      ),
    },
    fields: {
      date: i18n.t(`export:customerServiceData.date`),
      weekdays: {
        monday: i18n.t(`export:customerServiceData.monday`),
        tuesday: i18n.t(`export:customerServiceData.tuesday`),
        wednesday: i18n.t(`export:customerServiceData.wednesday`),
        thursday: i18n.t(`export:customerServiceData.thursday`),
        friday: i18n.t(`export:customerServiceData.friday`),
        saturday: i18n.t(`export:customerServiceData.saturday`),
        sunday: i18n.t(`export:customerServiceData.sunday`),
      },
      waitTime: i18n.t(`export:customerServiceData.waitTime`),
      processTime: i18n.t(`export:customerServiceData.processTime`),
      doneRate: i18n.t(`export:customerServiceData.doneRate`),
      sentMessage: i18n.t(`export:customerServiceData.sentMessage`),
      selectRange: i18n.t(`export:customerServiceData.selectRange`),
      currentRange: i18n.t(`export:customerServiceData.currentRange`),
      previosRange: i18n.t(`export:customerServiceData.previosRange`),
      sort: i18n.t(`export:customerServiceData.sort`),
      userName: i18n.t(`export:customerServiceData.userName`),
      serviceCustomers: i18n.t(`export:customerServiceData.serviceCustomers`),
      assign: i18n.t(`export:customerServiceData.assign`),
      accUndone: i18n.t(`export:customerServiceData.accUndone`),
      platform: i18n.t(`export:customerServiceData.platform`),
      totalProcessTime: i18n.t(`export:customerServiceData.totalProcessTime`),
      avgWaitingTime: i18n.t(`export:customerServiceData.avgWaitingTime`),
      avgProcessTime: i18n.t(`export:customerServiceData.avgProcessTime`),
      avgMessageSent: i18n.t(`export:customerServiceData.avgMessageSent`),
      avgWaitTime: i18n.t(`export:customerServiceData.avgWaitTime`),
      avgDoneRate: i18n.t(`export:customerServiceData.avgDoneRate`),
      avgSentMessage: i18n.t(`export:customerServiceData.avgSentMessage`),
      done: i18n.t(`export:customerServiceData.done`),
      undone: i18n.t(`export:customerServiceData.undone`),
      unassigned: i18n.t(`export:customerServiceData.unassigned`),
      caseTypeAssigned: i18n.t(`export:customerServiceData.caseTypeAssigned`),
      caseTypeUnassigned: i18n.t(
        `export:customerServiceData.caseTypeUnassigned`,
      ),
      total: i18n.t(`export:customerServiceData.total`),
      average: i18n.t(`export:customerServiceData.average`),
      caseNo: i18n.t(`export:customerServiceData.caseNo`),
      customerName: i18n.t(`export:customerServiceData.customerName`),
      caseStatus: i18n.t(`export:customerServiceData.caseStatus`),
      assignedAt: i18n.t(`export:customerServiceData.assignedAt`),
      assignAt: i18n.t(`export:customerServiceData.assignAt`),
      doneAt: i18n.t(`export:customerServiceData.doneAt`),
      firstResponseAt: i18n.t(`export:customerServiceData.firstResponseAt`),
      createdAt: i18n.t(`export:customerServiceData.createdAt`),
      totalCaseCount: i18n.t(`export:customerServiceData.totalCaseCount`),
      period: {
        previos: {
          day: i18n.t(`export:customerServiceData.period.previos.day`),
          lastWeek: i18n.t(
            `export:customerServiceData.period.previos.lastWeek`,
          ),
          week: i18n.t(`export:customerServiceData.period.previos.week`),
          lastMonth: i18n.t(
            `export:customerServiceData.period.previos.lastMonth`,
          ),
          month: i18n.t(`export:customerServiceData.period.previos.month`),
          custom: i18n.t(`export:customerServiceData.period.previos.custom`),
        },
        current: {
          day: i18n.t(`export:customerServiceData.period.current.day`),
          lastWeek: i18n.t(
            `export:customerServiceData.period.current.lastWeek`,
          ),
          week: i18n.t(`export:customerServiceData.period.current.week`),
          lastMonth: i18n.t(
            `export:customerServiceData.period.current.lastMonth`,
          ),
          month: i18n.t(`export:customerServiceData.period.current.month`),
          custom: i18n.t(`export:customerServiceData.period.current.custom`),
        },
      },
      processing: i18n.t(`export:customerServiceData.processing`),
      finished: i18n.t(`export:customerServiceData.finished`),
      createdBy: i18n.t(`export:customerServiceData.createdBy`),
      assigner: i18n.t(`export:customerServiceData.assigner`),
      user: {
        bot: i18n.t(`organization-tabs.message-bot.bot-theme`),
      },
    },
  };
};

// S-point 使用與儲值紀錄
const aiBotHistory = fileName => {
  return {
    locale: getLocale(),
    fileName,
    sheets: {
      usage: i18n.t(`aiBot:pointHistoryModal.usageSheet`),
      add: i18n.t(`aiBot:pointHistoryModal.addSheet`),
    },
    fields: {
      range: i18n.t(`aiBot:pointHistoryModal.range`),
      date: i18n.t(`aiBot:pointHistoryModal.useHistory`),
      add: i18n.t(`aiBot:pointHistoryModal.add`),
      aibot: i18n.t(`aiBot:pointHistoryModal.aibot`),
      super8ai: i18n.t(`aiBot:pointHistoryModal.super8ai`),
      autoReply: i18n.t(`aiBot:pointHistoryModal.autoReply`),
      autoSummary: i18n.t(`aiBot:pointHistoryModal.autoSummary`),
      total: i18n.t(`aiBot:pointHistoryModal.totalUseByDay`),
    },
  };
};

// 匯出 - shop8 order
const shop8Order = () => {
  return {
    locale: getLocale(),
    fileName: i18n.t('chatPay:exportOrder.fileName', {
      date: moment().format('YYYYMMDDHHmmss'),
    }),
    fields: {
      number: i18n.t('chatPay:exportOrder.number'),
      date: i18n.t('chatPay:exportOrder.date'),
      saleUserName: i18n.t('chatPay:exportOrder.saleUserName'),
      paymentStatus: i18n.t('chatPay:exportOrder.paymentStatus'),
      shippingType: i18n.t('chatPay:exportOrder.shippingType'),
      customerName: i18n.t('chatPay:exportOrder.customerName'),
      customerPhone: i18n.t('chatPay:exportOrder.customerPhone'),
      receiverName: i18n.t('chatPay:exportOrder.receiverName'),
      receiverPhone: i18n.t('chatPay:exportOrder.receiverPhone'),
      receiverAddress: i18n.t('chatPay:exportOrder.receiverAddress'),
      receiverRemark: i18n.t('chatPay:exportOrder.receiverRemark'),
      productName: i18n.t('chatPay:exportOrder.productName'),
      brandName: i18n.t('chatPay:exportOrder.brandName'),
      productOption1: i18n.t('chatPay:exportOrder.productOption1'),
      productOption2: i18n.t('chatPay:exportOrder.productOption2'),
      price: i18n.t('chatPay:exportOrder.price'),
      count: i18n.t('chatPay:exportOrder.count'),
      totalPrice: i18n.t('chatPay:exportOrder.totalPrice'),
      discountPrice: i18n.t('chatPay:exportOrder.discountPrice'),
      shippingFee: i18n.t('chatPay:exportOrder.shippingFee'),
      orderPrice: i18n.t('chatPay:exportOrder.orderPrice'),
      orderNote: i18n.t('chatPay:exportOrder.orderNote'),
    },
  };
};

export default {
  aiBotHistory,
  retargeting,
  growthTool,
  recurringMessage,
  eInvoice,
  coupon,
  slotMachine,
  scratch,
  roulette,
  broadcast,
  customerGroup,
  customers,
  superLink,
  overviewFindTagAccounting,
  overviewCustomerAnalysis,
  overviewMessageAnalysis,
  customerServiceData,
  shop8Order,
};
