import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconFilterOpen = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill={isHover ? hexRgba(color.$2, 40) : color.$2}>
        <path d="M17.267 3c.29 0 .508.145.654.436.145.218.072.509-.073.727l-5.344 6.303c.601-.298 1.28-.466 1.996-.466 2.485 0 4.5 2.015 4.5 4.5S16.985 19 14.5 19c-1.372 0-2.6-.614-3.426-1.583l-2.818-1.41c-.29-.145-.436-.363-.436-.653v-4.506L2.152 4.163c-.145-.218-.218-.509-.073-.727.146-.29.364-.436.654-.436zm-1.393 9.71l-1.95 2.13-.84-.761c-.257-.234-.66-.223-.904.023l-.002.865.026.025 1.322 1.199c.264.24.681.22.922-.042l2.39-2.609c.236-.258.212-.653-.054-.882-.266-.23-.674-.206-.91.052zm-.133-8.257H4.33l4.797 5.741c.073.073.145.29.145.436v4.288l.801.4c-.049-.265-.074-.538-.074-.818 0-.904.267-1.746.726-2.452V10.63c0-.218.073-.363.146-.509l4.869-5.668z" />{' '}
      </g>
    </svg>
  );
};

export default IconFilterOpen;
