import actionType, {
  SET_FACEBOOK_DATA,
  SET_INSTAGRAM_DATA,
  ORGANIZATION_RESET,
  ORGANIZATION_ADD,
  ORGANIZATION_REMOVE,
  ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS,
} from '../actions/type';

const INITIAL_STATE = {
  isLoading: false,
  isFetchLoading: false,
  orgData: [],
  selectOrg: null,
  orgMembers: [],
  isExporting: false,
  errorMessage: null,
  integrations: [],
  // 判斷是否取得過integrations，防止角色權限判斷異常
  isInitedIntegrations: false,
  fbData: {
    auth: null,
    profile: null,
    fanPageList: [],
    selectedFanPageIndex: 0,
  },

  igData: {
    auth: null,
    profile: null,
    fanPageList: [],
    selectedFanPageIndex: -1,
  },
  orgAnnouncements: null,
  decreaseHeight: 0,
  isAnnouncementOpen: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_ORGANIZATION:
      return { ...state, ...action.payload };
    case actionType.FETCH_ORGANIZATION:
      return { ...state, orgData: action.payload };
    case actionType.FETCH_OWN_ORGANIZATION:
      return { ...state, orgData: action.payload };
    case actionType.FETCH_ORGANIZATION_LOADING:
      return {
        ...state,
        isFetchLoading: action.payload,
      };
    case actionType.SELECT_ORGANIZATION:
      // eslint-disable-next-line no-console
      // console.log('SELECT_ORGANIZATION');
      return {
        ...state,
        selectOrg: action.payload,
        isInitedIntegrations: false,
      };
    case actionType.GET_ORGANIZATION_MEMBERS:
      return { ...state, orgMembers: action.payload };
    case actionType.EXPORT_CUSTOMERS_REQUEST:
      return {
        ...state,
        isExporting: true,
        errorMessage: null,
      };
    case actionType.EXPORT_CUSTOMERS_FAILURE:
      return {
        ...state,
        isExporting: false,
        errorMessage: action.errorMessage,
      };
    case actionType.EXPORT_CUSTOMERS_SUCCESS:
    case actionType.EXPORT_CUSTOMERS_STOP:
      return {
        ...state,
        isExporting: false,
      };
    case actionType.GET_INTEGRATION_REQUEST:
    case actionType.GET_INTEGRATION_FAILURE:
      return {
        ...state,
        integrations: [],
      };
    case actionType.GET_INTEGRATION_SUCCESS:
      // eslint-disable-next-line no-console
      // console.log('GET_INTEGRATION_SUCCESS');
      return {
        ...state,
        integrations: action.data,
        isInitedIntegrations: true,
      };
    case actionType.CREATE_INTEGRATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.CREATE_INTEGRATION_SUCCESS:
    case actionType.CREATE_INTEGRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionType.DELETE_INTEGRATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.DELETE_INTEGRATION_SUCCESS:
    case actionType.DELETE_INTEGRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actionType.SET_NOTHING:
      return { ...state };
    case SET_FACEBOOK_DATA:
      return {
        ...state,
        fbData: {
          auth: action.auth,
          profile: action.profile,
          fanPageList: action.fanPageList,
          selectedFanPageIndex: 0,
        },
      };
    case SET_INSTAGRAM_DATA:
      return {
        ...state,
        igData: {
          auth: action.auth,
          profile: action.profile,
          fanPageList: action.fanPageList,
          selectedFanPageIndex: action.fanPageList.findIndex(d => d.name),
        },
      };

    case actionType.CLEAR_FACEBOOK_DATA:
      return {
        ...state,
        fbData: {
          auth: null,
          profile: null,
          fanPageList: [],
          selectedFanPageIndex: 0,
        },
      };
    case actionType.SELECT_FAN_PAGE:
      return {
        ...state,
        fbData: {
          ...state.fbData,
          selectedFanPageIndex: action.fanPageIndex,
        },
      };
    case actionType.SELECT_IG_FAN_PAGE:
      return {
        ...state,
        igData: {
          ...state.igData,
          selectedFanPageIndex: action.fanPageIndex,
        },
      };
    case ORGANIZATION_RESET:
      return INITIAL_STATE;
    case ORGANIZATION_ADD:
      return {
        ...state,
        orgData: [...state.orgData, action.payload],
      };
    case ORGANIZATION_REMOVE:
      return {
        ...state,
        orgData: state.orgData.filter(org => org.objectId !== action.payload),
        selectOrg: null,
      };
    case actionType.SET_ORG_ANNOUNCEMENT:
      return {
        ...state,
        orgAnnouncements: action.payload,
        decreaseHeight:
          action.payload?.nailedAnnouncement && action.payload?.announcement
            ? 50
            : action.payload?.nailedAnnouncement || action.payload?.announcement
            ? 24
            : 0,
      };
    case ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS:
      return {
        ...state,
        isAnnouncementOpen: action.payload,
      };
    default:
      return state;
  }
};
