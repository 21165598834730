import actionType from './type';
import SuperMarketService from '../services/SuperMarketService';
import { enqueueSnackbar } from './snackbarAction';
import { SnackBarType } from '../types';
import i18n from '../i18n';

const setType = date => dispatch => {
  dispatch({ type: actionType.SET_SUPERMARKET_TYPE, payload: date });
};

const setTabIndex = date => dispatch => {
  dispatch({ type: actionType.SET_SUPERMARKET_TABINDEX, payload: date });
};

const resetSuperMarket = date => dispatch => {
  dispatch({ type: actionType.RESET_SUPERMARKET, payload: date });
};

const checkService = (
  orgId,
  serviceId,
  isShowSnackbar = true,
) => async dispatch => {
  let res = false;
  const response = await SuperMarketService.getService(orgId, serviceId);
  if (
    response &&
    response.length > 0 &&
    (response[0].available || response[0].isUAT)
  ) {
    res = true;
  } else {
    res = false;
    if (isShowSnackbar) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('superMarket:noPermission'),
        }),
      );
    }
  }

  return res;
};

export { setType, setTabIndex, resetSuperMarket, checkService };
