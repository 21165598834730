import hexRgba from 'hex-rgba';
import { useState } from 'react';
import { color } from '../../../themes';

const IconFollowup = ({ ...props }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.2148 2.5L16.5809 5.73361L13.3343 5.5106"
        stroke={isHover ? hexRgba(color.$11, 40) : color.$11}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5175 3.4736C12.8263 3.77817 14.0133 4.46926 14.9242 5.4571C15.2238 5.7819 15.7299 5.80238 16.0547 5.50285C16.3795 5.20333 16.4 4.69721 16.1005 4.37241C14.9719 3.14867 13.5015 2.29255 11.8802 1.91524C10.2588 1.53793 8.56151 1.65686 7.00864 2.25659C5.45578 2.85631 4.1191 3.90909 3.17225 5.27826L3.17285 5.27867C3.17276 5.27879 3.17268 5.27891 3.1726 5.27903C2.22576 6.64819 1.7125 8.27042 1.69947 9.93503C1.68645 11.5996 2.17426 13.2297 3.09956 14.6135C4.02486 15.9973 5.34488 17.0709 6.88818 17.6949C8.43148 18.3188 10.1267 18.4643 11.7538 18.1124C13.3808 17.7605 14.8645 16.9275 16.012 15.7216C16.043 15.689 16.0707 15.6545 16.0951 15.6184C16.5098 15.171 16.876 14.6769 17.1855 14.1438C18.0212 12.7041 18.4047 11.0464 18.2862 9.386C18.2548 8.94529 17.872 8.61352 17.4313 8.64497C16.9906 8.67642 16.6588 9.05918 16.6903 9.49989C16.7859 10.8402 16.4763 12.1784 15.8017 13.3405C15.1271 14.5027 14.1186 15.4351 12.9072 16.0167C11.6958 16.5983 10.3376 16.8023 9.00882 16.602C7.68007 16.4018 6.44223 15.8066 5.45607 14.8938C4.46992 13.981 3.78102 12.7928 3.47886 11.4834C3.46855 11.4388 3.45469 11.3958 3.43765 11.3549C3.34254 10.8943 3.29571 10.4227 3.29942 9.94755C3.30994 8.60383 3.72426 7.29431 4.48857 6.18909C4.49476 6.18014 4.50074 6.17112 4.50651 6.16201C5.2697 5.06962 6.3415 4.22942 7.58508 3.74914C8.8386 3.26502 10.2087 3.16902 11.5175 3.4736Z"
        fill={isHover ? hexRgba(color.$11, 40) : color.$11}
      />
      <circle
        cx="9.9987"
        cy="10.0007"
        r="4.16667"
        fill="white"
        stroke={isHover ? hexRgba(color.$11, 40) : color.$11}
        strokeWidth="1.8"
      />
      <path
        d="M10 8.75V10.4167H11.6667"
        stroke={isHover ? hexRgba(color.$11, 40) : color.$11}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFollowup;
