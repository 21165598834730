import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconBell = ({ iconColor, width, height, mute, onClick }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      {mute ? (
        <svg
          onClick={onClick}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
        >
          <defs>
            <path
              id="a"
              d="M14.127 19.773A2.466 2.466 0 0 1 12 21c-.41 0-.818-.082-1.227-.327a2.377 2.377 0 0 1-.9-.9c-.246-.41-.082-.9.327-1.146.41-.245.9-.082 1.145.328.082.081.164.245.328.327.409.245.9.082 1.145-.327.246-.41.737-.491 1.146-.328.409.164.409.737.163 1.146zM12 3c3.6 0 6.545 2.945 6.545 6.545v4.091c0 .9.737 1.637 1.637 1.637.49 0 .818.327.818.818 0 .49-.327.818-.818.818H3.818c-.49 0-.818-.327-.818-.818 0-.491.327-.818.818-.818.9 0 1.637-.737 1.637-1.637v-4.09C5.455 5.945 8.4 3 12 3zm0 1.636c-2.7 0-4.91 2.21-4.91 4.91v4.09c0 .573-.163 1.146-.408 1.637h10.636a3.724 3.724 0 0 1-.409-1.637v-4.09c0-2.7-2.209-4.91-4.909-4.91zm-1.11 3.648l1.13 1.13 1.128-1.13a.8.8 0 0 1 1.132 1.132l-1.129 1.129 1.134 1.133a.8.8 0 0 1-1.132 1.132l-1.133-1.134-1.134 1.134a.8.8 0 0 1-1.132-1.131l1.134-1.134-1.129-1.13a.8.8 0 0 1 1.132-1.13z"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <g
              fill={
                iconColor === color.$12
                  ? isHover
                    ? hexRgba(iconColor, 40)
                    : iconColor
                  : iconColor
              }
              mask="url(#b)"
            >
              <path d="M0 0h24v24H0z" />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          onClick={onClick}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <defs>
            <path
              id="a"
              d="M14.127 19.773A2.466 2.466 0 0 1 12 21c-.41 0-.818-.082-1.227-.327a2.377 2.377 0 0 1-.9-.9c-.246-.41-.082-.9.327-1.146.41-.245.9-.082 1.145.328.082.081.164.245.328.327.409.245.9.082 1.145-.327.246-.41.737-.491 1.146-.328.409.164.409.737.163 1.146zM21 16.09c0 .49-.327.818-.818.818H3.818c-.49 0-.818-.327-.818-.818 0-.491.327-.818.818-.818.9 0 1.637-.737 1.637-1.637v-4.09C5.455 5.945 8.4 3 12 3s6.545 2.945 6.545 6.545v4.091c0 .9.737 1.637 1.637 1.637.49 0 .818.327.818.818zm-3.682-.818a3.724 3.724 0 0 1-.409-1.637v-4.09c0-2.7-2.209-4.91-4.909-4.91-2.7 0-4.91 2.21-4.91 4.91v4.09c0 .573-.163 1.146-.408 1.637h10.636z"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <g
              fill={isHover ? hexRgba(iconColor, 40) : iconColor}
              mask="url(#b)"
            >
              <path d="M0 0h24v24H0z" />
            </g>
          </g>
        </svg>
      )}
    </>
  );
};

IconBell.defaultProps = {
  iconColor: color.$12,
  width: 24,
  height: 24,
};

export default IconBell;
