import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { findIsBuyPartnerTag } from './actions/allCustomersAction';
import {
  fetchOrganization,
  getIntegration,
  getOrgAnnouncement,
  getOrgOwnData,
  selectOrganization,
  setOrgAnnouncement,
} from './actions/organizationAction';
import { getPreference } from './actions/preferenceAction';
import {
  fetchUser,
  getPermission,
  getUserOrgFeature,
  inviteOrganization,
  resetUserOrgFeature,
  setInviteCodeByUrl,
} from './actions/userAction';
import config from './config';
import { AuthorityType } from './types';

const routeRule = {
  noIntegrations: {
    defaultPage: 'organization-setting',
    allowPages: [
      'superMarket',
      'account-setting',
      'add-organization',
      'organization-setting',
      'changePassword',
      'invite-fail',
      'change-password-fail',
      'signup-verify',
      'chatPay',
    ],
  },
  orgNormalUser: {
    defaultPage: 'message-center',
    allowPages: [
      'overview',
      'message-center',
      'superMarket',
      'account-setting',
      'add-organization',
      'organization-setting',
      'changePassword',
      'invite-fail',
      'change-password-fail',
      'signup-verify',
      'chatPay',
    ],
  },
  orgAdmin: {
    defaultPage: 'overview',
    allowPages: [
      'overview',
      'super-link',
      'customer-management',
      'broadcast',
      'superMarket',
      'message-center',
      'message-bot',
      'marketing-automation',
      'webview-editor',
      'organization-setting',
      'account-setting',
      'add-organization',
      'changePassword',
      'invite-fail',
      'change-password-fail',
      'signup-verify',
      'chatPay',
    ],
  },
};

const newMapPage = {
  overview: 'overview',
  superLink: 'super-link',
  msgCenter: 'message-center',
  messageBot: 'message-bot',
  customerMgmt: 'customer-management',
  broadcast: 'broadcast',
  superMarket: 'superMarket',
  webviewEditor: 'webview-editor',
  orgSetting: 'organization-setting',
};

const namesMap = {
  overview: 'overview',
  'super-link': 'superLink',
  'message-center': 'msgCenter',
  'message-bot': 'messageBot',
  'marketing-automation': 'marketingAutomation',
  'customer-management': 'customerMgmt',
  broadcast: 'broadcast',
  superMarket: 'superMarketSetting',
  'webview-editor': 'webviewEditor',
  chatPay: 'chatpay',
  'organization-setting': 'orgSetting',
};

const getAuthNames = (needAuthNames, vpcSetting) => {
  const results = [];
  needAuthNames.map(name => {
    const authName = namesMap[name];
    if (vpcSetting && vpcSetting.data[authName]?.enabled) {
      results.push(name);
    }
    return null;
  });
  return results;
};

function getRule(authorityName, integrations, isInitedIntegrations) {
  const isNotLinkIntegration =
    isInitedIntegrations && !(integrations || []).length;
  if (isNotLinkIntegration) {
    return routeRule.noIntegrations;
  }
  if (authorityName === AuthorityType.ADMIN) {
    return routeRule.orgAdmin;
  }

  return routeRule.orgNormalUser;
}

function filterRule(
  authorityName,
  integrations,
  isInitedIntegrations,
  vpcSetting,
) {
  const result = getRule(authorityName, integrations, isInitedIntegrations);
  const { allowPages } = result;
  let finalNames = getAuthNames(allowPages, vpcSetting);
  finalNames = finalNames.concat([
    'account-setting',
    'add-organization',
    'changePassword',
    'invite-fail',
    'change-password-fail',
    'signup-verify',
  ]);
  let { defaultPage } = result;
  if (finalNames.indexOf(result.defaultPage) === -1) {
    defaultPage = finalNames[0];
  }
  return {
    defaultPage,
    allowPages: finalNames,
  };
}

let orgDataLength = 0;

let isInit = false;
function AuthorizeLayer({ children }) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgData = useSelector(store => store.organization.orgData);
  const selectOrg = useSelector(store => store.organization.selectOrg);
  const integrations = useSelector(store => store.organization.integrations);
  const vpcSetting = useSelector(store => store.vpcSetting);
  const isInitedIntegrations = useSelector(
    store => store.organization.isInitedIntegrations,
  );
  const { orgOwnData, userData } = useSelector(store => store.auth);
  const { authorityName } = orgOwnData || {};
  const feature = useSelector(store => store.auth.feature);

  const features = selectOrg?.features;

  const hideOrgAnnouencements = !features?.orgAnnouncements?.enable;

  const getOrgIdFromUrl = () => {
    const tokens = pathname.split('/');
    const page = tokens[1];
    let orgId = tokens[2];

    if (
      page === 'account-setting' ||
      page === 'add-organization' ||
      page === 'changePassword' ||
      page === 'invite-fail' ||
      page === 'change-password-fail' ||
      page === 'signup-verify'
    ) {
      orgId = null;
    }
    return orgId;
  };

  useEffect(() => {
    function initIntercom(user, org) {
      if (window.Intercom && user && org) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          email: user?.email,
          user_id: user?.objectId,
          created_at: user?.createdAt,
          name: `${user?.firstName} ${user?.lastName}`,
          phone: user?.phone,
          avatar: {
            type: 'avatar',
            image_url: user?.picture,
          },
          company: {
            company_id: org?.objectId,
            created_at: org?.createdAt,
            name: org?.displayName,
            plan: org?.perm?.plan,
          },
        });
      }
    }
    async function detail(localOrgData) {
      let org = null;
      const tokens = pathname.split('/');
      const page = tokens[1];
      const orgId = getOrgIdFromUrl();

      if (!selectOrg || (selectOrg.objectId !== orgId && orgId)) {
        org = await dispatch(selectOrganization(localOrgData, orgId));
      } else {
        org = selectOrg;
      }

      if (org) {
        const rule = filterRule(
          authorityName,
          integrations,
          isInitedIntegrations,
          vpcSetting,
        );

        if (authorityName && rule) {
          if (pathname === '/' || (orgId !== null && orgId !== org?.objectId)) {
            navigate(`/${rule.defaultPage}/${org.objectId}`, { replace: true });
          } else if (page && page !== '') {
            if (!~rule.allowPages.indexOf(page)) {
              navigate(`/${rule.defaultPage}/${org.objectId}`, {
                replace: true,
              });
            }
          }
        }
      }
    }
    async function directTo(currentUser) {
      if (orgData.length === 0) {
        await dispatch(inviteOrganization(currentUser));
        let orgs;
        if (currentUser?.email === 'sales@no8.io') {
          const orgId = getOrgIdFromUrl();
          // eslint-disable-next-line no-console
          // console.log('directTo superAccount fetchOrganization');
          orgs = await dispatch(fetchOrganization(currentUser.objectId, orgId));
        } else {
          orgs = await dispatch(fetchOrganization(currentUser.objectId));
        }

        if (!orgs) {
          return;
        }
        if (orgs.length === 0 && pathname.indexOf('/account-setting') === -1) {
          navigate('/add-organization');
        }
      } else if (orgDataLength === orgData.length && vpcSetting.isInitVpc) {
        await detail(orgData, currentUser);
      } else {
        orgDataLength = orgData.length;
      }
    }
    async function checkUser() {
      const excludePath = [
        '/login',
        '/signup-verify',
        '/signup',
        '/signup-content',
        '/forget',
        '/resetPassword',
        '/invite-fail',
        '/change-password-fail',
        '/changePassword',
      ];

      const isMatchExcludePath = excludePath.some(
        path => !!~pathname.indexOf(path),
      );

      // 因改版暫存不相容暫時都先重新拿取user資料
      const currentUser = await dispatch(fetchUser());
      if (pathname !== '/login') await dispatch(setInviteCodeByUrl(search));

      initIntercom(currentUser, selectOrg);

      if (currentUser) {
        if (currentUser?.emailVerified) {
          if (localStorage.getItem(config.EC_REFERRAL) && !isMatchExcludePath) {
            localStorage.removeItem(config.LOCAL_STORAGE);
            navigate('/login', { replace: true });
          } else {
            directTo(currentUser);
          }
        } else {
          navigate('/signup-verify', { replace: true });
        }
      } else if (isMatchExcludePath) {
        navigate(pathname + search, { replace: true });
      } else {
        navigate('/login', { replace: true });
        if (
          navigator.userAgent.includes('iPhone') ||
          navigator.userAgent.includes('Android')
        ) {
          window.location.reload();
        }
      }
    }
    // eslint-disable-next-line no-console
    // console.log(
    //   'pathname, selectOrg, authorityName, isInitedIntegrations, vpcSetting',
    //   pathname,
    //   selectOrg ? JSON.stringify(selectOrg).length : null,
    //   authorityName,
    //   isInitedIntegrations,
    //   vpcSetting ? JSON.stringify(vpcSetting).length : null,
    // );
    checkUser();
  }, [pathname, selectOrg, authorityName, isInitedIntegrations, vpcSetting]);

  useEffect(() => {
    function hotjarUpdate(user) {
      if (window.hj) {
        window.hj('identify', user?.objectId, {});
      }
    }
    if (userData?.objectId && !isInit) {
      setTimeout(() => {
        hotjarUpdate(userData);
        isInit = true;
      }, 2000);
    }
  }, [userData?.objectId]);

  useEffect(async () => {
    if (selectOrg) {
      dispatch(getPreference());
      dispatch(getPermission());
      await dispatch(getOrgOwnData(selectOrg.objectId));
      dispatch(getIntegration(selectOrg.objectId));
      dispatch(findIsBuyPartnerTag('', selectOrg.objectId));

      if (hideOrgAnnouencements) {
        dispatch(setOrgAnnouncement());
      } else {
        dispatch(getOrgAnnouncement(selectOrg.objectId));
      }
    }
  }, [selectOrg?.objectId]);

  useEffect(() => {
    if (selectOrg) {
      if (orgOwnData?.orgRole) {
        dispatch(getUserOrgFeature(selectOrg.objectId));
      } else {
        dispatch(resetUserOrgFeature());
      }
    }
  }, [orgOwnData?.orgRole?._id]);

  useEffect(() => {
    if (selectOrg && feature) {
      const tokens = pathname.split('/');
      const page = tokens[1];

      let enablePage = Object.keys(feature).filter(
        value => feature[value].enabled,
      );

      // enablePage superMarketSetting map to superMarket
      enablePage = enablePage.map(d =>
        d === 'superMarketSetting' ? 'superMarket' : d,
      );

      const gotoPage = () => {
        if (enablePage.length > 0) {
          navigate(`/${newMapPage[enablePage[0]]}/${selectOrg.objectId}`, {
            replace: true,
          });
        } else {
          navigate('/account-setting', {
            replace: true,
          });
        }
      };

      if (!page) {
        gotoPage();
      } else if (
        !enablePage.map(d => newMapPage[d]).includes(page) &&
        page !== 'account-setting'
      ) {
        gotoPage();
      }
    }
  }, [feature]);

  return <>{children}</>;
}

export default AuthorizeLayer;
