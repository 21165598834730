import { SET_MEMBER_MANAGEMENT } from '../actions/type';

const INITIAL_STATE = {
  list: [],
  isLoading: true,
  count: 0,
  resource: null,
};

export default function memberManagementReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MEMBER_MANAGEMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
