import { useState } from 'react';
import { color as colorStore } from '../../../themes';

const IconShare = ({ className, color, colorHover }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill={isHover ? colorHover || colorStore.$1 : color || colorStore.$10}>
        <path d="M11.0241 4.37846C10.5011 3.95465 9.72038 4.32685 9.72038 5V8.10165C5.70068 8.47289 3.15325 10.8383 2.21885 15.0645C2.04561 15.848 3.0052 16.3703 3.56914 15.7994C5.77717 13.5641 7.8222 12.5286 9.72038 12.6189V15.4997C9.72038 16.1728 10.5011 16.545 11.0241 16.1212L17.5023 10.8714C17.8974 10.5512 17.8974 9.94848 17.5023 9.62831L11.0241 4.37846ZM11.3204 8.85494V6.67801L15.728 10.2498L11.3204 13.8217V11.909C11.3204 11.524 11.0462 11.1936 10.6678 11.1227C8.75754 10.7645 6.81101 11.2797 4.83635 12.6311C5.9994 10.7029 7.88112 9.73023 10.5431 9.65462C10.9759 9.64232 11.3204 9.28792 11.3204 8.85494Z" />{' '}
      </g>
    </svg>
  );
};

export default IconShare;
