import coreAPI from '../common/axios';

export default class TagService {
  /**
   *
   * @param {Array<String>} tagNames
   * @param {String} orgId - required
   * @param {String} customerId - optional
   * @param {object} options - optional
   */
  static async findTag(tagName = '', orgId, customerId, options) {
    if (!orgId) {
      throw new Error('findTag: orgId undefined');
    }
    const query = {
      where: {
        organization: {
          className: 'Organization',
          objectId: orgId,
        },
        customer: customerId
          ? {
              className: 'Customer',
              objectId: customerId,
            }
          : undefined,
        name: {
          $options: 'i',
          $regex: tagName,
        },
      },
      sort: { lastUsed: -1 },
      limit: 10,
      ...options,
    };
    const response = await coreAPI.post('/functions/findTag', query);
    return response.result;
  }

  /**
   *
   * @param {Array<String>} tagNames
   * @param {String} orgId - required
   * @param {String} customerId - optional
   * @param {object} options - optional
   */
  static async findTagV3(
    tagName = '',
    orgId,
    customerId,
    options,
    output = ['name', 'count'],
  ) {
    if (!orgId) {
      throw new Error('findTag: orgId undefined');
    }
    const query = {
      where: {
        organization: {
          className: 'Organization',
          objectId: orgId,
        },
        customer: customerId
          ? {
              className: 'Customer',
              objectId: customerId,
            }
          : undefined,
        name: {
          $options: 'i',
          $regex: tagName,
        },
      },
      sort: { lastUsed: -1 },
      limit: 10,
      ...options,
      options: {
        ...options?.options,
        output,
      },
    };
    const response = await coreAPI.post('/functions/findTagV3', query);
    return response.result;
  }

  /**
   *
   * @param {string} customerId
   * @param {string} orgId
   * @param {Array<string>} tagNames
   */
  static async addTagToCustomer(customerId, orgId, tagNames) {
    if (!customerId) {
      throw new Error('"customerId" undefind.');
    }
    if (!orgId) {
      throw new Error('"orgId" undefined');
    }
    if (!Array.isArray(tagNames)) {
      throw new Error('"tagNames" is not a array.');
    }
    const message = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const tagName of tagNames) {
      message[tagName] = true;
    }
    const query = {
      message,
      where: {
        customer: { customerId },
        organization: { className: 'Organization', objectId: orgId },
      },
      options: { version: 2 },
    };

    const response = await coreAPI.post('functions/tagging', query);
    return response.result;
  }

  /**
   *
   * @param {string} customerId
   * @param {string} orgId
   * @param {string} tagName
   */
  static async deleterCustomerTag(customerId, orgId, tagName) {
    if (!customerId) {
      throw new Error('"customerId" undefind.');
    }
    if (!orgId) {
      throw new Error('"orgId" undefined.');
    }
    if (!tagName) {
      throw new Error('"tagName" undefined.');
    }

    let message = { [tagName]: true };

    if (Array.isArray(tagName)) {
      message = {};
      tagName.forEach(tag => {
        message[tag] = true;
      });
    }

    const query = {
      message,
      where: {
        customer: { customerId },
        organization: { className: 'Organization', objectId: orgId },
      },
      options: { version: 2 },
      method: 'DELETE',
    };

    const response = await coreAPI.post('functions/tagging', query);
    return response.result;
  }

  /**
   *
   * @param {string} orgId
   * @param {Array<string>} tags
   */
  static async addOrgTag(orgId, tags) {
    if (!orgId) {
      throw new Error('"orgId" undefined.');
    }
    if (!Array.isArray(tags)) {
      throw new Error('"tags" is invalid.');
    }
    if (tags.length === 0) {
      throw new Error('"tags" is empty');
    }
    const payload = {
      method: 'add',
      tags,
      query: {
        orgId,
      },
      forOrganization: true,
    };

    const response = coreAPI.post('functions/task.tag', payload);
    return response.result;
  }

  /**
   *
   * @param {string} orgId
   * @param {Array<string>} tags
   */
  static async deleteOrgTag(orgId, tags) {
    if (!orgId) {
      throw new Error('"orgId" undefined.');
    }
    if (!Array.isArray(tags)) {
      throw new Error('"tags" is invalid.');
    }
    if (tags.length === 0) {
      throw new Error('"tags" is empty');
    }
    const payload = {
      method: 'del',
      tags,
      query: {
        orgId,
      },
      forOrganization: true,
    };

    const response = coreAPI.post('functions/task.tag', payload);
    return response.result;
  }

  static async findExternalTag(
    tagName = '',
    organizationId,
    customerId,
    options = {},
  ) {
    if (!organizationId) {
      throw new Error('findTag: orgId undefined');
    }
    const query = {
      where: {
        organizationId,
        customerId,
        name: {
          $options: 'i',
          $regex: tagName,
        },
      },
      ...options,
    };
    const response = await coreAPI.post('/functions/findExternalTag', query);
    return response.result;
  }
}
