import moment from 'moment';
import { SnackBarType, CouponType, VendorStore } from '../types';
import actionType from './type';
import { enqueueSnackbar } from './snackbarAction';
import CouponService from '../services/CouponService';
import AddOnSettingService from '../services/AddOnSettingService';
import i18n from '../i18n';
import utils, { paserCsv } from '../common/utils';
import config from '../config';
import exportData from '../common/exportData';

export const getList = orgId => async dispatch => {
  try {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        isLoading: true,
      },
    });

    // super link and growth tool get select menu list need to check AddOnSettingCount
    const addOnSettingCount = await AddOnSettingService.getAddOnSettingCount(
      orgId,
      VendorStore.COUPON_VENDOR_ID,
    );

    let list = [];

    if (addOnSettingCount) {
      list = await CouponService.getList(orgId);
    }

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        list,
        isLoading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: actionType.SET_COUPON_FAIL,
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const getOne = (orgId, couponSetId) => async dispatch => {
  try {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        isLoading: true,
      },
    });

    const coupon = await CouponService.getOne(orgId, couponSetId);

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        coupon: { ...coupon, needUploadCoupon: false },
        isLoading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: actionType.SET_COUPON_FAIL,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const createCoupon = () => async dispatch => {
  try {
    dispatch({
      type: actionType.CREATE_COUPON,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const editCoupon = (orgId, couponSetId) => async dispatch => {
  try {
    const coupon = await CouponService.getOne(orgId, couponSetId);

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        coupon: { ...coupon, needUploadCoupon: false },
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const saveCoupon = (orgId, couponSetId, coupon) => async dispatch => {
  try {
    const data = {
      couponMessage: coupon?.couponMessage,
      couponName: coupon?.couponName,
      couponType: coupon?.couponType,
      drawLimit: coupon?.drawLimit,
      needRedeem: coupon?.needRedeem,
      imgUrl: coupon?.imgUrl,
      couponSetS3FileUrl: coupon?.couponSetS3FileUrl,
      couponDesc: coupon?.couponDesc,
      lotteryTags: coupon?.lotteryTags,
      expiredAt: coupon?.expiredAt
        ? moment(coupon?.expiredAt).format('YYYY-MM-DD HH:mm')
        : null,
      expirePeriodDays: coupon?.expirePeriodDays,
      needUploadCoupon: coupon?.needUploadCoupon,
      triggerKeyword: coupon?.triggerKeyword,
      awardsBtn: coupon?.awardsBtn,
      warnMessages: coupon?.warnMessages,
    };

    if (couponSetId) {
      await CouponService.update(orgId, couponSetId, data);
    } else {
      await CouponService.create(orgId, data);
    }

    return true;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
    return false;
  }
};

export const uploadFile = (orgId, file, isCouponFile) => async dispatch => {
  try {
    if (isCouponFile) {
      const { data: csvData, meta } = await paserCsv(file);
      const { fields = [] } = meta || {};

      if (csvData.length === 0 || fields[0] !== 'coupon_code') {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t(`COUPON_IMPORT-text`),
          }),
        );
        return null;
      }
    }

    const response = await CouponService.uploadFile(orgId, file?.name, file);

    return response?.fileUrl || '';
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
  return '';
};

export const onChangeCouponData = data => async (dispatch, getStore) => {
  try {
    const { coupon } = getStore().coupon;
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        coupon: {
          ...coupon,
          ...data,
        },
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const onChangeCouponFolderData = data => async (dispatch, getStore) => {
  try {
    const { couponFolder } = getStore().coupon;
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        couponFolder: {
          ...couponFolder,
          ...data,
        },
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const copyCoupon = (orgId, couponSetId) => async dispatch => {
  try {
    const coupon = await CouponService.getOne(orgId, couponSetId);

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        coupon: {
          ...coupon,
          couponSetS3FileUrl: null,
          needUploadCoupon: false,
          couponType: CouponType.CODE,
          needRedeem: false,
          expiredAt: null,
          expirePeriodDays: null,
          totalCount: 0,
          sentCount: 0,
          couponSetId: '',
          id: '',
        },
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const deleteCoupon = (orgId, name, couponSetId) => async dispatch => {
  try {
    dispatch({
      type: actionType.SET_ROULETTE,
      payload: {
        isLoading: true,
      },
    });

    await CouponService.delete(orgId, couponSetId);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('coupon:delete-success', { name }),
      }),
    );

    await dispatch(getList(orgId));
  } catch (error) {
    dispatch({
      type: actionType.SET_ROULETTE,
      payload: {
        isLoading: false,
      },
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const importCoupon = (orgId, couponSetId, url) => async dispatch => {
  try {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        isLoading: true,
      },
    });

    await CouponService.import(orgId, couponSetId, url);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('coupon:upload-success'),
      }),
    );

    await dispatch(getOne(orgId, couponSetId));
  } catch (error) {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        isLoading: false,
      },
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const exportCoupon = (orgId, couponSetId) => async (
  dispatch,
  getState,
) => {
  try {
    const { couponName } = getState()?.coupon?.coupon;
    const i18nData = exportData.coupon(couponName);

    await CouponService.export(orgId, couponSetId, i18nData);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const resetCoupon = (orgId, couponSetId) => async dispatch => {
  try {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        isLoading: true,
      },
    });

    await CouponService.reset(orgId, couponSetId);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('coupon:reset-success'),
      }),
    );

    await dispatch(getOne(orgId, couponSetId));
  } catch (error) {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        isLoading: false,
      },
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const getStatisticData = (
  orgId,
  couponSetId,
  startDate,
  endDate,
) => async dispatch => {
  try {
    const statisticData = await CouponService.getStatisticData(
      orgId,
      couponSetId,
      startDate,
      endDate,
    );

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        statisticData,
      },
    });
  } catch (error) {
    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        statisticData: [],
      },
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const getOneFolderAndLineChannelInfo = orgId => async dispatch => {
  try {
    dispatch({
      type: actionType.SET_COUPON,
    });

    const couponFolder = await CouponService.getOneFolderAndLineChannelInfo(
      orgId,
    );

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        couponFolder: {
          triggerKeyword: couponFolder?.triggerKeyword,
          folderMessageCard: couponFolder?.folderMessageCard,
        },
        lineChannelInfo: {
          liffId: couponFolder?.lineLiffId,
          channelId: couponFolder?.lineChannelId,
          channelSecret: couponFolder?.lineChannelSecret,
          isLegacy: couponFolder?.isLegacy,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: actionType.SET_COUPON_FAIL,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error || error?.message,
      }),
    );
  }
};

export const saveCouponFolderAndLineChannelInfo = (
  orgId,
  newCouponFolder,
  newLineChannelInfo,
) => async (dispatch, getStore) => {
  const { lineChannelInfo, couponFolder } = getStore().coupon;

  try {
    const response = await CouponService.updateFolderAndLineChannelInfo(orgId, {
      ...(newCouponFolder || couponFolder),
      ...(newLineChannelInfo || lineChannelInfo),
    });

    dispatch({
      type: actionType.SET_COUPON,
      payload: {
        couponFolder: {
          triggerKeyword: response?.triggerKeyword,
          folderMessageCard: response?.folderMessageCard,
        },
        lineChannelInfo: {
          liffId: response?.lineLiffId,
          channelId: response?.lineChannelId,
          channelSecret: response?.lineChannelSecret,
          isLegacy: response?.isLegacy,
        },
      },
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('coupon:set-connect-success'),
      }),
    );

    return true;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error || error?.message,
      }),
    );
    return false;
  }
};

export const copyCouponFolderLink = orgId => async dispatch => {
  try {
    utils.copyToClipboard(
      `${config.COUPON_SERVER_URL}/coupon/liff/folder?oid=${orgId}`,
      () => {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.SUCCESS,
            message: i18n.t('coupon:copy-link-success'),
          }),
        );
      },
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const reset = () => dispatch => {
  dispatch({
    type: actionType.SET_COUPON_FAIL,
  });
};

export const copyCouponLink = (orgId, couponSetId) => async dispatch => {
  try {
    utils.copyToClipboard(
      `${config.COUPON_SERVER_URL}/coupon/liff/send?oid=${orgId}&csid=${couponSetId}`,
      () => {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.SUCCESS,
            message: i18n.t('coupon:copy-link-success'),
          }),
        );
      },
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const getCouponAddOnSetting = orgId => async dispatch => {
  try {
    const response = await AddOnSettingService.getAddOnSettingCount(
      orgId,
      VendorStore.COUPON_VENDOR_ID,
    );

    return response;
  } catch {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: 'get coupon addOnSetting error',
      }),
    );

    return 0;
  }
};
