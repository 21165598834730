import axios from 'axios';
import imageCompression from 'browser-image-compression';
import CryptoJS from 'crypto-js';
import currencyJS from 'currency.js';
import linkify from 'linkify-it';
import _escape from 'lodash/escape';
import moment from 'moment';
import Papa from 'papaparse';
import { Fragment, Suspense } from 'react';
import Loading from '../components/shared/Loading';
import config from '../config';
import { PlatformType } from '../types';

const linkifyInstance = linkify();

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};

/**
 * {
 *   data:   // array of parsed data
 *   errors: // array of errors
 *   meta:   // object with extra info
 * }
 */
export const paserCsv = (file, option = {}) => {
  return new Promise(resolve => {
    Papa.parse(file, {
      header: true,
      delimiter: ',',
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: res => resolve(res || {}),
      ...option,
    });
  });
};

export const emailValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const dateFormat = (time, format) => moment(time, 'X').format(format);

const isToday = time => {
  const today = moment().startOf('day');
  return today.isSame(time, 'day');
};

const isTodayDateFormat = (time, type = '') => {
  const today = moment().startOf('day');
  if (today.isSame(time, 'day')) {
    return dateFormat(time, 'HH:mm');
  }
  if (type === 'customer') {
    return dateFormat(time, 'YYYY-MM-DD');
  }
  return dateFormat(time, 'YYYY-MM-DD HH:mm');
};

export const delay = ms =>
  new Promise(resolve => setTimeout(() => resolve('timeout'), ms));

const copyToClipboard = async (text, callback) => {
  try {
    await navigator.clipboard.writeText(text);
    callback();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const sso = url => {
  if (url) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.target = '_blank';
    a.href = url;
    a.click();
    document.body.removeChild(a);
  }
};

function createKey(key) {
  return key;
}

export function stringWithNewLine(str, reactKey) {
  return str.split('\n').map((subString, index) => (
    <Fragment key={`${reactKey}-${createKey(index)}`}>
      {subString}
      <br />
    </Fragment>
  ));
}

export function TWD(value = 0, formatWithSymbol = true) {
  const currency = currencyJS(value, {
    precision: 0,
    formatWithSymbol,
  });
  const result = formatWithSymbol
    ? currency.format()
    : currency.format({ symbol: '' });
  currency.format = () => result;
  return currency;
}

export function sendOriginalFormRequest(
  method = 'GET',
  action = '/',
  inputData = {},
) {
  const form = document.createElement('form');
  form.method = method;
  form.action = action;
  Object.keys(inputData).forEach(key => {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = inputData[key];

    form.appendChild(hiddenField);
  });
  document.body.appendChild(form);
  form.submit();
}

export function transformTagsToString(tags) {
  return tags.reduce((pre, tag, index) => {
    if (index === 0) {
      return `${pre}「${tag}」`;
    }
    return `${pre}、「${tag}」`;
  }, '');
}

/**
 *
 * @param {string} text
 */
export function tranformToRegex(text) {
  let output = '';
  for (let i = 0; i < text.length; i += 1) {
    switch (text[i]) {
      case '\\':
        output += '\\\\';
        break;
      case '^':
        output += '\\^';
        break;
      case '$':
        output += '\\$';
        break;
      case '(':
        output += '\\(';
        break;
      case ')':
        output += '\\)';
        break;
      case '[':
        output += '\\[';
        break;
      case ']':
        output += '\\]';
        break;
      case '*':
        output += '\\*';
        break;
      case '+':
        output += '\\+';
        break;
      case '?':
        output += '\\?';
        break;
      case '.':
        output += '\\.';
        break;
      default:
        output += text[i];
    }
  }
  return output;
}

export function simpleHash(input) {
  let hash = 0;
  let i = 0;
  let chr = null;
  for (i = 0; i < input.length; i += 1) {
    chr = input.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function validateHttpsPath(path) {
  return /^(https:\/\/)([\w.]+\/?)\S*/g.test(path);
}

export function validateHttpsAndHttpPath(path) {
  return /^((http|https):\/\/)([\w.]+\/?)\S*/g.test(path);
}

export function validateNo8Path(path) {
  return /^(no8:\/\/)([\w.]+\/?)\S*/g.test(path);
}

export function validateLinePath(path) {
  return /^(line:\/\/)([\w.]+\/?)\S*/g.test(path);
}

export function scrollToWithAnimation(element, to, duration) {
  const start = element.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);
  for (let i = 0; i < n; i += 1) {
    u8arr[i] = bstr.charCodeAt(i);
  }
  return new File([u8arr], filename, { type: mime });
}

// t = current time
// b = start value
// c = change in value
// d = duration

Math.easeInOutQuad = (t, b, c, d) => {
  // eslint-disable-next-line no-param-reassign
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  // eslint-disable-next-line no-param-reassign
  t -= 1;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export function transformPhone(phone) {
  if (phone[0] === '0') {
    return `+886${phone.slice(1)}`;
  }
  return phone;
}

export function validatePhone(phone) {
  if (phone[0] === '+') return true;
  return false;
}

export function clearArray(array) {
  while (array.length > 0) {
    array.pop();
  }
}

const fileNameRegex = new RegExp(
  '^[\u4e00-\u9fa5A-Za-z0-9\u3001-\u3003\uff1f\uff0c\\w]+$',
);

const cellPhoneRegex = new RegExp(/^[\\+\-0-9]*$/);

export function validateFileName(name) {
  return fileNameRegex.test(name);
}

export function validateCellPhone(value) {
  return cellPhoneRegex.test(value);
}

export async function downloadImage(file) {
  const fileName = file.replace(/^.*[\\/]/, '');
  const response = await axios.get(`${file}?time=${new Date().getTime()}`, {
    responseType: 'blob',
  });
  const imageURL = URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = fileName;
  link.click();
}

export const numberMul = (number1, number2) => {
  let decimal = 0;
  const n1 = number1.toString();
  const n2 = number2.toString();

  if (n1.indexOf('.') !== -1) {
    decimal += n1.split('.')[1].length;
  }

  if (n2.indexOf('.') !== -1) {
    decimal += n2.split('.')[1].length;
  }

  return (
    (Number(n1.replace('.', '')) * Number(n2.replace('.', ''))) / 10 ** decimal
  );
};

export const percentage = (partialValue, totalValue) => {
  return `${Math.round(((100 * partialValue) / totalValue) * 100) / 100}%`;
};

export const HASHTAG = '<%=name%>';
export const HASHTAG_REGEX = new RegExp(HASHTAG, 'g');

export const WA_TEMP_PLACEHOLDER_REGEX = new RegExp(/\{\{(\d+)\}\}/, 'g');

export const getBrowserLanguage = () => {
  let browserlLang = navigator.language || navigator.userLanguage;
  if (browserlLang === 'ja') {
    browserlLang = 'ja-JP';
  }

  if (!~config.SUPPORT_BROWSER_LANGUAGE.indexOf(browserlLang)) {
    return 'en-US';
  }

  return browserlLang;
};

export const WaitingComponent = (Component, props) => (
  <Suspense fallback={<Loading />}>
    <Component {...props} />
  </Suspense>
);

export const signPayload = (payload, token) => {
  const nonce = new Date().getTime().toString();

  const baseString = CryptoJS.HmacSHA256(
    payload,
    `Super8_SHA256/${token}/${nonce}`,
  ).toString(CryptoJS.enc.Hex);

  const signature = CryptoJS.MD5(baseString).toString(CryptoJS.enc.Hex);

  const signedPayload = JSON.stringify({
    signature,
    nonce,
    data: payload,
  });
  return signedPayload;
};

export const addPendingMessage = messageObject => {
  const message = messageObject;
  message.createdAt = new Date();
  const data = JSON.stringify(message);
  const pendingMessages = JSON.parse(
    localStorage.getItem(config.PENDING_MESSAGES) || '[]',
  );
  pendingMessages.push(data);
  localStorage.setItem(
    config.PENDING_MESSAGES,
    JSON.stringify(pendingMessages),
  );
};

export const removePendingMessage = receiptId => {
  let pendingMessages = JSON.parse(
    localStorage.getItem(config.PENDING_MESSAGES) || '[]',
  );
  pendingMessages = pendingMessages.filter(m => {
    const message = JSON.parse(m);
    const rId = message.messages[0]?.receiptId;
    return rId !== receiptId;
  });
  localStorage.setItem(
    config.PENDING_MESSAGES,
    JSON.stringify(pendingMessages),
  );
};

export const linkifyUrl = rawText => {
  const linkifyResult = linkifyInstance.match(rawText);

  const linkMap = [];

  const patternResult =
    linkifyResult &&
    linkifyResult.reduce((pre, current, idx) => {
      linkMap.push(
        `<a href='${linkifyResult[idx].url}' target='_blank'  class='textMessageLink'>${linkifyResult[idx].text}</a>`,
      );
      return pre.replace(linkifyResult[idx].text, `%{${idx}}%`);
    }, rawText);
  const htmlText =
    linkMap.length > 0
      ? linkMap.reduce((pre, current, idx) => {
          return pre.replace(`%{${idx}}%`, linkMap[idx]);
        }, patternResult)
      : rawText;

  return { html: htmlText };
};

export const showText = (content, isNoBreakline) => {
  let text = '';
  const clean = _escape(content);
  const res = linkifyUrl(clean);
  if (res.html) {
    text = res.html;
  }
  if (text && text.length > 0) {
    if (isNoBreakline) {
      text = text.replace(/(\r\n|\n|\r)/gm, ' ');
    } else {
      text = text.replace(/(\r\n|\n|\r)/gm, '<br/>');
    }
  }

  return text;
};

export const randomText = (len = 10) => {
  let text = '';
  const raw = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let index = 0; index < len; index += 1) {
    text += raw.charAt(Math.floor(Math.random() * raw.length));
  }

  return text;
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const handleGetCompressImage = eventFile => {
  return new Promise(resolve => {
    if (/^image\/(jpeg|jpg|png)/i.test(eventFile.type)) {
      imageCompression(eventFile, {
        initialQuality: 0.7,
      }).then(newFile => {
        if (newFile.size < eventFile.size) {
          resolve(newFile);
        } else {
          resolve(eventFile);
        }
      });
    } else {
      resolve(eventFile);
    }
  });
};

export function validateText(text) {
  return !!text;
}

export function validateMax(text, num) {
  return text && text.length <= num;
}

export function validateMin(text, num) {
  return text && text.length >= num;
}

export function encryptOrgId(orgId, key) {
  const orgEncyptionKey = CryptoJS.enc.Hex.parse(key);
  const orgEncyptionIV = CryptoJS.enc.Hex.parse('TheKey');

  const encryptedOrgId = CryptoJS.RC4.encrypt(orgId, orgEncyptionKey, {
    iv: orgEncyptionIV,
  })
    .toString()
    .replaceAll('/', '@@');
  return encryptedOrgId;
}

export function validateDate(start, end) {
  return !!start && !!end && moment(end).isSameOrAfter(start, 'minute');
}

export function validateRichvideoData(cardData) {
  for (let i = 0; i < cardData.length; i += 1) {
    const d = cardData[i];
    if (d.videoUrl === '' || !validateHttpsPath(d.videoUrl)) return false;
    if (d.previewImageUrl === '' || !validateHttpsPath(d.previewImageUrl))
      return false;
    if (d.fileName === '') return false;

    for (
      let buttonIndex = 0;
      buttonIndex < d.buttons.length;
      buttonIndex += 1
    ) {
      const button = d.buttons[buttonIndex];
      if (button.title && button.title.length > config.MAX_BUTTON_TITLE_LENGTH)
        return false;
      if (
        button.type === 'url' &&
        button.data &&
        !validateHttpsAndHttpPath(button.data) &&
        !validateNo8Path(button.data)
      ) {
        return false;
      }
    }
  }
  return true;
}

export function validateImageCardData(cardData) {
  let totalImages = 0;
  let totalButtons = 0;
  for (let i = 0; i < cardData.length; i += 1) {
    const d = cardData[i];
    totalImages += d.imageUrl !== '' ? 1 : 0;
    totalButtons += d.buttons.length;

    if (d.imageType === 'url' && !validateHttpsPath(d.imageUrl)) {
      return false;
    }

    const button = d.buttons[0];
    if (
      button.type === 'postback' &&
      (button.title === '' ||
        button.title.length > config.MAX_BUTTON_TITLE_LENGTH)
    )
      return false;
    if (button.data === '' && button.type !== 'location') return false;
    if (button.type === 'location') return false;
    if (
      button.type === 'url' &&
      !validateHttpsAndHttpPath(button.data) &&
      !validateNo8Path(button.data)
    ) {
      return false;
    }
    if (button.type === 'phone' && !validatePhone(button.data)) {
      return false;
    }
  }
  if (totalImages !== 0 && totalImages !== cardData.length) {
    return false;
  }
  if (totalButtons / cardData[0].buttons.length !== cardData.length) {
    return false;
  }
  return true;
}

export function validateCardData(cardData, platformType) {
  let totalImages = 0;
  let totalButtons = 0;
  for (let i = 0; i < cardData.length; i += 1) {
    const d = cardData[i];
    if (d.imageType === 'url' && !validateHttpsPath(d.imageUrl)) return false;
    if (
      d.title === '' ||
      d.title.length >
        (platformType === PlatformType.WHATSAPP
          ? 1024
          : config.MAX_CARD_TITLE_LENGTH)
    )
      return false;
    if (
      d.subtitle === '' ||
      d.subtitle.length > config.MAX_CARD_SUBTITLE_LENGTH
    )
      return false;
    totalImages += d.imageUrl !== '' ? 1 : 0;
    totalButtons += d.buttons.length;

    for (
      let buttonIndex = 0;
      buttonIndex < d.buttons.length;
      buttonIndex += 1
    ) {
      const button = d.buttons[buttonIndex];
      if (
        button.title === '' ||
        button.title.length > config.MAX_BUTTON_TITLE_LENGTH
      )
        return false;
      if (button.data === '' && button.type !== 'location') return false;
      if (button.type === 'location') return false;
      if (
        button.type === 'url' &&
        !validateHttpsAndHttpPath(button.data) &&
        !validateNo8Path(button.data)
      ) {
        return false;
      }
      if (button.type === 'phone' && !validatePhone(button.data)) {
        return false;
      }
    }
  }
  if (totalImages !== 0 && totalImages !== cardData.length) {
    return false;
  }
  if (totalButtons / cardData[0].buttons.length !== cardData.length) {
    return false;
  }
  return true;
}

export function validateConfirmCardData(cardData) {
  if (cardData.title === '') return false;
  for (let i = 0; i < cardData.buttons.length; i += 1) {
    if (
      cardData.buttons[i].title === '' ||
      cardData.buttons[i].title.length > 20
    )
      return false;
    if (cardData.buttons[i].type === 'postback') {
      if (
        cardData.buttons[i].data === '' ||
        cardData.buttons[i].data.length > 20
      )
        return false;
    }
    if (cardData.buttons[i].type === 'url') {
      if (
        cardData.buttons[i].data === '' ||
        (!validateHttpsAndHttpPath(cardData.buttons[i].data) &&
          !validateNo8Path(cardData.buttons[i].data))
      )
        return false;
    }
  }
  return true;
}

export function validateImagemapData(data) {
  if (!data.imageUrl) return false;
  for (let i = 0; i < data.actions.length; i += 1) {
    const area = data.actions[i];
    if (!area.action.displayText && area.action.type !== 'location')
      return false;
    if (area.action.type === 'location') return false;
    if (
      area.action.type === 'url' &&
      !validateHttpsAndHttpPath(area.action.displayText) &&
      !validateNo8Path(area.action.displayText) &&
      !validateLinePath(area.action.displayText)
    )
      return false;
  }
  return true;
}

export const doPercentage = (num, total) => {
  return total ? (((num || 0) / (total || 0) || 0) * 100).toFixed(1) : '0.0';
};

export const num = value => {
  return value < 0 ? 0 : value || 0;
};

export const getTagDensityTitle = (tag, t) => {
  const { type, tags: dTags, startDate, endDate, count } = tag;

  let res = '';
  if (startDate && endDate) {
    res += ` ${
      t('broadcast:setTime') + moment(startDate).format('YYYY-MM-DD')
    } ~ ${moment(endDate).format('YYYY-MM-DD')}`;
  } else {
    res += `${t('broadcast:noSetTime')}`;
  }

  if (type === 'include' || type === 'exclude') {
    res += `，${t(`broadcast:detail.${type}`)}`;
  }
  if (dTags && dTags.length > 0) {
    res += dTags.join('、');
  }

  if (type === 'include') {
    res += `，${t(`broadcast:andTagingCount`)} ${count} ${t(
      `broadcast:count`,
    )}`;
  }

  if (type === 'anyGreater') {
    res += `，${t(`broadcast:someTagCount`)} ${count} ${t(`broadcast:count`)}`;
  }

  if (type === 'none') {
    res += `，${t(`broadcast:noTag`)}`;
  }
  return res;
};

export const changeSeconds = (seconds = 0, changeType = 'minute') => {
  switch (changeType) {
    case 'second':
      return Math.floor(seconds % 60);
    case 'year':
      return Math.floor(seconds / 60 / 60 / 24 / 30 / 12);
    case 'month':
      return Math.floor((seconds / 60 / 60 / 24 / 30) % 12);
    case 'day':
      return Math.floor((seconds / 60 / 60 / 24) % 30);
    case 'hour':
      return Math.floor((seconds / 60 / 60) % 24);
    case 'minute':
    default:
      return Math.floor((seconds / 60) % 60);
  }
};

// 0 ~ maxNum
export const generateDateItem = (maxNum, span = 1, begin = 0) => {
  return Array.from(Array(maxNum / span + 1)).map((v, index) => ({
    text: (index * span + begin).toString(),
    value: index * span + begin,
  }));
};

export const formatSecondToTime = second => {
  const time = moment.duration(second, 'seconds');

  // HH:mm:ss
  return `${Math.floor(second ? second / 3600 : 0)
    .toString()
    .padStart(2, '0')}:${time
    .minutes()
    .toString()
    .padStart(2, '0')}:${time.seconds().toString().padStart(2, '0')}`;
};

export default {
  emailValid,
  dateFormat,
  delay,
  isTodayDateFormat,
  isToday,
  copyToClipboard,
  TWD,
  transformTagsToString,
  linkifyUrl,
  showText,
  arraysEqual,
  handleGetCompressImage,
  addPendingMessage,
  removePendingMessage,
};
