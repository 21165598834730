import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { findTag } from '../../actions/allCustomersAction';
import Button from './Button';
import AutoCompletedTextField from './AutoCompletedTextField';
import { color } from '../../themes';
import { TWD } from '../../common/utils';
import config from '../../config';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '6000 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflow: 'unset',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.$7,
      fontSize: 20,
      lineHeight: 1,
      borderRadius: 12,
      padding: '24px 24px 24px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px 24px 12px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
    },
    'content-block': {
      width: 450,
      '& > p': {
        fontSize: 15,
        lineHeight: 1,
        color: color.$3,
        marginBottom: 12,
      },
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '12px 24px 24px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      borderRadius: 12,
    },
    'button--margin-right': {
      marginRight: 8,
    },
    autoCompelete: {
      width: '100%',
      maxWidth: 450,
    },
  },
  {
    name: 'DeleteTagsModal',
  },
);

let findTagTimer = null;

function DeleteTagsModal({ isOpen, onCancel, onClick, orgId, count }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tagCandidates = useSelector(state => state.allCustomers.tagCandidates);
  const [tags, setTags] = useState([]);

  function handleOnEnter(tagName) {
    setTags(tags.concat([tagName]));
  }

  // eslint-disable-next-line no-shadow
  function handleOnSetTags(tags) {
    setTags(tags);
  }

  async function handleOnClick() {
    onCancel();
    setTimeout(() => {
      onClick(tags);
    }, 200);
  }

  async function handleOnChange(value) {
    clearTimeout(findTagTimer);
    findTagTimer = setTimeout(() => {
      dispatch(findTag(value, orgId, undefined, { limit: 20 }, ['name']));
    }, config.DEBOUNCE_DELAY);
  }

  useEffect(() => {
    dispatch(findTag('', orgId, undefined, { limit: 20 }, ['name']));
  }, []);

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.title}>{t('modal.remove-tag')}</div>
      <div className={classes.content}>
        <div className={classes['content-block']}>
          {count && count > 0 && (
            <Typography>
              {t('batch-management.delete-tags-content-title', {
                count: TWD(count, false).format(),
              })}
            </Typography>
          )}
          <AutoCompletedTextField
            className={classes.autoCompelete}
            placeholder={t('modal.delete-tag-placeholder')}
            candidates={tagCandidates}
            type="inline"
            onEnter={handleOnEnter}
            onSetTags={handleOnSetTags}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          topic="primaryBlue"
          className={classes['button--margin-right']}
          onClick={onCancel}
        >
          {t('modal.cancel')}
        </Button>
        <Button
          topic="primaryBlueHighLight"
          onClick={handleOnClick}
          isDisable={tags.length === 0}
        >
          {t('modal.submit')}
        </Button>
      </div>
    </Dialog>
  );
}

export default DeleteTagsModal;
