import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactFileReader from 'react-file-reader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import { uploadFile, importCoupon } from '../../../actions/couponAction';
import Button from '../../shared/Button';
import { color } from '../../../themes';
import config from '../../../config';
import { TWD } from '../../../common/utils';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '6000 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflow: 'unset',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.$7,
      fontSize: 20,
      lineHeight: 1,
      borderRadius: 12,
      padding: '24px 24px 24px',
    },
    content: {
      padding: '24px 24px 12px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.4',
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '12px 24px 24px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      borderRadius: 12,
    },
    'button--margin-right': {
      marginRight: 8,
    },
    download: {
      color: color.$2,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: 15,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
  },
  {
    name: 'AddCouponModal',
  },
);

function AddCouponModal({ isOpen, onCancel, orgId, couponSetId, count }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function handleOnClick(files) {
    const csvFile = files[0];
    const url = await dispatch(uploadFile(orgId, csvFile, true));
    if (url) {
      await dispatch(importCoupon(orgId, couponSetId, url));
    }

    onCancel();
  }

  function handleOnDownloadSample() {
    const a = document.createElement('a');
    a.href = config.DEFAULT_DOWNLOAD_COUPON_EXAMPLE_FILE;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.title}>{t('coupon:upload-modal.title')}</div>
      <div className={classes.content}>
        {t('coupon:upload-modal.content', {
          count: TWD(count, false).format(),
        })}
        <br />
        {t('coupon:upload-modal.content1')}
        <span> </span>
        <span className={classes.download} onClick={handleOnDownloadSample}>
          {t('coupon:create.file-sample')}
        </span>
      </div>
      <div className={classes.actions}>
        <Button
          topic="primaryBlue"
          className={classes['button--margin-right']}
          onClick={onCancel}
        >
          {t('coupon:upload-modal.cancelText')}
        </Button>
        <ReactFileReader
          className="react-file-reader"
          fileTypes={['.csv']}
          handleFiles={handleOnClick}
        >
          <Button topic="primaryBlueHighLight">
            {t('coupon:upload-modal.submitText')}
          </Button>
        </ReactFileReader>
      </div>
    </Dialog>
  );
}

export default AddCouponModal;
