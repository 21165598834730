import { couponApi } from '../common/axios';

export default class CouponService {
  static async getList(orgId) {
    if (!orgId) {
      throw new Error('CouponService.getList: orgId undefined');
    }

    const response = await couponApi.get(`/coupon/api/v1/couponSet/${orgId}`);

    return response?.data?.couponList || [];
  }

  static async getOne(orgId, couponSetId) {
    if (!orgId) {
      throw new Error('CouponService.getOne: orgId undefined');
    }

    if (!couponSetId) {
      throw new Error('CouponService.getOne: couponSetId undefined');
    }

    const response = await couponApi.get(
      `/coupon/api/v1/couponSet/${orgId}/${couponSetId}`,
    );

    return response?.data || {};
  }

  static async uploadFile(orgId, fileName, file) {
    if (!orgId) {
      throw new Error('CouponService.uploadFile: orgId undefined');
    }

    if (!fileName) {
      throw new Error('CouponService.uploadFile: fileName undefined');
    }

    if (!file) {
      throw new Error('CouponService.uploadFile: file undefined');
    }

    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('organizationId', orgId);
    formData.append('filename', fileName);

    const response = await couponApi.post(
      `/coupon/api/v1/file/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response?.data || {};
  }

  static async delete(orgId, couponSetId) {
    if (!orgId) {
      throw new Error('CouponService.delete: orgId undefined');
    }

    if (!couponSetId) {
      throw new Error('CouponService.delete: couponSetId undefined');
    }

    const response = await couponApi.delete(
      `/coupon/api/v1/couponSet/${orgId}/${couponSetId}`,
    );

    return response;
  }

  static async import(orgId, couponSetId, couponSetS3FileUrl) {
    if (!orgId) {
      throw new Error('CouponService.import: orgId undefined');
    }
    if (!couponSetId) {
      throw new Error('CouponService.import: couponSetId undefined');
    }

    if (!couponSetS3FileUrl) {
      throw new Error('CouponService.import: couponSetS3FileUrl undefined');
    }

    const response = await couponApi.post(`/coupon/api/v1/couponPool/import`, {
      organizationId: orgId,
      couponSetId,
      couponSetS3FileUrl,
    });

    return response?.data || {};
  }

  static async export(orgId, couponSetId, i18nData) {
    if (!orgId) {
      throw new Error('CouponService.export: orgId undefined');
    }

    if (!couponSetId) {
      throw new Error('CouponService.export: couponSetId undefined');
    }

    const response = await couponApi.post(`/coupon/api/v1/couponSet/export`, {
      i18n: i18nData,
      organizationId: orgId,
      couponSetId,
    });

    return response;
  }

  static async create(orgId, coupon) {
    if (!orgId) {
      throw new Error('CouponService.create: orgId undefined');
    }

    if (!coupon) {
      throw new Error('CouponService.create: coupon undefined');
    }

    const response = await couponApi.post(`/coupon/api/v1/couponSet`, {
      organizationId: orgId,
      ...coupon,
    });

    return response?.data || {};
  }

  static async update(orgId, couponSetId, coupon) {
    if (!orgId) {
      throw new Error('CouponService.update: orgId undefined');
    }

    if (!couponSetId) {
      throw new Error('CouponService.update: couponSetId undefined');
    }

    if (!coupon) {
      throw new Error('CouponService.update: coupon undefined');
    }

    const response = await couponApi.put(
      `/coupon/api/v1/couponSet/${orgId}/${couponSetId}`,
      coupon,
    );

    return response?.data || {};
  }

  static async reset(orgId, couponSetId) {
    if (!orgId) {
      throw new Error('CouponService.update: orgId undefined');
    }

    if (!couponSetId) {
      throw new Error('CouponService.update: couponSetId undefined');
    }

    const response = await couponApi.post(`/coupon/api/v1/couponPool/reset`, {
      organizationId: orgId,
      couponSetId,
    });

    return response;
  }

  static async getStatisticData(orgId, couponSetId, startDate, endDate) {
    if (!orgId) {
      throw new Error('CouponService.getStatisticData: orgId undefined');
    }

    if (!couponSetId) {
      throw new Error('CouponService.getStatisticData: couponSetId undefined');
    }

    if (!startDate) {
      throw new Error('CouponService.getStatisticData: startDate undefined');
    }

    if (!endDate) {
      throw new Error('CouponService.getStatisticData: endDate undefined');
    }

    const response = await couponApi.get(
      `/coupon/api/v1/couponPool/statistics?organizationId=${orgId}&couponSetId=${couponSetId}&startDate=${startDate}&endDate=${endDate}`,
    );

    return response?.data || [];
  }

  static async getOneFolderAndLineChannelInfo(orgId) {
    if (!orgId) {
      throw new Error(
        'CouponService.getOneFolderAndLineChannelInfo: orgId undefined',
      );
    }

    const response = await couponApi.get(
      `/coupon/api/v1/couponFolder/${orgId}`,
    );

    return response?.data || {};
  }

  static async updateFolderAndLineChannelInfo(orgId, data) {
    const {
      triggerKeyword,
      folderMessageCard,
      liffId,
      channelId,
      channelSecret,
    } = data;

    if (!orgId) {
      throw new Error(
        'CouponService.updateFolderAndLineChannelInfo: orgId undefined',
      );
    }

    if (!data) {
      throw new Error(
        'CouponService.updateFolderAndLineChannelInfo: couponFolder undefined',
      );
    }

    const response = await couponApi.put(
      `/coupon/api/v1/couponFolder/${orgId}`,
      {
        triggerKeyword,
        folderMessageCard,
        lineLiffId: liffId,
        lineChannelId: channelId,
        lineChannelSecret: channelSecret,
      },
    );

    return response?.data || {};
  }
}
