import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiIconButton from '@material-ui/core/IconButton';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    colorPrimary: {
      color: color.$15,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
  {
    name: 'IconButton',
  },
);

function IconButton({ className, children, ...otherProps }) {
  const classes = useStyles();
  return (
    <MuiIconButton
      className={className}
      classes={{
        colorPrimary: classes.colorPrimary,
      }}
      {...otherProps}
    >
      {children}
    </MuiIconButton>
  );
}

IconButton.defaultProps = {
  className: undefined,
};

IconButton.propTypes = {
  className: PropTypes.string,
};

export default IconButton;
