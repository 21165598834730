import hexRgba from 'hex-rgba';
import { useState } from 'react';
import { color } from '../../../themes';

const IconMessagePacket = ({ onClick, className }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.65002 3.90039C4.13124 3.90039 2.90002 5.13161 2.90002 6.65039V10.9004C2.90002 12.4192 4.13124 13.6504 5.65002 13.6504V12.1504C4.95967 12.1504 4.40002 11.5907 4.40002 10.9004V6.65039C4.40002 5.96003 4.95967 5.40039 5.65002 5.40039H15.4C16.0904 5.40039 16.65 5.96003 16.65 6.65039H18.15C18.15 5.13161 16.9188 3.90039 15.4 3.90039H5.65002Z"
        fill={isHover ? hexRgba(color.$12, 40) : color.$12}
      />
      <path
        d="M13.6667 18L14.0525 17.3569C13.936 17.2869 13.8026 17.25 13.6667 17.25V18ZM17 20L16.6141 20.6431C16.8458 20.7821 17.1344 20.7858 17.3695 20.6526C17.6047 20.5195 17.75 20.2702 17.75 20H17ZM17 18V17.25C16.5858 17.25 16.25 17.5858 16.25 18H17ZM7.75 10C7.75 9.30964 8.30964 8.75 9 8.75V7.25C7.48122 7.25 6.25 8.48122 6.25 10H7.75ZM7.75 16V10H6.25V16H7.75ZM9 17.25C8.30964 17.25 7.75 16.6904 7.75 16H6.25C6.25 17.5188 7.48122 18.75 9 18.75V17.25ZM13.6667 17.25H9V18.75H13.6667V17.25ZM13.2808 18.6431L16.6141 20.6431L17.3859 19.3569L14.0525 17.3569L13.2808 18.6431ZM17.75 20V18H16.25V20H17.75ZM19 17.25H17V18.75H19V17.25ZM20.25 16C20.25 16.6904 19.6904 17.25 19 17.25V18.75C20.5188 18.75 21.75 17.5188 21.75 16H20.25ZM20.25 10V16H21.75V10H20.25ZM19 8.75C19.6904 8.75 20.25 9.30964 20.25 10H21.75C21.75 8.48122 20.5188 7.25 19 7.25V8.75ZM9 8.75H19V7.25H9V8.75Z"
        fill={isHover ? hexRgba(color.$12, 40) : color.$12}
      />
      <circle
        cx="9.75012"
        cy="13.75"
        r="0.75"
        fill={isHover ? hexRgba(color.$12, 40) : color.$12}
      />
      <circle
        cx="13.7501"
        cy="13.75"
        r="0.75"
        fill={isHover ? hexRgba(color.$12, 40) : color.$12}
      />
      <circle
        cx="17.7501"
        cy="13.75"
        r="0.75"
        fill={isHover ? hexRgba(color.$12, 40) : color.$12}
      />
    </svg>
  );
};

export default IconMessagePacket;
