import moment from 'moment';
import { richMenuAPI, nextAPI } from '../common/axios';
import i18n from '../i18n';

export default class RichMenuService {
  static async getResource(orgId) {
    return richMenuAPI.get('/api/v1/rich-menu-resource', {
      data: {
        orgId,
      },
    });
  }

  static async createRichMenuGroup(orgId, data) {
    const payload = {
      title: `${i18n.t('rich-menu-setting.rich-menu')} ${moment().format(
        'YYYY-MM-DD HH:mm:ss',
      )}`,
      tag: null,
      isSelected: true,
      richMenu: {
        data: {
          size: {
            width: 2500,
            height: 1686,
          },
          selected: false,
          name: i18n.t(`rich-menu-setting.start-menu`),
          chatBarText: i18n.t(`rich-menu-setting.chatBarText`),
          areas: [
            {
              bounds: {
                x: 0,
                y: 0,
                width: 833,
                height: 843,
              },
              action: { type: 'postback', tags: [], displayText: '' },
            },
            {
              bounds: {
                x: 833,
                y: 0,
                width: 833,
                height: 843,
              },
              action: { type: 'postback', tags: [], displayText: '' },
            },
            {
              bounds: {
                x: 1666,
                y: 0,
                width: 833,
                height: 843,
              },
              action: { type: 'postback', tags: [], displayText: '' },
            },
            {
              bounds: {
                x: 0,
                y: 843,
                width: 833,
                height: 843,
              },
              action: { type: 'postback', tags: [], displayText: '' },
            },
            {
              bounds: {
                x: 833,
                y: 843,
                width: 833,
                height: 843,
              },
              action: { type: 'postback', tags: [], displayText: '' },
            },
            {
              bounds: {
                x: 1666,
                y: 843,
                width: 833,
                height: 843,
              },
              action: { type: 'postback', tags: [], displayText: '' },
            },
          ],
        },
      },
      ...data,
    };

    return richMenuAPI.post('/api/v1/rich-menu-group', {
      ...payload,
      orgId,
    });
  }

  static async getRichMenuGroups(orgId) {
    return richMenuAPI.get('/api/v1/rich-menu-group', {
      data: {
        orgId,
      },
    });
  }

  static async getOneRichMenuGroups(orgId, groupId) {
    return richMenuAPI.get(`/api/v1/rich-menu-group/${groupId}`, {
      data: {
        orgId,
      },
    });
  }

  static async setRichMenuGroup(orgId, groupId, richMenuData) {
    return richMenuAPI.put(`/api/v1/rich-menu-group/${groupId}`, {
      ...richMenuData,
      orgId,
    });
  }

  static async deleteRichMenuGroup(orgId, groupId) {
    return richMenuAPI.delete(`/api/v1/rich-menu-group/${groupId}`, {
      data: { orgId },
    });
  }

  static async publishRichMenuGroup(orgId, groupId) {
    return richMenuAPI.post(`/api/v1/rich-menu-group/${groupId}/publish`, {
      orgId,
    });
  }

  static async unpublishRichMenuGroup(orgId, groupId) {
    return richMenuAPI.delete(`/api/v1/rich-menu-group/${groupId}/publish`, {
      data: { orgId },
    });
  }

  static async getAllRichMenuLength(orgId) {
    return richMenuAPI.get('/api/v1/rich-menu/all/count', {
      data: { orgId },
    });
  }

  static async getLineChannelInfo(orgId) {
    if (!orgId) {
      throw new Error('RichMenuService.getLineChannelInfo: orgId undefined');
    }
    const response = await richMenuAPI.get('/api/v1/liff-setting', {
      data: { orgId },
    });

    return response || {};
  }

  static async getAnalytics(orgId, richGroupId) {
    const response = await nextAPI.get(
      `/richmenu/${richGroupId}/analytics?orgId=${orgId}`,
    );

    return response;
  }
}
