import actionType from '../actions/type';
import { MetricsPeriod } from '../types';

export default (
  state = {
    comprehensiveLastUpdateTime: null,
    tagAccounting: [],
    tagCandidates: [],
    messageAnalysisData: {},
    campaigns: [],
    broadcasts: [],
    messageAnalysisPeriodType: MetricsPeriod.WEEK,
    tagAnalysisPeriodType: MetricsPeriod.WEEK,
    customerDetailData: {
      fb: {
        joinTotal: 0,
        blockNumber: 0,
        messageTotal: 0,
        messageIncrement: 0,
        messageIncrementLast: 0,
      },
      line: {
        joinTotal: 0,
        blockNumber: 0,
        messageTotal: 0,
        messageIncrement: 0,
        messageIncrementLast: 0,
      },
      total: {
        joinTotal: 0,
        blockNumber: 0,
        messageTotal: 0,
        messageIncrement: 0,
        messageIncrementLast: 0,
      },
      instagram: {
        joinTotal: 0,
        messageTotal: 0,
        messageIncrement: 0,
        messageIncrementLast: 0,
      },
      livechat: {
        joinTotal: 0,
        messageTotal: 0,
        messageIncrement: 0,
        messageIncrementLast: 0,
      },
    },
    purview: {},
    comprehensiveData: {},
    comprehensiveRecommend: {},
    isComprehensiveLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case actionType.SET_COMPREHENSIVE_STATUS:
      return {
        ...state,
        comprehensiveLastUpdateTime: action.payload,
      };
    case actionType.SET_TAG_ANALYSIS_TAG_ACCOUNTING_REQUEST:
    case actionType.SET_TAG_ANALYSIS_TAG_ACCOUNTING_FAILURE:
      return {
        ...state,
        tagAccounting: [],
      };
    case actionType.SET_TAG_ANALYSIS_TAG_ACCOUNTING_SUCCESS:
      return {
        ...state,
        tagAccounting: action.payload,
      };
    case actionType.SET_TAG_ANALYSIS_FIND_TAG_REQUEST:
    case actionType.SET_TAG_ANALYSIS_FIND_TAG_FAILURE:
      return {
        ...state,
        tagCandidates: [],
      };
    case actionType.SET_TAG_ANALYSIS_FIND_TAG_SUCCESS:
      return {
        ...state,
        tagCandidates: action.payload,
      };
    case actionType.SET_TAG_ANALYSIS_PERIOD_TYPE:
      return {
        ...state,
        tagAnalysisPeriodType: action.payload,
      };
    case actionType.SET_MESSAGE_ANALYSIS_DATA:
      return {
        ...state,
        messageAnalysisData: action.messageAnalysisData,
        campaigns: action.campaigns,
        broadcasts: action.broadcasts,
      };
    case actionType.SET_MESSAGE_ANALYSIS_DATA_FAIL:
      return {
        ...state,
        messageAnalysisData: {},
        campaigns: [],
        broadcasts: [],
      };
    case actionType.SET_MESSAGE_ANALYSIS_PERIOD_TYPE:
      return {
        ...state,
        messageAnalysisPeriodType: action.payload,
      };
    case actionType.SET_CUSTOMER_DETAIL_DATA:
      return {
        ...state,
        customerDetailData: action.payload,
      };
    case actionType.SET_CUSTOMER_DETAIL_DATA_FAIL:
      return {
        ...state,
        customerDetailData: {
          fb: {
            joinTotal: 0,
            blockNumber: 0,
            messageTotal: 0,
            messageIncrement: 0,
            messageIncrementLast: 0,
          },
          line: {
            joinTotal: 0,
            blockNumber: 0,
            messageTotal: 0,
            messageIncrement: 0,
            messageIncrementLast: 0,
          },
          total: {
            joinTotal: 0,
            blockNumber: 0,
            messageTotal: 0,
            messageIncrement: 0,
            messageIncrementLast: 0,
          },
        },
      };
    default:
      return state;
  }
};
