import { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Circular from '../shared/Circular';
import Notifier from './Notifier';
import iconSrc from '../../assets/iconSrc';
import { color } from '../../themes';
import AnnouncementService from '../../services/AnnouncementService';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '10px 0 96px',
      overflowY: 'auto',
      height: 'calc(100% - 108px)',
    },
    loading: {
      textAlign: 'center',
      padding: '24px 0',
      display: 'none',
      width: '100%',
    },
    show: {
      display: 'block',
    },
    empty: {
      padding: 'calc((100% - 143px) / 2) 48px 0',
    },
    emptyImg: {
      margin: 'auto',
      backgroundImage: `url(${iconSrc.emptyNotification})`,
      width: 80,
      height: 80,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    emptyTitle: {
      fontSize: 13,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      color: color.$12,
      textAlign: 'center',
      margin: '8px 0',
    },
    emptyContent: {
      fontSize: 13,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
      color: color.$10,
      textAlign: 'center',
    },
  }),
  {
    name: 'Msg',
  },
);

function Msg() {
  const contentRef = useRef(null);
  const classes = useStyles();
  const orgId = useSelector(store => store.organization.selectOrg.objectId);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const [isLazyLoad, setIsLazyLoad] = useState(false);
  const [isHasNext, setIsHasNext] = useState(false);

  const listRef = useRef(null);
  listRef.current = list;

  const isHasNextRef = useRef(null);
  isHasNextRef.current = isHasNext;
  const isLazyLoadRef = useRef(null);
  isLazyLoadRef.current = isLazyLoad;
  const limit = 20;

  const init = async () => {
    setIsLoading(true);
    const res = await AnnouncementService.getList({ orgId, limit });
    setList(res.announcements);
    setIsHasNext(res.announcements.length === limit);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleScroll = async () => {
    const { scrollHeight, scrollTop, clientHeight } = contentRef.current;
    if (
      !isLazyLoadRef.current &&
      isHasNextRef.current &&
      scrollHeight > window.innerHeight &&
      scrollHeight - (scrollTop + clientHeight) < 50
    ) {
      setIsLazyLoad(true);
      const res = await AnnouncementService.getList({
        orgId,
        limit,
        skip: listRef.current.length,
      });
      setIsHasNext(res.announcements.length === limit);
      setList([...listRef.current, ...res.announcements]);
      setIsLazyLoad(false);
    }
  };

  const renderNotifier = () => {
    return list.map(d => <Notifier key={d._id} msg={d} />);
  };

  return (
    <div ref={contentRef} onScroll={handleScroll} className={classes.root}>
      {list.length > 0 && renderNotifier()}
      <div
        className={classnames(classes.loading, {
          [classes.show]: isLoading || isLazyLoad,
        })}
      >
        <Circular className={classes.progressIcon} topic="grey" size={20} />
      </div>
    </div>
  );
}

export default Msg;
