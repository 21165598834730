import { enqueueSnackbar } from './snackbarAction';
import { SnackBarType } from '../types';
import VpcFeatureService from '../services/VpcFeatureService';
import actionType from './type';
import { initState } from '../reducers/vpcFeatureReducer';

export const setVpcSetting = payload => async dispatch => {
  dispatch({
    type: actionType.SET_VPC_FEATURE,
    payload,
  });
};

export const getVpcSetting = orgId => async dispatch => {
  try {
    const setting = await VpcFeatureService.getVpcSetting(orgId);
    if (setting) {
      setting.isInitVpc = true;
      dispatch(setVpcSetting(setting));
    } else {
      dispatch(
        setVpcSetting({
          ...initState,
          isInitVpc: true,
          orgId,
        }),
      );
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};
