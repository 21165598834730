import PropTypes from 'prop-types';

const IconAddCircle = ({
  width,
  height,
  color,
  className,
  onClick,
  viewBox,
  id,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      width={width}
      height={height}
      onClick={onClick}
      viewBox={viewBox}
    >
      <defs>
        <path
          id={`ic-add-a-${id}`}
          d="M12,3 C7.00909091,3 3,7.00909091 3,12 C3,16.9909091 7.00909091,21 12,21 C16.9909091,21 21,16.9909091 21,12 C21,7.00909091 16.9909091,3 12,3 Z M12,19.3636364 C7.90909091,19.3636364 4.63636364,16.0909091 4.63636364,12 C4.63636364,7.90909091 7.90909091,4.63636364 12,4.63636364 C16.0909091,4.63636364 19.3636364,7.90909091 19.3636364,12 C19.3636364,16.0909091 16.0909091,19.3636364 12,19.3636364 Z M16.0909091,12 C16.0909091,12.4909091 15.7636364,12.8181818 15.2727273,12.8181818 L12.8181818,12.8181818 L12.8181818,15.2727273 C12.8181818,15.7636364 12.4909091,16.0909091 12,16.0909091 C11.5090909,16.0909091 11.1818182,15.7636364 11.1818182,15.2727273 L11.1818182,12.8181818 L8.72727273,12.8181818 C8.23636364,12.8181818 7.90909091,12.4909091 7.90909091,12 C7.90909091,11.5090909 8.23636364,11.1818182 8.72727273,11.1818182 L11.1818182,11.1818182 L11.1818182,8.72727273 C11.1818182,8.23636364 11.5090909,7.90909091 12,7.90909091 C12.4909091,7.90909091 12.8181818,8.23636364 12.8181818,8.72727273 L12.8181818,11.1818182 L15.2727273,11.1818182 C15.7636364,11.1818182 16.0909091,11.5090909 16.0909091,12 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width={24} height={24} />
        <mask id={`ic-add-b-${id}`} fill="#fff">
          <use xlinkHref={`#ic-add-a-${id}`} />
        </mask>
        <g fill={color} mask={`url(#ic-add-b-${id})`}>
          <rect width={24} height={24} />
        </g>
      </g>
    </svg>
  );
};

IconAddCircle.defaultProps = {
  width: 24,
  height: 24,
  color: '#9CC100',
  viewBox: '0 0 24 24',
};

IconAddCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  viewBox: PropTypes.string,
};

export default IconAddCircle;
