import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';

import { color } from '../../themes';
import Button from './Button';
import TextField from './TextField';

const useStyles = makeStyles(
  themes => ({
    paper: {
      width: 'calc(100vw * 2 / 3)',
      borderRadius: '12px',
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: '#f9f9f9',
      maxWidth: 960,
    },
    title: {
      backgroundColor: color.$7,
      padding: '24px',
      '& *': {
        ...themes.typography.h3,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal',
        textAlign: 'center',
        fontSize: 20,
        color: color.$3,
      },
    },
    contentTitle: {
      fontSize: '15px',
      margin: '0 0 32px 0',
      lineHeight: 'normal',
    },
    orgLogo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginBottom: '36px',
    },
    orgLogoControl: {
      marginLeft: '24px',
      '& > span': {
        display: 'inline-block',
        fontSize: '13px',
        color: color.$11,
        paddingTop: '18px',
        marginBottom: '16px',
      },
      '& > div': {
        '& > button': {
          marginRight: '8px',
        },
        '& > button:nth-last-child(1)': {
          marginRight: 0,
        },
      },
    },
    orgDeleteBlock: {
      '& > label': {
        display: 'inline-block',
        marginBottom: '12px',
        color: color.$3,
        fontSize: '17px',
        lineHeight: 1,
        '& span': {
          color: color.$2,
          userSelect: 'none',
        },
      },
    },
  }),
  {
    name: 'ConfirmDeleteModal',
  },
);

const ConfirmDeleteModal = ({
  isOpen,
  onCancel,
  onClick,
  title,
  inputTitle,
  content,
  cancelText,
  submitText,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(inputTitle !== undefined);
  const { t } = useTranslation();

  const onInputChange = e => {
    setIsDisable(
      !((e?.target?.value || '').trim() === (inputTitle || '').trim()),
    );
  };

  const onClickDelete = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
    onCancel();
  };

  return (
    <Modal
      paperClass={classes.paper}
      open={isOpen}
      title={title}
      content={
        <>
          <p className={classes.contentTitle}>{content}</p>
          {inputTitle && (
            <div className={classes.orgDeleteBlock}>
              <label htmlFor="delete-org">
                {t(`delete-organization-dialog.check1`)}
                <span> {inputTitle} </span>
                {t(`delete-organization-dialog.check2`)}
              </label>
              <TextField
                id="delete-org"
                placeholder={t(
                  `delete-organization-dialog.check-input-placeholder`,
                )}
                onChange={onInputChange}
              />
            </div>
          )}
        </>
      }
      actions={
        <>
          <Button
            onClick={onCancel}
            topic="primaryBlue"
            style={{ marginRight: 8 }}
          >
            {cancelText || t(`delete-organization-dialog.cancel`)}
          </Button>
          <Button
            onClick={onClickDelete}
            topic="primaryBlueHighLight"
            isDisable={isDisable}
            isLoading={isLoading}
          >
            {submitText || t(`delete-organization-dialog.delete`)}
          </Button>
        </>
      }
    />
  );
};

export default ConfirmDeleteModal;
