import {
  SET_SOCKETIO_CLIENT,
  SET_SOCKETIO_CLIENT_READY,
  SET_SOCKETIO_SOUND,
  SET_SOCKETIO_IS_PLAY,
} from '../actions/type';

const initialState = {
  client: null,
  ready: false,
  sound: null,
  isPlay: false,
};
export default function socketioReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SOCKETIO_CLIENT:
      return { ...state, client: action.payload };
    case SET_SOCKETIO_CLIENT_READY:
      return { ...state, ready: action.payload };
    case SET_SOCKETIO_SOUND:
      return { ...state, sound: action.payload };
    case SET_SOCKETIO_IS_PLAY:
      return { ...state, isPlay: action.payload };

    default:
      return state;
  }
}
