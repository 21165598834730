import actionType from './type';
import axiosInstance from '../common/axios';
import { enqueueSnackbar } from './snackbarAction';
import { SnackBarType, FacebookTagType } from '../types';

const getTemplateData = (data, name, templateType) => ({
  type: actionType.GET_TEMPLATE_DATA,
  payload: { data, name, templateType },
});

const getTemplates = (orgId, params = {}) => async dispatch => {
  const { type, name, user, count, templateId, group } = params;
  const options = {
    where: {
      organization: {
        __type: 'Pointer',
        className: 'Organization',
        objectId: orgId,
      },
    },
  };

  if (type) {
    options.where.templateType = type;
  }

  if (templateId) {
    options.where.templateId = templateId;
  }

  if (name) {
    options.where.name = { $regex: name, $options: 'i' };
  }

  if (user === true) {
    options.where.vendor = { $ne: true };
  }

  if (group !== undefined) {
    options.group = group;
  }

  if (count) {
    options.count = count;
  } else {
    const keys = ['limit', 'skip', 'sort', 'accounting'];
    keys.forEach(key => {
      if (params[key]) {
        options[key] = params[key];
      }
    });
  }
  let { result } = await axiosInstance.post(
    '/functions/queryTemplate',
    options,
  );
  result = result.filter(item => item.templateType !== 'list');
  dispatch({
    type: actionType.GET_TEMPLATE,
    payload: result,
  });
};

const removeTemplate = (orgId, templateId) => async dispatch => {
  const payload = {
    templateId,
    organization: {
      __type: 'Pointer',
      className: 'Organization',
      objectId: orgId,
    },
  };
  await axiosInstance.post('/functions/removeTemplate', payload);
  dispatch(getTemplates(orgId, { user: true }));
  dispatch({
    type: actionType.REMOVE_TEMPLATE,
  });
};

const publishTemplate = (
  template,
  conversationId,
  orgId,
  publishType = 'card',
  name = '',
  receiptId,
  templateId,
  fbMessengerTag,
) => async dispatch => {
  const payload = {
    template: {
      __type: 'Pointer',
      className: 'Template',
      objectId: template.objectId,
    },
    conversation: {
      __type: 'Pointer',
      className: 'Conversation',
      objectId: conversationId,
    },
  };

  if (conversationId === null) {
    delete payload.conversation;
  }
  let elements;
  let content;
  if (publishType === 'text') {
    content = template;
  } else {
    elements = template.map(element => {
      const temp = { ...element };
      delete temp.id;
      temp.imageUrl =
        temp.imageType === 'url' ? encodeURI(temp.imageUrl) : temp.imageUrl;
      temp.buttons = temp.buttons.map(button => {
        const tempButton = { ...button };
        delete tempButton.id;
        return tempButton;
      });
      return temp;
    });
  }

  if (templateId) {
    payload.template = {
      className: 'Template',
      objectId: templateId,
    };
  } else {
    payload.template = {
      className: 'Template',
      data: {
        elements,
        content,
        templateType: publishType,
        receiptId,
      },
      hidden: true,
      name,
      templateType: publishType,
      organization: {
        className: 'Organization',
        objectId: orgId,
        __type: 'Pointer',
      },
    };
  }

  payload.receiptId = receiptId;

  if (fbMessengerTag !== FacebookTagType.NO_TAG) {
    payload.options = {
      fbMessengerTag,
    };
  }

  try {
    await axiosInstance.post('/functions/publishTemplate', payload);
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export { getTemplateData, getTemplates, removeTemplate, publishTemplate };
