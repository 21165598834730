import actionType from '../actions/type';

const initState = {
  isLoading: false,
  list: [],
  campaign: {},
  analytics: {},
  awardGameData: {},
  lineChannelInfo: {},
  manaulReviewList: [],
  manaulReviewCount: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_EINVOICE:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_EINVOICE_FAIL:
      return initState;
    default:
      return state;
  }
};
