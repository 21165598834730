import SENDER_TYPE from './senderType';
import FILTER_TYPE from './filterType';
import INBOX_TYPE from './inboxType';
import INBOX_ICON_TYPE from './inboxIconType';
import TEMPLATE_BUTTON_TYPE from './templateButtonType';
import NOTIFY_EVENT from './notifyEventType';
import GROWTHTOOL_TYPE from './growthToolIconType';
import SUPER_LINK from './superLink';

const CONSTANTS = {
  MESSAGE: {
    TYPE_SYSTEM_READ: 'application/x-read',
    TYPE_TYPING: 'application/x-typing',
  },
  SENDER_TYPE,
  INBOX_TYPE,
  INBOX_ICON_TYPE,
  GROWTHTOOL_TYPE,
  NOTIFY_EVENT,
  FILTER_TYPE,
  TEMPLATE_BUTTON_TYPE,
  SUPER_LINK,
};

export default CONSTANTS;
