import {
  getBroadcastList,
  resetBroadcast,
  sendBroadcast,
  setBroadcastCreateCondition,
  setBroadcastCreateMessage,
  setBroadcastCreateName,
  setBroadcastCreateTiming,
  setBroadcastCreateTimingType,
  setBroadcastCustomerNum,
  updateBroadcast,
} from './broadcastAction';
import {
  addMessageOfConversation,
  removeMessageOfConversation,
  addMessagesOfConversation,
  assignCustomer,
  customerAccountingList,
  findCustomer,
  findMessage,
  getCustomerAccounting,
  getCustomerByCustomerIds,
  getCustomerFromId,
  getCustomerLazy,
  getKeptMessages,
  getLastRead,
  getMessagelazy,
  keepMessage,
  queryFirstMsgByDate,
  queryMessagesOfConversation,
  receiveMessage,
  removeMessageOfHistory,
  setCustomerList,
  setSelectConvCustomer,
  unKeepMessage,
  updateCustomer,
} from './customerAction';
import {
  deleteFile,
  emptyBucket,
  listFiles,
  uploadToBucket,
} from './fileAction';
import crawl from './metaCrawlAction';
import { fetchOrganization, selectOrganization } from './organizationAction';
import { getPreference, toggleConversationMute } from './preferenceAction';
import { addTag, removeTag } from './tagAction';
import {
  getTemplateData,
  getTemplates,
  publishTemplate,
  removeTemplate,
} from './templateAction';
import {
  closePasswordSendTag,
  fetchUser,
  getPermission,
  mfaLogin,
  normalLogin,
  normalSignup,
  sendPasswordEmail,
  setInviteCodeByUrl,
  setPasswordLevel,
  socialLogin,
  userLogout,
} from './userAction';

import {
  createCampaign,
  deleteCampaign,
  emptyCreateResult,
  exportShareRank,
  getCampaignAnalyze,
  getCampaignByID,
  getCampaignChart,
  getCampaigns,
  getChannelInfo,
  getSharerData,
  resetCampaignSelected,
  toggleCampaign,
  toggleSharerFan,
  updateCampaign,
} from './growthToolAction';

import {
  deleteSuper8apiKey,
  getSuper8apiKey,
  getSuper8apiKeyV2,
  getSuper8apiPermissions,
  setSuper8apiKeyName,
} from './super8apiAction';

import config from '../config';
import actionType from './type';

const toggleNotifyPanel = data => ({
  type: actionType.TOGGLE_NOTIFY,
  payload: data,
});

const toggleFilterPanel = data => ({
  type: actionType.TOGGLE_FILTER_PANEL,
  payload: data,
});

const messageInfoSwitch = data => ({
  type: actionType.SWITCH_INFO_PANEL,
  payload: data,
});

const toggleNavBar = isOpen => {
  localStorage.setItem(config.IS_SIDEBAR_EXPAND_NAME, isOpen);
  return {
    type: actionType.TOGGLE_NAVBAR,
    payload: isOpen,
  };
};

export {
  addMessageOfConversation,
  removeMessageOfConversation,
  addMessagesOfConversation,
  addTag,
  assignCustomer,
  closePasswordSendTag,
  crawl,
  createCampaign,
  customerAccountingList,
  deleteCampaign,
  deleteFile,
  deleteSuper8apiKey,
  emptyBucket,
  emptyCreateResult,
  exportShareRank,
  fetchOrganization,
  fetchUser,
  findCustomer,
  findMessage,
  getBroadcastList,
  getCampaignAnalyze,
  getCampaignByID,
  getCampaignChart,
  getCampaigns,
  getChannelInfo,
  getCustomerAccounting,
  getCustomerByCustomerIds,
  getCustomerFromId,
  getCustomerLazy,
  getKeptMessages,
  getLastRead,
  getMessagelazy,
  getPermission,
  getPreference,
  getSharerData,
  getSuper8apiKey,
  getSuper8apiKeyV2,
  getSuper8apiPermissions,
  getTemplateData,
  getTemplates,
  keepMessage,
  listFiles,
  messageInfoSwitch,
  mfaLogin,
  normalLogin,
  normalSignup,
  publishTemplate,
  queryFirstMsgByDate,
  queryMessagesOfConversation,
  receiveMessage,
  removeMessageOfHistory,
  removeTag,
  removeTemplate,
  resetBroadcast,
  resetCampaignSelected,
  selectOrganization,
  sendBroadcast,
  sendPasswordEmail,
  setBroadcastCreateCondition,
  setBroadcastCreateMessage,
  setBroadcastCreateName,
  setBroadcastCreateTiming,
  setBroadcastCreateTimingType,
  setBroadcastCustomerNum,
  setCustomerList,
  setInviteCodeByUrl,
  setPasswordLevel,
  setSelectConvCustomer,
  setSuper8apiKeyName,
  socialLogin,
  toggleCampaign,
  toggleConversationMute,
  toggleFilterPanel,
  toggleNavBar,
  toggleNotifyPanel,
  toggleSharerFan,
  unKeepMessage,
  updateBroadcast,
  updateCampaign,
  updateCustomer,
  uploadToBucket,
  userLogout,
};
