import actionType from '../actions/type';

export default (
  state = {
    fileList: [],
    uploadedFile: null,
    deleteFile: false,
    isLoading: false,
    hasNextPage: false,
  },
  action,
) => {
  switch (action.type) {
    case actionType.LIST_FILES:
      return { ...state, fileList: action.payload };
    case actionType.LIST_FILES_REQUEST:
      return { ...state, isLoading: true };
    case actionType.LIST_FILES_DONE:
      return { ...state, isLoading: false };
    case actionType.UPLOAD_FILE:
      return {
        ...state,
        uploadedFile: action.payload,
      };
    case actionType.EMPTY_BUCKET:
      return {
        ...state,
        uploadedFile: action.payload,
      };
    case actionType.DELETE_FILE:
      return { ...state, deleteFile: action.payload };
    case actionType.FILELIST_HAS_NEXT_PAGE:
      return {
        ...state,
        hasNextPage: action.payload,
      };
    default:
      return state;
  }
};
