import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Circular from '../shared/Circular';
import Notifier from './Notifier';
import iconSrc from '../../assets/iconSrc';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '12px 0px',
      overflowY: 'auto',
      height: 'calc(100% - 108px)',
    },
    empty: {
      padding: 'calc((100% - 143px) / 2) 48px 0',
    },
    emptyImg: {
      margin: 'auto',
      backgroundImage: `url(${iconSrc.emptyNotification})`,
      width: 80,
      height: 80,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    emptyTitle: {
      fontSize: 13,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      color: color.$12,
      textAlign: 'center',
      margin: '8px 0',
    },
    emptyContent: {
      fontSize: 13,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
      color: color.$10,
      textAlign: 'center',
    },
  }),
  {
    name: 'NotificationCenterMsg',
  },
);

function NotificationCenterMsg({ systemMsgs, onMsgScroll }) {
  const contentRef = useRef(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLoading } = useSelector(store => store.notificationCenter);
  const [isLazyLoad, setIsLazyLoad] = useState(false);
  const isLazyLoadRef = useRef(null);
  isLazyLoadRef.current = isLazyLoad;

  const handleScroll = async () => {
    const { scrollHeight, scrollTop, clientHeight } = contentRef.current;
    if (
      !isLazyLoadRef.current &&
      scrollHeight - (scrollTop + clientHeight) < 50
    ) {
      setIsLazyLoad(true);
      await onMsgScroll();
      setIsLazyLoad(false);
    }
  };

  const renderNotifier = () => {
    return systemMsgs.map(msg => <Notifier key={msg._id} msg={msg} />);
  };

  const renderEmptyNotifier = () => {
    if (isLoading) {
      return null;
    }
    return (
      <div className={classes.empty}>
        <div className={classes.emptyImg} />
        <div className={classes.emptyTitle}>
          {t('notification-center:msg.empty-title')}
        </div>
        <div className={classes.emptyContent}>
          {t('notification-center:msg.empty-content')}
        </div>
      </div>
    );
  };

  return (
    <div ref={contentRef} onScroll={handleScroll} className={classes.root}>
      {systemMsgs.length ? renderNotifier() : renderEmptyNotifier()}
      {isLazyLoad && (
        <div className="loading" style={{ height: 30 }}>
          <Circular topic="grey" variant="indeterminate" size={20} />
        </div>
      )}
    </div>
  );
}

NotificationCenterMsg.defaultProps = {};

NotificationCenterMsg.propTypes = {
  systemMsgs: PropTypes.array.isRequired,
  onMsgScroll: PropTypes.func.isRequired,
};

export default NotificationCenterMsg;
