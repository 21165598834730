import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SOCKETIO_EVENT_TYPE } from '../../types';
import NotificationCenterModal from '../../components/notification-center/NotificationCenterModal';
import {
  getSystemMessage,
  updateSystemMessage,
  closeNotificationCenterModal,
} from '../../actions/notificationCenterAction';

function NotificationCenterModalContainer() {
  const dispatch = useDispatch();
  const { systemMsgs } = useSelector(store => store.notificationCenter);
  const client = useSelector(store => store.socketio.client);
  const clientReady = useSelector(store => store.socketio.ready);

  const onCancel = () => {
    dispatch(closeNotificationCenterModal());
  };

  const handleMsgScroll = async () => {
    await dispatch(getSystemMessage(true));
  };

  useEffect(() => {
    dispatch(getSystemMessage());
  }, []);

  const eventListener = event => {
    if (event.type === SOCKETIO_EVENT_TYPE.SYSTEM_NOFITY) {
      dispatch(updateSystemMessage());
    }
  };

  useEffect(() => {
    if (client && clientReady) {
      client.on('event', eventListener);
    }
    return () => {
      if (client && clientReady) {
        client.off('event', eventListener);
      }
    };
  }, [client, clientReady]);

  return (
    <NotificationCenterModal
      onCancel={onCancel}
      systemMsgs={systemMsgs || []}
      onMsgScroll={handleMsgScroll}
    />
  );
}

NotificationCenterModalContainer.defaultProps = {};

NotificationCenterModalContainer.propTypes = {};

export default NotificationCenterModalContainer;
