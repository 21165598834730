import {
  RICH_MENU_SETTING_RESET,
  RICH_MENU_SETTING_SET_CREATE_REQUEST,
  RICH_MENU_SETTING_GET_GROUPS_SUCCESS,
  RICH_MENU_SETTING_SET_GROUP,
  RICH_MENU_SETTING_GROUPS_STATUS_LOADING,
  SET_RICH_MENU_SETTING,
} from '../actions/type';
import { RichMenuGroupStatusType } from '../types';

const INITIAL_STATE = {
  createRequesting: false,
  list: [],
  lineChannelInfo: {},
};

export default function richMenuSettingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RICH_MENU_SETTING_RESET:
      return {
        ...state,
        list: [],
      };
    case RICH_MENU_SETTING_SET_GROUP:
      return {
        ...state,
        ...action.payload,
      };
    case SET_RICH_MENU_SETTING:
      return {
        ...state,
        ...action.payload,
      };
    case RICH_MENU_SETTING_SET_CREATE_REQUEST:
      return {
        ...state,
        createRequesting: action.bool,
      };
    case RICH_MENU_SETTING_GET_GROUPS_SUCCESS:
      return {
        ...state,
        list: action.list,
      };
    case RICH_MENU_SETTING_GROUPS_STATUS_LOADING:
      return {
        ...state,
        list: state.list.map(o => {
          return o.id === action.groupId
            ? { ...o, status: RichMenuGroupStatusType.LOADING }
            : o;
        }),
      };
    default:
      return state;
  }
}
