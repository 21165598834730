import axiosInstance, { fileUploadInstance, nextAPI } from '../common/axios';
import config from '../config';

export default class FileService {
  static async upload(file, onUploadProgress) {
    // get file url
    const response = await fileUploadInstance.get(
      `${config.UPLOAD_URL}/${encodeURIComponent(file.name)}`,
    );

    // put file to server
    await fileUploadInstance.put(response.url, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress,
    });

    return { name: file.name, url: response.location };
  }

  static async updateFile(fileObjectId) {
    const query = {
      _method: 'put',
    };
    await axiosInstance.post(`/classes/File/${fileObjectId}`, query);
  }

  static async videoTranscoding(fileUrl, orgId) {
    await nextAPI.post(`/template/video_transcoding?orgId=${orgId}`, {
      type: 's3',
      bucket: 'assets.no8.io',
      key: fileUrl.replace('https://assets.no8.io/', ''),
    });
  }
}
