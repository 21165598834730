import actionType from '../actions/type';

const INITIAL_STATE = {
  permissions: [],
  isLoading: false,
  key: '',
  issueKeyLoading: false,
  keys: [],
  isNext: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_SUPER8API_PERMISSIONS_REQUEST:
      return { ...state, isLoading: true };
    case actionType.GET_SUPER8API_PERMISSIONS_FAILURE:
      return { ...state, permissions: action.payload, isLoading: false };
    case actionType.GET_SUPER8API_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.permissions,
        keys: action.payload.keys,
        isLoading: false,
        isNext: action.payload.isNext,
      };
    case actionType.GET_SUPER8API_KEY_REQUEST:
      return { ...state, issueKeyLoading: true };
    case actionType.GET_SUPER8API_KEY_FAILURE:
      return { ...state, key: action.payload, issueKeyLoading: false };
    case actionType.GET_SUPER8API_KEY_SUCCESS:
      return { ...state, key: action.payload, issueKeyLoading: false };
    default:
      return state;
  }
};
