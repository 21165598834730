import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconSavedReplay = ({ onClick, className, id, infoPanel }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="savedReply"
          d="M15.892 4a5 5 0 0 1 5 5v4a5 5 0 0 1-5 5h-3.18l.097 1.918a1 1 0 0 1-1.778.676L8.946 18H8a5 5 0 0 1-5-5V9a5 5 0 0 1 5-5h7.892zm0 1.6H8A3.4 3.4 0 0 0 4.6 9v4A3.4 3.4 0 0 0 8 16.4h.946a1.6 1.6 0 0 1 1.247.598l.925 1.15-.003-.068a1.6 1.6 0 0 1 1.598-1.68h3.18a3.4 3.4 0 0 0 3.4-3.4V9a3.4 3.4 0 0 0-3.4-3.4zM7.5 10.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4.5 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4.5 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`savedReplyMask${id}`} fill="#fff">
            <use xlinkHref="#savedReply" />
          </mask>
          <g
            fill={
              infoPanel === 'defaultTemplate'
                ? color.$3
                : isHover
                ? hexRgba(color.$12, 40)
                : color.$12
            }
            mask={`url(#savedReplyMask${id})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconSavedReplay;
