import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconStar = ({ onlyUnstar, isSaved }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g>
        {isHover || isSaved !== 0 ? (
          <path
            fill={onlyUnstar && isHover ? hexRgba(color.$16, 40) : color.$16}
            d="M6.25807 17.371C5.67107 17.68 4.98507 17.181 5.09707 16.528L5.81207 12.361L2.78407 9.41002C2.30907 8.94702 2.57107 8.14002 3.22807 8.04502L7.41107 7.43702L9.28307 3.64602C9.57607 3.05102 10.4231 3.05102 10.7171 3.64602L12.5891 7.43702L16.7721 8.04502C17.4291 8.14102 17.6911 8.94702 17.2161 9.41002L14.1881 12.36L14.9031 16.528C15.0151 17.181 14.3291 17.68 13.7431 17.371L10.0001 15.404L6.25807 17.37V17.371Z"
          />
        ) : (
          <path
            fill={color.$10}
            d="M6.25807 17.371C5.67107 17.68 4.98507 17.181 5.09707 16.528L5.81207 12.361L2.78407 9.41002C2.30907 8.94702 2.57107 8.14002 3.22807 8.04502L7.41107 7.43702L9.28307 3.64602C9.57607 3.05102 10.4231 3.05102 10.7171 3.64602L12.5891 7.43702L16.7721 8.04502C17.4291 8.14102 17.6911 8.94702 17.2161 9.41002L14.1881 12.36L14.9031 16.528C15.0151 17.181 14.3291 17.68 13.7431 17.371L10.0001 15.404L6.25807 17.37V17.371ZM6.94807 15.201L9.62807 13.791C9.86107 13.669 10.1391 13.669 10.3721 13.791L13.0521 15.201L12.5401 12.217C12.4961 11.957 12.5821 11.692 12.7701 11.509L14.9381 9.39502L11.9421 8.96002C11.6821 8.92202 11.4561 8.75902 11.3401 8.52202L10.0001 5.80802L8.66007 8.52202C8.54407 8.75902 8.31807 8.92202 8.05807 8.96002L5.06207 9.39502L7.23007 11.51C7.41807 11.693 7.50407 11.958 7.46007 12.218L6.94807 15.2V15.201Z"
          />
        )}
      </g>
    </svg>
  );
};

export default IconStar;
