import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Slider from './Slider';
import Button from './Button';
import { color } from '../../themes';
import onboarding01 from '../../assets/images/png/img-onboarding01.png';
import onboarding02 from '../../assets/images/png/img-onboarding-02.png';
import onboarding03 from '../../assets/images/png/img-onboarding-03.png';
import { setToturialDisabled } from '../../actions/userAction';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '9999 !important',
    },
    paper: {
      width: 960,
      height: 724,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    contentBlock: {
      borderRadius: 12,
      width: 960,
      height: 724,
      position: 'relative',
    },
    btn: {
      position: 'absolute',
      bottom: 24,
      left: 24,
    },
    rightBtn: {
      left: 'auto',
      right: 24,
    },
    image: {
      width: '100%',
      height: 540,
    },
    title: {
      fontSize: 20,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.3,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: color.$3,
      marginTop: 23,
      marginBottom: 7,
    },
    content: {
      fontSize: 13,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: color.$12,
      padding: '0 24px',
    },
  },
  {
    name: 'OnboardingModal',
  },
);

function OnboardingModal({ isOpen, onCancel }) {
  const sliderRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectOrg } = useSelector(store => store.organization);
  const [activeSlide, setActiveSlide] = useState(0);

  const onboardingData = [
    {
      imageSrc: onboarding01,
      title: t('welcome'),
      content: t('welcomeContent'),
    },
    {
      imageSrc: onboarding02,
      title: t('welcomeStep2'),
      content: t('welcomeContentStep2'),
    },
    {
      imageSrc: onboarding03,
      title: t('welcomeStep3'),
      content: t('welcomeContentStep3'),
    },
  ];

  const renderOnboarding = () => {
    return onboardingData.map(row => {
      return (
        <div key={`Onboarding${row}`}>
          <img className={classes.image} src={row.imageSrc} alt={row} />
          <div className={classes.title}>{row.title}</div>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: row.content,
            }}
          />
        </div>
      );
    });
  };

  const handleCancel = () => {
    dispatch(setToturialDisabled(true));
    onCancel();
    setTimeout(() => {
      if (selectOrg?.objectId) {
        navigate(`/organization-setting/${selectOrg?.objectId}/linked-app`);
      }
    }, 1000);
  };

  const handleNext = () => {
    const { current } = sliderRef || {};
    if (current) {
      const { slickNext } = current || {};
      if (slickNext) {
        slickNext();
      }
    }
  };

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.contentBlock}>
        <Slider
          sliderRef={sliderRef}
          settings={{
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            draggable: false,
            beforeChange: (current, next) => setActiveSlide(next),
          }}
        >
          {renderOnboarding()}
        </Slider>
        <Button
          className={classes.btn}
          topic="primaryBlue"
          onClick={handleCancel}
        >
          {t('onboarding.skip')}
        </Button>
        {activeSlide >= onboardingData.length - 1 ? (
          <Button
            className={classnames(classes.btn, classes.rightBtn)}
            topic="primaryGreen"
            onClick={handleCancel}
          >
            {t('onboarding.finish')}
          </Button>
        ) : (
          <Button
            className={classnames(classes.btn, classes.rightBtn)}
            topic="primaryGreen"
            onClick={handleNext}
          >
            {t('onboarding.next')}
          </Button>
        )}
      </div>
    </Dialog>
  );
}

export default OnboardingModal;
