import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconWhatsappTemplate = ({ onClick, className, id, infoPanel }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
      id={`IconWhatsappTemplate${id}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7354 22.2615C20.308 22.2615 22.3936 20.1882 22.3936 17.6307C22.3936 15.0733 20.308 13 17.7354 13C15.1627 13 13.0771 15.0733 13.0771 17.6307C13.0771 20.1882 15.1627 22.2615 17.7354 22.2615ZM21.072 16.3964C21.291 16.1477 21.2668 15.7686 21.0181 15.5496C20.7693 15.3307 20.3902 15.3548 20.1713 15.6036L17.1367 19.0512L16.071 17.6386C15.8714 17.3741 15.4952 17.3215 15.2306 17.521C14.9661 17.7206 14.9134 18.0968 15.113 18.3614L16.6219 20.3614C16.7305 20.5053 16.8981 20.5928 17.0783 20.5996C17.2585 20.6064 17.4321 20.5318 17.5513 20.3964L21.072 16.3964Z"
        fill={
          infoPanel === 'whatsappTemplate'
            ? color.$3
            : isHover
            ? hexRgba(color.$12, 40)
            : color.$12
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9862 4H8.04725C5.26944 4 3.01758 6.23858 3.01758 9V13C3.01758 15.7614 5.26944 18 8.04725 18H8.99891L11.0964 20.5944C11.2985 20.8443 11.6089 20.9828 11.9311 20.9667C12.4859 20.9389 12.9131 20.4692 12.8852 19.9176L12.788 18H13.9998V16.4H12.788C11.8673 16.4 11.1342 17.1664 11.1806 18.0805L11.184 18.1487L10.2534 16.9977C9.94794 16.6198 9.48657 16.4 8.99891 16.4H8.04725C6.15834 16.4 4.62707 14.8778 4.62707 13V9C4.62707 7.12223 6.15834 5.6 8.04725 5.6H15.9862C17.8751 5.6 19.4063 7.12223 19.4063 9V13C19.4063 13.7476 19.1637 14.4388 18.7523 15H20.5973C20.8665 14.3875 21.0158 13.7111 21.0158 13V9C21.0158 6.23858 18.764 4 15.9862 4ZM8.55022 11.5C8.55022 12.0523 8.09985 12.5 7.54428 12.5C6.98872 12.5 6.53835 12.0523 6.53835 11.5C6.53835 10.9477 6.98872 10.5 7.54428 10.5C8.09985 10.5 8.55022 10.9477 8.55022 11.5ZM12.071 12.5C12.6266 12.5 13.0769 12.0523 13.0769 11.5C13.0769 10.9477 12.6266 10.5 12.071 10.5C11.5154 10.5 11.0651 10.9477 11.0651 11.5C11.0651 12.0523 11.5154 12.5 12.071 12.5ZM17.6036 11.5C17.6036 12.0523 17.1533 12.5 16.5977 12.5C16.0421 12.5 15.5918 12.0523 15.5918 11.5C15.5918 10.9477 16.0421 10.5 16.5977 10.5C17.1533 10.5 17.6036 10.9477 17.6036 11.5Z"
        fill={
          infoPanel === 'whatsappTemplate'
            ? color.$3
            : isHover
            ? hexRgba(color.$12, 40)
            : color.$12
        }
      />
    </svg>
  );
};

export default IconWhatsappTemplate;
