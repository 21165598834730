import { useState } from 'react';
import { color } from '../../../themes';

const IconSave20 = ({ onClick, className, id }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="save"
          d="M17.275 6.975l-3.75-3.75A.68.68 0 0 0 13 3H4.75C3.475 3 2.5 3.975 2.5 5.25v10.5c0 1.275.975 2.25 2.25 2.25h10.5c1.275 0 2.25-.975 2.25-2.25V7.5a.68.68 0 0 0-.225-.525zM13 16.5H7V12h6v4.5zm3-.75c0 .45-.3.75-.75.75h-.75v-5.25c0-.45-.3-.75-.75-.75h-7.5c-.45 0-.75.3-.75.75v5.25h-.75c-.45 0-.75-.3-.75-.75V5.25c0-.45.3-.75.75-.75h.75v3c0 .45.3.75.75.75h6c.45 0 .75-.3.75-.75s-.3-.75-.75-.75H7V4.5h5.7L16 7.8v7.95z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`saveMask${id}`} fill="#fff">
          <use xlinkHref="#save" />
        </mask>
        <g fill={isHover ? color.$15 : color.$10} mask={`url(#saveMask${id})`}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

export default IconSave20;
