import qs from 'query-string';
import { nextAPI } from '../common/axios';
import config from '../config';

let getECCustomerRequest = null;
let getECOrdersRequest = null;

export default class SuperMarketService {
  static sessionToken() {
    const { sessionToken } = JSON.parse(
      localStorage.getItem(config.LOCAL_STORAGE),
    );
    return sessionToken;
  }

  static async openSuperMarketSSO(orgId, url, provider) {
    window.open(
      `${config.NEXT_API_URL}/super_market/sso?${qs.stringify({
        orgId,
        provider,
        redirectUrl: `${config.EC_DOMAIN_URL}${url}`,
        _SessionToken: this.sessionToken(),
      })}`,
    );
  }

  static async getECSetting(orgId, provider) {
    const response = await nextAPI.get(`/ec/${provider}?orgId=${orgId}`);

    return response;
  }

  static async getECCustomer(orgId, provider, ecCustomerId) {
    if (getECCustomerRequest) {
      getECCustomerRequest.cancel('getECCustomer canceled');
    }

    getECCustomerRequest = nextAPI.CancelToken.source();

    const response = await nextAPI.get(
      `/ec/${provider}/customer/${ecCustomerId}?orgId=${orgId}`,
      {
        cancelToken: getECCustomerRequest.token,
      },
    );
    return response;
  }

  static async getECOrders(orgId, provider, ecCustomerId) {
    if (getECOrdersRequest) {
      getECOrdersRequest.cancel('getECOrders canceled');
    }

    getECOrdersRequest = nextAPI.CancelToken.source();

    const response = await nextAPI.get(
      `/ec/${provider}/customer/${ecCustomerId}/ordersSearch?orgId=${orgId}`,
      {
        cancelToken: getECOrdersRequest.token,
      },
    );
    return response;
  }

  static async getAllServices(orgId) {
    const response = await nextAPI.get(`/services/${orgId}`);
    return response;
  }

  static async getService(orgId, provider) {
    const response = await nextAPI.get(`/services/${orgId}/${provider}`);
    return response;
  }

  static async sendPartnerEmail(data) {
    const response = await nextAPI.post(`/market/partner/contact`, data);
    return response;
  }

  static async getPartnerContact(orgId) {
    const response = await nextAPI.get(
      `/market/partner/contact?orgId=${orgId}`,
    );
    return response;
  }
}
