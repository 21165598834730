import PropTypes from 'prop-types';

const IconCheckCircle = props => {
  const { width, height, color, className, onClick, id } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      width={width}
      height={height}
      onClick={onClick}
    >
      <defs>
        <path
          id="IconCheckCircle"
          d="M19.957 11.785v.693a8.454 8.454 0 0 1-8.479 8.479A8.454 8.454 0 0 1 3 12.478 8.454 8.454 0 0 1 11.478 4c1.156 0 2.39.231 3.469.77.385.155.54.617.385 1.003-.154.385-.617.54-1.002.385-.925-.385-1.85-.616-2.852-.616a6.908 6.908 0 0 0-6.936 6.936 6.908 6.908 0 0 0 6.936 6.937 6.908 6.908 0 0 0 6.937-6.937v-.693c0-.463.308-.771.77-.771.463 0 .772.308.772.77zm.539-6.783a.745.745 0 0 0-1.079 0l-7.939 7.939-1.772-1.773a.745.745 0 0 0-1.08 0 .745.745 0 0 0 0 1.079l2.313 2.312a.833.833 0 0 0 .54.232.833.833 0 0 0 .539-.232l8.478-8.478a.745.745 0 0 0 0-1.079z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={id} fill="#fff">
          <use xlinkHref="#IconCheckCircle" />
        </mask>
        <g fill={color} mask={`url(#${id})`}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

IconCheckCircle.defaultProps = {
  width: 24,
  height: 24,
  color: '#FFF',
  id: 'IconCheckCircle-b',
};

IconCheckCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconCheckCircle;
