import {
  ALL_CUSTOMERS_SET,
  ALL_CUSTOMERS_RESET,
  ALL_CUSTOMERS_FETCH_DATA_REQUEST,
  ALL_CUSTOMERS_FETCH_DATA_REQUEST_CONTINUE,
  ALL_CUSTOMERS_FETCH_DATA_SUCCESS,
  ALL_CUSTOMERS_SET_FETCH_STATUS,
  ALL_CUSTOMERS_FIND_TAG_REQUEST,
  ALL_CUSTOMERS_FIND_TAG_SUCCESS,
  ALL_CUSTOMERS_FIND_TAG_FAILURE,
  ALL_CUSTOMERS_GET_COUNT_REQUEST,
  ALL_CUSTOMERS_GET_COUNT_SUCCESS,
  ALL_CUSTOMERS_GET_COUNT_FAILURE,
  ALL_CUSTOMERS_FETCH_DATA_FAILURE,
  ALL_CUSTOMERS_GET_CUSTOMER_DATA_REQUEST,
  ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS,
  ALL_CUSTOMERS_GET_CUSTOMER_DATA_FAILURE,
  ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_REQUEST,
  ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_SUCCESS,
  ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_FAILURE,
  ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_REQUEST,
  ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_SUCCESS,
  ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_FAILURE,
  ALL_CUSTOMERS_ADD_FILTER,
  ALL_CUSTOMERS_FIND_PARTNER_TAG_SUCCESS,
  ALL_CUSTOMERS_FIND_PARTNER_TAG_FAILURE,
  ALL_CUSTOMERS_FIND_IS_BUY_PARTNER_TAG_SUCCESS,
  ALL_CUSTOMERS_MAPPING_QUERY,
} from './type';
import { FetchStatus, SnackBarType } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import config from '../config';
import TagServices from '../services/TagService';
import CustomerService from '../services/CustomerService';
import i18n from '../i18n';

export function set(newState = {}) {
  return {
    type: ALL_CUSTOMERS_SET,
    newState,
  };
}

export const reset = () => dispatch => {
  dispatch({ type: ALL_CUSTOMERS_RESET });
};

export const mappingQuery = () => (dispatch, getState) => {
  const { tempQuery, tempOptions } = getState().allCustomers;
  if (tempQuery.tagDensity?.type) {
    tempQuery.tagDensity = [tempQuery.tagDensity];
  }
  dispatch({
    type: ALL_CUSTOMERS_MAPPING_QUERY,
    query: tempQuery,
    options: tempOptions,
  });
};

let haveNextCustomerData = false;

export const fetchDataInit = (orgId, ifFirst = false) => async (
  dispatch,
  getState,
) => {
  try {
    const { fetchStatus, query, options } = getState().allCustomers;
    if (fetchStatus !== FetchStatus.FETCHING) {
      dispatch({ type: ALL_CUSTOMERS_GET_COUNT_REQUEST });
      dispatch({ type: ALL_CUSTOMERS_FETCH_DATA_REQUEST });

      const [count, customers] = await Promise.all([
        await CustomerService.getCustomerCount(orgId, query, options),
        await CustomerService.getCustomers(orgId, query, options, 0, true),
      ]);

      haveNextCustomerData =
        customers.length === config.ALL_CUSTOMERS_FIRST_LIMIT_NUMBER;

      dispatch({
        type: ALL_CUSTOMERS_GET_COUNT_SUCCESS,
        count,
      });
      dispatch({
        type: ALL_CUSTOMERS_FETCH_DATA_SUCCESS,
        data: customers,
        status: ifFirst ? FetchStatus.FIRST_DONE : FetchStatus.DONE,
      });
    }
  } catch (error) {
    if (error.message !== 'getCustomers canceled') {
      dispatch({ type: ALL_CUSTOMERS_GET_COUNT_FAILURE });
      dispatch({ type: ALL_CUSTOMERS_FETCH_DATA_FAILURE });

      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.message,
        }),
      );
    }
  }
};

export const fetchDataContinue = orgId => async (dispatch, getState) => {
  try {
    const { fetchStatus, query, options, data } = getState().allCustomers;
    if (fetchStatus !== FetchStatus.FETCHING) {
      if (haveNextCustomerData) {
        dispatch({ type: ALL_CUSTOMERS_FETCH_DATA_REQUEST_CONTINUE });
        const customers = await CustomerService.getCustomers(
          orgId,
          query,
          options,
          data.length,
        );

        haveNextCustomerData =
          customers.length === config.ALL_CUSTOMERS_LIMIT_NUMBER;

        dispatch({
          type: ALL_CUSTOMERS_FETCH_DATA_SUCCESS,
          data: customers,
        });

        setTimeout(() => {
          dispatch({
            type: ALL_CUSTOMERS_SET_FETCH_STATUS,
            status: FetchStatus.DONE,
          });
        }, config.ALL_CUSTOMERS_QUERY_DELAY);
      }
    }
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_GET_COUNT_FAILURE });
    dispatch({ type: ALL_CUSTOMERS_FETCH_DATA_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const findTag = (
  tagName,
  orgId,
  customerId,
  options,
  output,
) => async dispatch => {
  try {
    dispatch({ type: ALL_CUSTOMERS_FIND_TAG_REQUEST });
    const result = await TagServices.findTagV3(
      tagName,
      orgId,
      customerId,
      options,
      output,
    );
    const tags = result.data.map(tag => ({
      id: tag._id,
      name: tag._id,
    }));
    dispatch({ type: ALL_CUSTOMERS_FIND_TAG_SUCCESS, tags });
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_FIND_TAG_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const findPartnerTag = (
  tagName,
  orgId,
  customerId,
  options,
) => async dispatch => {
  try {
    dispatch({ type: ALL_CUSTOMERS_FIND_TAG_REQUEST });
    const result = await TagServices.findExternalTag(
      tagName,
      orgId,
      customerId,
      {
        options: { vendorType: 'partner' },
        ...options,
      },
    );
    const tags = result
      ? result.map(tag => ({
          id: tag?._id,
          ...tag,
        }))
      : null;
    dispatch({ type: ALL_CUSTOMERS_FIND_PARTNER_TAG_SUCCESS, tags });
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_FIND_PARTNER_TAG_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const findIsBuyPartnerTag = (
  tagName,
  orgId,
  customerId,
) => async dispatch => {
  try {
    dispatch({ type: ALL_CUSTOMERS_FIND_TAG_REQUEST });
    const result = await TagServices.findExternalTag(
      tagName,
      orgId,
      customerId,
      {
        options: { vendorType: 'partner' },
        limit: 1,
      },
    );
    const isBuyPartnerTag = !!result;

    dispatch({
      type: ALL_CUSTOMERS_FIND_IS_BUY_PARTNER_TAG_SUCCESS,
      isBuyPartnerTag,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const getCustomerData = customerId => async dispatch => {
  try {
    dispatch({ type: ALL_CUSTOMERS_GET_CUSTOMER_DATA_REQUEST });
    const data = await CustomerService.getCustomerAccounting(customerId);
    dispatch({ type: ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS, data });
    return data;
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_GET_CUSTOMER_DATA_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
    return null;
  }
};

export const getUpdateCustomerData = customerId => async dispatch => {
  try {
    const data = await CustomerService.getCustomerAccounting(customerId);
    dispatch({ type: ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS, data });
    return data;
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_GET_CUSTOMER_DATA_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
    return null;
  }
};

export const setCustomerData = (objectId, data) => async dispatch => {
  try {
    await CustomerService.setCustomer(objectId, data);
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.data-update-success'),
      }),
    );
  } catch (error) {
    if (error.message !== 'setCustomer canceled') {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.message,
        }),
      );
    }
  }
};

export const addTagToCustomer = (
  customerId,
  orgId,
  tagNames,
) => async dispatch => {
  try {
    dispatch({ type: ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_REQUEST });
    await TagServices.addTagToCustomer(customerId, orgId, tagNames);
    dispatch({ type: ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_SUCCESS });
    const tagNameList = tagNames.reduce((pre, tagName, index) => {
      if (index < tagNames.length - 1) {
        return `${pre}${tagName} `;
      }
      return pre + tagName;
    }, '');
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.add-tag-to-customer-success-notification', {
          tagNameList,
        }),
      }),
    );
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const deleteCustomerTag = (
  customerId,
  orgId,
  tagName,
) => async dispatch => {
  try {
    dispatch({ type: ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_REQUEST });
    await TagServices.deleterCustomerTag(customerId, orgId, tagName);
    dispatch({ type: ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_SUCCESS });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.delete-customer-tag-success-notification', {
          tagName,
        }),
      }),
    );
  } catch (error) {
    dispatch({ type: ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const setFilter = (
  query = {},
  options = {},
  from = null,
) => dispatch => {
  dispatch({
    type: ALL_CUSTOMERS_ADD_FILTER,
    query,
    options,
    from,
  });
};

export const findPartnerTagIntegration = orgId => async dispatch => {
  try {
    const response = await CustomerService.getPartnerTagIntegration(orgId);

    dispatch({
      type: ALL_CUSTOMERS_SET,
      newState: {
        integration: response,
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

export const setPartnerTagIntegration = (orgId, data) => async (
  dispatch,
  getState,
) => {
  try {
    const { integration } = getState().allCustomers;
    if (integration) {
      await CustomerService.setPartnerTagIntegration(orgId, data);
    } else {
      await CustomerService.createPartnerTagIntegration(orgId, data);
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.set-partner-tag-setting-success'),
      }),
    );

    return true;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
    return false;
  }
};
