import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from './Button';
import { color } from '../../themes';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '6000 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflow: 'unset',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.$7,
      fontSize: 20,
      lineHeight: 1,
      borderRadius: 12,
      padding: '24px 24px 24px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px 24px 12px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
    },
    'content-block': {
      width: '100%',
      '& > p': {
        fontSize: 15,
        lineHeight: 1,
        color: color.$3,
        marginBottom: 12,
      },
    },
    fontLimit: {
      fontSize: 15,
      color: color.$12,
      backgroundColor: 'rgba(249, 249, 249, 1)',
      paddingLeft: 150,
      height: 25,
    },
    fontLimitCount: {
      border: '1px solid #BDBDBD',
      padding: '2px 6px',
      borderRadius: 8,
      backgroundColor: color.$7,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '12px 24px 24px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      borderRadius: 12,
    },
    'button--margin-right': {
      marginRight: 8,
    },
  },
  {
    name: 'DeleteNoteModal',
  },
);

function DeleteNoteModal({ isOpen, onCancel, onClick, id }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function handleOnClick() {
    onCancel();
    setTimeout(() => {
      onClick(id);
    }, 200);
  }

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.title}>{t('modal.delete-note')}</div>
      <div className={classes.content}>
        <div className={classes['content-block']}>
          <Typography>{t('modal.delete-note-placeholder')}</Typography>
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          topic="primaryBlue"
          className={classes['button--margin-right']}
          onClick={onCancel}
        >
          {t('modal.cancel')}
        </Button>
        <Button topic="primaryBlueHighLight" onClick={handleOnClick}>
          {t('modal.submit')}
        </Button>
      </div>
    </Dialog>
  );
}

export default DeleteNoteModal;
