import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../actions/modalAction';
import UploadSVG from '../../assets/images/svg/img-photo-upload.svg';
import { dataURLtoFile, handleGetCompressImage } from '../../common/utils';
import FileService from '../../services/FileService';
import { color } from '../../themes';
import { MessageContentType, PlatformType } from '../../types';
import Button from './Button';
import ProgressBar from './ProgressBar';

const useStyles = makeStyles(
  theme => ({
    root: { zIndex: '9999 !important' },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
    },
    title: {
      fontSize: 20,
      lineHeight: 1,
      color: color.$3,
      textAlign: 'center',
      padding: 24,
    },
    contentRoot: {
      backgroundColor: color.$19,
      padding: 24,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: color.$10,
      borderRadius: 12,
      backgroundColor: color.$8,
      padding: '32px 16px',
      '& > p': {
        fontSize: 15,
        lineHeight: '20px',
        margin: '0 0 8px 0',
        '& > span': {
          ...theme.link.primaryGreen.default,
        },
        '&:nth-child(3)': {
          margin: 0,
        },
      },
    },
    'content--uploading': {
      paddingTop: 24,
      paddingBottom: 16,
    },
    actionRoot: {
      backgroundColor: color.$19,
      padding: '0 24px 24px',
    },
    uploadContent: {
      width: '100%',
      fontSize: 13,
      lineHeight: 1,
      color: color.$12,
      '& > div': {
        position: 'relative',
        '& > img': {
          height: 100,
          marginRight: 12,
        },
        '& > video': {
          height: 100,
          marginRight: 12,
        },
        '& > audio': {
          width: 300,
          marginRight: 12,
        },
      },
    },
    fileName: {
      display: 'inline-block',
      maxWidth: 320,
    },
    progressBar: {
      width: '100%',
      margin: '16px 0 0',
    },
    errorContent: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      color: color.$17,
      maxWidth: 420,
    },
    'errorContent--audio': {
      width: 236,
    },
    uploadContainer: {
      textAlign: 'center',
    },
    uploadDiv: {
      fontSize: 15,
    },
  }),
  { name: 'UploadDialog' },
);

function UploadDialog({
  isOpen,
  orgId,
  onUploaded,
  platformType,
  isVideo,
  isWhatsappTemplate,
  isCardImage,
  accept,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [isSendFile, setIsSendFile] = useState(false);
  const [uploadURL, setUploadURL] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [errorContent, setErrorContent] = useState({
    isError: false,
    message: '',
  });

  const acceptString =
    accept ||
    (isVideo
      ? '.mp4,.mov'
      : isWhatsappTemplate
      ? '.mp4,.png,.jpg,.jpeg,.pdf'
      : platformType === PlatformType.WHATSAPP
      ? '.jpg,.jpeg,.png,.mp4,.3gpp,.mp3,.aac,.amr,.opus,.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx'
      : '.mp4,.mov,.mp3,.jpg,.jpeg,.png');

  const uploadFileRef = useRef(null);
  uploadFileRef.current = uploadFile;

  const UPLOAD_IMAGE_FILE_SIZE_LIMIT_BYTES = 5000000; // 5mb
  let UPLOAD_AUDIO_FILE_SIZE_LIMIT_BYTES = 20000000; // 20mb
  let UPLOAD_VIDEO_FILE_SIZE_LIMIT_BYTES = 20000000; // 20mb
  const UPLOAD_DOCUMENT_FILE_SIZE_LIMIT_BYTES = 100000000; // 100mb

  let typeReg = /^(?<mimeType>image|audio|video)\/.*/;
  if (isWhatsappTemplate || platformType === PlatformType.WHATSAPP) {
    typeReg = /^(?<mimeType>image|audio|video|application)\/.*/;
    UPLOAD_AUDIO_FILE_SIZE_LIMIT_BYTES = 16000000; // 16mb
    UPLOAD_VIDEO_FILE_SIZE_LIMIT_BYTES = 16000000; // 16mb
  }

  if (platformType === PlatformType.LINE) {
    UPLOAD_VIDEO_FILE_SIZE_LIMIT_BYTES = 210000000; // 210mb code video size
  }

  function onUploadProgress(e) {
    setProgress(Math.floor((e.loaded * 100) / e.total));
  }

  async function onVideoLoaded(video, file) {
    const { videoWidth } = video;
    const { videoHeight } = video;

    let w = 512;
    let h = 512;

    if (videoWidth > videoHeight) {
      w = 512;
      h = videoHeight * (512 / videoWidth);
    } else {
      h = 512;
      w = videoWidth * (512 / videoHeight);
    }

    const canvas = document.createElement('canvas');
    canvas.hidden = true;
    canvas.width = w;
    canvas.height = h;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(video, 0, 0, w, h);

    const dataURI = canvas.toDataURL('image/png');
    const prefix = file.name.split('.')[0];

    const imageFile = dataURLtoFile(dataURI, `${prefix}.png`);
    const response = await FileService.upload(imageFile, onUploadProgress);
    return { previewUrl: response.url, aspectRatio: `${w}:${h}` };
  }

  function validateImgSize(width, height) {
    return (
      width === 960 && (height === 960 || height === 1553 || height === 500)
    );
  }
  const validImage = (file, reader) => {
    return new Promise(resolve => {
      if (/^image\/.*/.test(file.type)) {
        if (isCardImage) {
          const img = new Image();
          img.onload = () => {
            if (!validateImgSize(img.width, img.height)) {
              setErrorContent({
                isError: true,
                message: t('templates:upload-image-remind'),
              });
              resolve(false);
            } else {
              resolve(true);
            }
          };
          img.onerror = () => {
            setErrorContent({
              isError: true,
              message: t('upload-dialog:error-message.image-load-error'),
            });
            resolve(false);
          };
          img.src = reader.result;
        } else if (!/^image\/(jpeg|jpg|png)/.test(file.type)) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.file-format-error'),
          });
          resolve(false);
        } else if (file.size > UPLOAD_IMAGE_FILE_SIZE_LIMIT_BYTES) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.image-over-size'),
          });
          resolve(false);
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  };

  const validAudio = file => {
    if (/^audio\/.*/.test(file.type)) {
      if (platformType === PlatformType.INSTAGRAM) {
        setErrorContent({
          isError: true,
          message: t('upload-dialog:error-message.file-format-errorIG'),
        });
        return false;
      }

      if (platformType === PlatformType.WHATSAPP) {
        if (isWhatsappTemplate) {
          if (/^audio\/.*/.test(file.type)) {
            setErrorContent({
              isError: true,
              message: t(
                'upload-dialog:error-message.file-format-errorWhatsappTemplate',
              ),
            });
            return false;
          }
        }
        if (!/^audio\/(mpeg|aac|amr|ogg)/.test(file.type)) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.file-format-errorWhatsapp'),
          });
          return false;
        }
      }

      if (platformType !== PlatformType.WHATSAPP) {
        if (!/^audio\/(mpeg)/.test(file.type)) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.file-format-error'),
          });
          return false;
        }
      }

      if (file.size > UPLOAD_AUDIO_FILE_SIZE_LIMIT_BYTES) {
        if (platformType === PlatformType.WHATSAPP) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.audio-over-sizeWhatsapp'),
          });
          return false;
        }
        setErrorContent({
          isError: true,
          message: t('upload-dialog:error-message.audio-over-size'),
        });
        return false;
      }
    }
    return true;
  };

  const validVideo = file => {
    if (/^video\/.*/.test(file.type)) {
      if (platformType === PlatformType.INSTAGRAM) {
        setErrorContent({
          isError: true,
          message: t('upload-dialog:error-message.file-format-errorIG'),
        });
        return false;
      }

      if (platformType === PlatformType.WHATSAPP) {
        if (isWhatsappTemplate) {
          if (!/^video\/(mp4|quicktime)/.test(file.type)) {
            setErrorContent({
              isError: true,
              message: t(
                'upload-dialog:error-message.file-format-errorWhatsappTemplate',
              ),
            });
            return false;
          }
        }
        if (!/^video\/(mp4|quicktime|3gpp)/.test(file.type)) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.file-format-errorWhatsapp'),
          });
          return false;
        }
      }

      if (platformType !== PlatformType.WHATSAPP) {
        if (!/^video\/(mp4|quicktime)/.test(file.type)) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.file-format-error'),
          });
          return false;
        }
      }

      if (file.size > UPLOAD_VIDEO_FILE_SIZE_LIMIT_BYTES) {
        if (platformType === PlatformType.WHATSAPP) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.video-over-sizeWhatsapp'),
          });
          return false;
        }
        if (platformType === PlatformType.LINE) {
          setErrorContent({
            isError: true,
            message: t('upload-dialog:error-message.video-over-sizeLine'),
          });
          return false;
        }

        setErrorContent({
          isError: true,
          message: t('upload-dialog:error-message.video-over-size'),
        });
        return false;
      }
    }
    return true;
  };

  const validDocument = file => {
    if (
      !(
        file.type.startsWith('image/') ||
        file.type.startsWith('video/') ||
        file.type.startsWith('audio/')
      )
    ) {
      if (file.size > UPLOAD_DOCUMENT_FILE_SIZE_LIMIT_BYTES) {
        setErrorContent({
          isError: true,
          message: t('upload-dialog:error-message.video-over-size'),
        });
        return false;
      }
    }
    return true;
  };

  async function validFile(file, reader) {
    if (
      !file.name.match(
        /^[\u4e00-\u9fa5_a-zA-Z0-9%][\u4e00-\u9fa5a-zA-Z0-9@%._-]*$/,
      )
    ) {
      setErrorContent({
        isError: true,
        message: t('upload-dialog:error-message.file-name-error'),
      });
      return false;
    }

    const groupResult = typeReg.exec(file.type);

    if (!(groupResult?.groups && groupResult?.groups?.mimeType)) {
      setErrorContent({
        isError: true,
        message: t('upload-dialog:error-message.file-format-error'),
      });
      return false;
    }

    if (!(await validImage(file, reader))) {
      return false;
    }

    if (!validAudio(file)) {
      return false;
    }

    if (!validVideo(file)) {
      return false;
    }

    if (!validDocument(file)) {
      return false;
    }

    setErrorContent({
      isError: false,
      message: '',
    });
    return true;
  }

  const handleUpload = (contentType, data) => {
    onUploaded({
      contentType,
      data,
    });
    dispatch(closeModal());
  };

  const handleUploadAudio = (file, url, mimeType) => {
    if (file.type !== 'audio/amr') {
      const media = new Audio(URL.createObjectURL(file));

      media.onloadedmetadata = () => {
        const duration = Math.floor(media.duration * 1000);
        handleUpload(mimeType, {
          name: file.name,
          url,
          duration,
          contentType: file.type,
        });
      };
    } else {
      handleUpload(mimeType, {
        name: file.name,
        text: isWhatsappTemplate ? file.name : undefined,
        url,
        contentType: file.type,
      });
    }
  };

  const handleUploadVideo = (file, url, mimeType) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);

    video.addEventListener('loadeddata', () => {
      video.currentTime = 1;
    });

    video.addEventListener('seeked', async () => {
      let result;
      if (file.type !== 'video/3gpp') {
        result = await onVideoLoaded(video, file);
      }
      handleUpload(mimeType, {
        name: file.name,
        url,
        text: isWhatsappTemplate ? file.name : undefined,
        contentType: file.type,
        previewUrl: result?.previewUrl,
        aspectRatio: result?.aspectRatio,
      });
    });
  };

  async function upload(file) {
    try {
      if (file.type.includes('image')) {
        // eslint-disable-next-line no-param-reassign
        file = await handleGetCompressImage(file);
      }
      setIsSendFile(true);
      const response = await FileService.upload(file, onUploadProgress);

      const groupResult = typeReg.exec(file.type);
      let mineType = `application/x-${groupResult.groups.mimeType}`;

      if (file.type.indexOf('application') !== -1) {
        mineType = MessageContentType.TYPE_FILE;
      }

      if (groupResult.groups.mimeType === 'audio') {
        handleUploadAudio(file, response.url, mineType);
      } else if (groupResult.groups.mimeType === 'video') {
        FileService.videoTranscoding(response.url, orgId);
        handleUploadVideo(file, response.url, mineType);
      } else {
        handleUpload(mineType, {
          name: file.name,
          text: isWhatsappTemplate ? file.name : undefined,
          url: response.url,
          contentType: file.type,
        });
      }
    } catch (error) {
      setErrorContent({
        isError: true,
        message: t('upload-dialog:error-message.error'),
      });
    } finally {
      setIsSendFile(false);
    }
  }

  const reader = useMemo(() => {
    const localReader = new FileReader();
    localReader.onload = () => {
      setUploadURL(URL.createObjectURL(uploadFileRef.current));
      Promise.resolve(validFile(uploadFileRef.current, reader)).then(
        isValid => {
          if (isValid) {
            upload(uploadFileRef.current);
          }
        },
      );
    };
    return localReader;
  }, []);

  function handleOpenFileChoooser() {
    fileRef.current.click();
  }

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function isValidFileAccept(fileName) {
    const extension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
    const acceptList = acceptString.split(',').map(item => item.trim());
    return acceptList.includes(extension);
  }

  function handleDrop(e) {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (isValidFileAccept(file.name)) {
        setUploadFile(file);
        reader.readAsDataURL(file);
      }
    }
    e.stopPropagation();
  }

  function handleChooseFile(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (isValidFileAccept(file.name)) {
        setUploadFile(file);
        reader.readAsDataURL(file);
      }
    }
  }

  function handleCloseDialog() {
    dispatch(closeModal());
  }

  return (
    <Dialog
      open={isOpen}
      className={classes.root}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.title} disableTypography>
        {t('upload-dialog:title')}
      </DialogTitle>
      <DialogContent className={classes.contentRoot}>
        <div
          className={classnames(classes.content, {
            [classes['content--uploading']]: uploadURL !== '',
          })}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {uploadURL === '' && !errorContent.isError ? (
            <>
              <img src={UploadSVG} alt="Upload File" />
              <p className={classes.uploadContainer}>
                {isVideo ? (
                  platformType === PlatformType.LINE ? (
                    <div>
                      <div>{t('upload-dialog:uploadFileTitle')}</div>
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:line.uploadFileVideo', {
                          count: 200,
                        })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>{t('upload-dialog:uploadFileTitle')}</div>
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:line.uploadFileVideo', {
                          count: 100,
                        })}
                      </div>
                    </div>
                  )
                ) : platformType === PlatformType.LINE ? (
                  <div>
                    <div>{t('upload-dialog:uploadFileTitle')}</div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:line.uploadFileVideo', { count: 200 })}
                    </div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:line.uploadFileAudio', { count: 20 })}
                    </div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:line.uploadFile', { count: 5 })}
                    </div>
                  </div>
                ) : platformType === PlatformType.FACEBOOK ? (
                  <div>
                    <div>{t('upload-dialog:uploadFileTitle')}</div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:facebook.uploadFileVideo', {
                        count: 25,
                      })}
                    </div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:facebook.uploadFileAudio', {
                        count: 20,
                      })}
                    </div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:facebook.uploadFile', { count: 5 })}
                    </div>
                  </div>
                ) : platformType === PlatformType.INSTAGRAM ? (
                  <div>
                    <div>{t('upload-dialog:uploadFileTitle')}</div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:instagram.uploadFile', { count: 5 })}
                    </div>
                  </div>
                ) : isWhatsappTemplate ||
                  platformType === PlatformType.WHATSAPP ? (
                  <div>
                    <div>{t('upload-dialog:uploadFileTitle')}</div>
                    {!isWhatsappTemplate ? (
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:whatsapp.uploadFileVideo', {
                          count: 16,
                        })}
                      </div>
                    ) : (
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:whatsapp.uploadTempFileVideo', {
                          count: 16,
                        })}
                      </div>
                    )}
                    {isWhatsappTemplate && (
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:whatsapp.uploadFileImage', {
                          count: 5,
                        })}
                      </div>
                    )}
                    {!isWhatsappTemplate && (
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:whatsapp.uploadFileAudio', {
                          count: 16,
                        })}
                      </div>
                    )}
                    {!isWhatsappTemplate ? (
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:whatsapp.uploadFile', {
                          count: 100,
                        })}
                      </div>
                    ) : (
                      <div className={classes.uploadDiv}>
                        {t('upload-dialog:whatsapp.uploadTempFile', {
                          count: 20,
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div>{t('upload-dialog:uploadFileTitle')}</div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:default.uploadFileVideo', {
                        count: 100,
                      })}
                    </div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:default.uploadFileAudio', {
                        count: 100,
                      })}
                    </div>
                    <div className={classes.uploadDiv}>
                      {t('upload-dialog:default.uploadFile', { count: 100 })}
                    </div>
                  </div>
                )}
              </p>
              <p>
                <span onClick={handleOpenFileChoooser}>
                  {t('upload-dialog:choose-file')}
                </span>
              </p>
            </>
          ) : (
            <div className={classes.uploadContent}>
              <div>
                {/^video\/(mp4|quicktime)/.test(uploadFile.type) && (
                  <video
                    id="upload-dialog-video"
                    src={uploadURL}
                    muted
                    autoPlay
                  />
                )}
                {/^audio\/(mpeg)/.test(uploadFile.type) && (
                  <audio src={uploadURL} autoPlay controls>
                    <track kind="captions" label={uploadFile.name} />
                  </audio>
                )}
                {/^image\/.*/.test(uploadFile.type) && (
                  <img src={uploadURL} alt="" />
                )}
                <span className={classes.fileName}>{uploadFile.name}</span>
                {errorContent.isError && (
                  <span
                    className={classnames(classes.errorContent, {
                      [classes['errorContent--audio']]: /^audio\/(mpeg)/.test(
                        uploadFile.type,
                      ),
                    })}
                  >
                    {errorContent.message}
                  </span>
                )}
              </div>
              <div className={classes.progressBar}>
                <ProgressBar
                  type={errorContent.isError ? 'error' : 'dark'}
                  percentage={progress}
                />
              </div>
            </div>
          )}
          <input
            ref={fileRef}
            type="file"
            hidden
            accept={acceptString}
            onChange={handleChooseFile}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actionRoot}>
        <Button
          topic="primaryBlue"
          disabled={isSendFile}
          onClick={handleCloseDialog}
        >
          {t('modal.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UploadDialog.defaultProps = {
  onUploaded: () => {},
};

UploadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onUploaded: PropTypes.func,
};

export default UploadDialog;
