import { useState } from 'react';
import { color } from '../../../themes';

const IconTag20 = ({ iconColor, hoverColor, onClick, className, id }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="tag"
          d="M17.363 9.297l-6.085-6.085A.642.642 0 0010.783 3H3.708C3.283 3 3 3.283 3 3.708v7.075c0 .212.07.354.212.495l6.085 6.085c.425.425.92.637 1.486.637s1.132-.212 1.486-.637l5.094-5.094c.85-.85.85-2.123 0-2.972zm-.99 1.981l-5.095 5.095a.684.684 0 01-.99 0L4.415 10.5V4.415H10.5l5.873 5.873a.684.684 0 010 .99zM7.74 6.75a.642.642 0 01.212.495c0 .213-.07.354-.212.496a.642.642 0 01-.496.212.642.642 0 01-.495-.212c-.07-.071-.142-.142-.142-.213-.07-.07-.07-.212-.07-.283 0-.212.07-.353.212-.495.212-.212.495-.283.778-.142.071 0 .142.071.213.142z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`tagMask${id}`} fill="#fff">
          <use xlinkHref="#tag" />
        </mask>
        <g fill={isHover ? hoverColor : iconColor} mask={`url(#tagMask${id})`}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

IconTag20.defaultProps = {
  iconColor: color.$10,
  hoverColor: color.$1,
};

export default IconTag20;
