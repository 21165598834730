import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL_PROPS } from '../actions/type';

const initialState = {
  isOpen: false,
  modalType: null,
  modalProps: null,
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case CLOSE_MODAL:
      return {
        isOpen: false,
        modalType: null,
        modalProps: null,
      };
    case UPDATE_MODAL_PROPS:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...action.modalProps,
        },
      };
    default:
      return state;
  }
}
