import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import hexRgba from 'hex-rgba';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { color } from '../../themes';
import ImageDisplay from './ImageDisplay';
import imgSrc from '../../assets/iconSrc';
import IconButton from './IconButton';

const useStyles = makeStyles(
  () => ({
    textStyleOpen: {
      color: color.$7,
      marginLeft: 4,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textStyleClose: { display: 'none' },
    listitem: {
      padding: '0px 14px',
      '&:hover': {
        backgroundColor: hexRgba(color.$7, 10),
      },
    },
    selected: {
      backgroundColor: `${hexRgba(color.$7, 10)} !important`,
    },
    'beta--postfix': {
      color: color.$18,
      fontSize: 11,
      marginLeft: 8,
    },
  }),
  {
    name: 'NavigationBarFeatureList',
  },
);

function NavigationBarFeatureList({
  open,
  pageName,
  iconName,
  onClick,
  badgeShow,
  name,
  tabIndex,
}) {
  const classes = useStyles();
  const location = useLocation();
  const { i18n } = useTranslation();

  const pathNameRegex = new RegExp(`^/${pageName}.*`);

  const [menuTitle, setMenuTitle] = useState('');

  const lookupTilte = {
    overview: 'organization.overview',
    'super-link': 'organization.super-link',
    'message-center': 'organization.message-center',
    'marketing-automation': 'organization.marketing-automation',
    'customer-management': 'organization.customer-management',
    broadcast: 'organization.broadcast',
    superMarket: 'organization.superMarket',
    'message-bot': 'organization.message-bot',
    'webview-editor': 'organization.webview-editor',
    chatPay: 'organization.chatPay',
    'organization-setting': 'organization.organization-setting',
  };

  useEffect(() => {
    setMenuTitle(`${i18n.t(lookupTilte[iconName] || name)}`);
  }, [i18n.language]);

  const item = (
    <ListItem
      button
      className={classes.listitem}
      selected={pathNameRegex.test(location.pathname)}
      classes={{ selected: classes.selected }}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <IconButton color="primary" component="span">
        <ImageDisplay
          className={classes.icon}
          src={imgSrc[iconName]}
          width={24}
          height={24}
          badgeColor={iconName === 'chatPay' ? 'primary' : 'secondary'}
          badgeShow={badgeShow}
        />
      </IconButton>
      <div style={{ width: '172px' }}>
        <Typography
          variant="subtitle1"
          className={open ? classes.textStyleOpen : classes.textStyleClose}
        >
          {iconName === 'organization-setting' ? (
            <>
              {`${menuTitle}`}
              <span className={classes['beta--postfix']}>
                {i18n.t('account-setting.addons.rich_menu_editor-name')}
              </span>
            </>
          ) : (
            `${menuTitle}`
          )}
        </Typography>
      </div>
    </ListItem>
  );

  return item;
}

NavigationBarFeatureList.defaultProps = {
  name: undefined,
  badgeShow: false,
};

NavigationBarFeatureList.propTypes = {
  open: PropTypes.bool.isRequired,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  badgeShow: PropTypes.bool,
  name: PropTypes.string,
};

export default NavigationBarFeatureList;
