import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconSound = ({
  iconColor,
  iconHoverColor,
  width,
  height,
  mute,
  onClick,
  className,
  children,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {mute ? (
        <svg
          className={className}
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
        >
          <mask id="path-1-inside-1_1053_99708" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.33015 12.8866H2C1.44772 12.8866 1 12.4389 1 11.8866V7.8866C1 7.33431 1.44772 6.8866 2 6.8866H5.33011L10.6805 3.17973C11.3437 2.72025 12.25 3.19491 12.25 4.00173L12.25 15.7714C12.25 16.5783 11.3437 17.0529 10.6805 16.5934L5.33015 12.8866Z"
            />
          </mask>
          <path
            d="M5.33015 12.8866L6.01354 11.9002L5.70523 11.6866H5.33015V12.8866ZM5.33011 6.8866V8.0866H5.70519L6.0135 7.87299L5.33011 6.8866ZM10.6805 3.17973L9.99711 2.19334V2.19334L10.6805 3.17973ZM12.25 4.00173H11.05V4.00173L12.25 4.00173ZM12.25 15.7714H13.45H12.25ZM10.6805 16.5934L11.3639 15.607H11.3639L10.6805 16.5934ZM5.33015 11.6866H2V14.0866H5.33015V11.6866ZM2 11.6866C2.11046 11.6866 2.2 11.7761 2.2 11.8866H-0.2C-0.2 13.1016 0.784973 14.0866 2 14.0866V11.6866ZM2.2 11.8866V7.8866H-0.2V11.8866H2.2ZM2.2 7.8866C2.2 7.99705 2.11046 8.0866 2 8.0866V5.6866C0.784974 5.6866 -0.2 6.67157 -0.2 7.8866H2.2ZM2 8.0866H5.33011V5.6866H2V8.0866ZM6.0135 7.87299L11.3639 4.16613L9.99711 2.19334L4.64671 5.9002L6.0135 7.87299ZM11.3639 4.16613C11.2313 4.25802 11.05 4.16309 11.05 4.00173H13.45C13.45 2.22673 11.4562 1.18248 9.99711 2.19334L11.3639 4.16613ZM11.05 4.00173L11.05 15.7714H13.45L13.45 4.00173L11.05 4.00173ZM11.05 15.7714C11.05 15.6101 11.2313 15.5151 11.3639 15.607L9.99711 17.5798C11.4562 18.5907 13.45 17.5464 13.45 15.7714H11.05ZM11.3639 15.607L6.01354 11.9002L4.64675 13.873L9.99711 17.5798L11.3639 15.607Z"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
            mask="url(#path-1-inside-1_1053_99708)"
          />
          <rect
            x="5.25"
            y="7.25"
            width="0.5"
            height="4.5"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
            strokeWidth="0.5"
          />
          <path
            d="M18.8929 11.3929C19.0357 11.5357 19.0357 11.75 18.8929 11.8929C18.8214 11.9643 18.75 12 18.6429 12C18.5357 12 18.4643 11.9643 18.3929 11.8929L16.5 10L14.6071 11.8929C14.5357 11.9643 14.4643 12 14.3571 12C14.25 12 14.1786 11.9643 14.1071 11.8929C13.9643 11.75 13.9643 11.5357 14.1071 11.3929L16 9.5L14.1071 7.60714C13.9643 7.46429 13.9643 7.25 14.1071 7.10714C14.25 6.96429 14.4643 6.96429 14.6071 7.10714L16.5 9L18.3929 7.10714C18.5357 6.96429 18.75 6.96429 18.8929 7.10714C19.0357 7.25 19.0357 7.46429 18.8929 7.60714L17 9.5L18.8929 11.3929Z"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
            strokeWidth="0.5"
          />
        </svg>
      ) : (
        <svg
          onClick={onClick}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
        >
          <mask id="path-1-inside-1_1053_99697" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.33015 12.8866H2C1.44772 12.8866 1 12.4389 1 11.8866V7.8866C1 7.33431 1.44772 6.8866 2 6.8866H5.33011L10.6805 3.17973C11.3437 2.72025 12.25 3.19491 12.25 4.00173L12.25 15.7714C12.25 16.5783 11.3437 17.0529 10.6805 16.5934L5.33015 12.8866Z"
            />
          </mask>
          <path
            d="M5.33015 12.8866L6.01354 11.9002L5.70523 11.6866H5.33015V12.8866ZM5.33011 6.8866V8.0866H5.70519L6.0135 7.87299L5.33011 6.8866ZM10.6805 3.17973L9.99711 2.19334V2.19334L10.6805 3.17973ZM12.25 4.00173H11.05V4.00173L12.25 4.00173ZM12.25 15.7714H13.45H12.25ZM10.6805 16.5934L11.3639 15.607H11.3639L10.6805 16.5934ZM5.33015 11.6866H2V14.0866H5.33015V11.6866ZM2 11.6866C2.11046 11.6866 2.2 11.7761 2.2 11.8866H-0.2C-0.2 13.1016 0.784973 14.0866 2 14.0866V11.6866ZM2.2 11.8866V7.8866H-0.2V11.8866H2.2ZM2.2 7.8866C2.2 7.99705 2.11046 8.0866 2 8.0866V5.6866C0.784974 5.6866 -0.2 6.67157 -0.2 7.8866H2.2ZM2 8.0866H5.33011V5.6866H2V8.0866ZM6.0135 7.87299L11.3639 4.16613L9.99711 2.19334L4.64671 5.9002L6.0135 7.87299ZM11.3639 4.16613C11.2313 4.25802 11.05 4.16309 11.05 4.00173H13.45C13.45 2.22673 11.4562 1.18248 9.99711 2.19334L11.3639 4.16613ZM11.05 4.00173L11.05 15.7714H13.45L13.45 4.00173L11.05 4.00173ZM11.05 15.7714C11.05 15.6101 11.2313 15.5151 11.3639 15.607L9.99711 17.5798C11.4562 18.5907 13.45 17.5464 13.45 15.7714H11.05ZM11.3639 15.607L6.01354 11.9002L4.64675 13.873L9.99711 17.5798L11.3639 15.607Z"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
            mask="url(#path-1-inside-1_1053_99697)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 12.6176C13 13.131 13.5477 13.4406 13.9239 13.0913C14.8553 12.2264 15.4381 10.9913 15.4381 9.62004C15.4381 8.24879 14.8553 7.01365 13.9239 6.14882C13.5477 5.79948 13 6.10907 13 6.62247C13 6.8192 13.0887 7.00356 13.2264 7.14399C13.8522 7.78185 14.2381 8.65588 14.2381 9.62004C14.2381 10.5842 13.8522 11.4582 13.2264 12.0961C13.0887 12.2365 13 12.4209 13 12.6176Z"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 14.7732C15 15.2727 15.5396 15.5725 15.9243 15.2538C17.5227 13.93 18.541 11.9302 18.541 9.69264C18.541 7.45502 17.5227 5.45525 15.9243 4.13142C15.5396 3.81281 15 4.11257 15 4.61209C15 4.81168 15.0939 4.9984 15.2452 5.12855C16.5284 6.23207 17.341 7.86746 17.341 9.69264C17.341 11.5178 16.5284 13.1532 15.2452 14.2567C15.0939 14.3869 15 14.5736 15 14.7732Z"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
          />
          <rect
            x="5"
            y="7"
            width="1"
            height="5"
            fill={
              isHover ? iconHoverColor || hexRgba(iconColor, 40) : iconColor
            }
          />
        </svg>
      )}
      {children}
    </div>
  );
};

IconSound.defaultProps = {
  iconColor: color.$13,
  width: 20,
  height: 20,
};

export default IconSound;
