import i18n from '../i18n';
import ProvisionService from '../services/ProvisionService';
import RichMenuService from '../services/RichMenuService';
import InspectAddOnService from '../services/InspectAddOnService';
import { VendorType, SnackBarType, VendorStore } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import {
  RICH_MENU_SETTING_SET_CREATE_REQUEST,
  RICH_MENU_SETTING_GET_GROUPS_SUCCESS,
  RICH_MENU_SETTING_SET_GROUP,
  SET_RICH_MENU_SETTING,
} from './type';

export const getAllRichMenuLength = orgId => async dispatch => {
  try {
    const { count } = await RichMenuService.getAllRichMenuLength(orgId);
    return count;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message || error,
      }),
    );
    return null;
  }
};

export const getResource = orgId => async () => {
  try {
    const response = await InspectAddOnService.getResource(
      orgId,
      VendorStore.RICH_MENU_VENDOR_ID,
    );
    return {
      ...response,
      quota: response?.resources?.rich_menu_max_group?.quota,
    };
  } catch (error) {
    return Promise.resolve(RichMenuService.getResource(orgId));
  }
};

export const createRichMenuGroup = (orgId, data) => async dispatch => {
  try {
    dispatch({ type: RICH_MENU_SETTING_SET_CREATE_REQUEST, bool: true });
    const response = await RichMenuService.createRichMenuGroup(orgId, data);
    dispatch({ type: RICH_MENU_SETTING_SET_CREATE_REQUEST, bool: false });
    return response;
  } catch (error) {
    dispatch({ type: RICH_MENU_SETTING_SET_CREATE_REQUEST, bool: false });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message || error,
      }),
    );
    return null;
  }
};

export const createAddOn = orgId => async dispatch => {
  let response;

  try {
    response = await InspectAddOnService.getResource(
      orgId,
      VendorStore.RICH_MENU_VENDOR_ID,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  try {
    const resource = response?.resources?.rich_menu_editor;
    const { quota, used } = resource || {};

    if (!resource || quota > used) {
      return ProvisionService.createAddOn(
        orgId,
        VendorStore.RICH_MENU_VENDOR_ID,
        VendorType.RICH_MENU_EDITOR.type,
      );
    }

    return {};
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
    return { error };
  }
};

export const getRichMenuGroups = orgId => async dispatch => {
  try {
    const list = await RichMenuService.getRichMenuGroups(orgId);
    dispatch({ type: RICH_MENU_SETTING_GET_GROUPS_SUCCESS, list });
    return list;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message || error,
      }),
    );
    return null;
  }
};

export const setRichmenuGroup = (orgId, groupId, data) => async dispatch => {
  try {
    return RichMenuService.setRichMenuGroup(orgId, groupId, data);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message || error,
      }),
    );
    return null;
  }
};

export const deleteRichMenuGroup = (orgId, groupId) => async (
  dispatch,
  getStore,
) => {
  try {
    await RichMenuService.deleteRichMenuGroup(orgId, groupId);
    dispatch({
      type: RICH_MENU_SETTING_SET_GROUP,
      payload: {
        list: getStore().richMenuSetting.list.filter(
          group => group.id !== groupId,
        ),
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message || error,
      }),
    );
    throw new Error(error.message || error);
  }
};

export const publishRichMenuGroup = (
  orgId,
  groupId,
  tag,
  title,
) => async dispatch => {
  try {
    return await RichMenuService.publishRichMenuGroup(orgId, groupId);
  } catch (error) {
    switch (error) {
      case 'error/no-effective-area':
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t('rich-menu-setting.error-deplicate-none-tag'),
          }),
        );
        break;
      case 'error/deplicate-tag':
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t('rich-menu-setting.error-deplicate-tag', { tag }),
          }),
        );
        break;
      default:
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t('rich-menu-setting.error-content', { title }),
          }),
        );
        break;
    }
    dispatch(getRichMenuGroups(orgId));
    return false;
  }
};

export const unpublishRichMenuGroup = (orgId, groupId) => async dispatch => {
  try {
    return await RichMenuService.unpublishRichMenuGroup(orgId, groupId);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message || error,
      }),
    );
    return false;
  }
};

export const getLineChannelInfo = orgId => async dispatch => {
  try {
    const response = await RichMenuService.getLineChannelInfo(orgId);

    dispatch({
      type: SET_RICH_MENU_SETTING,
      payload: {
        lineChannelInfo: {
          liffId: response?.liffId,
          channelId: response?.channelId,
          channelSecret: response?.channelSecret,
          isLegacy: response?.isLegacy,
        },
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error || error?.message,
      }),
    );
  }
};

const actions = {
  createAddOn,
  createRichMenuGroup,
  deleteRichMenuGroup,
  getRichMenuGroups,
  setRichmenuGroup,
  publishRichMenuGroup,
  unpublishRichMenuGroup,
};

export default actions;
