import hexRgba from 'hex-rgba';
import { useState } from 'react';
import { color } from '../../../themes';

const IconFollowup24 = ({ ...props }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.457 3L19.8964 6.88033L16.0004 6.61272"
        stroke={isHover ? hexRgba(color.$12, 40) : color.$12}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8574 4.01264C15.4592 4.3854 16.9119 5.23121 18.0269 6.4402C18.3264 6.765 18.8325 6.78549 19.1573 6.48596C19.4821 6.18643 19.5026 5.68032 19.2031 5.35552C17.8706 3.91062 16.1345 2.89978 14.2201 2.45428C12.3058 2.00878 10.3017 2.1492 8.46817 2.85732C6.63467 3.56542 5.05642 4.80847 3.93846 6.42507L3.93917 6.42557C3.93907 6.42571 3.93897 6.42585 3.93887 6.426C2.82092 8.04259 2.2149 9.958 2.19952 11.9234C2.18414 13.8889 2.76012 15.8135 3.85264 17.4474C4.94515 19.0813 6.50373 20.3489 8.32594 21.0857C10.1481 21.8224 12.1498 21.9941 14.0709 21.5786C15.9919 21.1632 17.7437 20.1796 19.0986 18.7557C19.1324 18.7202 19.1623 18.6825 19.1883 18.6429C19.6814 18.1121 20.1168 17.5256 20.4843 16.8924C21.4711 15.1925 21.9239 13.2353 21.784 11.2747C21.7526 10.834 21.3698 10.5023 20.9291 10.5337C20.4884 10.5652 20.1566 10.9479 20.1881 11.3886C20.3052 13.0291 19.9262 14.6668 19.1006 16.0891C18.2749 17.5114 17.0406 18.6526 15.5581 19.3645C14.0755 20.0763 12.4131 20.3259 10.7869 20.0808C9.16066 19.8357 7.64569 19.1073 6.43876 17.9901C5.23182 16.873 4.3887 15.4187 4.01889 13.8162C4.00745 13.7667 3.99163 13.7192 3.97194 13.6743C3.85332 13.1055 3.79488 12.5229 3.79947 11.936C3.81234 10.2914 4.31942 8.68872 5.25485 7.33606C5.26169 7.32616 5.26828 7.31617 5.27461 7.30608C6.2088 5.96759 7.52143 4.93813 9.0446 4.34987C10.5788 3.75737 12.2556 3.63987 13.8574 4.01264Z"
        fill={isHover ? hexRgba(color.$12, 40) : color.$12}
      />
      <circle
        cx="12"
        cy="12"
        r="5"
        fill="white"
        stroke={isHover ? hexRgba(color.$12, 40) : color.$12}
        strokeWidth="1.8"
      />
      <path
        d="M12 10.5V12.5H14"
        stroke={isHover ? hexRgba(color.$12, 40) : color.$12}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFollowup24;
