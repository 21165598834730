import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import imgSuper8AIStatic from '../../assets/images/svg/imgSuper8AIStatic.svg';
import config from '../../config';
import { color } from '../../themes';
import Button from './Button';
import Loading from './Loading';
import TextEditor from './templates/TextEditor';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '6000 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflow: 'unset',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.$7,
      fontSize: 20,
      lineHeight: 1,
      borderRadius: 12,
      padding: '24px 24px 24px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px 24px 12px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
    },
    'content-block': {
      position: 'relative',
      width: '100%',
    },
    fontLimit: {
      fontSize: 15,
      color: color.$12,
      backgroundColor: 'rgba(249, 249, 249, 1)',
      paddingLeft: 24,
      height: 25,
    },
    fontLimitCount: {
      border: '1px solid #BDBDBD',
      padding: '2px 6px',
      borderRadius: 8,
      backgroundColor: color.$7,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '12px 24px 24px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      borderRadius: 12,
    },
    'button--margin-right': {
      marginRight: 8,
    },
    super8AITItle: {
      display: 'flex',
      alignItems: 'center',
      height: 44,
      background: color.$22,
      borderRadius: 12,
      fontSize: 11,
      width: 'max-content',
      padding: '0px 14px',
      color: color.$15,
      position: 'absolute',
      top: 28,
      left: 16,
    },
    loading: {
      height: 20,
      marginLeft: 8,
    },
  },
  {
    name: 'EditNoteModal',
  },
);

function EditNoteModal({
  isOpen,
  onCancel,
  onClick,
  id,
  note,
  isEdit,
  isSummarizing,
}) {
  const classes = useStyles();
  const isShowEmoji = true;
  const { t } = useTranslation();
  const [origriData, setOrigriData] = useState(note);

  const handleOnChange = value => {
    setOrigriData(value);
  };

  function handleOnClick() {
    if (isEdit) {
      onClick(id, origriData);
    } else {
      onClick(origriData);
    }
    setOrigriData('');
    onCancel();
  }

  useEffect(() => {
    setOrigriData(note);
  }, [note]);

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.title}>
        {isEdit ? t('modal.edit-note') : t('modal.add-note')}
      </div>
      <div className={classes.content}>
        <div className={classes['content-block']}>
          <TextEditor
            onChange={handleOnChange}
            placeholder={isSummarizing ? '' : t('modal.add-note-placeholder')}
            isShowEmoji={isShowEmoji}
            origriData={origriData}
            maxLength={0}
            isShowCustomerName={false}
            disabled={isSummarizing}
          />
          {isSummarizing && (
            <div className={classes.super8AITItle}>
              <img
                width={24}
                height={24}
                src={imgSuper8AIStatic}
                alt="super8AI"
                style={{ marginRight: 4 }}
              />
              {t('template.super8AIIng')}
              <Loading className={classes.loading} />
            </div>
          )}
        </div>
      </div>
      <div className={classes.fontLimit}>
        {t('modal.addNoteFontLimit')}
        <span className={classes.fontLimitCount}>
          {origriData?.length}/{config.NOTE_TEXT_LIMIT}
        </span>
      </div>
      <div className={classes.actions}>
        <Button
          topic="primaryBlue"
          className={classes['button--margin-right']}
          onClick={onCancel}
        >
          {t('modal.cancel')}
        </Button>
        <Button
          topic="primaryBlueHighLight"
          onClick={handleOnClick}
          isDisable={!origriData || origriData?.length > config.NOTE_TEXT_LIMIT}
        >
          {t('modal.submit')}
        </Button>
      </div>
    </Dialog>
  );
}

export default EditNoteModal;
