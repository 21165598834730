import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../themes';
import keepPNG from '../../assets/images/png/ic_pin@2x.png';
import closePNG from '../../assets/images/png/ic_x@2x.png';

import AnnouncementService from '../../services/AnnouncementService';
import { showText } from '../../common/utils';
import { openOrganizationAnnoucementModal } from '../../actions/organizationAction';

const useStyles = makeStyles(
  () => ({
    nailedAnnouncement: {
      height: 24,
      width: '100%',
      backgroundColor: color.$16,
      zIndex: 9998,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0px 16px',
      '& img': {
        marginRight: 6,
      },
      '& a': {
        color: color.$3,
        textDecoration: 'underline',
      },
    },
    announcement: {
      height: 24,
      width: '100%',
      backgroundColor: color.$16,
      zIndex: 9998,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0px 16px',
    },
    overShow: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    closeImg: {
      position: 'absolute',
      right: '20px',
    },
    announcementContent: {
      width: 'calc(77vw)',
      display: 'flex',
      fontSize: 13,
      color: color.$3,
    },
    nailedAnnouncementContent: {
      width: 'calc(77vw)',
      display: 'flex',
      fontSize: 13,
      color: color.$3,
    },
    title: {
      maxWidth: 'calc(15vw)',
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      fontWeight: 500,
      color: color.$3,
    },
  }),
  {
    name: 'Announcement',
  },
);

const Announcement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orgId = useSelector(store => store.organization?.selectOrg?.objectId);

  const announcements = useSelector(
    store => store.organization.orgAnnouncements,
  );

  const read = id => {
    AnnouncementService.markSeen(orgId, id);
  };

  const showAnnouncement = () => {
    dispatch(openOrganizationAnnoucementModal());
  };

  return (
    <>
      {announcements?.nailedAnnouncement && (
        <div
          className={classes.nailedAnnouncement}
          onClick={() => showAnnouncement()}
          onMouseDown={e => {
            e.stopPropagation();
          }}
        >
          <div className={classes.title}>
            <img src={keepPNG} width={16} height={16} alt="keep" />
            <span className={classes.overShow}>
              {announcements.nailedAnnouncement.name}
            </span>
          </div>
          <span style={{ margin: '0px 12px' }}> | </span>

          <div className={classes.nailedAnnouncementContent}>
            <span
              onClick={e => {
                if (e.target.tagName === 'A') e.stopPropagation();
              }}
              className={classes.overShow}
              dangerouslySetInnerHTML={{
                __html: showText(
                  announcements.nailedAnnouncement.content,
                  true,
                ),
              }}
            />
          </div>
        </div>
      )}
      {announcements?.announcement && (
        <div
          className={classes.announcement}
          style={{ marginTop: announcements?.nailedAnnouncement ? 2 : 0 }}
          onClick={() => showAnnouncement()}
          onMouseDown={e => {
            e.stopPropagation();
          }}
        >
          <div className={classes.title}>
            <span className={classes.overShow}>
              {announcements.announcement.name}
            </span>
          </div>
          <span style={{ margin: '0px 12px' }}> | </span>
          <div className={classes.announcementContent}>
            <span
              className={classes.overShow}
              dangerouslySetInnerHTML={{
                __html: showText(announcements.announcement.content, true),
              }}
            />
          </div>
          <img
            onClick={e => {
              e.stopPropagation();
              read(announcements.announcement._id);
            }}
            className={classes.closeImg}
            src={closePNG}
            width={24}
            height={24}
            alt="close"
          />
        </div>
      )}
    </>
  );
};

export default Announcement;
