import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import exportData from '../common/exportData';
import utils, { validateMax, validateText } from '../common/utils';
import i18n from '../i18n';
import { initState } from '../reducers/chatPayReducer';
import ChatPayService from '../services/ChatPayService';
import FileService from '../services/FileService';
import { ChatPayProductStatus, SnackBarType } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import actionType from './type';

export const setData = payload => async dispatch => {
  dispatch({
    type: actionType.SET_CHATPAY,
    payload,
  });
};

export const errorHandler = (err, callback) => async dispatch => {
  const errorCode =
    err?.response?.data?.message ||
    err?.response?.data?.code ||
    err?.response?.data?.error ||
    err?.message ||
    err;

  if (errorCode === 'Not enough stock') {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('chatPay:notEnoughStockError'),
      }),
    );
  } else if (errorCode === 'Duplicate sku') {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('chatPay:shop8.product.duplicate.sku'),
      }),
    );
  } else if (errorCode === 'Uber courier already dropoff') {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t(
          'chatPay:orderContainer.uberCourierAlreadyDropOffError',
        ),
      }),
    );
  } else {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: errorCode,
      }),
    );
  }

  dispatch(setData({ isLoading: false }));
  if (callback) await callback();
  return errorCode;
};

export const uploadFile = file => async dispatch => {
  try {
    const response = await FileService.upload(file);

    return response?.url || '';
  } catch (error) {
    dispatch(errorHandler(error));
  }
  return '';
};

export const doSpecToList = spec => async (dispatch, getState) => {
  try {
    const { products } = getState().chatPay;
    const tempList = [];
    const { list: oldList } = products || {};

    const defaultData = {
      sku: '',
      stock_quantity: 0,
      regular_price: 0,
      sale_price: '',
    };

    spec[0]?.items?.forEach(spec1Item => {
      if (spec[1]?.items?.length) {
        spec[1]?.items?.forEach(spec2Item => {
          const name = `${spec1Item} ${spec2Item}`;
          const oldListRow = oldList.find(l => l?.name === name);

          if (oldListRow) {
            tempList.push(oldListRow);
          } else {
            tempList.push({
              ...defaultData,
              name,
              currency: 'TWD',
              attributes: [
                {
                  name: spec[0]?.name,
                  option: spec1Item,
                },
                {
                  name: spec[1]?.name,
                  option: spec2Item,
                },
              ],
            });
          }
        });
      } else {
        const name = `${spec1Item}`;
        const oldListRow = oldList.find(l => l?.name === name);

        if (oldListRow) {
          tempList.push(oldListRow);
        } else {
          tempList.push({
            ...defaultData,
            name,
            currency: 'TWD',
            attributes: [
              {
                name: spec[0]?.name,
                option: spec1Item,
              },
            ],
          });
        }
      }
    });

    return tempList;
  } catch (error) {
    dispatch(errorHandler(error));
    return [];
  }
};

// 轉換商品資料格式（後端格式轉前端格式）
export const doProductsData = (data = {}) => {
  const { attributes = {}, variations = [] } = data || {};
  const specs = [];
  _forEach(attributes, (items, name) => {
    specs.push({
      name,
      items,
    });
  });

  const list = variations.map(v => {
    const temp = { ...v };
    const name = v?.attributes?.map(a => a?.option)?.join(' ');

    return { ...temp, name };
  });

  return { ...data, specs, list };
};

// 轉換商品資料格式（前端格式轉後端格式）
export const transformProductsData = (data = {}) => {
  const { specs = [], list = [] } = data;
  const attributes = {};
  specs?.forEach(spec => {
    if (spec?.name) {
      attributes[spec?.name] = spec?.items;
    }
  });

  const variations = list.map(a => {
    return {
      id: a?.id,
      sku: a?.sku,
      regular_price: a?.regular_price,
      sale_price: a?.sale_price,
      currency: a?.currency || 'TWD',
      stock_quantity: a?.stock_quantity,
      attributes: a?.attributes,
    };
  });

  return { ...data, attributes, variations };
};

export const checkStoreSettingData = data => {
  const { name, logo, contact } = data;

  return (
    validateText(name) &&
    validateMax(name, 25) &&
    validateText(logo) &&
    validateText(contact?.address?.location)
  );
};

export const getStoreSettings = orgId => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const response = await ChatPayService.getStoreSettings(orgId);

    if (_isEmpty(response)) {
      dispatch(
        setData({ storeSettings: initState.storeSettings, isLoading: false }),
      );
    } else {
      dispatch(setData({ storeSettings: response, isLoading: false }));
    }
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const saveStoreSettings = (orgId, data) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    await ChatPayService.saveStoreSettings(orgId, data);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('chatPay:saveSuccess'),
      }),
    );
    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const setDefaultProduct = () => async dispatch => {
  try {
    dispatch(
      setData({
        products: {
          status: ChatPayProductStatus.DRAFT,
          title: '',
          serial: '',
          desc: '',
          images: [],
          specs: [
            {
              name: '規格',
              items: ['預設'],
            },
          ],
          list: [
            {
              name: '預設',
              sku: '',
              stock_quantity: 0,
              regular_price: 0,
              sale_price: '',
              currency: 'TWD',
              attributes: [
                {
                  name: '規格',
                  option: '預設',
                },
              ],
            },
          ],
        },
      }),
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

// isContinue時，若get list result length = 0，則lazy load不在執行
let isProductsNoOther = false;

export const getProductsContinue = (
  orgId,
  search,
  stock,
  sort,
  status,
) => async (dispatch, getStore) => {
  try {
    if (!isProductsNoOther) {
      const { productsList } = getStore()?.chatPay;
      const dataLength = productsList?.length || 0;

      const { products } = await ChatPayService.getProducts(
        orgId,
        search || undefined,
        stock === 'all' ? undefined : stock,
        sort,
        status,
        parseInt(dataLength / 20, 10) + 1,
      );

      isProductsNoOther = products.length < 20;

      dispatch(
        setData({
          productsList: [...productsList, ...products],
        }),
      );
    }
  } catch (error) {
    isProductsNoOther = true;
    dispatch(errorHandler(error));
  }
};

export const getProductsInit = (
  orgId,
  search,
  stock,
  sort,
  status,
  page,
  limit,
) => async dispatch => {
  try {
    dispatch(
      setData({
        productsList: [],
        productsListCount: 0,
        isLoading: true,
      }),
    );

    const { total, products } = await ChatPayService.getProducts(
      orgId,
      search || undefined,
      stock === 'all' ? undefined : stock,
      sort,
      status,
      page,
      limit,
    );

    dispatch(
      setData({
        productsListCount: total,
        productsList: products,
        isLoading: false,
      }),
    );

    isProductsNoOther = products.length !== 20;
  } catch (error) {
    dispatch(
      errorHandler(error, async () => {
        await dispatch(
          setData({
            productsListCount: initState?.productsListCount,
            productsList: initState?.productsList,
            isLoading: false,
          }),
        );
      }),
    );
  }
};

export const getProduct = (orgId, productId) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const response = await ChatPayService.getProduct(orgId, productId);

    dispatch(setData({ products: doProductsData(response), isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const deleteProduct = (orgId, data) => async (dispatch, getStore) => {
  try {
    const { productsList } = getStore()?.chatPay;

    dispatch(setData({ isLoading: true }));

    await ChatPayService.deleteProduct(orgId, data?.id);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('chatPay:deleteSuccess', { name: data?.name }),
      }),
    );

    dispatch(
      setData({
        productsList: productsList.filter(product => product?.id !== data?.id),
        isLoading: false,
      }),
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const copyProduct = (
  orgId,
  procedureId,
  search,
  stock,
  sort,
  status,
) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const data = await ChatPayService.getProduct(orgId, procedureId);

    await ChatPayService.createProduct(orgId, {
      status: ChatPayProductStatus.DRAFT, // "publish" - 上架，"draft" - 下架
      title: `${data?.name} - 1`,
      desc: data?.desc,
      serial: data?.serial,
      images: data?.images,
      attributes: data?.attributes,
      variations: data?.variations?.map(v => ({
        ...v,
        id: undefined,
        sku: '',
      })),
    });

    await dispatch(getProductsInit(orgId, search, stock, sort, status));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const createProduct = (orgId, data) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const product = transformProductsData(data);

    await ChatPayService.createProduct(orgId, {
      status: product?.status,
      title: product?.name,
      desc: product?.desc,
      serial: product?.serial,
      images: product?.images,
      attributes: product?.attributes,
      variations: product?.variations,
    });

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const updateProduct = (orgId, procedureId, data) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const product = transformProductsData(data);

    await ChatPayService.updateProduct(orgId, procedureId, {
      status: product?.status,
      title: product?.name,
      desc: product?.desc,
      serial: product?.serial,
      images: product?.images,
      attributes: product?.attributes,
      variations: product?.variations,
    });

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const updateProductStatus = (orgId, procedureId, data) => async (
  dispatch,
  getState,
) => {
  try {
    const { productsList } = getState().chatPay;

    await ChatPayService.updateProduct(orgId, procedureId, data);

    dispatch(
      setData({
        productsList: productsList.map(p => (p?.id === data?.id ? data : p)),
      }),
    );

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:updateProductStatus-${data?.status}`, {
          name: data?.name,
        }),
      }),
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

// isContinue時，若get list result length = 0，則lazy load不在執行
let isOrdersNoOther = false;

export const getOrdersContinue = (orgId, query) => async (
  dispatch,
  getStore,
) => {
  try {
    if (!isOrdersNoOther) {
      const {
        orderList,
        orderFilterQuery,
        orderSearchQuery,
      } = getStore()?.chatPay;
      const dataLength = orderList?.length || 0;

      const { orders } = await ChatPayService.getOrders(
        orgId,
        {
          ...query,
          ...orderFilterQuery,
          ...orderSearchQuery,
        },
        parseInt(dataLength / 20, 10) + 1,
      );

      isOrdersNoOther = orders.length < 20;

      dispatch(
        setData({
          orderList: [...orderList, ...orders],
        }),
      );
    }
  } catch (error) {
    isOrdersNoOther = true;
    dispatch(errorHandler(error));
  }
};

export const getOrdersInit = (orgId, query) => async (dispatch, getStore) => {
  try {
    const { orderFilterQuery, orderSearchQuery } = getStore()?.chatPay;

    dispatch(
      setData({
        orderList: [],
        orderListCount: 0,
        isLoading: true,
      }),
    );

    const { total, orders } = await ChatPayService.getOrders(orgId, {
      ...query,
      ...orderFilterQuery,
      ...orderSearchQuery,
    });

    dispatch(
      setData({
        orderList: orders,
        orderListCount: total,
        isLoading: false,
      }),
    );

    isOrdersNoOther = orders.length !== 20;
  } catch (error) {
    dispatch(
      errorHandler(error, async () => {
        await dispatch(
          setData({
            orderList: [],
            orderListCount: 0,
            isLoading: false,
          }),
        );
      }),
    );
  }
};

export const getOrder = (orgId, orderId) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const response = await ChatPayService.getOrder(orgId, orderId);

    dispatch(setData({ order: response, isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const updateOrder = (orgId, orderId, data) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    await ChatPayService.updateOrder(orgId, orderId, {
      status: data?.status,
      shipping: data?.shipping,
      notes: data?.notes,
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:updateOrderSuccess`),
      }),
    );

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const sendProduct = (
  orgId,
  customerId,
  productId,
  fbMessengerTag,
) => async dispatch => {
  try {
    await ChatPayService.sendProduct(
      orgId,
      customerId,
      productId,
      fbMessengerTag,
    );

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:sendProductSuccess`),
      }),
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const createOrder = (
  orgId,
  customerId,
  data,
  fbMessengerTag,
) => async dispatch => {
  try {
    await ChatPayService.createOrder(orgId, fbMessengerTag, {
      customer_id: customerId,
      ...data,
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:sendProductSuccess`),
      }),
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const updateOrdersStatus = (
  orgId,
  orderIds,
  type,
  status,
) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    await ChatPayService.updateOrdersStatus(orgId, orderIds, type, status);

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const sendOrderStatus = (
  orgId,
  orderId,
  customerObjectId,
) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    await ChatPayService.sendOrderStatus(orgId, orderId, customerObjectId);

    await new Promise(resolve => setTimeout(resolve, 2000));

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:sendOrderStatusSuccess`),
      }),
    );

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const getStatsInfo = (orgId, startTime, endTime) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const res = await ChatPayService.getStatsInfo(orgId, startTime, endTime);

    dispatch(setData({ isLoading: false, statsInfo: res }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const getStatsRank = (orgId, startTime, endTime) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const res = await ChatPayService.getStatsRank(orgId, startTime, endTime);

    dispatch(setData({ isLoading: false, statsRank: res }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const getCustomerServiceInfo = (
  orgId,
  startTime,
  endTime,
  name,
  authority,
) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    const res = await ChatPayService.getCustomerServiceInfo(
      orgId,
      startTime,
      endTime,
      name,
      authority,
    );

    dispatch(setData({ isLoading: false, statsCustomerService: res }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const createCustomerGroup = (
  orgId,
  name,
  scope,
  startDate,
  endDate,
) => async dispatch => {
  try {
    await ChatPayService.createCustomerGroup(
      orgId,
      name,
      scope,
      startDate,
      endDate,
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const createTags = (
  orgId,
  tags,
  scope,
  startDate,
  endDate,
) => async dispatch => {
  try {
    await ChatPayService.createTags(orgId, tags, scope, startDate, endDate);
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const confirmOrder = (
  orgId,
  orderId,
  pickupRemark,
) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    await ChatPayService.confirmOrder(orgId, orderId, pickupRemark);

    await new Promise(resolve => setTimeout(resolve, 2000));

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:confirmOrderSuccess`),
      }),
    );

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const copyShareProductLink = (orgId, productId) => async dispatch => {
  try {
    const url = await ChatPayService.getShareProductLink(orgId, productId);

    utils.copyToClipboard(url, () => {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('chatPay:copyProductLinkSuccess'),
        }),
      );
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const copyShareOrderLink = (orgId, data) => async dispatch => {
  try {
    const url = await ChatPayService.getShareOrderLink(orgId, data);

    utils.copyToClipboard(url, () => {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('chatPay:copyOrderLinkSuccess'),
        }),
      );
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const cancelOrder = (orgId, orderId) => async dispatch => {
  try {
    dispatch(setData({ isLoading: true }));

    await ChatPayService.cancelOrder(orgId, orderId);

    await new Promise(resolve => setTimeout(resolve, 2000));

    dispatch(setData({ isLoading: false }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const importProducts = (orgId, s3FileUrl) => async () => {
  try {
    const response = await ChatPayService.importProducts(orgId, s3FileUrl);

    return response;
  } catch (error) {
    return {};
  }
};

export const getUnhandled = orgId => async dispatch => {
  try {
    const response = await ChatPayService.getUnhandled(orgId);

    dispatch(setData({ unhandledCount: response }));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const playSocketioSound = () => (dispatch, getState) => {
  const { isPlay } = getState().chatPay;
  if (isPlay) {
    const audio = getState().chatPay.sound.current;
    audio.currentTime = 0;
    audio.play();

    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 1000);
  }
};

export const exportOrders = (orgId, query, startTime, endTime) => async (
  dispatch,
  getStore,
) => {
  try {
    const { orderFilterQuery } = getStore()?.chatPay;
    const i18nData = exportData.shop8Order();
    await ChatPayService.exportOrders(
      orgId,
      {
        ...query,
        ...orderFilterQuery,
        date: {
          type: 'custom',
          start: startTime.toDate(),
          end: endTime.toDate(),
        },
      },
      i18nData,
    );

    await new Promise(resolve => setTimeout(resolve, 2000));

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:orderListContainer.export-success`),
      }),
    );
  } catch (error) {
    dispatch(errorHandler(error));
  }
};
