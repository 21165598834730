import { Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';

import ReduxProvider from './providers/ReduxProvider';
import DateProvider from './providers/DateProvider';
import ThemeProvider from './providers/ThemeProvider';
import SnackbarProvider from './providers/SnackbarProvider';

import App from './App';
import * as serviceWorkerRegistration from './service-worker';
import Loading from './components/shared/Loading';
import initFacebookSdk from './initFacebookSdk';
import { loadI18n } from './i18n';

import './assets/css/style.scss';

const useStyles = makeStyles(
  () => ({
    loading: {
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      height: '100vh',
    },
  }),
  {
    name: 'rootIndex',
  },
);

function Index() {
  const classes = useStyles();
  const [languageReady, setLanguageReady] = useState(false);

  const initI18n = async () => {
    await loadI18n();
    setLanguageReady(true);
  };

  const initHubSport = () => {
    const script = document.createElement('script');

    script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUB_SPOT_ID}.js`;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  };

  useEffect(() => {
    initI18n();
    if (process.env.REACT_APP_STAGE === 'production') {
      initHubSport();
    }
  }, []);

  return languageReady ? (
    <Suspense
      fallback={
        <div className={classes.loading}>
          <Loading />
        </div>
      }
    >
      <ReduxProvider>
        <ThemeProvider>
          <DateProvider>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </DateProvider>
        </ThemeProvider>
      </ReduxProvider>
    </Suspense>
  ) : (
    <div
      style={{
        width: '100vw',
        textAlign: 'center',
        padding: '100px 44px 44px 44px',
      }}
    >
      <Loading />
    </div>
  );
}

initFacebookSdk().then(
  ReactDOM.render(<Index />, document.querySelector('#root')),
);

serviceWorkerRegistration.register({
  onUpdate: reg => {
    try {
      reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('error', error);
    }
  },
});
