import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useTranslation, Trans } from 'react-i18next';

import Modal from '../../shared/Modal';
import Button from '../../shared/Button';

const useStyles = makeStyles(
  () => ({
    paper: {
      maxWidth: 960,
      width: 'calc(100vw * 2 / 3)',
      borderRadius: '12px',
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: '#f9f9f9',
    },
    content: {
      fontSize: 15,
      lineHeight: 'normal',
      '& > a': {
        color: '#9fc100',
        textDecoration: 'underline',
      },
    },
    'cancel-button': {
      marginRight: 8,
    },
  }),
  { name: 'ConnectInstagramTipModal' },
);

const I18N_PREFIX = 'organization-setting.linked-app';

const ConnectInstagramTipModal = props => {
  const { isOpen, onCancel, onClick } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      paperClass={classes.paper}
      title={t(`${I18N_PREFIX}.check-ig-identification`)}
      content={
        <Typography className={classes.content} component="span">
          <Trans i18nKey={`${I18N_PREFIX}.connect-instagram-description`}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t(`${I18N_PREFIX}.instagram.helpUrl`)}
            >
              {' '}
            </a>
          </Trans>
        </Typography>
      }
      actions={
        <>
          <Button
            className={classes['cancel-button']}
            topic="primaryBlue"
            onClick={onCancel}
          >
            {t('modal.not-now')}
          </Button>
          <Button topic="primaryBlueHighLight" onClick={onClick}>
            {t(`${I18N_PREFIX}.continue`)}
          </Button>
        </>
      }
    />
  );
};

ConnectInstagramTipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConnectInstagramTipModal;
