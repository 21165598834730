import { useState } from 'react';
import PropTypes from 'prop-types';
import { color } from '../../../themes';

function IconFile({ className, width, height, id, iconColor, viewBox }) {
  const [isHover, setIsHover] = useState(false);
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={viewBox}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <defs>
        <path
          id="icon-file"
          d="M20.72 12.65l-7.203 6.846C12.41 20.549 10.985 21 9.56 21a6.012 6.012 0 0 1-3.957-1.504 5.127 5.127 0 0 1 0-7.447l7.281-6.92C13.596 4.45 14.625 4 15.654 4s2.058.376 2.77 1.128c1.583 1.505 1.583 3.837 0 5.341l-7.281 6.92c-.95.903-2.454.903-3.324 0-.396-.376-.633-.977-.633-1.58 0-.601.237-1.203.712-1.579l6.727-6.394c.317-.3.792-.3 1.108 0 .317.301.317.752 0 1.053l-6.727 6.394c-.158.15-.238.301-.238.527 0 .225.08.376.238.526.316.301.791.301 1.108 0l7.281-6.92c.95-.903.95-2.332 0-3.16-.87-.827-2.453-.827-3.324 0L6.79 13.178c-1.583 1.504-1.583 3.836 0 5.34a4.062 4.062 0 0 0 5.62 0l7.28-6.92c.317-.3.792-.3 1.108 0 .317.301.238.753-.079 1.053z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={id} fill="#fff">
          <use xlinkHref="#icon-file" />
        </mask>
        <g fill={isHover ? color.$2 : iconColor} mask={`url(#${id})`}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

IconFile.defaultProps = {
  className: undefined,
  width: 24,
  height: 24,
  iconColor: color.$12,
  viewBox: '0,0,24,24',
};

IconFile.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  viewBox: PropTypes.string,
};

export default IconFile;
