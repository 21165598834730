import { useEffect } from 'react';
import hexRgba from 'hex-rgba';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import imgSrc from '../../assets/iconSrc';
import { color } from '../../themes';
import ImageDisplay from '../shared/ImageDisplay';
import { ModalType, SOCKETIO_EVENT_TYPE } from '../../types';
import {
  setShowNotificationCenterBadgeIcon,
  checkNotificationLastRead,
  resetNotificationLastRead,
  openNotificationCenterModal,
  closeNotificationCenterModal,
} from '../../actions/notificationCenterAction';
import IconButton from '../shared/IconButton';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0 14px',
      '&:hover': {
        backgroundColor: hexRgba(color.$7, 10),
      },
    },
    textStyleOpen: {
      color: color.$7,
      marginLeft: 4,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textStyleClose: { display: 'none' },
  }),
  {
    name: 'NotificationItem',
  },
);

function NotificationItem({ open, tabIndex }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isOpen, modalType } = useSelector(store => store.modal);
  const { userData } = useSelector(state => state.auth);
  const client = useSelector(store => store.socketio.client);
  const clientReady = useSelector(store => store.socketio.ready);
  const { objectId: userId } = userData || {};
  const { showNotificationCenterBadgeIcon } = useSelector(
    store => store.notificationCenter,
  );

  const handleOpenModal = () => {
    if (isOpen && modalType === ModalType.NOTIFICATION_CENTER_MODAL) {
      dispatch(closeNotificationCenterModal());
    } else {
      dispatch(resetNotificationLastRead());
      dispatch(setShowNotificationCenterBadgeIcon(false));
      dispatch(openNotificationCenterModal());
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(checkNotificationLastRead());
    }
  }, [userId]);

  const eventListener = event => {
    if (event.type === SOCKETIO_EVENT_TYPE.SYSTEM_NOFITY) {
      dispatch(setShowNotificationCenterBadgeIcon(true));
    }
  };

  useEffect(() => {
    if (client && clientReady) {
      client.on('event', eventListener);
    }
    return () => {
      if (client && clientReady) {
        client.off('event', eventListener);
      }
    };
  }, [client, clientReady]);

  return (
    <ListItem
      button
      onClick={handleOpenModal}
      className={classes.root}
      onMouseDown={e => {
        e.stopPropagation();
      }}
      tabIndex={tabIndex}
    >
      <IconButton color="primary" component="span">
        <ImageDisplay
          src={imgSrc.icNavBellSvg}
          width={24}
          height={24}
          badgeColor="primary"
          badgeShow={showNotificationCenterBadgeIcon}
        />
      </IconButton>
      <Typography
        variant="subtitle1"
        className={open ? classes.textStyleOpen : classes.textStyleClose}
      >
        {t('navigation.notification')}
      </Typography>
    </ListItem>
  );
}

export default NotificationItem;
