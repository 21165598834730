import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import iconSrc from '../../assets/iconSrc';
import { tranformToRegex } from '../../common/utils';
import { color } from '../../themes';
import Loading from './Loading';
import Tag from './Tag';
import Tooltip from './Tooltip';

const useStyles = makeStyles(
  () => ({
    textFieldContainer: {
      cursor: 'text',
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      minHeight: 44,
      border: `1px solid ${color.$10}`,
      borderRadius: 22,
      padding: '10px 40px 10px 24px',
      backgroundColor: color.$7,
    },
    'textFieldContainer--focus': {
      borderColor: color.$1,
    },
    inputContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'center',
      width: '100%',
    },
    input: {
      width: '100%',
      border: 'none',
      fontSize: 15,
      lineHeight: 1,
      backgroundColor: 'transparent',
      outline: 'none',
      '&::placeholder': {
        color: color.$10,
      },
    },
    textarea: {
      width: '100%',
      border: 'none',
      resize: 'none',
      overflowY: 'hidden',
      fontSize: 15,
      lineHeight: 1,
      'min-height': 50,
      backgroundColor: 'transparent',
      outline: 'none',
      '&::placeholder': {
        color: color.$10,
      },
    },
    dropdown: {
      position: 'absolute',
      top: 'calc(100% + 4px)',
      left: 0,
      right: 0,
      zIndex: 3,
      maxHeight: 294,
      overflow: 'auto',
      backgroundColor: color.$7,
      padding: '8px 0',
      borderRadius: 12,
      boxShadow:
        '0 16px 48px 0 rgba(0, 0, 0, 0.07), 0 6px 18px 0 rgba(0, 0, 0, 0.03)',
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: 13,
      lineHeight: '18px',
      padding: '13px 24px',
      '&:hover': {
        color: color.$2,
      },
    },
    deleteIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      right: 12,
      height: 24,
      transform: 'translateY(-50%)',
    },
    tag: {
      margin: '0 8px 8px 0',
    },
  }),
  { name: 'AutoCompletedTextField' },
);

const AutoCompletedTextField = forwardRef((props, ref) => {
  const {
    className,
    placeholder,
    onChange,
    onEnter,
    candidates,
    onFocus,
    onSetTags,
    type,
    max,
    isLoading,
    defaultTags,
    multiline,
    tagTheme,
    disabled,
  } = props;
  const classes = useStyles();
  const [text, setText] = useState('');
  const regex = new RegExp(`.*${tranformToRegex(text)}.*`);
  const [isFocus, setIsFocus] = useState(false);
  const textFieldContainerRef = useRef(null);
  const [tags, setTags] = useState(defaultTags || []);
  const inputRef = useRef(null);
  const itemComponents = candidates
    .filter(item => {
      return regex.test(item.name);
    })
    .filter(item => !tags.includes(item.name))
    .map(item => (
      <div
        key={item.id}
        className={classes.itemContainer}
        onClick={() => {
          if (max && tags.length >= max) {
            setText('');
            return;
          }

          if (type === 'inline' || type === 'inline-tooltip') {
            const newTags = tags.concat(item.name);
            setTags(newTags);
            onSetTags(newTags);
          }
          onEnter(item.name, item);
          setIsFocus(false);
          setText('');
          onChange('');
        }}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {item?.iconComponent}
        {item.name}
      </div>
    ));

  function changeText(e) {
    const { value } = e.target;
    setText(value);
    onChange(value);
  }

  function clearText() {
    setText('');
  }

  function handleBlur() {
    if (max && tags.length >= max) {
      clearText();
      return;
    }

    const tempText = text.trim();
    if (tempText !== '') {
      if (type === 'inline' || type === 'inline-tooltip') {
        const newTags = tags.concat(tempText);
        setTags(newTags);
        onSetTags(newTags);
      }
      onEnter(tempText);
    }
    clearText();
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      handleBlur();
    }
  }

  function handleOnFocus() {
    setIsFocus(true);
    onFocus(text);
  }

  let tagComponents = null;

  function handleDeleteTag(tagName) {
    const tempTags = tags.filter(tag => tag !== tagName);
    setTags(tempTags);
    onSetTags(tempTags);
  }

  useEffect(() => {
    if (defaultTags) {
      setTags(defaultTags);
    }
  }, [defaultTags]);

  if (type === 'inline') {
    tagComponents = tags.map((tagName, index) => (
      <Tag
        className={classes.tag}
        key={`auto-compeleted-text-field-${index}`}
        tag={tagName}
        color={tagTheme}
        clickable={!disabled}
        deleteable={!disabled}
        onDeleteFunction={handleDeleteTag}
      />
    ));
  }

  if (type === 'inline-tooltip') {
    tagComponents = tags.map((tagName, index) => (
      <Tooltip placement="top" title={tagName}>
        <div style={{ width: '100%' }}>
          <Tag
            className={classes.tag}
            key={`auto-compeleted-text-field-${index}`}
            tag={tagName}
            color={tagTheme}
            clickable={!disabled}
            deleteable={!disabled}
            onDeleteFunction={handleDeleteTag}
          />
        </div>
      </Tooltip>
    ));
  }

  useImperativeHandle(ref, () => ({
    clearTags() {
      setTags([]);
    },
  }));

  return (
    <ClickAwayListener onClickAway={() => setIsFocus(false)}>
      <div
        className={classnames(
          {
            [classes.textFieldContainer]: true,
            [classes['textFieldContainer--focus']]: isFocus && !disabled,
          },
          className,
        )}
        ref={textFieldContainerRef}
        onClick={() => {
          setIsFocus(true);
          inputRef.current.focus();
        }}
      >
        <div className={classes.inputContainer}>
          {tagComponents}

          {multiline ? (
            <textarea
              ref={inputRef}
              className={classes.textarea}
              placeholder={placeholder}
              value={text}
              onChange={changeText}
              onKeyDown={handleKeyDown}
              onFocus={handleOnFocus}
              onBlur={handleBlur}
              disabled={disabled}
            />
          ) : (
            <input
              ref={inputRef}
              className={classes.input}
              type="text"
              placeholder={placeholder}
              value={text}
              onChange={changeText}
              onKeyDown={handleKeyDown}
              onFocus={handleOnFocus}
              onBlur={handleBlur}
              disabled={disabled}
            />
          )}
        </div>
        {text !== '' && (
          <div className={classes.deleteIcon}>
            <img src={iconSrc.icClear} alt="clear" onClick={clearText} />
          </div>
        )}
        {isLoading && <Loading />}
        {!disabled && !isLoading && isFocus && itemComponents.length > 0 && (
          <div className={classes.dropdown}>{itemComponents}</div>
        )}
      </div>
    </ClickAwayListener>
  );
});

AutoCompletedTextField.defaultProps = {
  placeholder: '',
  onChange: () => {},
  onEnter: () => {},
  onFocus: () => {},
  type: 'normal',
  onSetTags: () => {},
  max: undefined,
  isLoading: false,
  multiline: false,
  tagTheme: 'colorPrimary',
};

AutoCompletedTextField.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  onFocus: PropTypes.func,
  type: PropTypes.oneOf(['normal', 'inline', 'inline-tooltip']),
  onSetTags: PropTypes.func,
  max: PropTypes.number,
  isLoading: PropTypes.bool,
  multiline: PropTypes.bool,
  tagTheme: PropTypes.string,
};

export default AutoCompletedTextField;
