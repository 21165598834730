import actionType from './type';
import axiosInstance from '../common/axios';

const muteConversation = async (conversationId, preference) => {
  const query = {
    mutedConversation: {
      __op: 'AddUnique',
      objects: [conversationId],
    },
    _method: 'PUT',
  };

  await axiosInstance.post(`/classes/Preference/${preference.objectId}`, query);
};

const unmuteConversation = async (conversationId, preference) => {
  const query = {
    mutedConversation: {
      __op: 'Remove',
      objects: [conversationId],
    },
    _method: 'PUT',
  };
  await axiosInstance.post(`/classes/Preference/${preference.objectId}`, query);
};

const toggleConversationMute = (
  conversationId,
  preference,
) => async dispatch => {
  const isMuted =
    preference.mutedConversation.filter(item => item === conversationId)
      .length !== 0;
  if (isMuted) {
    await unmuteConversation(conversationId, preference);
  } else {
    await muteConversation(conversationId, preference);
  }
  dispatch({
    type: actionType.TOGGLE_MUTE,
  });
};

const getPreference = () => async dispatch => {
  const query = {
    where: {},
    limit: 1,
    _method: 'GET',
  };
  const { results } = await axiosInstance.post('/classes/Preference', query);

  dispatch({
    type: actionType.GET_PREFERENCE,
    payload: results[0],
  });
};

export { getPreference, toggleConversationMute };
