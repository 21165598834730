import { useState } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(
  theme => ({
    root: {
      minWidth: 80,
    },
    buttonContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    isLoading: {
      cursor: 'not-allowed !important',
      '&:active': {
        pointerEvents: 'none',
      },
    },
    text: {
      padding: '14px 24px',
    },
    primaryBlue: {
      ...theme.button.primaryBlue.default,
      '&:hover': {
        ...theme.button.primaryBlue.hover,
      },
      '&.disabled': {
        ...theme.button.disabled,
        '&:hover': {
          ...theme.button.disabled,
        },
      },
    },

    primaryBlueHighLight: {
      ...theme.button.primaryBlueHighLight.default,
      '&:hover': {
        ...theme.button.primaryBlueHighLight.hover,
      },
      '&.disabled': {
        ...theme.button.disabled,
        '&:hover': {
          ...theme.button.disabled,
        },
      },
    },
    primaryBlueHighLightSpinner: {
      ...theme.button.primaryBlueHighLight.spinner.default,
      '&.hover': {
        ...theme.button.primaryBlueHighLight.spinner.hover,
      },
    },
    primaryBlueSpinner: {
      ...theme.button.primaryBlue.spinner.default,
      '&.hover': {
        ...theme.button.primaryBlue.spinner.hover,
      },
    },
    primaryHighlight: {
      ...theme.button.primaryHighlight.default,
      '&:hover': {
        ...theme.button.primaryHighlight.hover,
      },
      '&.disabled': {
        ...theme.button.disabled,
        '&:hover': {
          ...theme.button.disabled,
        },
      },
    },
    primaryHighlightSpinner: {
      ...theme.button.primaryHighlight.spinner.default,
      '&.hover': {
        ...theme.button.primaryHighlight.spinner.hover,
      },
    },
    primaryGreen: {
      ...theme.button.primaryGreen.default,
      '&:hover': {
        ...theme.button.primaryGreen.hover,
      },
      '&.disabled': {
        ...theme.button.disabled,
        '&:hover': {
          ...theme.button.disabled,
        },
      },
    },
    primaryGreenSpinner: {
      ...theme.button.primaryGreen.spinner,
    },
    primaryGrey: {
      ...theme.button.primaryGrey.default,
      '&:hover': {
        ...theme.button.primaryGrey.hover,
      },
      '&.disabled': {
        ...theme.button.disabled,
        '&:hover': {
          ...theme.button.disabled,
        },
      },
    },
    IconPrimaryGrey: {
      ...theme.button.IconPrimaryGrey.default,
      '&:hover': {
        ...theme.button.IconPrimaryGrey.hover,
      },
      '&.disabled': {
        ...theme.button.disabled,
        '&:hover': {
          ...theme.button.disabled,
        },
      },
    },
  }),
  {
    name: 'Button',
  },
);

const Button = ({
  className,
  style,
  type,
  topic,
  isDisable,
  children,
  width,
  height,
  isLoading,
  onClick,
  ...others
}) => {
  const [isHover, setIsHover] = useState(false);
  const classes = useStyles();

  return (
    <MUIButton
      type={type}
      disabled={isDisable}
      style={{ width, height, ...style }}
      className={classNames([
        classes[topic],
        ...(isDisable ? ['disabled'] : []),
        isLoading && classes.isLoading,
        className,
      ])}
      classes={{
        root: classes.root,
        text: classes.text,
      }}
      onFocus={() => {}}
      onClick={onClick}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      {...others}
    >
      {isLoading ? (
        <CircularProgress
          className={classNames([
            classes[`${topic}Spinner`],
            ...(isHover ? ['hover'] : []),
          ])}
        />
      ) : (
        <div className={classes.buttonContent}>{children}</div>
      )}
    </MUIButton>
  );
};

Button.defaultProps = {
  topic: 'primary',
  isDisable: false,
};

export default Button;
