import _uniqBy from 'lodash/unionBy';
import {
  ALL_CUSTOMERS_SET,
  ALL_CUSTOMERS_RESET,
  ALL_CUSTOMERS_FETCH_DATA_REQUEST,
  ALL_CUSTOMERS_FETCH_DATA_REQUEST_CONTINUE,
  ALL_CUSTOMERS_FETCH_DATA_SUCCESS,
  ALL_CUSTOMERS_FETCH_DATA_FAILURE,
  ALL_CUSTOMERS_ADD_FILTER,
  ALL_CUSTOMERS_DELETE_FILTER,
  ALL_CUSTOMERS_SET_FETCH_STATUS,
  ALL_CUSTOMERS_FIND_TAG_REQUEST,
  ALL_CUSTOMERS_FIND_TAG_SUCCESS,
  ALL_CUSTOMERS_FIND_TAG_FAILURE,
  ALL_CUSTOMERS_GET_COUNT_REQUEST,
  ALL_CUSTOMERS_GET_COUNT_SUCCESS,
  ALL_CUSTOMERS_GET_COUNT_FAILURE,
  ALL_CUSTOMERS_GET_CUSTOMER_DATA_REQUEST,
  ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS,
  ALL_CUSTOMERS_GET_CUSTOMER_DATA_FAILURE,
  ALL_CUSTOMERS_SEARCH_MATCH_PHONE_MAIL,
  ALL_CUSTOMERS_FIND_PARTNER_TAG_FAILURE,
  ALL_CUSTOMERS_FIND_PARTNER_TAG_SUCCESS,
  ALL_CUSTOMERS_FIND_IS_BUY_PARTNER_TAG_SUCCESS,
  ALL_CUSTOMERS_MAPPING_QUERY,
} from '../actions/type';
import { CustomerFilterType, FetchStatus } from '../types';

const INITIAL_STATE = {
  from: null,
  fetchStatus: FetchStatus.INITIAL,
  countFetchStatus: FetchStatus.INITIAL,
  data: [],
  count: 0,
  query: {
    originalDisplayName: null,
    displayName: null,
    joinDate: {
      type: null,
      afterJoinDate: null,
      beforeJoinDate: null,
    },
    conversationDate: {
      type: null,
      afterConversationDate: null,
      beforeConversationDate: null,
    },
    tagDensity: [],
    partnerTag: [],
    platforms: [],
    genders: [],
    cellPhone: null,
    email: null,
    friendship: null,
  },
  options: {
    isNoTagCustomer: false,
  },
  tempQuery: {
    originalDisplayName: null,
    displayName: null,
    joinDate: {
      type: null,
      afterJoinDate: null,
      beforeJoinDate: null,
    },
    conversationDate: {
      type: null,
      afterConversationDate: null,
      beforeConversationDate: null,
    },
    tagDensity: [],
    partnerTag: [],
    platforms: [],
    genders: [],
    cellPhone: null,
    email: null,
    friendship: null,
    inboxes: [],
  },
  tempOptions: {
    isNoTagCustomer: false,
  },
  tagCandidates: [],
  partnerTagCandidates: null,
  customerData: {},
  isSearchMatchPhoneMail: false,
  integration: {},
  isBuyPartnerTag: false,
};

export default function allCustomersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALL_CUSTOMERS_SET:
      return {
        ...state,
        ...action.newState,
      };
    case ALL_CUSTOMERS_SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.status,
      };
    case ALL_CUSTOMERS_RESET:
      return { ...INITIAL_STATE, isBuyPartnerTag: state.isBuyPartnerTag };
    case ALL_CUSTOMERS_FETCH_DATA_REQUEST:
      return {
        ...state,
        data: [],
        fetchStatus: FetchStatus.INITIAL,
      };
    case ALL_CUSTOMERS_FETCH_DATA_REQUEST_CONTINUE:
      return {
        ...state,
        fetchStatus: FetchStatus.FETCHING,
      };
    case ALL_CUSTOMERS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: _uniqBy(state.data.concat(action.data), 'objectId'),
        fetchStatus: action.status || state.fetchStatus,
      };
    case ALL_CUSTOMERS_FETCH_DATA_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    case ALL_CUSTOMERS_MAPPING_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...(action.query || {}),
        },
        options: {
          ...state.options,
          ...(action.options || {}),
        },
      };
    case ALL_CUSTOMERS_ADD_FILTER:
      return {
        ...state,
        tempQuery: {
          ...state.tempQuery,
          ...(action.query || {}),
        },
        tempOptions: {
          ...state.tempOptions,
          ...(action.options || {}),
        },
        from: action.from || null,
      };
    case ALL_CUSTOMERS_DELETE_FILTER:
      const query = {};
      const options = {};
      switch (action.filterType) {
        case CustomerFilterType.JOIN_DATE:
          query.joinDate = { ...INITIAL_STATE.query.joinDate };
          break;
        case CustomerFilterType.CONVERSATION_DATE:
          query.conversationDate = { ...INITIAL_STATE.query.conversationDate };
          break;
        case CustomerFilterType.TAG:
          query.tagDensity = [];
          break;
        case CustomerFilterType.PARTNER_TAG:
          query.partnerTag = [];
          break;
        case CustomerFilterType.PLATFORM:
          query.platforms = [];
          break;
        case CustomerFilterType.GENDER:
          query.genders = [];
          break;
        case CustomerFilterType.PHONE:
          query.cellPhone = null;
          break;
        case CustomerFilterType.EMAIL:
          query.email = null;
          break;
        case CustomerFilterType.FRIENDSHIP:
          query.friendship = null;
          break;
        case CustomerFilterType.NO_TAG:
          options.isNoTagCustomer = INITIAL_STATE.options.isNoTagCustomer;
          break;
        case CustomerFilterType.INBOX:
          options.inboxes = [];
          break;
        default:
      }
      return {
        ...state,
        tempQuery: {
          ...state.tempQuery,
          ...query,
        },
        tempOptions: {
          ...state.tempOptions,
          ...options,
        },
      };
    case ALL_CUSTOMERS_FIND_TAG_REQUEST:
    case ALL_CUSTOMERS_FIND_TAG_FAILURE:
      return {
        ...state,
        tagCandidates: [],
      };
    case ALL_CUSTOMERS_FIND_TAG_SUCCESS:
      return {
        ...state,
        tagCandidates: action.tags,
      };
    case ALL_CUSTOMERS_FIND_PARTNER_TAG_FAILURE:
      return {
        ...state,
        partnerTagCandidates: [],
      };
    case ALL_CUSTOMERS_FIND_PARTNER_TAG_SUCCESS:
      return {
        ...state,
        partnerTagCandidates: action.tags,
      };
    case ALL_CUSTOMERS_FIND_IS_BUY_PARTNER_TAG_SUCCESS:
      return {
        ...state,
        isBuyPartnerTag: action.isBuyPartnerTag,
      };
    case ALL_CUSTOMERS_GET_COUNT_REQUEST:
      return {
        ...state,
        countFetchStatus: FetchStatus.INITIAL,
        count: 0,
      };
    case ALL_CUSTOMERS_GET_COUNT_SUCCESS:
      return {
        ...state,
        countFetchStatus: FetchStatus.DONE,
        count: action.count,
      };
    case ALL_CUSTOMERS_GET_COUNT_FAILURE:
      return {
        ...state,
        countFetchStatus: FetchStatus.FAILURE,
      };
    case ALL_CUSTOMERS_GET_CUSTOMER_DATA_REQUEST:
    case ALL_CUSTOMERS_GET_CUSTOMER_DATA_FAILURE:
      return {
        ...state,
        customerData: {},
      };
    case ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        customerData: action.data,
      };
    case ALL_CUSTOMERS_SEARCH_MATCH_PHONE_MAIL:
      return {
        ...state,
        isSearchMatchPhoneMail: action.payload,
      };
    default:
      return state;
  }
}
