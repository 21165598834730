import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Button from '../shared/Button';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      padding: '12px 24px',
      '&:hover': {
        backgroundColor: color.$19,
      },
    },
    picture: props => ({
      backgroundImage: `url(${props.picture})`,
      width: 44,
      height: 44,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      marginRight: 12,
      borderRadius: 44,
      flex: '0 0 44px',
    }),
    contentBlock: {
      width: '100%',
    },
    content: {
      fontSize: 13,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
      color: color.$13,
      wordBreak: 'break-all',
      '& b': {
        fontWeight: 500,
      },
    },
    createdDate: {
      fontSize: 11,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      color: color.$11,
      marginTop: 6,
    },
    button: {
      margin: '7px 0 0 auto',
      display: 'none',
    },
    show: {
      display: 'block',
    },
  }),
  {
    name: 'Notifier',
  },
);

function Notifier({ msg }) {
  const { data, createdAt } = msg || {};
  const { message, link } = data.body || {};
  const { picture, title } = message || {};
  const createdDate = moment(createdAt.iso).format('YYYY-MM-DD HH:mm');
  const classes = useStyles({ picture });
  const { t } = useTranslation();

  const onLink = () => {
    window.open(link.href, '_blank');
  };

  return (
    <div className={classes.root}>
      <div className={classes.picture} />
      <div className={classes.contentBlock}>
        <div
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={classes.createdDate}>{createdDate}</div>
        <Button
          className={classnames(classes.button, {
            [classes.show]: !!link,
          })}
          topic="primaryBlue"
          onClick={onLink}
        >
          {t('notification-center:msg.linkBtn')}
        </Button>
      </div>
    </div>
  );
}

Notifier.defaultProps = {};

Notifier.propTypes = {
  msg: PropTypes.shape({}).isRequired,
};

export default Notifier;
