import {
  SET_SOCKETIO_CLIENT,
  SET_SOCKETIO_CLIENT_READY,
  SET_SOCKETIO_SOUND,
  SET_SOCKETIO_IS_PLAY,
} from './type';

export const setSocketioClient = newState => dispatch => {
  dispatch({
    type: SET_SOCKETIO_CLIENT,
    payload: newState,
  });
};

export const setSocketioClientReady = newState => dispatch => {
  dispatch({
    type: SET_SOCKETIO_CLIENT_READY,
    payload: newState,
  });
};

export const setSocketioSound = newState => dispatch => {
  dispatch({
    type: SET_SOCKETIO_SOUND,
    payload: newState,
  });
};
export const setSocketioIsPlay = newState => dispatch => {
  dispatch({
    type: SET_SOCKETIO_IS_PLAY,
    payload: newState,
  });
};

export const playSocketioSound = () => (dispatch, getState) => {
  const { isPlay } = getState().socketio;
  if (isPlay) {
    const audio = getState().socketio.sound.current;
    audio.currentTime = 0;
    audio.play();

    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 1000);
  }
};

export default {
  setSocketioClient,
  setSocketioClientReady,
  setSocketioSound,
  playSocketioSound,
  setSocketioIsPlay,
};
