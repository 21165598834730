import { useState } from 'react';
import { color } from '../../../themes';

const IconDownload20 = ({ className, id }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <defs>
        <path
          id="download"
          d="M4.816 14.367h10.368a.816.816 0 0 1 .102 1.627l-.102.006H4.816a.816.816 0 0 1-.102-1.627l.102-.006h10.368zM10 3c.417 0 .76.312.81.714l.007.103v7.475l1.493-1.493a.817.817 0 1 1 1.155 1.155l-2.887 2.888a.817.817 0 0 1-1.155 0l-2.888-2.888A.817.817 0 1 1 7.69 9.8l1.493 1.493V3.817c0-.451.366-.817.817-.817z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`downloadMask${id}`} fill="#fff">
          <use xlinkHref="#download" />
        </mask>
        <g
          fill={isHover ? color.$1 : color.$10}
          mask={`url(#downloadMask${id})`}
        >
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

export default IconDownload20;
