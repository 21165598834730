import { useState } from 'react';
import { color } from '../../../themes';

const IconEmoji44 = ({ onClick, className, id, width, height, iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <path
        id={`icon${id}`}
        fill={isHover ? color.$1 : iconColor}
        d="M22 33c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11zm0-1.6c-5.192 0-9.4-4.208-9.4-9.4s4.208-9.4 9.4-9.4 9.4 4.208 9.4 9.4-4.208 9.4-9.4 9.4zm0-3.6c-1.846 0-3.152-.87-3.752-2.527-.15-.416.064-.875.48-1.025.415-.15.874.064 1.024.48.366 1.009 1.06 1.472 2.248 1.472 1.2 0 1.945-.478 2.379-1.51.17-.407.64-.599 1.047-.428.407.172.599.64.428 1.048-.688 1.635-2.02 2.49-3.854 2.49zm-2-8.3c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5 1.5.672 1.5 1.5zm5.5 1.5c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5z"
      />
    </svg>
  );
};

IconEmoji44.defaultProps = {
  width: 44,
  height: 44,
  iconColor: color.$12,
};

export default IconEmoji44;
