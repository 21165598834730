import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconAssign = ({ onClick, className, id, width, height, iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  const random = Math.random();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="inboxAssign"
          d="M16.128 12a4.8 4.8 0 0 1 4.796 4.586l.004.214v3a.8.8 0 0 1-1.593.1l-.007-.1v-3a3.2 3.2 0 0 0-3.018-3.195l-.182-.005H12.3a.8.8 0 0 1-.1-1.594l.1-.006h3.828zm-8.334.18a.8.8 0 0 1 1.126.114l3 3.678a.81.81 0 0 1 .18.506.807.807 0 0 1-.206.536l.067-.086a.804.804 0 0 1-.075.095l-2.992 3.313a.8.8 0 1 1-1.188-1.072L9.5 17.277H3.8a.8.8 0 0 1-.1-1.593l.1-.006 5.815-.001-1.935-2.371a.8.8 0 0 1 .035-1.052zM14.3 3a3.8 3.8 0 1 1 0 7.6 3.8 3.8 0 0 1 0-7.6zm0 1.6a2.2 2.2 0 1 0 0 4.4 2.2 2.2 0 0 0 0-4.4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M-24-10h268v44H-24z" />
        <g>
          <mask id={`inboxAssignMask${id}${random}`} fill="#fff">
            <use xlinkHref="#inboxAssign" />
          </mask>
          <g
            fill={isHover ? hexRgba(iconColor, 40) : iconColor}
            mask={`url(#inboxAssignMask${id}${random})`}
          >
            <path d="M0 0h24v24H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconAssign.defaultProps = {
  width: 24,
  height: 24,
  iconColor: color.$12,
};

export default IconAssign;
