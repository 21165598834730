import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  ContentState,
  Editor,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HASHTAG,
  HASHTAG_REGEX,
  WA_TEMP_PLACEHOLDER_REGEX,
} from '../../../common/utils';
import EmojiPicker from '../EmojiPicker';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import TextDecorator from './TextDecorator';
import WhatsAppPlaceholderDecorator from './WhatsAppPlaceholderDecorator';
import IconAddCircle from '../../../assets/images/Icons/IconAddCircle';
import Dropdown from '../Dropdown';
import TextField from '../TextField';
// import imgSrc from '../../../assets/iconSrc';

import { color } from '../../../themes';

const useStyles = makeStyles(
  () => ({
    textContainer: {
      position: 'relative',
    },
    content: {
      marginTop: '12px',
      width: '100%',
      minHeight: 124,
      fontSize: 15,
      color: color.$3,
      borderRadius: 24,
      border: 'solid 1px #bdbdbd',
      backgroundColor: '#ffffff',
      padding: '16px 24px',
      margin: '12px 0 0 0',
      resize: 'none',
      outline: 'none',
      paddingBottom: 32,
    },
    tootip: {
      top: '12px',
    },
    endAdornment: props => ({
      position: 'absolute',
      right: 0,
      bottom: props.isWhatsappTemplate ? undefined : -5,
      top: props.isWhatsappTemplate ? 80 : undefined,
      display: 'flex',
      '& > span, & > div > span': {
        width: 44,
        height: 44,
        display: 'inline-block',
      },
    }),
    maxLength: {
      position: 'absolute',
      right: 12,
      top: 18,
      fontSize: '11px',
      color: color.$10,
    },
    addName: {
      position: 'absolute',
      left: 24,
      bottom: 14,
      display: 'inline-flex',
      alignItems: 'center',
      color: color.$2,
      fontSize: 15,
      lineHeight: 1,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.6',
      },
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '& > span > span': {
        marginRight: 4,
        width: 20,
        height: 20,
      },
    },
    addPlaceholderTitle: {
      marginTop: '12px',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '15px',
      color: color.$13,
    },
    placeholderCount: {
      marginTop: '8px',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
      color: color.$11,
    },
    addPlaceholderBtn: {
      width: '114px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '12px',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '15px',
      color: color.$2,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.6',
      },
    },
    addPlaceholderIcon: {
      marginRight: '4px',
    },
    placeholderItem: {
      display: 'flex',
      marginTop: '12px',
    },
    placeholderTitle: {
      width: '40px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '15px',
      color: color.$13,
    },
  }),
  {
    name: 'TextEditor',
  },
);

const TextEditor = ({
  onChange,
  origriData,
  placeholder,
  maxLength,
  addTooltip,
  isShowCustomerName,
  isShowEmoji,
  isDialog,
  disabled,
  style,
  className,
  isWhatsappTemplate,
  isWaParameterEditable,
  waPlaceholders,
  onWaPlaceholdersChange,
}) => {
  function countPlaceholders(str) {
    // 正则表达式匹配{{数字}}
    const regex = /\{\{\d+\}\}/g;
    // 使用match方法找到所有匹配项
    const matches = str.match(regex);
    // 如果有匹配项，返回数量，否则返回0
    return matches ? matches.length : 0;
  }
  const placeholderCount = countPlaceholders(origriData);
  const placeholderLimit = 5;

  const classes = useStyles({ isWhatsappTemplate });
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(
      isWhatsappTemplate &&
        (placeholderCount || (!placeholderCount && !disabled))
        ? WhatsAppPlaceholderDecorator
        : TextDecorator,
    ),
  );
  const [topValue, setTopValue] = useState(80); // 假设这是你想动态更新的样式值

  const waTempPlaceholderTypes = [
    {
      text: t('organization-setting.whatsappTemplate.placeholderType.customer'),
      value: 'customer',
    },
    {
      text: t('organization-setting.whatsappTemplate.placeholderType.custom'),
      value: 'custom',
    },
  ];

  const waTempPlaceholderCustomerValues = [
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.originalDisplayName',
      ),
      value: 'originalDisplayName',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.displayName',
      ),
      value: 'displayName',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.gender',
      ),
      value: 'gender',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.birthday',
      ),
      value: 'birthday',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.cellPhone',
      ),
      value: 'cellPhone',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.email',
      ),
      value: 'email',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.nation',
      ),
      value: 'nation',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.location',
      ),
      value: 'location',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.address',
      ),
      value: 'address',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.about',
      ),
      value: 'about',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.language',
      ),
      value: 'language',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.customField1',
      ),
      value: 'customField1',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.customField2',
      ),
      value: 'customField2',
    },
    {
      text: t(
        'organization-setting.whatsappTemplate.placeholderCustomer.customField3',
      ),
      value: 'customField3',
    },
  ];

  const editorContainer = useRef(null);
  const editor = useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  const getSearchTextAt = (blockText, position) => {
    const str = blockText.substr(0, position);
    const insert = str.length;

    return {
      begin: insert,
      end: insert,
    };
  };

  const getSearchText = selection => {
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    return getSearchTextAt(blockText, anchorOffset);
  };

  const getWhatsAppSearchText = (selection, teditorState) => {
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const currentContent = teditorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    return getSearchTextAt(blockText, anchorOffset);
  };

  function sortAddPlaceholders(str) {
    // 正则表达式匹配{{数字}}
    const regex = /\{\{(\d+)\}\}/g;
    let match;
    const tempPlaceholders = [];
    match = regex.exec(str);
    // 找到所有匹配项及其索引
    while (match !== null) {
      tempPlaceholders.push({
        index: match.index,
        value: match[0],
        number: parseInt(match[1], 10),
      });
      match = regex.exec(str);
    }

    // 根据数字升序排序
    tempPlaceholders.sort((a, b) => a.number - b.number);

    // 分割原始字符串为不包含占位符的部分
    const parts = str.split(/\{\{\d+\}\}/);

    // 重建字符串，交替添加文本部分和排序后的占位符
    let sortedStr = parts[0];
    tempPlaceholders.forEach((tempPlaceholder, i) => {
      sortedStr += tempPlaceholder.value;
      if (i < parts.length - 1) {
        // 确保在添加占位符后添加正确的文本部分
        sortedStr += parts[i + 1];
      }
    });

    return sortedStr;
  }

  function sortRemovePlaceholders(str) {
    // 正则表达式匹配{{数字}}
    const regex = /\{\{\d+\}\}/g;
    let counter = 1; // 初始化计数器
    // 使用正则表达式找到所有匹配项，并替换为按顺序递增的数字
    const sortedStr = str.replace(regex, () => {
      const c = counter;
      counter += 1;
      return `{{${c}}}`;
    });
    return sortedStr;
  }

  const transPlaceholderToEntity = (tEditorState, text) => {
    const tContentState = ContentState.createFromText(text);
    let newEditorState = EditorState.push(tEditorState, tContentState);
    const currentSelectionState = tEditorState.getSelection();
    const { begin: tBegin, end: tEnd } = getWhatsAppSearchText(
      currentSelectionState,
      tEditorState,
    );
    const tSelection = newEditorState.getSelection().merge({
      anchorOffset: tBegin,
      focusOffset: tEnd,
    });
    const regex = /\{\{(\d+)\}\}/g;
    const blocks = newEditorState.getCurrentContent().getBlockMap().toArray();

    blocks.forEach(block => {
      const blockKey = block.getKey();
      const blockText = block.getText();
      [...blockText.matchAll(regex)].forEach(match => {
        const contentState = newEditorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
          'mention',
          'SEGMENTED',
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const begin = match.index;
        const end = match.index + match[0].length;
        const mentionTextSelection = SelectionState.createEmpty(blockKey).merge(
          {
            anchorOffset: begin,
            focusOffset: end,
          },
        );
        const applyEntityContent = Modifier.applyEntity(
          contentStateWithEntity,
          mentionTextSelection,
          entityKey,
        );
        newEditorState = EditorState.push(
          newEditorState,
          applyEntityContent,
          'apply-entity',
        );
      });
    });

    newEditorState = EditorState.forceSelection(newEditorState, tSelection);

    return newEditorState;
  };

  const handleChange = teditorState => {
    let value = teditorState.getCurrentContent().getPlainText();

    if (isWhatsappTemplate) {
      for (let index = 0; index < placeholderCount; index += 1) {
        if (value.indexOf(`{{${index + 1}}}`) === -1) {
          value = sortRemovePlaceholders(value);
          const newEditorState = transPlaceholderToEntity(teditorState, value);
          setEditorState(newEditorState);
          onChange(value);
          return;
        }
      }
    }

    setEditorState(teditorState);
    onChange(value);
  };

  const insertCharacter = () => {
    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity('mention', 'SEGMENTED');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const currentSelectionState = editorState.getSelection();

    const { begin, end } = getSearchText(currentSelectionState);
    const mentionTextSelection = currentSelectionState.merge({
      anchorOffset: begin,
      focusOffset: end,
    });

    let mentionReplacedContent = Modifier.replaceText(
      editorState.getCurrentContent(),
      mentionTextSelection,
      HASHTAG,
      undefined, // no inline style needed
      entityKey,
    );

    if (begin === 0) {
      mentionReplacedContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        mentionTextSelection,
        ` ${HASHTAG}`,
        undefined, // no inline style needed
        entityKey,
      );
    }
    // If the mention is inserted at the end, a space is appended right after for
    // a smooth writing experience.
    const blockKey = mentionTextSelection.getAnchorKey();
    const blockSize = editorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getLength();
    if (blockSize === end) {
      mentionReplacedContent = Modifier.insertText(
        mentionReplacedContent,
        mentionReplacedContent.getSelectionAfter(),
        ' ',
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      mentionReplacedContent,
      'insert-fragment',
    );

    handleChange(
      EditorState.forceSelection(
        newEditorState,
        mentionReplacedContent.getSelectionAfter(),
      ),
    );
  };

  const handlePastedText = text => {
    const newState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text.trim(),
    );
    handleChange(EditorState.push(editorState, newState, 'insert-fragment'));

    return true;
  };

  const onEmojiClick = item => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      item,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters',
    );

    handleChange(newEditorState);
  };

  const insertWaTempPlaceholder = index => {
    const currentSelectionState = editorState.getSelection();
    const { begin, end } = getSearchText(currentSelectionState);
    const mentionTextSelection = currentSelectionState.merge({
      anchorOffset: begin,
      focusOffset: end,
    });

    let mentionReplacedContent = Modifier.replaceText(
      editorState.getCurrentContent(),
      mentionTextSelection,
      `{{${index}}}`,
      undefined, // no inline style needed
    );

    if (begin === 0) {
      mentionReplacedContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        mentionTextSelection,
        ` {{${index}}}`,
        undefined, // no inline style needed
      );
    }

    // If the mention is inserted at the end, a space is appended right after for
    // a smooth writing experience.
    const blockKey = mentionTextSelection.getAnchorKey();
    const blockSize = editorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getLength();
    if (blockSize === end) {
      mentionReplacedContent = Modifier.insertText(
        mentionReplacedContent,
        mentionReplacedContent.getSelectionAfter(),
        ' ',
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      mentionReplacedContent,
      'insert-fragment',
    );

    const teditorState = EditorState.forceSelection(
      newEditorState,
      mentionReplacedContent.getSelectionAfter(),
    );
    return teditorState;
  };

  function handleAddPlaceholder() {
    const newEditorState = insertWaTempPlaceholder(placeholderCount + 1);
    const newContent = newEditorState.getCurrentContent().getPlainText();
    const sortedContent = sortAddPlaceholders(newContent);
    const teditorState = transPlaceholderToEntity(
      newEditorState,
      sortedContent,
    );
    setEditorState(teditorState);
    onChange(sortedContent);
  }

  function handleChangePlaceholderType(index, type) {
    const newWaPlaceholders = JSON.parse(JSON.stringify(waPlaceholders));
    if (newWaPlaceholders[index].type !== type) {
      const waPlaceholder = newWaPlaceholders[index];
      if (type === 'customer') {
        waPlaceholder.type = 'customer';
        waPlaceholder.value = 'originalDisplayName';
      } else if (type === 'custom') {
        waPlaceholder.type = 'custom';
        waPlaceholder.value = '';
      }
      onWaPlaceholdersChange(newWaPlaceholders);
    }
  }

  function handleChangePlaceholderValue(index, value) {
    const newWaPlaceholders = JSON.parse(JSON.stringify(waPlaceholders));
    const waPlaceholder = newWaPlaceholders[index];
    waPlaceholder.value = value;
    onWaPlaceholdersChange(newWaPlaceholders);
  }

  const getEditorLength = () => {
    if (isWhatsappTemplate) {
      return editorState
        .getCurrentContent()
        .getPlainText()
        .replace(WA_TEMP_PLACEHOLDER_REGEX, (match, number) => {
          return t('organization-setting.whatsappTemplate.placeholderName', {
            number: number + 1,
          });
        }).length;
    }
    return editorState
      .getCurrentContent()
      .getPlainText()
      .replace(HASHTAG_REGEX, t('broadcast:customerName')).length;
  };

  const getStyles = () => {
    const res = {};
    if (maxLength) {
      res.paddingRight = 58;
      if (getEditorLength() > maxLength) {
        res.border = `1px solid ${color.$17}`;
      }
    }
    return res;
  };

  useEffect(() => {
    if (isWhatsappTemplate && editorContainer.current) {
      const editorContainerHeight = editorContainer.current.clientHeight;
      setTopValue(editorContainerHeight - 42);
    }
    if (origriData !== editorState.getCurrentContent().getPlainText()) {
      const newContentState = ContentState.createFromText(origriData);
      const newEditorState = EditorState.createWithContent(
        newContentState,
        isWhatsappTemplate &&
          (placeholderCount || (!placeholderCount && !disabled))
          ? WhatsAppPlaceholderDecorator
          : TextDecorator,
      );
      // 建立一個以文字末尾為基礎的選擇狀態
      const selectionState = SelectionState.createEmpty(
        newContentState.getBlockMap().first().getKey(),
      ).merge({
        anchorOffset: newContentState.getBlockMap().first().getLength(),
        focusOffset: newContentState.getBlockMap().first().getLength(),
      });
      // 使用新的選擇狀態更新編輯器狀態
      const finalEditorState = EditorState.forceSelection(
        newEditorState,
        selectionState,
      );
      setEditorState(finalEditorState);
    }
  }, [origriData]);

  return (
    <div className={classnames(classes.textContainer, className)} style={style}>
      <div
        ref={editorContainer}
        className={classes.content}
        style={getStyles()}
        onClick={focusEditor}
      >
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={e => handleChange(e)}
          placeholder={placeholder}
          handlePastedText={e => handlePastedText(e)}
          readOnly={disabled}
        />
      </div>
      {maxLength > 0 && (
        <div
          className={classes.maxLength}
        >{`${getEditorLength()}/${maxLength}`}</div>
      )}
      {!disabled && isShowCustomerName && !isWhatsappTemplate && (
        <div
          className={classes.addName}
          onClick={() => {
            insertCharacter();
          }}
        >
          <Tooltip placement="top" title={addTooltip}>
            <span>
              <Icon id="name" iconColor={color.$2} type="name" />
              {t('broadcast:addOriginName')}
            </span>
          </Tooltip>
        </div>
      )}
      {!disabled && isShowEmoji && (
        <div
          className={classes.endAdornment}
          style={{ top: isWhatsappTemplate ? `${topValue}px` : undefined }}
        >
          <EmojiPicker
            style={{
              top: 'inherit',
              bottom: 46,
              right: 0,
              position: 'absolute',
            }}
            isDialog={isDialog}
            title={t('broadcast:emojiTooltip')}
            isEmoji44
            iconColor={color.$10}
            onClick={onEmojiClick}
            tootipClass={classes.tootip}
          />
        </div>
      )}
      {isWhatsappTemplate && isWaParameterEditable && (
        <div className={classes.addPlaceholderTitle}>
          {t('organization-setting.whatsappTemplate.editTextPlaceholderTitle')}
        </div>
      )}
      {isWhatsappTemplate && !disabled && (
        <div>
          <div className={classes.addPlaceholderTitle}>
            {t('organization-setting.whatsappTemplate.createPlaceholderTitle')}
          </div>
          <div className={classes.placeholderCount}>
            <span>
              {t(
                'organization-setting.whatsappTemplate.createPlaceholderLimit',
              )}
              ：{placeholderLimit}
            </span>
            <span style={{ marginLeft: 12 }}>
              {t(
                'organization-setting.whatsappTemplate.createPlaceholderCount',
              )}
              ：{placeholderCount}
            </span>
          </div>
        </div>
      )}
      {isWhatsappTemplate && (
        <div>
          {waPlaceholders.map((p, index) => (
            <div
              key={`wa-temp-placeholder-${index}`}
              className={classes.placeholderItem}
            >
              <span className={classes.placeholderTitle}>
                {t('organization-setting.whatsappTemplate.placeholderName', {
                  number: index + 1,
                })}
              </span>
              <span style={{ marginLeft: '8px' }}>
                <Dropdown
                  value={p.type}
                  style={{ width: '180px' }}
                  onChange={e => {
                    handleChangePlaceholderType(index, e);
                  }}
                  menuItems={waTempPlaceholderTypes}
                  disabled={disabled && !isWaParameterEditable}
                />
              </span>
              <span style={{ marginLeft: '8px' }}>
                {p.type === 'customer' && (
                  <Dropdown
                    value={p.value}
                    style={{ width: '180px' }}
                    onChange={e => {
                      handleChangePlaceholderValue(index, e);
                    }}
                    menuItems={waTempPlaceholderCustomerValues}
                    disabled={disabled && !isWaParameterEditable}
                  />
                )}
                {p.type === 'custom' && (
                  <TextField
                    placeholder={t(
                      'organization-setting.whatsappTemplate.placeholderCustomPlaceholder',
                    )}
                    value={p.value}
                    style={{ width: '180px' }}
                    onChange={e => {
                      handleChangePlaceholderValue(index, e.target.value);
                    }}
                    disabled={disabled && !isWaParameterEditable}
                  />
                )}
              </span>
              {/* !disabled && (
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '8px',
                  }}
                  onClick={() => {}}
                >
                  <img src={imgSrc.icMinusCircle} alt="remove-placeholder" />
                </span>
              ) */}
            </div>
          ))}
        </div>
      )}
      {isWhatsappTemplate && !disabled && placeholderCount < placeholderLimit && (
        <div
          className={classes.addPlaceholderBtn}
          onClick={handleAddPlaceholder}
        >
          <IconAddCircle
            id="icon-add-message-type"
            width={20}
            height={20}
            className={classes.addPlaceholderIcon}
          />
          {t('organization-setting.whatsappTemplate.createPlaceholder')}
        </div>
      )}
    </div>
  );
};

TextEditor.defaultProps = {
  onChange: () => {},
  placeholder: '',
  origriData: '',
  maxLength: 0,
  addTooltip: '',
  isShowCustomerName: true,
  isShowEmoji: true,
  isDialog: false,
  isWhatsappTemplate: false,
  waPlaceholders: [],
  onWaPlaceholdersChange: () => {},
};

TextEditor.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  origriData: PropTypes.string,
  maxLength: PropTypes.number,
  addTooltip: PropTypes.string,
  isShowCustomerName: PropTypes.bool,
  isShowEmoji: PropTypes.bool,
  isDialog: PropTypes.bool,
  isWhatsappTemplate: PropTypes.bool,
  waPlaceholders: PropTypes.arrayOf(PropTypes.object),
  onWaPlaceholdersChange: PropTypes.func,
};

export default TextEditor;
