import actionType, {
  ORGANIZATION_RESET,
  ORGANIZATION_ADD,
  SET_MEMBER_MANAGEMENT,
  ORGANIZATION_REMOVE,
  ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS,
} from './type';
import { ModalType, SnackBarType, VendorStore } from '../types';

import axiosInstance from '../common/axios';
import config from '../config';
import { enqueueSnackbar } from './snackbarAction';
import PermissionService from '../services/PermissionService';
import OrganizationService from '../services/OrganizationService';
import i18n from '../i18n';
import AddOnSettingService from '../services/AddOnSettingService';
import ProvisionService from '../services/ProvisionService';
import InspectAddOnService from '../services/InspectAddOnService';
import AnnouncementService from '../services/AnnouncementService';
import { openModal, closeModal } from './modalAction';
import { getBrowserLanguage } from '../common/utils';

const getIntegration = (orgId = '', options = {}) => async dispatch => {
  const query = {
    where: {
      organization: {
        __type: 'Pointer',
        className: 'Organization',
        objectId: orgId,
      },
    },
    _method: 'GET',
    include: 'platform',
    ...options,
  };

  dispatch({ type: actionType.GET_INTEGRATION_REQUEST });
  axiosInstance
    .post('/classes/Integration', query)
    .then(response => {
      dispatch({
        type: actionType.GET_INTEGRATION_SUCCESS,
        data: response.results,
      });
    })
    .catch(error => {
      if (error.response) {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: error.response.data.error,
          }),
        );
      } else {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: error.toString(),
          }),
        );
      }
      dispatch({ type: actionType.GET_INTEGRATION_FAILURE });
    });
};

const reloadSelectOrganization = orgId => async dispatch => {
  const res = await OrganizationService.getOneOrganization(orgId);

  dispatch({
    type: actionType.SELECT_ORGANIZATION,
    payload: res,
  });
};

function selectOrganization(organization = [], objectId = null) {
  return async (dispatch, getState) => {
    const { selectOrg: selectedOrg } = getState().organization;

    if (organization.length > 0) {
      let selectOrg = null;
      let selectOrgId = objectId;
      if (!selectOrgId) {
        selectOrgId =
          localStorage.getItem(config.SELECTED_ORG_ID) !== 'null'
            ? localStorage.getItem(config.SELECTED_ORG_ID)
            : null;
      }
      if (!selectOrgId) {
        selectOrg = organization[0];
        localStorage.setItem(config.SELECTED_ORG_ID, selectOrg.objectId);
      } else {
        const filteredOrg = organization.find(
          item => item.objectId === selectOrgId,
        );

        if (filteredOrg) {
          selectOrg = filteredOrg;
          localStorage.setItem(config.SELECTED_ORG_ID, selectOrgId);
        } else {
          selectOrg = organization[0];
          localStorage.setItem(config.SELECTED_ORG_ID, selectOrg.objectId);
        }
      }

      if (selectOrg) {
        if (selectedOrg?.objectId !== selectOrg?.objectId) {
          dispatch({
            type: actionType.SELECT_ORGANIZATION,
            payload: selectOrg,
          });
        }

        return selectOrg;
      }

      return null;
    }

    return null;
  };
}

function selectOrganizationSuperAccount(organization = [], selectOrgId = null) {
  return async dispatch => {
    let selectedOrg;
    if (selectOrgId) {
      selectedOrg = await OrganizationService.getOneOrganization(selectOrgId);
      localStorage.setItem(config.SELECTED_ORG_ID, selectOrgId);
    } else {
      selectedOrg = organization[0];
      localStorage.setItem(config.SELECTED_ORG_ID, selectedOrg.objectId);
    }

    dispatch({
      type: actionType.SELECT_ORGANIZATION,
      payload: selectedOrg,
    });
  };
}

const fetchOrganization = (
  memberId,
  superAccountSelectOrgId,
) => async dispatch => {
  try {
    const query = {
      where: {
        members: {
          $inQuery: {
            where: {
              users: {
                __type: 'Pointer',
                className: '_User',
                objectId: memberId,
              },
            },
            className: 'Membership',
          },
        },
      },
      include:
        'conversation.lastMessage,conversation,members,owner,perm,accounting,owner',
      keys:
        'locale,features,redirectOptions,accounting,industry,displayName,icon,members,owner,conversation,owner.picture,owner.firstName,owner.lastName,accounting.message,accounting.customer,perm,status,_acl,_wperm,_rperm',
      order: 'displayName',
      _method: 'GET',
    };

    dispatch({ type: actionType.FETCH_ORGANIZATION_LOADING, payload: true });

    const { results } = await axiosInstance.post(
      '/classes/Organization',
      query,
    );
    dispatch({ type: actionType.FETCH_ORGANIZATION, payload: results });

    if (superAccountSelectOrgId) {
      dispatch(
        selectOrganizationSuperAccount(results, superAccountSelectOrgId),
      );
    } else {
      dispatch(selectOrganization(results));
    }
    return results;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
    return null;
  } finally {
    dispatch({ type: actionType.FETCH_ORGANIZATION_LOADING, payload: false });
  }
};

const createIntegration = integrationData => dispatch =>
  new Promise((resolve, reject) => {
    const data = {
      integrationId: integrationData.integrationId,
      integrationName: integrationData.integrationName,
      organization: {
        __type: 'Pointer',
        className: 'Organization',
        objectId: integrationData.orgId,
      },
      platform: {
        __type: 'Pointer',
        className: 'Platform',
        objectId: integrationData.platformId,
      },
      token: integrationData.token,
      secret: integrationData.secret || config.INTEGRETION_SECRET,
      account: integrationData.account,
    };

    if (integrationData.platformId === 'instagram') {
      data.pageId = integrationData.pageId;
    }

    dispatch({ type: actionType.CREATE_INTEGRATION_REQUEST });
    axiosInstance
      .post('/functions/integrate', data)
      .then(response => {
        dispatch({ type: actionType.CREATE_INTEGRATION_SUCCESS });
        resolve(response);
      })
      .catch(error => {
        dispatch({ type: actionType.CREATE_INTEGRATION_FAILURE });

        if (
          (error?.response?.data?.error || error.message) ===
          'error/object-already-existed'
        ) {
          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.ERROR,
              message: i18n.t('createIntegrationErrorObjectAlreadyExisted'),
            }),
          );
        } else if (
          (error?.response?.data?.error || error.message) ===
          'error/expire-perm'
        ) {
          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.ERROR,
              message: i18n.t('integration.expirePerm'),
            }),
          );
        } else {
          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.ERROR,
              message: error?.response?.data?.error || error.message,
            }),
          );
        }

        reject(error);
      });
  });

const deleteIntegration = integrationId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.DELETE_INTEGRATION_REQUEST });
    axiosInstance
      .post(`/classes/Integration/${integrationId}`, {
        _method: 'DELETE',
      })
      .then(response => {
        dispatch({ type: actionType.DELETE_INTEGRATION_SUCCESS });
        resolve(response);
      })
      .catch(error => {
        dispatch({ type: actionType.DELETE_INTEGRATION_FAILURE });
        reject(error);
      });
  });

const setNothing = () => dispatch => {
  dispatch({ type: actionType.SET_NOTHING });
};

export const getOrgMembersData = orgId => async dispatch => {
  dispatch({
    type: SET_MEMBER_MANAGEMENT,
    payload: {
      isLoading: true,
      count: 0,
      list: [],
    },
  });
  try {
    let response = await OrganizationService.findOrgMembers(orgId, {
      count: true,
    });
    const { count } = response;
    response = await OrganizationService.findOrgMembers(orgId);

    dispatch({
      type: SET_MEMBER_MANAGEMENT,
      payload: {
        isLoading: false,
        count,
        list: response,
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
  }
};

export const resetOrganization = () => {
  return { type: ORGANIZATION_RESET };
};

export const validateAddOrganization = () => async (dispatch, getState) => {
  try {
    const userId = getState().auth.userData.objectId;
    const permission = await PermissionService.getPermission(userId);
    if (!permission) return false;
    return permission.Organization > 0;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return false;
  }
};

export const createOrganization = (
  iconFile,
  orgName,
  propertyType,
  locale,
) => async (dispatch, getState) => {
  try {
    const { objectId: userId } = getState().auth?.userData;

    if (!(await dispatch(validateAddOrganization())))
      throw new Error('createOrganization: quota is not enough');
    const response = await OrganizationService.createOrganization(
      iconFile,
      orgName,
      propertyType,
      locale || getBrowserLanguage(),
    );

    const newOrg = await OrganizationService.getOrganization(
      response?.objectId,
      userId,
    );

    dispatch({
      type: ORGANIZATION_ADD,
      payload: newOrg,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('add-organization:add-organization-success', {
          orgName,
        }),
      }),
    );
    return newOrg;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const getPersistentMenuAddOnSetting = orgId => async dispatch => {
  try {
    const types = ['persistent_menu'];
    const response = await AddOnSettingService.getAddOnSettingByType(
      orgId,
      types,
    );

    return response && response.length ? response[0] : null;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const updateOrganizationInfo = ({
  orgId,
  needUpdateIcon,
  iconFile,
  orgName,
  propertyType,
  orgIconSrc,
  locale,
}) => async (dispatch, getstate) => {
  try {
    const response = await OrganizationService.updateOrganizationInfo(
      orgId,
      needUpdateIcon,
      iconFile,
      orgName,
      propertyType,
      locale,
    );

    const { selectOrg, orgData } = getstate().organization;

    const newOrgData = orgData.map(d => {
      if (d.objectId === orgId) {
        return {
          ...d,
          icon: orgIconSrc,
          displayName: orgName,
          industry: propertyType,
          locale,
        };
      }
      return d;
    });

    dispatch({
      type: actionType.SELECT_ORGANIZATION,
      payload: {
        ...selectOrg,
        icon: orgIconSrc,
        displayName: orgName,
        industry: propertyType,
        locale,
      },
    });

    dispatch({
      type: actionType.SET_ORGANIZATION,
      payload: {
        isInitedIntegrations: true,
      },
    });

    dispatch({
      type: actionType.FETCH_ORGANIZATION,
      payload: newOrgData,
    });

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('update-organization-success'),
      }),
    );
    return response;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const evictOrganization = (orgId, userId) => async dispatch => {
  try {
    const response = await OrganizationService.evictOrganization(
      orgId,
      [userId],
      [],
    );

    dispatch({
      type: ORGANIZATION_REMOVE,
      payload: orgId,
    });

    localStorage.removeItem(config.SELECTED_ORG_ID);
    return response;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const openAddOnEditor = (
  addOn,
  org,
  type = 'persistentMenu',
) => async dispatch => {
  try {
    const winRef = window.open(config.MESSAGEBOT_EDITOR_LOADING, '_blank');

    if (!addOn?.objectId) {
      throw new Error(`lose ${type} objectId`);
    }

    if (!org?.objectId) {
      throw new Error('lose org objectId');
    }

    const response = await ProvisionService.editAddOn(
      org?.objectId,
      addOn?.objectId,
    );

    if ('sso' in response) {
      winRef.location.replace(response.sso);
    }

    return response;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const deleteMember = (orgId, memberId, email) => async dispatch => {
  try {
    if (memberId) {
      await OrganizationService.evictOrganization(orgId, [memberId], []);
    } else {
      await OrganizationService.evictOrganization(orgId, [], [email]);
    }
    return true;
  } catch (error) {
    if (
      error.response?.data?.error ===
      'error/cannot-evict-subrdinates-having-users'
    ) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t(
            'permission-setting:cannot-evict-subrdinates-having-users',
          ),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error?.response?.data?.error ?? error.message,
        }),
      );
    }
    return false;
  }
};

export const deleteOrganization = orgId => async dispatch => {
  try {
    const response = await OrganizationService.deleteOrganization(orgId);

    dispatch({
      type: ORGANIZATION_REMOVE,
      payload: orgId,
    });

    localStorage.removeItem(config.SELECTED_ORG_ID);

    return response;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const provisionAddOn = (
  org,
  type = 'persistent_menu',
) => async dispatch => {
  try {
    const winRef = window.open(config.MESSAGEBOT_EDITOR_LOADING, '_blank');

    if (!org?.objectId) {
      throw new Error('lose org objectId');
    }

    const addOn = await AddOnSettingService.getAddOnSettingByDescription(type);

    if (addOn && addOn.length) {
      const response = await ProvisionService.openPlan(
        org?.objectId,
        addOn[0]?.objectId,
      );

      if ('sso' in response) {
        winRef.location.replace(response.sso);
      }
    } else {
      throw new Error('error/get-addon');
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
  }
};

export const getOrgMembersDataByUserIds = (
  orgId,
  userIds,
) => async dispatch => {
  try {
    const response = await OrganizationService.findOrgMembers(
      orgId,
      {},
      userIds,
    );
    return (response || [{}])[0] || {};
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export const saveDefaultTemplate = (
  orgId,
  templateId,
  templateType,
  name,
  elements,
  expiredAt,
  note,
) => async dispatch => {
  try {
    const response = await OrganizationService.saveDefaultTemplate(
      orgId,
      templateId,
      templateType,
      name,
      elements,
      expiredAt,
      note,
    );

    return response?.result?.template;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return undefined;
  }
};

export const getOrgAnnouncement = orgId => async (dispatch, getState) => {
  try {
    const userId = getState().auth.userData.objectId;
    const response = await AnnouncementService.getActived(orgId);
    if (response.announcement?.seenUsers?.includes(userId)) {
      response.announcement = null;
    }
    dispatch({ type: actionType.SET_ORG_ANNOUNCEMENT, payload: response });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
  }
};

export const setOrgAnnouncement = data => (dispatch, getState) => {
  const userId = getState().auth.userData.objectId;
  if (data?.announcement?.seenUsers?.includes(userId)) {
    // eslint-disable-next-line no-param-reassign
    data.announcement = null;
  }
  dispatch({ type: actionType.SET_ORG_ANNOUNCEMENT, payload: data });
};

export const getOrgOwnData = orgId => async (dispatch, getState) => {
  try {
    const userId = getState().auth.userData.objectId;
    const response = await dispatch(
      getOrgMembersDataByUserIds(orgId, [userId]),
    );

    await dispatch({
      type: actionType.SET_AUTH_ORG_OWN_DATA,
      payload: response,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
  }
};

export const checkEventLeftOrganization = orgId => async (
  dispatch,
  getState,
) => {
  const { orgData } = getState().organization;
  const { userData } = getState().auth;

  try {
    if (orgId) {
      const eventLeftOrgId = orgId;
      const isNeedRefetchOrganization = (orgData || []).some(
        org => org?.objectId === eventLeftOrgId,
      );

      if (userData?.objectId && isNeedRefetchOrganization) {
        localStorage.removeItem(config.SELECTED_ORG_ID);
        await dispatch(fetchOrganization(userData?.objectId));
      }
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

export const getInspectAddonResource = orgId => async dispatch => {
  try {
    const response = await InspectAddOnService.getResource(
      orgId,
      VendorStore.SUPER8,
    );

    dispatch({
      type: SET_MEMBER_MANAGEMENT,
      payload: {
        resource: response || {},
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.response?.data?.error || error.message);

    dispatch({
      type: SET_MEMBER_MANAGEMENT,
      payload: {
        resource: {},
      },
    });
  }
};

export const clearInspectAddonResource = () => async dispatch => {
  try {
    dispatch({
      type: SET_MEMBER_MANAGEMENT,
      payload: {
        resource: null,
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};

export const openOrganizationAnnoucementModal = () => dispatch => {
  dispatch({
    type: ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS,
    payload: true,
  });
  dispatch(openModal(ModalType.ORGANIZATION_ANNOUNCEMENT_MODAL));
};

export const closeOrganizationAnnoucementModal = () => (dispatch, getState) => {
  dispatch({
    type: ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS,
    payload: false,
  });
  setTimeout(() => {
    const { modalType } = getState().modal;
    if (modalType === ModalType.ORGANIZATION_ANNOUNCEMENT_MODAL) {
      dispatch(closeModal());
    }
  }, 450);
};

export const getBot91AppServiceAddOnSetting = orgId => async dispatch => {
  try {
    const types = ['91_app'];
    const response = await AddOnSettingService.getAddOnSettingByType(
      orgId,
      types,
    );
    return response?.[0] || {};
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
    return null;
  }
};

export {
  fetchOrganization,
  selectOrganization,
  getIntegration,
  createIntegration,
  deleteIntegration,
  setNothing,
  reloadSelectOrganization,
  selectOrganizationSuperAccount,
};
