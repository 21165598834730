import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import ChatFB from '../../assets/images/ChatFB';
import ChatLINE from '../../assets/images/ChatLINE';
import ChatInstagram from '../../assets/images/ChatInstagram';
import ChatLivechat from '../../assets/images/ChatLivechat';
import ChatWhatsapp from '../../assets/images/ChatWhatsapp';

import Badge from './Badge';
import imgSrc from '../../assets/iconSrc';
import { PlatformType } from '../../types';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    icon: {
      marginLeft: '-20px',
      zIndex: 2,
    },
  }),
  {
    name: 'ImageDisplay',
  },
);

const ImageDisplay = ({
  src,
  iconStyle,
  alt,
  isBot,
  style,
  className,
  onClick,
  width,
  height,
  platform,
  badgeClass,
  badgeColor,
  badgeShow,
}) => {
  const image = new Image();
  const classes = useStyles();
  const [stateSrc, setStateSrc] = useState(src || imgSrc.defaultUser);

  useEffect(() => {
    const useSrc = src || imgSrc.defaultUser;
    image.onload = () => {
      setStateSrc(useSrc);
    };
    image.onerror = () => {
      setStateSrc(imgSrc.defaultUser);
    };

    image.src = useSrc;
  }, [src]);

  const PlatformIcon = () => {
    const small = '0 0 20 20';
    return (
      <SvgIcon viewBox={small} className={iconStyle || classes.icon}>
        {platform === PlatformType.FACEBOOK ? (
          <ChatFB />
        ) : (
          platform === PlatformType.LINE && <ChatLINE />
        )}
      </SvgIcon>
    );
  };

  const renderImage = () => {
    return (
      <>
        {isBot && (
          <div className="bot-mask">
            <div className="bot-icon" />
          </div>
        )}
        <img
          role="presentation"
          alt={alt}
          style={style}
          className={className}
          onClick={onClick}
          src={stateSrc}
          width={width}
          height={height}
        />
      </>
    );
  };

  const renderPlatform = () => {
    return platform === 'instagram' ? (
      <ChatInstagram iconStyle={iconStyle} />
    ) : platform === 'livechat' ? (
      <ChatLivechat iconStyle={iconStyle} />
    ) : platform === 'whatsapp' ? (
      <ChatWhatsapp iconStyle={iconStyle} />
    ) : (
      <PlatformIcon iconStyle={iconStyle} />
    );
  };

  return (
    <>
      {platform ? (
        <span
          style={{
            display: 'flex',
            position: 'relative',
          }}
        >
          {renderImage()}
          {renderPlatform()}
        </span>
      ) : (
        <Badge
          className={badgeClass}
          badgeColor={badgeColor}
          invisible={!badgeShow}
          variant="dot"
        >
          {renderImage()}
        </Badge>
      )}
    </>
  );
};

ImageDisplay.defaultProps = {
  badgeClass: undefined,
};

ImageDisplay.propTypes = {
  badgeClass: PropTypes.string,
};

export default ImageDisplay;
