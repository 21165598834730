import { useState, useRef } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import Icon from './Icon';
import Tooltip from './Tooltip';
import emojiArray from '../../assets/emoji.json';

const EmojiPicker = ({
  onClick,
  className,
  width,
  height,
  style,
  iconColor,
  isEmoji44,
  tootipClass,
  title,
  isDialog,
}) => {
  const iconRef = useRef(null);

  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const onEmojiClick = item => {
    setOpen(false);
    onClick(item);
  };
  const onClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={className}>
        <Tooltip
          placement="top"
          title={title || t('chat.send-emoji')}
          detailClasses={{
            tooltipPlacementTop: tootipClass,
          }}
        >
          <span
            onClick={() => setOpen(!isOpen)}
            style={{ display: 'inline-block' }}
            ref={iconRef}
          >
            {isEmoji44 ? (
              <Icon id="emoji44" iconColor={iconColor} type="emoji44" />
            ) : (
              <Icon
                id="emoji"
                width={width}
                height={height}
                iconColor={iconColor}
                type="emoji"
              />
            )}
          </span>
        </Tooltip>
        {isDialog && isOpen && (
          <Portal>
            <div
              style={{
                top: iconRef.current.getBoundingClientRect().top - 206,
                left: iconRef.current.getBoundingClientRect().left - 340,
                display: 'flex',
              }}
              className="emoji-container"
            >
              {emojiArray.map(item => (
                <div
                  className="emoji-item"
                  key={item}
                  role="presentation"
                  onClick={() => onEmojiClick(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </Portal>
        )}

        {!isDialog && isOpen && (
          <div
            style={{ ...style, display: 'flex' }}
            className="emoji-container"
          >
            {emojiArray.map(item => (
              <div
                className="emoji-item"
                key={item}
                role="presentation"
                onClick={() => onEmojiClick(item)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default EmojiPicker;
