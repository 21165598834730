import actionType from '../actions/type';
import { CustomerServiceDataCaseType } from '../types';

const initState = {
  isLoading: false,
  caseType: CustomerServiceDataCaseType.ASSIGNED,
  caseStatisticsData: {},
  serviceEffectStatisticsData: {},
  serviceEffectGraphData: [],
  memberPerformanceDataCount: 0,
  memberPerformanceData: [],
  customerCaseFilterQuery: {
    caseStatus: undefined,
  },
  customerCaseData: [],
  customerCaseCount: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.SET_CUSTOMERSERVICEDATA:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_CUSTOMERSERVICEDATA_FAIL:
      return initState;
    default:
      return state;
  }
};
