import axios from 'axios';
import _forEach from 'lodash/forEach';
import moment from 'moment';
import exportData from '../common/exportData';
import i18n from '../i18n';
import BroadcastService, { getECWhere } from '../services/BroadcastService';
import CustomerGroupService from '../services/CustomerGroupService';
import ECService from '../services/ECService';
import InspectAddOnService from '../services/InspectAddOnService';
import TagServices from '../services/TagService';
import {
  CustomerGroupStatusType,
  FacebookTagType,
  PlatformType,
  SnackBarType,
  VendorStore,
  WhatsappTagType,
} from '../types';
import { enqueueSnackbar } from './snackbarAction';
import actionType, {
  BROADCAST_ADD_MESSAGE,
  BROADCAST_FIND_PARTNER_TAG,
  BROADCAST_FIND_TAG,
  BROADCAST_GET_CUSTOMER_GROUPS,
  BROADCAST_GET_CUSTOMERS,
  BROADCAST_RESET,
  COPY_BROADCAST,
  SET_BROADCAST,
} from './type';

export function messagesFixType(messages) {
  return messages.map(msg => {
    if (msg.contentType === 'application/x-template') {
      return {
        ...msg,
        className: 'Template',
        contentType: undefined,
        templateType: msg.data.templateType,
      };
    }
    return msg;
  });
}

export function setBroadcast(setting) {
  return {
    type: SET_BROADCAST,
    setting,
  };
}

const maptoAreas = (buttons, maxHeight, screenSize) => {
  return buttons.map(d => {
    return {
      bounds: {
        x: parseFloat(d.x) * 0.01 * screenSize,
        y: parseFloat(d.y) * 0.01 * maxHeight,
        width: parseFloat(d.width) * 0.01 * screenSize,
        height: parseFloat(d.height) * 0.01 * maxHeight,
      },
      action: {
        type: d.type,
        tags: d.tags,
        displayText: d.data,
      },
    };
  });
};

const oldMaptoAreas = (buttons, baseSize, screenSize) => {
  const scale = screenSize / baseSize.width;
  return buttons.map(d => {
    return {
      bounds: {
        x: parseFloat(d.area.x) * scale,
        y: parseFloat(d.area.y) * scale,
        width: parseFloat(d.area.width) * scale,
        height: parseFloat(d.area.height) * scale,
      },
      action: {
        type:
          d.type === 'message'
            ? 'postback'
            : d.type === 'uri'
            ? 'url'
            : 'postback',
        tags: d.tags,
        displayText: d.text || d.linkUri,
      },
    };
  });
};

export const oldImageMapToNew = d => {
  const screenSize = 320;
  const element = d.data;
  const { baseSize } = element;
  const newElement = {
    contentType: 'application/x-template',
    templateType: 'imagemap',
    data: {
      altText: d.data.altText,
      templateType: 'imagemap',
      messageTemplateType: 'ImagemapTemplate1',
      imageUrl: element.baseUrl,
      size: baseSize,
    },
    index: d.index,
  };
  newElement.data.actions = oldMaptoAreas(
    element.actions,
    baseSize,
    screenSize,
  );
  return newElement;
};

export const newimageMapTo = d => {
  const screenSize = 320;
  const element = d.data.elements[0];
  const { size } = element;
  const maxHeight = size?.height * (screenSize / size?.width);
  const newElement = {
    contentType: 'application/x-template',
    templateType: 'imagemap',
    data: {
      name: element.title,
      altText: d.data.altText,
      templateType: 'imagemap',
      messageTemplateType: element.messageTemplateType || 'ImagemapTemplate1',
      imageUrl: element.imageUrl,
      size,
    },
    index: d.index,
  };
  newElement.data.actions = maptoAreas(element.buttons, maxHeight, screenSize);
  return newElement;
};

const doBroadcastData = broadcast => {
  const messages = (broadcast?.options?.messages ?? [])
    .map(msg => msg?.contentType)
    .filter(o => !!o);
  const templates = (broadcast?.options?.messages ?? [])
    .filter(o => o.contentType === 'application/x-template')
    .map(msg => msg?.data?.templateType);

  const newMessage = broadcast.options.messages.map(d => {
    if (d.contentType === 'application/x-imagemap') {
      return oldImageMapToNew(d);
    }
    if (d.data?.templateType === 'imagemap') {
      return newimageMapTo(d);
    }
    return d;
  });

  if (
    broadcast.options.whatsappTag === WhatsappTagType.WHATSAPP_TEMPLATE &&
    newMessage[0] &&
    !newMessage[0].waTemplateId
  ) {
    newMessage[0].waTemplateId = undefined;
  }

  return {
    accounting: {
      success: broadcast?.success,
      total: broadcast?.total,
    },
    summary: {
      contentTypes: messages,
      templateTypes: templates,
    },
    name: broadcast?.options?.name ?? '',
    scheduleAt: broadcast?.scheduleAt ?? '',
    status: broadcast?.status ?? 'error',
    objectId: broadcast?.objectId,
    broadcast: {
      ...broadcast,
      options: {
        ...broadcast.options,
        messages: newMessage,
      },
    },
  };
};

const updateBroadcast = row => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_BROADCAST_LIST,
      payload: doBroadcastData(row),
    });
  } catch (error) {
    dispatch({
      type: actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE,
      errorMessage: error.response?.data?.error || error.message || error,
    });
    enqueueSnackbar({
      variant: SnackBarType.ERROR,
      message: error.response?.data?.error || error.message || error,
    });
  }
};

// isContinue時，若get list result length = 0，則lazy load不在執行
let isNoOther = false;

const getBroadcastList = (
  orgId,
  isContinue = false,
  options = {},
  whereConditions = '',
  isCancel,
) => async (dispatch, getState) => {
  try {
    let results = [];
    if (isContinue) {
      if (!isNoOther) {
        const dataLength = getState().broadcast.list.length;
        const broadcastName = getState().broadcast.searchName;

        results = await BroadcastService.getBroadcastList(
          orgId,
          {
            skip: dataLength,
            ...options,
          },
          {
            'options.name': { $regex: broadcastName, $options: 'i' },
          },
        );

        isNoOther = !results.length;

        dispatch({
          type: actionType.GET_BROADCAST_LIST_CONTINUE_SUCCESS,
          payload: results.map(d => doBroadcastData(d)),
        });
      }
    } else {
      dispatch({
        type: actionType.GET_BROADCAST_LIST_REQUEST,
      });
      results = await BroadcastService.getBroadcastList(
        orgId,
        options,
        whereConditions,
        isCancel,
      );

      isNoOther = false;

      dispatch({
        type: actionType.GET_BROADCAST_LIST_SUCCESS,
        payload: results.map(d => doBroadcastData(d)),
      });
    }
  } catch (error) {
    isNoOther = true;

    dispatch({
      type: actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE,
      errorMessage: error?.response?.data?.error ?? error.message,
    });
    enqueueSnackbar({
      variant: SnackBarType.ERROR,
      message: error.response?.data?.error || error.message || error,
    });
  }
};

const resetBroadcast = () => dispatch => {
  dispatch({ type: BROADCAST_RESET });
};

const setBroadcastCreateCondition = condition => dispatch => {
  dispatch({
    type: actionType.SET_BROADCAST_CREATE_CONDITION,
    payload: condition,
  });
};

const setBroadcastCreateTimingType = timingType => dispatch => {
  dispatch({
    type: actionType.SET_BROADCAST_CREATE_TIMINGTYPE,
    payload: timingType,
  });
  if (timingType === 'now') {
    dispatch({
      type: actionType.SET_BROADCAST_CREATE_TIMING,
      payload: '',
    });
  } else {
    dispatch({
      type: actionType.SET_BROADCAST_CREATE_TIMING,
      payload: moment().add(5, 'minutes'),
    });
  }
};

const setBroadcastCreateTiming = timing => dispatch => {
  dispatch({
    type: actionType.SET_BROADCAST_CREATE_TIMING,
    payload: timing,
  });
};

const setBroadcastCreateName = name => dispatch => {
  dispatch({
    type: actionType.SET_BROADCAST_CREATE_NAME,
    payload: name,
  });
};

const setBroadcastCreateMessage = message => dispatch => {
  dispatch({
    type: actionType.SET_BROADCAST_CREATE_MESSAGE,
    payload: message,
  });
};

async function transformConditionToWhere(conditions, orgId) {
  const platformSet = new Set();
  const includeCustomerSet = new Set();
  const genderSet = new Set();
  const tagDensity = [];
  const partnerTag = [];

  let shopline;
  let shopify;
  let cyberbiz;
  let recurring;
  let inboxes;

  try {
    if (conditions[0].value[0] && conditions[0].key === 'broadcast-by-fail') {
      return {
        orgId,
        task: {
          id: conditions[0].value,
          scope: 'fail',
        },
      };
    }

    if (
      conditions[1] &&
      conditions[1].value[0] &&
      conditions[1].key === 'customer-group'
    ) {
      const response = await CustomerGroupService.getCustomerGroup(
        conditions[1].value[0],
      );

      if (response) {
        if (response?.options?.isCustomCustomerGroup) {
          return {
            query: {
              orgId,
              groupId: conditions[1].value[0],
              ...{ platforms: [conditions[0].value[0]] },
            },
            options: {
              ...response.options,
            },
          };
        }
        return {
          query: {
            orgId,
            ...response.query,
            ...{ platforms: [conditions[0].value[0]] },
          },
          options: {
            ...response.options,
          },
        };
      }
    }

    for (let i = 0; i < conditions.length; i += 1) {
      const condition = conditions[i];
      if (condition.key === 'platform') {
        if (Array.isArray(condition.value) && condition.value[0] === 'all') {
          condition.value.forEach(v => {
            if (v === 'all') {
              platformSet.add('line');
              platformSet.add('facebook');
            } else {
              platformSet.add(v);
            }
          });
        } else {
          switch (condition.value) {
            case 'all':
              platformSet.add('line');
              platformSet.add('facebook');
              break;
            default:
              platformSet.add((condition.value || [])[0] || 'all');
          }
        }
      } else if (
        condition.key === 'customer' &&
        condition.boolean === 'include' &&
        condition.value.length > 0
      ) {
        condition.value.forEach(customer =>
          includeCustomerSet.add(customer.objectId),
        );
      } else if (condition.key === 'tag') {
        tagDensity.push(condition.value);
      } else if (condition.key === 'partner-tag') {
        partnerTag.push({
          type: condition.boolean,
          tags: (condition.value || []).map(cond => ({
            name: cond?.name,
            vendor: cond?.vendor,
          })),
        });
      } else if (condition.key === 'inbox') {
        inboxes = condition.value;
      } else if (condition.key === 'gender') {
        condition.value.forEach(v => genderSet.add(v));
      } else if (condition.key === 'shopline') {
        shopline = {
          ...shopline,
          ...getECWhere(condition, shopline),
        };
      } else if (condition.key === 'shopify') {
        shopify = {
          ...shopify,
          ...getECWhere(condition, shopify),
        };
      } else if (condition.key === 'cyberbiz') {
        cyberbiz = {
          ...cyberbiz,
          ...getECWhere(condition, cyberbiz),
        };
      } else if (condition.key === 'recurring') {
        recurring = condition?.value[0];
      }
    }

    return {
      orgId,
      platforms: Array.from(platformSet),
      customerObjectIds: Array.from(includeCustomerSet),
      tagDensity,
      partnerTag,
      gender: Array.from(genderSet),
      inboxes,
      shopline,
      shopify,
      cyberbiz,
      recurring,
    };
  } catch (error) {
    enqueueSnackbar({
      variant: SnackBarType.ERROR,
      message: error.response?.data?.error || error.message || error,
    });
    return {
      orgId,
      platforms: [],
      customerObjectIds: [],
      tagDensity,
      gender: [],
    };
  }
}

const { CancelToken } = axios;
let source = null;
const setBroadcastCustomerNum = orgId => async (dispatch, getState) => {
  const { conditions, fbMessengerTag, whatsappTag } = getState().broadcast;

  dispatch(
    setBroadcast({
      isLoading: true,
      customerNum: 0,
    }),
  );

  if (conditions?.length > 0) {
    let query = {};
    let options = {};
    if (
      conditions[1] &&
      conditions[1].value[0] &&
      conditions[1].key === 'customer-group'
    ) {
      const temp = await transformConditionToWhere(conditions, orgId);
      query = temp?.query || {};
      options = temp?.options || {};
    } else {
      query = await transformConditionToWhere(conditions, orgId);
    }

    try {
      if (source) {
        source.cancel('getCustomers canceled');
      }
      source = CancelToken.source();

      const response = await BroadcastService.getCustomers(
        orgId,
        query,
        {
          options: {
            ...options,
            fbMessengerTag:
              fbMessengerTag !== FacebookTagType.NO_TAG
                ? fbMessengerTag
                : undefined,
            whatsappTag:
              whatsappTag !== WhatsappTagType.NO_TAG ? whatsappTag : undefined,
            applyFacebook24Policy: true,
            applyLineUnfollowFilter: true,
          },
          returnCount: true,
          returnPipeline: false,
        },
        {
          cancelToken: source.token,
        },
      );
      dispatch(
        setBroadcast({ isLoading: false, customerNum: response.result.count }),
      );
      return true;
    } catch (error) {
      if (error.message !== 'getCustomers canceled') {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: error.response?.data?.error || error.message || error,
          }),
        );
      }
      dispatch(setBroadcast({ isLoading: false }));
      return false;
    }
  } else {
    dispatch(setBroadcast({ isLoading: false, customerNum: 0 }));
    return false;
  }
};

export const sendBroadcast = (orgId, taskId, isDraft) => async (
  dispatch,
  getState,
) => {
  const {
    name,
    conditions,
    shareMessageSetting,
    quickReply,
    messages,
    timing,
    timingType,
    fbMessengerTag,
    whatsappTag,
    altText,
    customerNum,
  } = getState().broadcast;

  try {
    return await BroadcastService.sendBroadcast(
      taskId,
      orgId,
      name,
      conditions,
      fbMessengerTag,
      whatsappTag,
      timingType === 'at' ? timing : undefined,
      shareMessageSetting,
      quickReply,
      messages,
      altText,
      customerNum,
      isDraft,
    );
  } catch (error) {
    if (error.response) {
      if (error.response.data.error === 'error/not-supported-content-type') {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t('broadcast:error.error/not-supported-content-type'),
          }),
        );
      } else if (
        error.response.data.error === 'error/schedule-must-be-future/30'
      ) {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t('broadcast:error.schedule-must-be-future'),
          }),
        );
      } else if (error.response.data.error === 'error/data-immutable') {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: i18n.t('broadcast:error.data-immutable'),
          }),
        );
      } else {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message:
              error.response.data.error || i18n.t('broadcast:default-error'),
          }),
        );
      }
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.message || i18n.t('broadcast:default-error'),
        }),
      );
    }

    return false;
  }
};

export const draftBroadcast = (orgId, taskId) => dispatch => {
  return dispatch(sendBroadcast(orgId, taskId, true));
};

export const findTag = (
  tagName,
  orgId,
  customerId,
  options,
) => async dispatch => {
  try {
    const result = await TagServices.findTagV3(
      tagName,
      orgId,
      customerId,
      options,
      ['name'],
    );
    const tags = result.data.map(tag => ({
      id: tag._id,
      name: tag._id,
    }));
    dispatch({ type: BROADCAST_FIND_TAG, candidateTags: tags });
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const findPartnerTag = (
  tagName,
  orgId,
  customerId,
  options,
) => async dispatch => {
  try {
    const result = await TagServices.findExternalTag(
      tagName,
      orgId,
      customerId,
      {
        options: {
          vendorType: 'partner',
        },
        ...options,
      },
    );
    const tags = result
      ? result.map(tag => ({
          id: tag?._id,
          ...tag,
        }))
      : null;
    dispatch({
      type: BROADCAST_FIND_PARTNER_TAG,
      candidatePartnerTags: tags,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export function addMessage() {
  return {
    type: BROADCAST_ADD_MESSAGE,
    payload: {
      contentType: 'text/plain',
      data: {
        content: '',
      },
    },
  };
}

export const deleteMessage = index => (dispatch, getState) => {
  const { messages } = getState().broadcast;
  dispatch({
    type: SET_BROADCAST,
    setting: {
      messages: messages
        .filter((message, messageIndex) => {
          return messageIndex !== index;
        })
        .map((message, messageIndex) => {
          return {
            ...message,
            index: messageIndex,
          };
        }),
    },
  });
};

export const upMessage = index => (dispatch, getState) => {
  const { messages } = getState().broadcast;
  const tempMessage = messages[index - 1];
  messages[index - 1] = messages[index];
  messages[index] = tempMessage;
  dispatch({
    type: SET_BROADCAST,
    setting: {
      messages: messages.map((message, messageIndex) => {
        return {
          ...message,
          index: messageIndex,
        };
      }),
    },
  });
};

export const downMessage = index => (dispatch, getState) => {
  const { messages } = getState().broadcast;
  const tempMessage = messages[index + 1];
  messages[index + 1] = messages[index];
  messages[index] = tempMessage;
  dispatch({
    type: SET_BROADCAST,
    setting: {
      messages: messages.map((message, messageIndex) => {
        return {
          ...message,
          index: messageIndex,
        };
      }),
    },
  });
};

export const getCustomerGroups = orgId => async dispatch => {
  const response = await CustomerGroupService.getList(
    orgId,
    {
      limit: 1000,
    },
    {
      status: CustomerGroupStatusType.READY,
    },
  );
  const payload = response.results.map(g => ({
    text: g.name,
    value: g.objectId,
    count: g.count,
    options: g.options,
    query: g.query,
  }));
  dispatch({ type: BROADCAST_GET_CUSTOMER_GROUPS, payload });
};

export const setCustomerNumber = n => {
  return {
    type: actionType.SET_BROADCAST_CUSTOMER_NUM,
    payload: n,
  };
};

const getBroadcast = (orgId, taskId) => async dispatch => {
  try {
    dispatch({
      type: SET_BROADCAST,
      setting: {
        isDetailBroadcastLoading: true,
        isDetailBroadcastTemplateAccountingLoading: true,
      },
    });
    const data = await BroadcastService.getBroadcast(orgId, taskId);
    dispatch({
      type: SET_BROADCAST,
      setting: {
        isDetailBroadcastLoading: false,
        isDetailBroadcastTemplateAccountingLoading: false,
      },
    });
    return (data || [{}])[0];
  } catch (error) {
    if (error.response) {
      dispatch({
        type: actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE,
        errorMessage: error.response.data.error,
      });
    }
    return {};
  }
};

const getBroadcastOpenlog = (orgId, taskId) => async dispatch => {
  try {
    const data = await BroadcastService.getBroadcastOpenlog(orgId, taskId);
    return data || {};
  } catch (error) {
    dispatch({
      type: actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE,
      errorMessage: error.response.data.error,
    });
    return {};
  }
};

const getCustomers = (orgId, query, options) => async dispatch => {
  try {
    const response = await BroadcastService.getCustomers(orgId, query, options);
    dispatch({
      type: BROADCAST_GET_CUSTOMERS,
      payload: response.result.customers,
    });
    return response;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
    return {};
  }
};

const getECConditions = (provider, queryProvider) => {
  const res = [];
  _forEach(queryProvider, (value, key) => {
    if (value) {
      if (key === 'tags') {
        (value || []).forEach(tag => {
          res.push({
            key: provider,
            type: key,
            value: tag,
          });
        });
      } else {
        res.push({
          key: provider,
          type: key,
          value,
        });
      }
    }
  });
  return res;
};

async function queryToConditions(orgId, query, group) {
  const conditions = [];

  if (query?.resendTaskId) {
    conditions.push({
      key: 'broadcast-by-fail',
      value: query.resendTaskId,
    });
    return conditions;
  }

  if (query) {
    if (query.platforms) {
      const platformSet = new Set();
      query.platforms.forEach(platform => {
        platformSet.add(platform);
      });
      if (
        platformSet.size ===
        Object.keys(PlatformType).filter(d => d !== PlatformType.LIVECHAT)
          .length
      ) {
        conditions.push({
          key: 'platform',
          value: ['line'],
        });
      } else {
        conditions.push({
          key: 'platform',
          value: Array.from(platformSet),
        });
      }
    } else {
      conditions.push({
        key: 'platform',
        value: ['line'],
      });
    }

    if (query.customerObjectIds) {
      const response = await BroadcastService.getCustomers(
        orgId,
        {
          customerObjectIds: query.customerObjectIds,
        },
        { limit: 9999999 },
      );

      conditions.push({
        key: 'customer',
        boolean: 'include',
        value: response?.result?.customers || [],
      });
    }

    if (query.tagDensity) {
      query.tagDensity.forEach(tagData => {
        conditions.push({
          key: 'tag',
          value: tagData,
        });
      });
    }

    if (query.partnerTag) {
      query.partnerTag.forEach(tagData => {
        conditions.push({
          key: 'partner-tag',
          boolean: tagData.type,
          value: tagData.tags,
        });
      });
    }

    if (query.gender) {
      conditions.push({
        key: 'gender',
        boolean: 'eq',
        value: query.gender,
      });
    }

    if (query.inboxes) {
      conditions.push({
        key: 'inbox',
        value: query.inboxes,
      });
    }

    if (query.shopline) {
      conditions.push(...getECConditions('shopline', query.shopline));
    }

    if (query.shopify) {
      conditions.push(...getECConditions('shopify', query.shopify));
    }

    if (query.cyberbiz) {
      conditions.push(...getECConditions('cyberbiz', query.cyberbiz));
    }

    if (query.recurring) {
      conditions.push({
        key: 'recurring',
        value: [query.recurring],
      });
    }
  } else {
    conditions.push({
      key: 'platform',
      value: ['line'],
    });
  }

  if (group) {
    if (group.id) {
      conditions.push({
        key: 'customer-group',
        value: [group.id],
      });
    }
  }
  return conditions;
}

export const copyBroadcast = (orgId, data) => async dispatch => {
  const broadcastData = {
    name: data.name,
    customerNum: 0,
    conditions: await queryToConditions(
      orgId,
      data.broadcast.options.query,
      data.broadcast.options.group,
    ),
    fbMessengerTag: data.broadcast.options?.fbMessengerTag ?? 'NO_TAG',
    whatsappTag: data.broadcast.options?.whatsappTag ?? 'NO_TAG',
    timingType: data.broadcast.scheduleAt ? 'at' : 'now',
    timing: data.broadcast.scheduleAt
      ? moment(data.broadcast.scheduleAt)
      : moment(),
    shareMessageSetting: data.broadcast?.options?.shareMessageSetting,
    quickReply: data.broadcast?.options?.quickReply,
    messages: messagesFixType(data.broadcast.options.messages),
    altText: data.broadcast.options.messages.find(d => d.data.altText)?.data
      .altText,
    status: data.broadcast?.status,
  };
  dispatch({
    type: COPY_BROADCAST,
    payload: broadcastData,
  });
};

export const addTagByBroadcastAnalysis = (
  orgId,
  tags,
  scope,
  option,
) => async dispatch => {
  try {
    await BroadcastService.tagsOperationByBroadcastAnalysis(
      orgId,
      tags,
      scope,
      option,
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

export const createCustomerGroupByBroadcastAnalysis = (
  orgId,
  name,
  scope,
  option,
) => async dispatch => {
  try {
    await BroadcastService.createCustomerGroupByBroadcastAnalysis(
      orgId,
      name,
      scope,
      option,
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

export const stopBroadcast = (orgId, taskId, name) => async dispatch => {
  try {
    await BroadcastService.stopBroadcast(orgId, taskId);
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('broadcast:stop-broadcast-modal.success', {
          name,
        }),
      }),
    );
  } catch (error) {
    if (error?.response?.data?.error === 'error/data-immutable') {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('broadcast:error.data-immutable'),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.response?.data?.error || error.message || error,
        }),
      );
    }
  }
};

export const getInspectAddonResource = orgId => async dispatch => {
  try {
    const response = await InspectAddOnService.getResource(
      orgId,
      VendorStore.SUPER8,
    );

    dispatch(
      setBroadcast({
        resource: response || {},
      }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.response?.data?.error || error.message);

    dispatch(
      setBroadcast({
        resource: {},
      }),
    );
  }
};

export const getLineChannelInfo = integretionId => async dispatch => {
  try {
    const response = await BroadcastService.getLineChannelInfo(integretionId);
    const { extension, login, liff } = response;

    let tempExtension = extension;
    if (
      !login &&
      tempExtension &&
      tempExtension.find(d => d.type === 'liff-shareMessage')
    ) {
      tempExtension = tempExtension.map(d => {
        if (d.type === 'liff-shareMessage') {
          return {
            ...d,
            app_id: {
              ...d.app_id,
              isLegacy: true,
            },
          };
        }
        return d;
      });
    }

    const newExtension = login
      ? [
          {
            app_id: {
              ...login,
              liffId: liff?.share_message,
              isLegacy: false,
            },
            type: 'liff-shareMessage',
          },
        ]
      : tempExtension || [];

    dispatch(
      setBroadcast({
        extension: newExtension,
      }),
    );
  } catch (error) {
    dispatch(
      setBroadcast({
        extension: [],
      }),
    );
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error || error?.message,
      }),
    );
  }
};

export const clearInspectAddonResource = () => async dispatch => {
  try {
    dispatch(
      setBroadcast({
        resource: null,
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message:
          error.response?.data?.name ||
          error.response?.data?.error ||
          error.message,
      }),
    );
  }
};

export const createLineLiff = (orgId, liffName) => async dispatch => {
  try {
    await BroadcastService.createLineLiff(orgId, liffName);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('broadcast:autoGenerateSuccess'),
      }),
    );
  } catch (error) {
    if (error?.response?.data.code === 'error/maximum_liff_apps') {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t(
            'organization-setting.linked-app.lineLoginSetting.error/maximum_liff_apps',
          ),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error?.response?.data?.error || error?.message,
        }),
      );
    }
  }
};

export const deleteLineLiff = (orgId, liffName) => async dispatch => {
  try {
    await BroadcastService.deleteLineLiff(orgId, liffName);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('broadcast:deleteLiffSuccess'),
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error || error?.message,
      }),
    );
  }
};

export const exportBroadcast = (orgId, startDate, endDate) => async (
  dispatch,
  getState,
) => {
  try {
    const { displayName } = getState()?.organization?.selectOrg;
    const i18nData = exportData.broadcast(displayName, startDate, endDate);

    await BroadcastService.exportBroadcast(orgId, startDate, endDate, i18nData);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message:
          error.response?.data?.name ||
          error.response?.data?.error ||
          error.message,
      }),
    );
  }
};

export const getOneTaskToEditOrViewPage = (orgId, taskId) => async dispatch => {
  try {
    const res = await BroadcastService.getBroadcast(orgId, taskId, true);
    const data = doBroadcastData((res || [{}])[0]);
    dispatch(copyBroadcast(orgId, data));
  } catch (error) {
    if (error.response) {
      dispatch({
        type: actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE,
        errorMessage: error.response.data.error,
      });
    }
  }
};

export const findECCandidateTags = (
  provider,
  keywords,
  orgId,
) => async dispatch => {
  try {
    const result = await ECService.findTag(provider, orgId, keywords);

    dispatch({
      type: SET_BROADCAST,
      setting: {
        ECCandidateTags: result.map(tag => ({
          id: tag._id,
          name: tag.name,
        })),
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const findMembershipTier = (provider, orgId) => async dispatch => {
  try {
    const result = await ECService.findMembershipTier(provider, orgId);

    dispatch({
      type: SET_BROADCAST,
      setting: {
        membershipTier: result.map(shipTier => ({
          id: shipTier?.tierId,
          name: shipTier?.displayName,
        })),
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export {
  getBroadcast,
  getBroadcastList,
  getBroadcastOpenlog,
  getCustomers,
  resetBroadcast,
  setBroadcastCreateCondition,
  setBroadcastCreateMessage,
  setBroadcastCreateName,
  setBroadcastCreateTiming,
  setBroadcastCreateTimingType,
  setBroadcastCustomerNum,
  updateBroadcast,
};
