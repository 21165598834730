import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import { Portal } from 'react-portal';
import imgSrc from '../../assets/iconSrc';
import Circular from './Circular';

import { color } from '../../themes';

const useStyles = makeStyles(
  theme => ({
    dropdown: {
      position: 'relative',
      display: 'flex',
      height: '44px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: `1px solid ${color.$10}`,
      padding: '12px 24px',
      borderRadius: '50px',
      WebkitUserSelect: 'none',
      userSelect: 'none',
      cursor: 'pointer',
      backgroundColor: color.$7,
      '&.focus': {
        borderColor: color.$1,
      },
    },
    filterDropdown: {
      display: 'block',
      border: 'none',
      padding: '0px',
      position: 'relative',
      cursor: 'pointer',
    },
    field: {
      ...theme.typography.subtitle1,
      color: color.$13,
      width: '100%',
      lineHeight: 'normal',
    },
    filterField: {
      ...theme.typography.body1,
      color: color.$3,
      lineHeight: 'normal',
    },
    icon: {
      top: 'calc(50% - 12px)',
      right: '8px',
      color: 'rgba(0, 0, 0, 0.3)',
      padding: '0px',
      position: 'absolute',
      pointerEvents: 'none',
      width: '24px',
      height: '24px',
    },
    startIcon: {
      padding: '0px',
      marginRight: '8px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    menu: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 3,
      backgroundColor: `${color.$7}`,
      boxShadow:
        '0 16px 48px 0 rgba(0, 0, 0, 0.07), 0 6px 18px 0 rgba(0, 0, 0, 0.03)',
      borderRadius: '12px',
      padding: '8px 0',
      margin: 0,
      listStyle: 'none',
      maxHeight: '294px',
      overflowY: 'auto',
      minWidth: '215px',
      transition:
        'opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    item: {
      display: 'flex',
      height: '44px',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '13px 24px',
      cursor: 'pointer',
      fontSize: '13px',
      lineHeight: '18px',
      '& > div': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
      },
      '&:hover': {
        color: `${color.$2}`,
      },
    },
    selected: {
      color: `${color.$2}`,
    },
    itemDescription: {
      width: '100%',
      color: color.$11,
    },
    contentContainer: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    default: {
      color: color.$10,
    },
  }),
  {
    name: 'Dropdown',
  },
);

function Dropdown({
  value,
  defaultValue,
  menuItems,
  name,
  onChange,
  isFilter,
  isIcon,
  style,
  endCount,
  menuClass,
  iconSource,
  isIconEdit,
  className,
  disabled,
  onFocus,
  filterItems,
  placeholder,
  notSetDefaultValue,
  isLoading,
  isDialog,
}) {
  const classes = useStyles();

  const dropdownRef = useRef(null);
  const [timer, setTimer] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(
    value || defaultValue || menuItems?.[0]?.value,
  );
  const [selectValue, setSelectValue] = useState(
    value || defaultValue || menuItems?.[0]?.value,
  );
  const menuItemMap = new Map();

  useEffect(() => {
    if (!notSetDefaultValue) {
      setDropdownValue(value || defaultValue || menuItems[0]?.value);
    }
  }, [menuItems]);

  useEffect(() => {
    setDropdownValue(value);
  }, [value]);

  useEffect(() => {
    if (dropdownValue !== value) {
      setDropdownValue(value);
    }
  }, [dropdownValue]);

  const onMenuClick = item => {
    setDropdownValue(item.value);
    setOpen(false);
    if (onChange) {
      onChange(item.value, item);
    }
  };

  const onMouseEnter = itemValue => {
    setSelectValue(itemValue);
  };

  const onMouseOut = () => {
    setSelectValue(value);
  };
  const filterItemValueSet = new Set(filterItems);
  menuItems.forEach(item => {
    menuItemMap.set(item.value, item);
  });

  const menuItemComponents = menuItems
    .filter(item => !filterItemValueSet.has(item.value))
    .map(item => {
      return (
        <li
          id={item?.id || null}
          className={classnames([
            isFilter && classes.filterField,
            classes.item,
            dropdownValue === item.value ? classes.selected : null,
          ])}
          key={`${name}-item-${item.value}`}
          onMouseEnter={() => onMouseEnter(item.value)}
          onMouseLeave={() => onMouseOut()}
          onClick={() => onMenuClick(item)}
        >
          <div title={item.text}>
            {isIcon &&
              iconSource &&
              (isIconEdit ? (
                <img
                  alt="icon"
                  className={classes.startIcon}
                  src={
                    dropdownValue === item.value || selectValue === item.value
                      ? iconSource[`${item.value}Hover`]
                      : iconSource[item.value]
                  }
                />
              ) : (
                <IconButton className={classes.startIcon}>
                  <img alt="icon" src={iconSource[item.value]} />
                </IconButton>
              ))}
            <div> {item.text}</div>
            {item.desc && (
              <div className={classes.itemDescription}>{item.desc}</div>
            )}

            {isLoading ? (
              <span style={{ marginLeft: 'auto' }}>
                <Circular topic="grey" variant="indeterminate" size={20} />
              </span>
            ) : (
              endCount && (
                <span style={{ marginLeft: 'auto' }}>
                  {endCount[item.value]}
                </span>
              )
            )}
          </div>
        </li>
      );
    });

  const onClickAway = () => {
    setOpen(false);
    onFocus(false);
  };

  const onClick = e => {
    e.preventDefault();
    if (!disabled) {
      setOpen(!isOpen);
      setTimer(true);
      setTimeout(() => {
        setTimer(false);
      });
    }
    onFocus(!isOpen);
  };

  let dropDownRect = { width: 0, top: 0, left: 0 };
  if (isOpen) {
    dropDownRect = dropdownRef.current.getBoundingClientRect();
  }
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={classnames([
          isFilter ? classes.filterDropdown : classes.dropdown,
          isOpen ? 'focus' : null,
          className,
        ])}
        style={style}
        ref={dropdownRef}
        onClick={onClick}
        name={name}
      >
        <div className={classes.contentContainer}>
          <span
            style={disabled ? { color: color.$10 } : {}}
            className={classnames(
              isFilter ? classes.filterField : classes.field,
              {
                [classes.default]:
                  dropdownValue === undefined || dropdownValue === null,
              },
            )}
          >
            {dropdownValue === undefined || dropdownValue === null
              ? placeholder
              : menuItemMap.get(dropdownValue) &&
                menuItemMap.get(dropdownValue)?.text}
          </span>
        </div>
        {isOpen ? (
          <IconButton
            className={classes.icon}
            style={isFilter && { right: 'unset' }}
          >
            {isFilter ? (
              <img alt="chevron-up" src={imgSrc.arrowUp} />
            ) : (
              <img alt="chevron-up" src={imgSrc.chevronUpIcon} />
            )}
          </IconButton>
        ) : (
          <IconButton
            className={classes.icon}
            style={isFilter && { right: 'unset' }}
          >
            {isFilter ? (
              <img alt="chevron-down" src={imgSrc.arrowDown} />
            ) : (
              <img alt="chevron-down" src={imgSrc.chevronDownIcon} />
            )}
          </IconButton>
        )}

        {isDialog && isOpen && (
          <Portal>
            <ul
              className={classnames([classes.menu, menuClass])}
              style={{
                width: dropDownRect.width,
                top: dropDownRect.top + 44,
                left: dropDownRect.left,
                zIndex: 10000,
                opacity: timer ? 0 : 1,
              }}
            >
              {menuItemComponents}
            </ul>
          </Portal>
        )}

        {!isFilter && !isDialog && isOpen && (
          <ul
            className={classnames([classes.menu, menuClass])}
            style={{
              top: dropdownRef.current.offsetHeight + 2,
              minWidth: dropdownRef.current?.style.width,
              opacity: timer ? 0 : 1,
            }}
          >
            {menuItemComponents}
          </ul>
        )}

        {isFilter && !isDialog && isOpen && (
          <div
            style={{
              position: 'relative',
              height: '100%',
              width: '100%',
              zIndex: 4,
            }}
          >
            <ul
              className={classnames([classes.menu, menuClass])}
              style={{
                minWidth: dropdownRef.current?.style.width,
                maxHeight: 'unset',
                opacity: timer ? 0 : 1,
              }}
            >
              {menuItemComponents}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

Dropdown.defaultProps = {
  onFocus: () => {},
  filterItems: [],
  placeholder: '',
  notSetDefaultValue: false,
  isLoading: false,
  isDialog: false,
};

Dropdown.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  onFocus: PropTypes.func,
  filterItems: PropTypes.array,
  placeholder: PropTypes.string,
  notSetDefaultValue: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDialog: PropTypes.bool,
};

export default Dropdown;
