import actionType from '../actions/type';

const INITIAL_STATE = {
  templateList: [],
  template: null,
  isSuccess: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_TEMPLATE_DATA:
      return { ...state, template: action.payload, isSuccess: true };
    case actionType.GET_TEMPLATE:
      return { ...state, templateList: action.payload, isSuccess: true };
    case actionType.REMOVE_TEMPLATE:
      return { ...state, isSuccess: true };
    default:
      return state;
  }
};
