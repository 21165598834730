const ChatFB = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <circle cx="10" cy="10" r="10" fill="#0090FE" />
        <polygon
          fill="#FFF"
          points="3.368 12.857 8.921 6.995 11.746 9.82 16.632 7.225 11.192 13.005 8.335 10.177"
        />
      </g>
      <rect width="20" height="20" />
    </g>
  </svg>
);

export default ChatFB;
