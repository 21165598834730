import coreAPI from '../common/axios';

export default class AddOnSettingService {
  static async getAddOnSetting(orgId, vendorId) {
    let vendor = null;

    if (vendorId) {
      vendor = {
        className: 'AddOnVendor',
        __type: 'Pointer',
        objectId: vendorId,
      };
    }

    const response = await coreAPI.post('/classes/AddOnSetting', {
      _method: 'GET',
      where: {
        organization: {
          className: 'Organization',
          __type: 'Pointer',
          objectId: orgId,
        },
        vendor,
      },
    });

    return response.results;
  }

  static async getAddOnSettingCount(orgId, vendorId) {
    let vendor = null;

    if (vendorId) {
      vendor = {
        className: 'AddOnVendor',
        __type: 'Pointer',
        objectId: vendorId,
      };
    }

    const response = await coreAPI.post('/classes/AddOnSetting', {
      _method: 'GET',
      where: {
        organization: {
          className: 'Organization',
          __type: 'Pointer',
          objectId: orgId,
        },
        vendor,
      },
      count: 1,
      limit: 0,
    });

    return response?.count || 0;
  }

  static async getAddOnSettingByType(orgId, types) {
    const response = await coreAPI.post('/classes/AddOnSetting', {
      order: '-createdAt',
      _method: 'GET',
      where: {
        organization: {
          className: 'Organization',
          __type: 'Pointer',
          objectId: orgId,
        },
        type: {
          $in: types,
        },
      },
    });

    return response.results;
  }

  static async getAddOnSettingByDescription(description) {
    const response = await coreAPI.post('/classes/AddOn', {
      limit: 1,
      _method: 'GET',
      where: {
        description,
      },
    });

    return response.results;
  }
}
