import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Circular from './Circular';

const useStyles = makeStyles(
  {
    loading: () => ({
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  },
  { name: 'Loading' },
);

export default function Loading({ className, style = {} }) {
  const classes = useStyles();
  return (
    <div className={classnames(classes.loading, className)} style={style}>
      <Circular topic="grey" variant="indeterminate" size={20} />
    </div>
  );
}
