import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconBell = ({ iconColor, width, height, onClick }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onClick={onClick}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      width={width}
      height={height}
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3636 13.8182C16.7455 13.8182 17 13.5636 17 13.1818C17 12.8 16.7455 12.5455 16.3636 12.5455C15.6636 12.5455 15.0909 11.9727 15.0909 11.2727V8.09091C15.0909 5.29091 12.8 3 10 3C7.2 3 4.90909 5.29091 4.90909 8.09091V11.2727C4.90909 11.9727 4.33636 12.5455 3.63636 12.5455C3.25455 12.5455 3 12.8 3 13.1818C3 13.5636 3.25455 13.8182 3.63636 13.8182H16.3636ZM10 17C10.6364 17 11.2727 16.6818 11.6545 16.0455C11.8455 15.7273 11.8455 15.2818 11.5273 15.1545C11.2091 15.0273 10.8273 15.0909 10.6364 15.4091C10.4455 15.7273 10.0636 15.8545 9.74545 15.6636C9.65672 15.6193 9.59891 15.544 9.55048 15.4809C9.52944 15.4535 9.51018 15.4284 9.49091 15.4091C9.3 15.0909 8.91818 14.9636 8.6 15.1545C8.28182 15.3455 8.15455 15.7273 8.34545 16.0455C8.53636 16.3636 8.72727 16.5545 9.04545 16.7455C9.36364 16.9364 9.68182 17 10 17ZM14.1364 12.5455C13.9455 12.1636 13.8182 11.7182 13.8182 11.2727V8.09091C13.8182 5.99091 12.1 4.27273 10 4.27273C7.9 4.27273 6.18182 5.99091 6.18182 8.09091V11.2727C6.18182 11.7182 6.05455 12.1636 5.86364 12.5455H14.1364ZM10.8932 7.1104L10.0154 7.98826L9.13712 7.11002C8.89413 6.86703 8.50016 6.86703 8.25716 7.11002C8.01417 7.35302 8.01417 7.74698 8.25716 7.98998L9.1354 8.86822L8.25336 9.75026C8.01036 9.99326 8.01036 10.3872 8.25336 10.6302C8.49635 10.8732 8.89032 10.8732 9.13331 10.6302L10.0154 9.74817L10.897 10.6298C11.14 10.8728 11.534 10.8728 11.777 10.6298C12.02 10.3868 12.02 9.99288 11.777 9.74989L10.8953 8.86822L11.7732 7.99035C12.0162 7.74736 12.0162 7.35339 11.7732 7.1104C11.5302 6.8674 11.1362 6.8674 10.8932 7.1104Z"
        fill={
          iconColor === color.$12
            ? isHover
              ? hexRgba(iconColor, 40)
              : iconColor
            : iconColor
        }
      />
    </svg>
  );
};

IconBell.defaultProps = {
  iconColor: color.$12,
  width: 20,
  height: 20,
};

export default IconBell;
