import classnames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      borderRadius: 8,
    },
    bar: {
      borderRadius: 8,
    },
    'colorPrimary--light': {
      backgroundColor: color.$8,
    },
    'barColorPrimary--light': {
      backgroundColor: color.$1,
    },
    'colorPrimary--dark': {
      backgroundColor: color.$9,
    },
    'barColorPrimary--dark': {
      backgroundColor: color.$1,
    },
    'colorPrimary--error': {
      backgroundColor: color.$8,
    },
    'barColorPrimary--error': {
      backgroundColor: color.$17,
    },
  }),
  { name: 'ProgressBar' },
);

function ProgressBar({ className, type, percentage }) {
  const classes = useStyles();
  return (
    <LinearProgress
      className={classnames(classes.root, className)}
      classes={{
        bar: classes.bar,
        colorPrimary: classes[`colorPrimary--${type}`],
        barColorPrimary: classes[`barColorPrimary--${type}`],
      }}
      variant="determinate"
      type={type}
      value={type === 'error' ? 100 : percentage}
    />
  );
}

ProgressBar.defaultProps = {
  className: undefined,
  type: 'dark',
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['light', 'dark', 'error']),
  percentage: PropTypes.number.isRequired,
};

export default ProgressBar;
