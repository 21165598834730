import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';
import hexRgba from 'hex-rgba';
import ImageDisplay from './ImageDisplay';
import { color as themeColor } from '../../themes';
import imgSrc from '../../assets/iconSrc';
import Tooltip from './Tooltip';

const useStyles = makeStyles(
  theme => ({
    root: {
      ...theme.chip.root,
      maxWidth: '100%',
    },
    tagStyle: {
      height: 24,
      fontSize: 13,
      color: themeColor.$7,
      padding: '0px 12px',
      margin: '0 5px',
    },
    'tagStyle--size-m': {
      height: 32,
      fontSize: 15,
    },
    'tagStyle--with-icon': {
      padding: '0px 12px 0px 8px',
    },
    tagStyleClickable: {
      padding: '0px 6px 0 12px',
    },
    'tagStyleClickable--size-m': {
      padding: '9px 6px 8px 12px',
    },
    label: theme.chip.label,
    deleteIcon: {
      width: 20,
      height: 20,
      margin: 0,
    },
    colorPrimary: theme.chip.colorPrimary,
    colorSecondary: theme.chip.colorSecondary,
    colorPrimaryEnabled: theme.chip.colorPrimaryEnabled,
    lightBlue: theme.chip.lightBlue,
    black: theme.chip.black,
    basicDisable: {
      ...theme.chip.colorPrimary,
      backgroundColor: themeColor.$9,
      '&:hover, &:focus': {},
    },
    red: theme.chip.red,
    transPrimary: {
      ...theme.typography.body1,
      color: themeColor.$2,
      padding: '6px 7px 5px 8px',
      backgroundColor: hexRgba(themeColor.$1, 10),
    },
    transSecondary: {
      ...theme.typography.body1,
      color: themeColor.$18,
      padding: '6px 7px 5px 8px',
      backgroundColor: hexRgba(themeColor.$16, 10),
    },
    blue: {
      ...theme.typography.body1,
      color: themeColor.$7,
      backgroundColor: themeColor.$15,
      '&:hover': {
        backgroundColor: '#177e8f',
      },
    },
    icon: { objectFit: 'contain', cursor: 'pointer', margin: '0 2px 0 0' },
    'icon--clickable': {
      margin: '0 0 0 2px',
    },
    'icon--size-m': {
      width: 24,
      height: 24,
    },
    count: {
      background: themeColor.$21,
      borderRadius: '50px',
      color: themeColor.$2,
      padding: '3px 5px',
      marginRight: '4px',
      display: 'inline-flex',
      height: '18px',
      alignItems: 'center',
      minWidth: 18,
      justifyContent: 'center',
    },
  }),
  {
    name: 'Tag',
  },
);

const Tag = ({
  tag,
  idx,
  conditionIndex,
  addable,
  deleteable,
  onClickFunction,
  onDeleteFunction,
  isCondition,
  color,
  className,
  clickable,
  style,
  iconStyle,
  icon,
  size,
  isShowDelete,
  onClick,
  count,
}) => {
  const classes = useStyles();

  const handleClickIcon = e => {
    e.stopPropagation();

    if (addable) {
      onClickFunction(tag);
    }
    if (deleteable) {
      if (isCondition) {
        onDeleteFunction(conditionIndex, idx);
      } else {
        onDeleteFunction(tag);
      }
    }
  };

  if (!clickable) {
    return (
      <Chip
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        label={
          count ? (
            <Tooltip placement="top" title={count}>
              <span>
                {!!count && (
                  <span className={classes.count}>
                    {count > 99 ? '99+' : count}
                  </span>
                )}
                {tag}
              </span>
            </Tooltip>
          ) : (
            <span>{tag}</span>
          )
        }
        icon={
          icon && (
            <img
              className={classes.icon}
              alt={tag}
              src={icon}
              width={15}
              height={15}
            />
          )
        }
        onClick={onClick}
        className={classnames([classes.tagStyle, classes[color], className], {
          [classes['tagStyle--size-m']]: size === 'M',
          [classes['tagStyle--with-icon']]: icon !== null && icon !== undefined,
        })}
        style={count ? { paddingLeft: 4, ...style } : style}
      />
    );
  }
  return (
    <Chip
      classes={{
        root: classes.root,
        label: classes.label,
        deleteIcon: classnames(
          classes.deleteIcon,
          classes['icon--clickable'],
          iconStyle,
        ),
      }}
      style={count ? { paddingLeft: 4 } : null}
      label={
        count ? (
          <Tooltip placement="top" title={count}>
            <span style={!isShowDelete ? { paddingRight: 6 } : null}>
              <span className={classes.count}>
                {count > 99 ? '99+' : count}
              </span>

              {tag}
            </span>
          </Tooltip>
        ) : (
          <span style={!isShowDelete ? { paddingRight: 6 } : null}>{tag}</span>
        )
      }
      icon={
        icon && (
          <ImageDisplay
            alt={tag}
            className={classes.icon}
            src={icon ? imgSrc.icZap : imgSrc.icZap}
          />
        )
      }
      onClick={onClick}
      onDelete={isShowDelete ? handleClickIcon : null}
      className={classnames(
        classes.tagStyle,
        classes.tagStyleClickable,
        classes[color],
        className,
        {
          [classes['tagStyle--size-m']]: size === 'M',
          [classes['tagStyleClickable--size-m']]: size === 'M',
        },
      )}
      clickable={onClick ? clickable : null}
      deleteIcon={
        <ImageDisplay
          alt={tag}
          className={classnames(classes.icon, {
            [classes['icon--size-m']]: size === 'M',
          })}
          src={addable ? imgSrc.tagAdd : imgSrc.tagDelete}
        />
      }
    />
  );
};

Tag.defaultProps = {
  size: 'S',
  isShowDelete: true,
};

Tag.propTypes = {
  size: PropTypes.oneOf(['S', 'M']),
  isShowDelete: PropTypes.bool,
};

export default Tag;
