import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/modalAction';
import { ModalType } from '../../types';
import ConnectLineBizTipModal from '../organization-setting/linked-app/ConnectLineBizTipModal';
import DisconnectPlatformModal from '../organization-setting/DisconnectPlatformModal';
import ConnectMessengerTipModal from '../organization-setting/linked-app/ConnectMessengerTipModal';
import ConnectInstagramTipModal from '../organization-setting/linked-app/ConnectInstagramTipModal';
import ConnectWhatsappTipModal from '../organization-setting/linked-app/ConnectWhatsappTipModal';

import AddCouponModal from '../superMarket/coupon-detail/AddCouponModal';

import AddTagModal from './AddTagModal';
import DeleteNoteModal from './DeleteNoteModal';
import EditNoteModal from './EditNoteModal';
// import AnnouncementModal from './AnnouncementModal';
import DeleteTagModal from './DeleteTagModal';
import DeleteTagsModal from './DeleteTagsModal';
import ExportCustomer from './ExportCustomer';
import ExportChatpayOrder from './ExportChatpayOrder';
import SystemTaskModalContainer from '../../containers/system-task/SystemTaskModalContainer';
import NotificationCenterModalContainer from '../../containers/notification-center/NotificationCenterModalContainer';
import OrganizationAnnouncementModalContainer from '../../containers/organizationAnnouncement/OrganizationAnnouncementModalContainer';

import AlertModal from './AlertModal';
import DialogText from './DialogText';
import UploadDialog from './UploadDialog';
import ECEnableModal from './ECEnableModal';
import ChangeMailModal from '../../containers/ChangeMailModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const ModalRoot = () => {
  const { isOpen, modalType, modalProps } = useSelector(state => state.modal);
  const dispatch = useDispatch();
  let ModalComponent = null;

  const onCancel = () => {
    dispatch(closeModal());
  };

  switch (modalType) {
    case ModalType.EC_ENABLE_MODAL:
      ModalComponent = ECEnableModal;
      break;
    case ModalType.ADD_COUPON_MODAL:
      ModalComponent = AddCouponModal;
      break;
    case ModalType.CHANGE_MAIL_MODAL:
      ModalComponent = ChangeMailModal;
      break;
    case ModalType.DISCONNECT_PLATFORM_MODAL:
      ModalComponent = DisconnectPlatformModal;
      break;
    case ModalType.CONNECT_MESSENGER_MODAL:
      ModalComponent = ConnectMessengerTipModal;
      break;
    case ModalType.CONNECT_WHATSAPP_MODAL:
      ModalComponent = ConnectWhatsappTipModal;
      break;
    case ModalType.CONNECT_LINE_BIZ_TIP_MODAL:
      ModalComponent = ConnectLineBizTipModal;
      break;
    case ModalType.CONNECT_INSTAGRAM_MODAL:
      ModalComponent = ConnectInstagramTipModal;
      break;
    case ModalType.ADD_TAG_MODAL:
      ModalComponent = AddTagModal;
      break;
    case ModalType.DELETE_NOTE_MODAL:
      ModalComponent = DeleteNoteModal;
      break;
    case ModalType.EDIT_NOTE_MODAL:
      ModalComponent = EditNoteModal;
      break;
    case ModalType.DELETE_TAG_MODAL:
      ModalComponent = DeleteTagModal;
      break;
    case ModalType.DELETE_TAGS_MODAL:
      ModalComponent = DeleteTagsModal;
      break;
    case ModalType.EXPORT_CUSTOMERS_MODAL:
      ModalComponent = ExportCustomer;
      break;
    case ModalType.EXPORT_CHATPAY_ORDER_MODAL:
      ModalComponent = ExportChatpayOrder;
      break;
    case ModalType.NOTIFICATION_CENTER_MODAL:
      ModalComponent = NotificationCenterModalContainer;
      break;
    case ModalType.ORGANIZATION_ANNOUNCEMENT_MODAL:
      ModalComponent = OrganizationAnnouncementModalContainer;
      break;
    case ModalType.SYSTEM_TASK_MODAL:
      ModalComponent = SystemTaskModalContainer;
      break;
    case ModalType.ALERT_MODAL:
      ModalComponent = AlertModal;
      break;
    case ModalType.CONFIRM_DELETE_MODAL:
      ModalComponent = ConfirmDeleteModal;
      break;
    case ModalType.DIALOG_TEXT:
      ModalComponent = DialogText;
      break;
    case ModalType.UPLOAD_DIALOG:
      ModalComponent = UploadDialog;
      break;
    default:
  }
  if (!ModalComponent) return null;
  return <ModalComponent isOpen={isOpen} onCancel={onCancel} {...modalProps} />;
};

export default ModalRoot;
