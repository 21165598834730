import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import config from './config';
import { getBrowserLanguage } from './common/utils';

const getCurrentUserByLocalStorage = () => {
  const localStorageCurrentUser = localStorage.getItem(config.LOCAL_STORAGE);
  const currentUser = localStorageCurrentUser
    ? JSON.parse(localStorageCurrentUser)
    : null;

  return currentUser;
};

const currentUser = getCurrentUserByLocalStorage();
const lang = currentUser?.language || getBrowserLanguage();

const ns = [
  'chatPay',
  'customerServiceData',
  'surveyCake',
  'recurringMessage',
  'export',
  'retargeting',
  'livechat',
  'ma',
  'eInvoice',
  'add-organization',
  'broadcast',
  'main',
  'member-management',
  'notification-center',
  'notify',
  'overview',
  'momentFormat',
  'coupon',
  'roulette',
  'scratch',
  'slot-machine',
  'super-link',
  'templates',
  'types',
  'upload-dialog',
  'webviewEditor',
  'bot',
  'permission-setting',
  'superMarket',
  'rich-menu-setting',
  'ga4Event',
  'aiBot',
];
let importedNs = [];

export const loadI18n = async () => {
  importedNs = [];
  await new Promise((resolve, reject) => {
    i18n
      .use(
        resourcesToBackend((language, namespace, callback) => {
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "[request]" */ `./i18n/${language}/${namespace}.json`
          )
            .then(resources => {
              if (lang === language) importedNs.push(namespace);

              callback(null, resources);

              if (importedNs.length === ns.length) resolve();
            })
            .catch(error => {
              importedNs = [];
              callback(error, null);
              reject();
            });
        }),
      )
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        ns,
        fallbackLng: config.DEFAULT_LANGUAGE, // 未偵測到時的後備語系
        lng: lang,
        defaultNS: 'main',
        interpolation: {
          escapeValue: false, // not needed for react
        },
        supportedLngs: config.SUPPORT_BROWSER_LANGUAGE,
        react: {
          useSuspense: true,
        },
      });
  });
};

export default i18n;
