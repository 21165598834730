import actionType from '../actions/type';

const INITIAL_STATE = {
  list: [],
  data: {},
  analytics: {},
  selectMAList: [],
  isLoading: false,
  startTimeDatepicker: null,
  endTimeDatepicker: null,
  resource: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_MA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
