import qs from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { nextAPI } from '../common/axios';
import { FacebookTagType } from '../types';

export default class ChatPayService {
  static async getStoreSettings(orgId) {
    if (!orgId) {
      throw new Error('ChatPayService.getStoreSettings: orgId undefined');
    }

    const response = await nextAPI.get(`/shop8/${orgId}/settings`);
    return response;
  }

  static async saveStoreSettings(orgId, data) {
    if (!orgId) {
      throw new Error('ChatPayService.saveStoreSettings: orgId undefined');
    }

    if (!data) {
      throw new Error('ChatPayService.saveStoreSettings: data undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/settings`, {
      ...data,
    });
    return response;
  }

  /**
   *
   * @param orgId
   * @param stock => instock, outofstock
   * @param search
   */
  static async getProducts(
    orgId,
    search = undefined,
    stock = undefined,
    sort,
    status,
    page = 1,
    limit = 20,
  ) {
    if (!orgId) {
      throw new Error('ChatPayService.getProductsList: orgId undefined');
    }

    const response = await nextAPI.get(
      `/shop8/${orgId}/products?${qs.stringify({
        search,
        stock,
        status,
        page,
        limit,
        ...(sort
          ? {
              orderBy: 'serial',
              order: sort,
            }
          : {}),
      })}`,
    );
    return response;
  }

  static async getProduct(orgId, productId) {
    if (!orgId) {
      throw new Error('ChatPayService.getOneProduct: orgId undefined');
    }

    if (!productId) {
      throw new Error('ChatPayService.getOneProduct: productId undefined');
    }

    const response = await nextAPI.get(`/shop8/${orgId}/product/${productId}`);
    return response;
  }

  static async createProduct(orgId, data) {
    if (!orgId) {
      throw new Error('ChatPayService.createProduct: orgId undefined');
    }

    if (!data) {
      throw new Error('ChatPayService.createProduct: data undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/product`, {
      ...data,
    });
    return response;
  }

  static async updateProduct(orgId, procedureId, data) {
    if (!orgId) {
      throw new Error('ChatPayService.updateProduct: orgId undefined');
    }

    if (!procedureId) {
      throw new Error('ChatPayService.updateProduct: procedureId undefined');
    }

    if (!data) {
      throw new Error('ChatPayService.updateProduct: data undefined');
    }

    const response = await nextAPI.put(
      `/shop8/${orgId}/product/${procedureId}`,
      {
        ...data,
      },
    );
    return response;
  }

  static async deleteProduct(orgId, productId) {
    if (!orgId) {
      throw new Error('ChatPayService.deleteProduct: orgId undefined');
    }

    if (!productId) {
      throw new Error('ChatPayService.deleteProduct: productId undefined');
    }

    const response = await nextAPI.delete(
      `/shop8/${orgId}/product/${productId}`,
    );
    return response;
  }

  static async getOrders(orgId, query, page = 1, limit = 20) {
    if (!orgId) {
      throw new Error('ChatPayService.getOrders: orgId undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/orders/`, {
      ...query,
      page,
      limit,
    });
    return response;
  }

  static async getOrder(orgId, orderId) {
    if (!orgId) {
      throw new Error('ChatPayService.getOrder: orgId undefined');
    }

    if (!orderId) {
      throw new Error('ChatPayService.getOrder: orderId undefined');
    }

    const response = await nextAPI.get(`/shop8/${orgId}/order/${orderId}`);
    return response;
  }

  static async createOrder(orgId, fbMessengerTag, data) {
    if (!orgId) {
      throw new Error('ChatPayService.createOrder: orgId undefined');
    }

    if (!data) {
      throw new Error('ChatPayService.createOrder: data undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/order`, {
      ...data,
      receiptId: uuidv4(),
      fbMessengerTag,
    });
    return response;
  }

  static async updateOrder(orgId, orderId, data) {
    if (!orgId) {
      throw new Error('ChatPayService.updateOrder: orgId undefined');
    }

    if (!orderId) {
      throw new Error('ChatPayService.updateOrder: orderId undefined');
    }

    if (!data) {
      throw new Error('ChatPayService.updateOrder: data undefined');
    }

    const response = await nextAPI.put(`/shop8/${orgId}/order/${orderId}`, {
      ...data,
    });
    return response;
  }

  static async sendProduct(orgId, customerObjectId, productId, fbMessengerTag) {
    if (!orgId) {
      throw new Error('ChatPayService.sendProduct: orgId undefined');
    }

    if (!customerObjectId) {
      throw new Error('ChatPayService.sendProduct: customerObjectId undefined');
    }

    if (!productId) {
      throw new Error('ChatPayService.sendProduct: productId undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/product/send`, {
      customerObjectId,
      productId,
      receiptId: uuidv4(),
      fbMessengerTag,
    });
    return response;
  }

  static async updateOrdersStatus(orgId, orderIds, type, status) {
    if (!orgId) {
      throw new Error('ChatPayService.updateOrdersStatus: orgId undefined');
    }

    if (!orderIds) {
      throw new Error('ChatPayService.updateOrdersStatus: orderIds undefined');
    }

    if (!type) {
      throw new Error('ChatPayService.updateOrdersStatus: type undefined');
    }

    if (!status) {
      throw new Error('ChatPayService.updateOrdersStatus: status undefined');
    }

    const response = await nextAPI.put(`/shop8/${orgId}/order/status/batch`, {
      orderIds,
      type,
      status,
    });
    return response;
  }

  static async sendOrderStatus(
    orgId,
    orderId,
    customerObjectId,
    fbMessengerTag = FacebookTagType.HUMAN_AGENT,
  ) {
    if (!orgId) {
      throw new Error('ChatPayService.sendOrderStatus: orgId undefined');
    }

    if (!orderId) {
      throw new Error('ChatPayService.sendOrderStatus: orderId undefined');
    }

    if (!customerObjectId) {
      throw new Error(
        'ChatPayService.sendOrderStatus: customerObjectId undefined',
      );
    }

    const response = await nextAPI.post(`/shop8/${orgId}/order_status/send`, {
      orderId,
      customerObjectId,
      receiptId: uuidv4(),
      fbMessengerTag,
    });
    return response;
  }

  static async getStatsInfo(orgId, startTime, endTime) {
    if (!orgId) {
      throw new Error('ChatPayService.getStatsInfo: orgId undefined');
    }

    const response = await nextAPI.get(
      `/shop8/stats/${orgId}/info?${qs.stringify({
        starttime: startTime ? moment(startTime).format('YYYYMMDD') : undefined,
        endtime: endTime ? moment(endTime).format('YYYYMMDD') : undefined,
      })}`,
    );
    return response || {};
  }

  static async getCustomerServiceInfo(
    orgId,
    startTime,
    endTime,
    name,
    authority,
  ) {
    if (!orgId) {
      throw new Error('ChatPayService.getCustomerServiceInfo: orgId undefined');
    }

    const response = await nextAPI.get(
      `/shop8/stats/${orgId}/customerservice/info?${qs.stringify({
        starttime: startTime ? moment(startTime).format('YYYYMMDD') : undefined,
        endtime: endTime ? moment(endTime).format('YYYYMMDD') : undefined,
        name,
        authority,
      })}`,
    );
    return response || [];
  }

  static async getStatsRank(orgId, startTime, endTime) {
    if (!orgId) {
      throw new Error('ChatPayService.getStatsRank: orgId undefined');
    }

    const response = await nextAPI.get(
      `/shop8/stats/${orgId}/rank?${qs.stringify({
        starttime: startTime ? moment(startTime).format('YYYYMMDD') : undefined,
        endtime: endTime ? moment(endTime).format('YYYYMMDD') : undefined,
      })}`,
    );
    return response || {};
  }

  static async createCustomerGroup(orgId, name, scope, startDate, endDate) {
    if (!orgId) {
      throw new Error('ChatPayService.createCustomerGroup: orgId undefined');
    }

    if (!name) {
      throw new Error('ChatPayService.createCustomerGroup: name undefined');
    }

    if (!scope) {
      throw new Error('ChatPayService.createCustomerGroup: scope undefined');
    }

    const response = await nextAPI.post(`/customer_group/create`, {
      orgId,
      query: {
        shop8: {
          scope,
          startDate: startDate
            ? moment(startDate).format('YYYY-MM-DD')
            : undefined,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
        },
      },
      name,
    });
    return response || {};
  }

  static async createTags(orgId, tags, scope, startDate, endDate) {
    if (!orgId) {
      throw new Error('ChatPayService.createTags: orgId undefined');
    }

    if (!tags) {
      throw new Error('ChatPayService.createTags: tags undefined');
    }

    if (!scope) {
      throw new Error('ChatPayService.createTags: scope undefined');
    }

    const response = await nextAPI.post(`/tag/create`, {
      orgId,
      query: {
        shop8: {
          scope,
          startDate: startDate
            ? moment(startDate).format('YYYY-MM-DD')
            : undefined,
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
        },
      },
      tags,
    });
    return response || {};
  }

  static async confirmOrder(
    orgId,
    orderId,
    pickupRemark,
    fbMessengerTag = FacebookTagType.HUMAN_AGENT,
  ) {
    if (!orgId) {
      throw new Error('ChatPayService.confirmOrder: orgId undefined');
    }

    if (!orderId) {
      throw new Error('ChatPayService.confirmOrder: orderId undefined');
    }

    const response = await nextAPI.post(
      `/shop8/${orgId}/order/shipping/confirm`,
      {
        orderId,
        receiptId: uuidv4(),
        fbMessengerTag,
        pickupRemark,
      },
    );
    return response;
  }

  static async getShareProductLink(orgId, productId) {
    if (!orgId) {
      throw new Error('ChatPayService.getShareProductLink: orgId undefined');
    }

    if (!productId) {
      throw new Error(
        'ChatPayService.getShareProductLink: productId undefined',
      );
    }

    const response = await nextAPI.post(`/shop8/${orgId}/product/share_link`, {
      productId,
    });
    return response?.url;
  }

  static async getShareOrderLink(orgId, data) {
    if (!orgId) {
      throw new Error('ChatPayService.getShareOrderLink: orgId undefined');
    }

    if (!data) {
      throw new Error('ChatPayService.getShareOrderLink: data undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/order/share_link`, {
      ...data,
    });
    return response?.url;
  }

  static async cancelOrder(orgId, orderId) {
    if (!orgId) {
      throw new Error('ChatPayService.cancelOrder: orgId undefined');
    }

    if (!orderId) {
      throw new Error('ChatPayService.cancelOrder: orderId undefined');
    }

    const response = await nextAPI.post(
      `/shop8/${orgId}/order/shipping/cancel`,
      {
        orderId,
      },
    );
    return response;
  }

  static async importProducts(orgId, s3FileUrl) {
    if (!orgId) {
      throw new Error('ChatPayService.importProducts: orgId undefined');
    }

    if (!s3FileUrl) {
      throw new Error('ChatPayService.importProducts: s3FileUrl undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/csv/products/import`, {
      s3FileUrl,
    });

    return response;
  }

  static async getUnhandled(orgId) {
    if (!orgId) {
      throw new Error('ChatPayService.getUnhandled: orgId undefined');
    }

    const response = await nextAPI.get(`/shop8/${orgId}/orders/unhandled`);

    return response?.unhandledCount || 0;
  }

  static async exportOrders(orgId, query, i18nData) {
    if (!orgId) {
      throw new Error('ChatPayService.exportOrders: orgId undefined');
    }

    const response = await nextAPI.post(`/shop8/${orgId}/orders/export`, {
      ...query,
      i18n: i18nData,
    });
    return response;
  }
}
