import { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavigationBarAllOpened from './components/shared/NavigationBarAllOpened';
import Onboarding from './components/shared/Onboarding';
import Initial from './components/shared/Initial';
import CheckTagQuota from './components/shared/CheckTagQuota';
import ModalRoot from './components/shared/ModalRoot';
import history from './common/history';
import Notifier from './components/shared/Notifier';
import AuthorizeLayer from './AuthorizeLayer';
import Block from './Block';
import { fetchUser, logInStorage } from './actions/userAction';
import i18n from './i18n';
import config from './config';
import { WaitingComponent } from './common/utils';
import SocketioLayer from './SocketioLayer';

const Login = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "Login" */ './containers/Login'
  ),
);

const SignUp = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "SignUp" */ './containers/SignUp'
  ),
);
const SignUpContent = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "SignUpContent" */ './containers/SignUpContent'
  ),
);
const SignUpVerify = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "SignUpVerify" */ './containers/SignUpVerify'
  ),
);
const Forget = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "Forget" */ './containers/Forget'
  ),
);
const ResetPassword = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "ResetPassword" */ './containers/ResetPassword'
  ),
);
const ChangePassword = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "ChangePassword" */ './containers/ChangePassword'
  ),
);
const InviteFail = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "InviteFail" */ './containers/InviteFail'
  ),
);
const ChangePasswordFail = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "ChangePasswordFail" */ './containers/ChangePasswordFail'
  ),
);
const Overview = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "Overview" */ './containers/overview/Router'
  ),
);

const MessageCenter = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "MessageCenter" */ './containers/MessageCenter'
  ),
);

const MessageBot = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "MessageBot" */ './containers/message-bot/MessageBotRouter'
  ),
);
const WebviewEditor = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "WebviewEditor" */ './containers/webview-editor/WebviewEditorContainer'
  ),
);
const CustomerManagement = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "CustomerManagement" */ './containers/customer-management/CustomerManagementRouter'
  ),
);
const Broadcast = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "Broadcast" */ './containers/broadcast/BroadcastRouter'
  ),
);
const MA = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "Broadcast" */ './containers/marketing-automation/MARouter'
  ),
);
const OrganizationSettingRouter = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "OrganizationSetting" */ './containers/organization-setting/Router'
  ),
);

const AccountSettingRouter = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "AccountSetting" */ './containers/account-setting/AccountSettingRouter'
  ),
);

const SuperLinkRouter = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "SuperLinkRouter" */ './containers/super-link/SuperLinkRouter'
  ),
);

const AddOrganization = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "AddOrganization" */ './components/navigation/AddOrganization'
  ),
);

const ShoplineRouter = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "ShoplineRouter" */ './containers/superMarket/Router'
  ),
);

const ChatPayRouter = lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "ChatPayRouter" */ './containers/chatPay/Router'
  ),
);

const App = () => {
  const widthLessThan1024 = useMediaQuery('(max-width:1023px)');
  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      const currentUser = await dispatch(fetchUser(true));

      if (currentUser?.language) {
        if (!config.LANGUAGE_VALUE.includes(currentUser.language)) {
          currentUser.language = config.DEFAULT_LANGUAGE;
          logInStorage(currentUser);
        }
        i18n.changeLanguage(currentUser.language);
      }
    };
    getUser();
  }, []);

  if (widthLessThan1024) return <Block />;

  return (
    <BrowserRouter history={history}>
      <AuthorizeLayer>
        <Notifier />
        <Routes>
          <Route path="/login" exact element={WaitingComponent(Login)} />
          <Route path="/forget" exact element={WaitingComponent(Forget)} />
          <Route path="/signup" exact element={WaitingComponent(SignUp)} />
          <Route
            path="/changePassword"
            exact
            element={WaitingComponent(ChangePassword)}
          />
          <Route
            path="/resetPassword"
            exact
            element={WaitingComponent(ResetPassword)}
          />
          <Route
            path="/signup-content"
            exact
            element={WaitingComponent(SignUpContent)}
          />
          <Route
            path="/signup-verify"
            exact
            element={WaitingComponent(SignUpVerify)}
          />
          <Route
            path="/invite-fail"
            exact
            element={WaitingComponent(InviteFail)}
          />
          <Route
            path="/change-password-fail"
            exact
            element={WaitingComponent(ChangePasswordFail)}
          />

          <Route
            path="/"
            element={
              <>
                <SocketioLayer />
                <Onboarding />
                <Initial />
                <CheckTagQuota />
                <NavigationBarAllOpened />
              </>
            }
          >
            <Route
              path="chatPay/:orgId/*"
              element={WaitingComponent(ChatPayRouter)}
            />
            <Route
              path="customer-management/:orgId/*"
              element={WaitingComponent(CustomerManagement)}
            />
            <Route
              path="overview/:orgId/*"
              element={WaitingComponent(Overview)}
            />
            <Route
              path="organization-setting/:orgId/*"
              element={WaitingComponent(OrganizationSettingRouter)}
            />
            <Route
              path="message-center/:orgId"
              element={WaitingComponent(MessageCenter)}
            />
            <Route
              path="message-center/:orgId/conversation/:conversationId/customer/:customerId"
              element={WaitingComponent(MessageCenter)}
            />
            <Route
              path="broadcast/:orgId/*"
              element={WaitingComponent(Broadcast)}
            />
            <Route
              path="message-bot/:orgId/*"
              element={WaitingComponent(MessageBot)}
            />
            <Route
              path="marketing-automation/:orgId/*"
              element={WaitingComponent(MA)}
            />
            <Route
              path="webview-editor/:orgId/*"
              exact
              element={WaitingComponent(WebviewEditor)}
            />
            <Route
              path="super-link/:orgId/*"
              element={WaitingComponent(SuperLinkRouter)}
            />
            <Route
              path="account-setting/*"
              element={WaitingComponent(AccountSettingRouter)}
            />
            <Route
              path="add-organization/*"
              element={WaitingComponent(AddOrganization)}
            />

            <Route
              path="superMarket/:orgId/*"
              element={WaitingComponent(ShoplineRouter)}
            />
          </Route>
        </Routes>
        <ModalRoot />
      </AuthorizeLayer>
    </BrowserRouter>
  );
};

export default App;
