import coreAPI, { nextAPI } from '../common/axios';
import { TaskStatusType } from '../types';
import { transformCustomerGroupQuery } from './ServiceUtility';

export default class SystemTaskService {
  static async getTaskList(orgId, options) {
    if (!orgId) {
      throw new Error('SystemTaskService.getTaskList: orgId undefined');
    }
    const query = {
      where: {
        organization: {
          className: 'Organization',
          __type: 'Pointer',
          objectId: orgId,
        },
        status: { $ne: TaskStatusType.DRAFT },
      },
      order: '-createdAt',
      limit: 10,
      _method: 'GET',
      keys: 'options.name,status,type,total,options.title,url',
      ...options,
    };

    const response = await coreAPI.post('/classes/Task', query);
    return response.results;
  }

  /**
   * @typedef {{
   *  method: 'add'|'del',
   *  groupId: string,
   *  forOrganization: boolean,
   * }} TaskTagOptions
   */

  /**
   *
   * @param {Array<string>} tags
   * @param {*} query
   * @param {TaskTagOptions} options
   */
  static async tagsOperationByTask(tags, query = {}, options = {}, other) {
    if (!other.method) {
      throw new Error('No specifies method');
    }
    const realQuery = transformCustomerGroupQuery(query);
    const payload = {
      tags,
      query: realQuery,
      options,
      ...other,
    };
    const response = coreAPI.post('functions/task.tag', payload);
    return response.result;
  }

  static async tagsOperationByTaskGroupId(tags, query = {}, groupId, other) {
    const payload = {
      tags,
      query,
      groupId,
      ...other,
    };
    const response = coreAPI.post('functions/task.tag', payload);
    return response.result;
  }

  /**
   * @typedef {{
   *  groupId: string,
   * }} TaskExportOptions
   */

  /**
   *
   * @param {Array<string>} tags
   * @param {*} query
   * @param {TaskExportOptions} options
   */
  static async exportCustomersByTask(query = {}, options = {}, i18nData) {
    const realQuery = transformCustomerGroupQuery(query);
    const payload = {
      i18n: i18nData,
      query: realQuery,
      options,
    };
    const response = coreAPI.post('functions/task.export', payload);
    return response.result;
  }

  /**
   * @param {string} orgId - required
   * @param {string} groupId - required
   */
  static async exportCustomerGroupById(orgId, groupId, i18nData) {
    if (!orgId) {
      throw new Error('orgId is empty');
    }

    if (!groupId) {
      throw new Error('groupId is empty');
    }

    const response = await coreAPI.post('functions/task.export', {
      i18n: i18nData,
      groupId,
      query: {
        orgId,
      },
    });

    return response;
  }

  static async deleteTask(taskId) {
    return nextAPI.put(`/broadcast/delete/${taskId}`);
  }
}
