import actionType from './type';
import axiosInstance from '../common/axios';
import { enqueueSnackbar } from './snackbarAction';
import { SnackBarType } from '../types';

const getTaggingPayload = (name, customer, organization, otherConditions) => {
  let query = {
    message: {
      [name]: true,
    },
    where: {
      customer: {
        customerId: customer ? customer.customerId : '',
      },
      organization: {
        objectId: organization ? organization.objectId : '',
      },
    },
    options: {
      version: 2,
    },
  };

  if (otherConditions) {
    query = { ...query, ...otherConditions };
  }

  return query;
};

const addTag = (
  name,
  customer,
  organization,
  otherConditions,
) => async dispatch => {
  const query = getTaggingPayload(
    name,
    customer,
    organization,
    otherConditions,
  );
  dispatch({ type: actionType.ADD_TAG_REQUEST });
  try {
    await axiosInstance.post('/functions/tagging', query);
    dispatch({ type: actionType.ADD_TAG_SUCCESS });
  } catch (error) {
    dispatch({ type: actionType.ADD_TAG_FAILURE });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response.data.error,
      }),
    );
  }
};

const removeTag = (name, customer, organization) => async dispatch => {
  const query = getTaggingPayload(name, customer, organization);
  query.method = 'DELETE';
  await axiosInstance.post('/functions/tagging', query);

  dispatch({ type: actionType.REMOVE_TAG });
};

export { addTag, removeTag };
