import { nextAPI } from '../common/axios';

export default class ECService {
  static async findTag(provider, orgId, keywords) {
    if (!orgId) {
      throw new Error('ECService.findTag: orgId undefined');
    }

    const response = await nextAPI.get(
      `/ec/${provider}/tags?orgId=${orgId}${
        keywords ? `&keywords=${keywords}` : ''
      }`,
    );

    return response?.results || [];
  }

  static async findMembershipTier(provider, orgId) {
    if (!orgId) {
      throw new Error('ECService.findMembershipTier: orgId undefined');
    }

    const response = await nextAPI.get(
      `/ec/${provider}/membershipTiers?orgId=${orgId}`,
    );

    return response?.results || [];
  }

  static async cyberbizSendEmailAndEnable(
    orgId,
    isEnableProvider = false,
    headers = {},
  ) {
    if (!orgId) {
      throw new Error('ECService.sendEmailAndEnable: orgId undefined');
    }

    const response = await nextAPI.post(
      `/ec/cyberbiz/email/send`,
      {
        isEnableProvider,
        orgId,
      },
      { headers },
    );

    return response;
  }

  static async getOwnerOrAdminOrg(provider) {
    const response = await nextAPI.get(`/ec/${provider}/organization`);

    return response || [];
  }

  static async connectEC(orgId, provider, data) {
    if (!orgId) {
      throw new Error('ECService.connect: orgId undefined');
    }

    if (!data) {
      throw new Error('ECService.connect: data undefined');
    }

    if (!provider) {
      throw new Error('ECService.connect: provider undefined');
    }

    const response = await nextAPI.post(`/ec/${provider}/connect`, {
      orgId,
      ...data,
    });

    return response;
  }

  static async shoplineConnectWithApp(orgId, sessionToken) {
    if (!orgId) {
      throw new Error('ECService.shoplineConnectWithApp: orgId undefined');
    }

    if (!sessionToken) {
      throw new Error(
        'ECService.shoplineConnectWithApp: sessionToken undefined',
      );
    }

    const response = await nextAPI.get(
      `/ec/shopline/connectWithApp?orgId=${orgId}&_sessionToken=${sessionToken}`,
    );

    return response?.url || '';
  }
}
