import { PlatformType, GenderType } from '../types';

export function transformCustomerGroupQuery(query) {
  const realQuery = { ...query };

  if (realQuery?.joinDate?.type) {
    realQuery.beforeJoinDate = realQuery.joinDate.beforeJoinDate;
    realQuery.afterJoinDate = realQuery.joinDate.afterJoinDate;
  }
  delete realQuery.joinDate;

  if (realQuery?.conversationDate?.type) {
    realQuery.beforeConversationDate =
      realQuery.conversationDate.beforeConversationDate;
    realQuery.afterConversationDate =
      realQuery.conversationDate.afterConversationDate;
  }
  delete realQuery.conversationDate;

  if (
    realQuery?.platforms?.length ===
    Object.keys(PlatformType).filter(d => d !== PlatformType.LIVECHAT).length
  ) {
    realQuery.platforms = [];
  }

  if (realQuery?.genders?.length === Object.keys(GenderType).length) {
    realQuery.genders = [];
  }

  realQuery.gender = realQuery.genders;
  delete realQuery.genders;

  return realQuery;
}

export default {
  transformCustomerGroupQuery,
};
