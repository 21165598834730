import { useState } from 'react';
import PropTypes from 'prop-types';
import { color as themeColor } from '../../../themes';

function IconX({
  className,
  width,
  height,
  id,
  color,
  hoverColor,
  viewBox,
  onClick = () => {},
}) {
  const [mainColor, setMainColor] = useState(color);
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={viewBox}
      onMouseEnter={() => setMainColor(hoverColor)}
      onMouseLeave={() => setMainColor(color)}
      onClick={onClick}
    >
      <defs>
        <path
          id="icon-x"
          d="M17.743 16.543a.829.829 0 0 1 0 1.2.778.778 0 0 1-.6.257.778.778 0 0 1-.6-.257L12 13.2l-4.543 4.543a.778.778 0 0 1-.6.257.778.778 0 0 1-.6-.257.829.829 0 0 1 0-1.2L10.8 12 6.257 7.457a.829.829 0 0 1 0-1.2.829.829 0 0 1 1.2 0L12 10.8l4.543-4.543a.829.829 0 0 1 1.2 0 .829.829 0 0 1 0 1.2L13.2 12l4.543 4.543z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id={id} fill="#FFF">
          <use xlinkHref="#icon-x" />
        </mask>
        <g fill={mainColor} mask={`url(#${id})`}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

IconX.defaultProps = {
  className: undefined,
  width: 24,
  height: 24,
  color: themeColor.$10,
  hoverColor: themeColor.$10,
  viewBox: '0,0,24,24',
};

IconX.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  viewBox: PropTypes.string,
};

export default IconX;
