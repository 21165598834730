export const OPEN_MODAL = 'OPEN_MODAL';
export const UPDATE_MODAL_PROPS = 'UPDATE_MODAL_PROPS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const ALL_CUSTOMERS_FETCH_DATA_REQUEST =
  'ALL_CUSTOMERS_FETCH_DATA_REQUEST';
export const ALL_CUSTOMERS_FETCH_DATA_REQUEST_CONTINUE =
  'ALL_CUSTOMERS_FETCH_DATA_REQUEST_CONTINUE';
export const ALL_CUSTOMERS_FETCH_DATA_SUCCESS =
  'ALL_CUSTOMERS_FETCH_DATA_SUCCESS';
export const ALL_CUSTOMERS_FETCH_DATA_FAILURE =
  'ALL_CUSTOMERS_FETCH_DATA_FAILURE';
export const ALL_CUSTOMERS_SET = 'ALL_CUSTOMERS_SET';
export const ALL_CUSTOMERS_RESET = 'ALL_CUSTOMERS_RESET';
export const ALL_CUSTOMERS_MAPPING_QUERY = 'ALL_CUSTOMERS_MAPPING_QUERY';
export const ALL_CUSTOMERS_ADD_FILTER = 'ALL_CUSTOMERS_ADD_FILTER';
export const ALL_CUSTOMERS_SEARCH_MATCH_PHONE_MAIL =
  'ALL_CUSTOMERS_SEARCH_MATCH_PHONE_MAIL';
export const ALL_CUSTOMERS_DELETE_FILTER = 'ALL_CUSTOMERS_DELETE_FILTER';
export const ALL_CUSTOMERS_SET_FETCH_STATUS = 'ALL_CUSTOMERS_SET_FETCH_STATUS';
export const ALL_CUSTOMERS_FIND_TAG_REQUEST = 'ALL_CUSTOMERS_FIND_TAG_REQUEST';
export const ALL_CUSTOMERS_FIND_TAG_SUCCESS = 'ALL_CUSTOMERS_FIND_TAG_SUCCESS';
export const ALL_CUSTOMERS_FIND_TAG_FAILURE = 'ALL_CUSTOMERS_FIND_TAG_FAILURE';

export const ALL_CUSTOMERS_FIND_PARTNER_TAG_FAILURE =
  'ALL_CUSTOMERS_FIND_PARTNER_TAG_FAILURE';
export const ALL_CUSTOMERS_FIND_PARTNER_TAG_SUCCESS =
  'ALL_CUSTOMERS_FIND_PARTNER_TAG_SUCCESS';
export const ALL_CUSTOMERS_FIND_IS_BUY_PARTNER_TAG_SUCCESS =
  'ALL_CUSTOMERS_FIND_IS_BUY_PARTNER_TAG_SUCCESS';
export const ALL_CUSTOMERS_GET_COUNT_REQUEST =
  'ALL_CUSTOMERS_GET_COUNT_REQUEST';
export const ALL_CUSTOMERS_GET_COUNT_SUCCESS =
  'ALL_CUSTOMERS_GET_COUNT_SUCCESS';
export const ALL_CUSTOMERS_GET_COUNT_FAILURE =
  'ALL_CUSTOMERS_GET_COUNT_FAILURE';
export const ALL_CUSTOMERS_GET_CUSTOMER_DATA_REQUEST =
  'ALL_CUSTOMERS_GET_CUSTOMER_DATA_REQUEST';
export const ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS =
  'ALL_CUSTOMERS_GET_CUSTOMER_DATA_SUCCESS';
export const ALL_CUSTOMERS_GET_CUSTOMER_DATA_FAILURE =
  'ALL_CUSTOMERS_GET_COUNT_FAILURE';
export const ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_REQUEST =
  'ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_REQUEST';
export const ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_SUCCESS =
  'ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_SUCCESS';
export const ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_FAILURE =
  'ALL_CUSTOMERS_ADD_TAG_TO_CUSTOMER_FAILURE';
export const ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_REQUEST =
  'ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_REQUEST';
export const ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_SUCCESS =
  'ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_SUCCESS';
export const ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_FAILURE =
  'ALL_CUSTOMERS_DELETE_CUSTOMER_TAG_FAILURE';

export const TAG_MANAGEMENT_RESET = 'TAG_MANAGEMENT_RESET';
export const TAG_MANAGEMENT_QUERY_TAG_REQUEST =
  'TAG_MANAGEMENT_QUERY_TAG_REQUEST';
export const TAG_MANAGEMENT_QUERY_TAG_SUCCESS =
  'TAG_MANAGEMENT_QUERY_TAG_SUCCESS';
export const TAG_MANAGEMENT_QUERY_TAG_FAILURE =
  'TAG_MANAGEMENT_QUERY_TAG_FAILURE';
export const TAG_MANAGEMENT_COUNT_QUERY_TAG_REQUEST =
  'TAG_MANAGEMENT_COUNT_QUERY_TAG_REQUEST';
export const TAG_MANAGEMENT_COUNT_QUERY_TAG_SUCCESS =
  'TAG_MANAGEMENT_COUNT_QUERY_TAG_SUCCESS';
export const TAG_MANAGEMENT_COUNT_QUERY_TAG_FAILURE =
  'TAG_MANAGEMENT_COUNT_QUERY_TAG_FAILURE';
export const TAG_MANAGEMENT_SET_DELETE_TAG_LIST =
  'TAG_MANAGEMENT_SET_DELETE_TAG_LIST';
export const TAG_MANAGEMENT_SET_RESOURCE = 'TAG_MANAGEMENT_SET_RESOURCE';
export const SYSTEM_TASK_GET_TASK_LIST_REQUEST =
  'SYSTEM_TASK_GET_TASK_LIST_REQUEST';
export const SYSTEM_TASK_GET_TASK_LIST_SUCCESS =
  'SYSTEM_TASK_GET_TASK_LIST_SUCCESS';
export const SYSTEM_TASK_GET_TASK_LIST_CONTINUE_SUCCESS =
  'SYSTEM_TASK_GET_TASK_LIST_CONTINUE__SUCCESS';
export const SYSTEM_TASK_GET_TASK_LIST_FAILURE =
  'SYSTEM_TASK_GET_TASK_LIST_FAILURE';
export const SYSTEM_TASK_SET_TASK_LIST_DATA = 'SYSTEM_TASK_SET_DATA';
export const SYSTEM_TASK_INSERT_TASK = 'SYSTEM_TASK_INSERT_TASK';
export const SYSTEM_TASK_SET_SHOW_BADGE_ICON =
  'SYSTEM_TASK_SET_SHOW_BADGE_ICON';
export const SYSTEM_TASK_DELETE_TASK = 'SYSTEM_TASK_DELETE_TASK';
export const SET_SOCKETIO_CLIENT = 'SET_SOCKETIO_CLIENT';
export const SET_SOCKETIO_CLIENT_READY = 'SET_SOCKETIO_CLIENT_READY';
export const SET_SOCKETIO_SOUND = 'SET_SOCKETIO_SOUND';
export const SET_SOCKETIO_IS_PLAY = 'SET_SOCKETIO_IS_PLAY';
export const SET_FACEBOOK_DATA = 'SET_FACEBOOK_DATA';
export const SET_INSTAGRAM_DATA = 'SET_INSTAGRAM_DATA';
export const CUSTOMER_GROUPS_UPDATE_GROUP = 'CUSTOMER_GROUPS_UPDATE_GROUP';
export const CUSTOMER_GROUPS_GET_LIST_REQUEST =
  'CUSTOMER_GROUPS_GET_LIST_REQUEST';
export const CUSTOMER_GROUPS_GET_LIST_SUCCESS =
  'CUSTOMER_GROUPS_GET_LIST_SUCCESS';
export const CUSTOMER_GROUPS_GET_LIST_CONTINUE_SUCCESS =
  'CUSTOMER_GROUPS_GET_LIST_CONTINUE_SUCCESS';
export const CUSTOMER_GROUPS_GET_LIST_FAILURE =
  'CUSTOMER_GROUPS_GET_LIST_FAILURE';
export const CUSTOMER_GROUPS_UPDATE_GROUP_NAME_REQUEST =
  'CUSTOMER_GROUPS_UPDATE_GROUP_NAME_REQUEST';
export const CUSTOMER_GROUPS_UPDATE_GROUP_NAME_SUCCESS =
  'CUSTOMER_GROUPS_UPDATE_GROUP_NAME_SUCCESS';
export const CUSTOMER_GROUPS_UPDATE_GROUP_NAME_FAILURE =
  'CUSTOMER_GROUPS_UPDATE_GROUP_NAME_FAILURE';
export const CUSTOMER_GROUPS_GET_GROUP_DATA_SUCCESS =
  'CUSTOMER_GROUPS_GET_GROUP_DATA_SUCCESS';
export const CUSTOMER_GROUPS_GET_CUTOMERS_REQUEST =
  'CUSTOMER_GROUPS_GET_CUTOMERS_REQUEST';
export const CUSTOMER_GROUPS_GET_CUTOMERS_SUCCESS =
  'CUSTOMER_GROUPS_GET_CUTOMERS_SUCCESS';
export const CUSTOMER_GROUPS_GET_CUTOMERS_FAILURE =
  'CUSTOMER_GROUPS_GET_CUTOMERS_FAILURE';
export const CREATE_CUSTOMER_GROUPS_REQUEST = 'CREATE_CUSTOMER_GROUPS_REQUEST';
export const CREATE_CUSTOMER_GROUPS_SUCCESS = 'CREATE_CUSTOMER_GROUPS_SUCCESS';
export const CREATE_CUSTOMER_GROUPS_FAILURE = 'CREATE_CUSTOMER_GROUPS_FAILURE';
export const DELETE_CUSTOMER_GROUPS_REQUEST = 'DELETE_CUSTOMER_GROUPS_REQUEST';
export const DELETE_CUSTOMER_GROUPS_SUCCESS = 'DELETE_CUSTOMER_GROUPS_SUCCESS';
export const DELETE_CUSTOMER_GROUPS_FAILURE = 'DELETE_CUSTOMER_GROUPS_FAILURE';
export const UPDATE_CUSTOMER_GROUPS_STATE = 'UPDATE_CUSTOMER_GROUPS_STATE';
export const UPDATE_CUSTOMER_GROUPS_REQUEST = 'UPDATE_CUSTOMER_GROUPS_REQUEST';
export const UPDATE_CUSTOMER_GROUPS_SUCCESS = 'UPDATE_CUSTOMER_GROUPS_SUCCESS';
export const UPDATE_CUSTOMER_GROUPS_FAILURE = 'UPDATE_CUSTOMER_GROUPS_FAILURE';
export const RESET_CUSTOMER_GROUPS = 'RESET_CUSTOMER_GROUPS';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const SSO_DIRECT_PAGE = 'SSO_DIRECT_PAGE';
export const SET_BROADCAST = 'SET_BROADCAST';
export const SET_NEXT_RESOURCE = 'SET_NEXT_RESOURCE';
export const RICH_MENU_SETTING_RESET = 'RICH_MENU_SETTING_RESET';
export const RICH_MENU_SETTING_SET_CREATE_REQUEST =
  'RICH_MENU_SETTING_SET_CREATE_REQUEST';
export const RICH_MENU_SETTING_GET_GROUPS_SUCCESS =
  'RICH_MENU_SETTING_GET_GROUPS_SUCCESS';
export const RICH_MENU_SETTING_SET_GROUP = 'RICH_MENU_SETTING_SET_GROUP';
export const NOTIFICATION_CENTER_SET_ANNOUNCEMENT =
  'NOTIFICATION_CENTER_SET_ANNOUNCEMENT';
export const NOTIFICATION_CENTER_SET_ANNOUNCEMENT_FAILURE =
  'NOTIFICATION_CENTER_SET_ANNOUNCEMENT_FAILURE';
export const NOTIFICATION_CENTER_SET_SHOW_BADGE_ICON =
  'NOTIFICATION_CENTER_SET_SHOW_BADGE_ICON';
export const NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_CONTINUE_SUCCESS =
  'NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_CONTINUE_SUCCESS';
export const NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS =
  'NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS';
export const NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_FAILURE =
  'NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_FAILURE';
export const NOTIFICATION_CENTER_SET_ISLOADING =
  'NOTIFICATION_CENTER_SET_ISLOADING';
export const NOTIFICATION_CENTER_SET_MODAL_STATUS =
  'NOTIFICATION_CENTER_SET_MODAL_STATUS';
export const ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS =
  'ORGAZIZATION_ANNOUNCEMENT_SET_MODAL_STATUS';
export const SET_RICH_MENU_SETTING = 'SET_RICH_MENU_SETTING';
export const RICH_MENU_SETTING_GROUPS_STATUS_LOADING =
  'RICH_MENU_SETTING_GROUPS_STATUS_LOADING';
export const BROADCAST_FIND_TAG = 'BROADCAST_FIND_TAG';
export const BROADCAST_FIND_PARTNER_TAG = 'BROADCAST_FIND_PARTNER_TAG';
export const BROADCAST_RESET = 'BROADCAST_RESET';
export const BROADCAST_ADD_MESSAGE = 'BROADCAST_ADD_MESSAGE';
export const BROADCAST_GET_CUSTOMER_GROUPS = 'BROADCAST_GET_CUSTOMER_GROUPS';
export const BROADCAST_SET_SHOW_DEFAULT_TEMPLATE =
  'BROADCAST_SET_SHOW_DEFAULT_TEMPLATE';
export const BROADCAST_SET_DEFAULT_TEMPLATE = 'BROADCAST_SET_DEFAULT_TEMPLATE';
export const COPY_BROADCAST = 'COPY_BROADCAST';
export const BROADCAST_GET_CUSTOMERS = 'BROADCAST_GET_CUSTOMERS';
export const ORGANIZATION_RESET = 'ORGANIZATION_RESET';
export const ORGANIZATION_ADD = 'ORGANIZATION_ADD';
export const SET_MEMBER_MANAGEMENT = 'SET_MEMBER_MANAGEMENT';
export const ORGANIZATION_REMOVE = 'ORGANIZATION_REMOVE';

export default {
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_ORGANIZATION: 'SET_ORGANIZATION',
  SET_CHATPAY: 'SET_CHATPAY',
  SET_VPC_FEATURE: 'SET_VPC_FEATURE',
  SET_CHATPAY_FAIL: 'SET_CHATPAY_FAIL',
  SET_VPC_FEATURE_FAIL: 'SET_VPC_FEATURE_FAIL',
  SET_CUSTOMERSERVICEDATA: 'SET_CUSTOMERSERVICEDATA',
  SET_CUSTOMERSERVICEDATA_FAIL: 'SET_CUSTOMERSERVICEDATA_FAIL',
  SET_RETARGETING: 'SET_RETARGETING',
  SET_RETARGETING_FAIL: 'SET_RETARGETING_FAIL',
  SET_LIVECHAT: 'SET_LIVECHAT',
  SET_LIVECHAT_FAIL: 'SET_LIVECHAT_FAIL',
  SET_SURVEYCAKE: 'SET_SURVEYCAKE',
  SET_SURVEYCAKE_FAIL: 'SET_SURVEYCAKE_FAIL',
  SET_RECURRINGMESSAGE: 'SET_RECURRINGMESSAGE',
  SET_RECURRINGMESSAGE_FAIL: 'SET_RECURRINGMESSAGE_FAIL',
  SET_EINVOICE: 'SET_EINVOICE',
  SET_EINVOICE_FAIL: 'SET_EINVOICE_FAIL',
  SET_MA: 'SET_MA',
  SET_SUPERLINK_RESOURCE: 'SET_SUPERLINK_RESOURCE',
  SET_COUPON: 'SET_COUPON',
  CREATE_COUPON: 'CREATE_COUPON',
  SET_COUPON_FAIL: 'SET_COUPON_FAIL',
  SET_SLOTMACHINE: 'SET_SLOTMACHINE',
  SET_SLOTMACHINE_FAIL: 'SET_SLOTMACHINE_FAIL',
  SET_SCRATCH: 'SET_SCRATCH',
  SET_SCRATCH_FAIL: 'SET_SCRATCH_FAIL',
  SET_ROULETTE: 'SET_ROULETTE',
  SET_ROULETTE_FAIL: 'SET_ROULETTE_FAIL',
  SET_WEBVIEW_EDITOR_STATE: 'SET_WEBVIEW_EDITOR_STATE',
  SET_TRUST_ROUTE_FAIL: 'SET_TRUST_ROUTE_FAIL',
  SET_TRUST_ROUTE_LIST: 'SET_TRUST_ROUTE_LIST',
  SET_AUTH_ORG_OWN_DATA: 'SET_AUTH_ORG_OWN_DATA',
  SET_ORG_ANNOUNCEMENT: 'SET_ORG_ANNOUNCEMENT',
  SET_AUTH_ISLOADING: 'SET_AUTH_ISLOADING',
  SET_COMPREHENSIVE_STATUS: 'SET_COMPREHENSIVE_STATUS',
  GET_ADDONSETTING_COUNT: 'GET_ADDONSETTING_COUNT',
  SET_TAG_ANALYSIS_TAG_ACCOUNTING_REQUEST:
    'SET_TAG_ANALYSIS_TAG_ACCOUNTING_REQUEST',
  SET_TAG_ANALYSIS_TAG_ACCOUNTING_FAILURE:
    'SET_TAG_ANALYSIS_TAG_ACCOUNTING_FAILURE',
  SET_TAG_ANALYSIS_TAG_ACCOUNTING_SUCCESS:
    'SET_TAG_ANALYSIS_TAG_ACCOUNTING_SUCCESS',
  SET_TAG_ANALYSIS_FIND_TAG_SUCCESS: 'SET_TAG_ANALYSIS_FIND_TAG_SUCCESS',
  SET_TAG_ANALYSIS_FIND_TAG_REQUEST: 'SET_TAG_ANALYSIS_FIND_TAG_REQUEST',
  SET_TAG_ANALYSIS_FIND_TAG_FAILURE: 'SET_TAG_ANALYSIS_FIND_TAG_FAILURE',
  SET_TAG_ANALYSIS_PERIOD_TYPE: 'SET_TAG_ANALYSIS_PERIOD_TYPE',
  SET_MESSAGE_ANALYSIS_DATA: 'SET_MESSAGE_ANALYSIS_DATA',
  SET_MESSAGE_ANALYSIS_DATA_FAIL: 'SET_MESSAGE_ANALYSIS_DATA_FAIL',
  SET_MESSAGE_ANALYSIS_PERIOD_TYPE: 'SET_MESSAGE_ANALYSIS_PERIOD_TYPE',
  SET_CUSTOMER_DETAIL_DATA: 'SET_CUSTOMER_DETAIL_DATA',
  SET_CUSTOMER_DETAIL_DATA_FAIL: 'SET_CUSTOMER_DETAIL_DATA_FAIL',
  FETCH_USER: 'FETCH_USER',
  NORMAL_SIGNUP: 'NORMAL_SIGNUP',
  SOCIAL_SIGNUP: 'SOCIAL_SIGNUP',
  NORMAL_LOGIN: 'NORMAL_LOGIN',
  SOCIAL_LOGIN: 'SOCIAL_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  SEND_PASSWORD: 'SEND_PASSWORD',
  CLOSE_PASSWORD_TAG: 'CLOSE_PASSWORD_TAG',
  SET_PASSWORD_LEVEL: 'SET_PASSWORD_LEVEL',
  GET_PERMISSION: 'GET_PERMISSION',
  SET_USER_ORG_FEATURE: 'SET_USER_ORG_FEATURE',
  // Toggle NavBar
  TOGGLE_NAVBAR: 'TOGGLE_NAVBAR',
  // Toggle NotifyPanel
  TOGGLE_NOTIFY: 'TOGGLE_NOTIFY',
  TOGGLE_FILTER_PANEL: 'TOGGLE_FILTER_PANEL',
  SWITCH_INFO_PANEL: 'SWITCH_INFO_PANEL',
  // Upload file
  UPLOAD_FILE: 'UPLOAD_FILE',
  EMPTY_BUCKET: 'EMPTY_BUCKET',
  DELETE_FILE: 'DELETE_FILE',
  LIST_FILES: 'LIST_FILES',
  LIST_FILES_REQUEST: 'LIST_FILES_REQUEST',
  LIST_FILES_DONE: 'LIST_FILES_DONE',
  // Organization
  FETCH_ORGANIZATION_LOADING: 'FETCH_ORGANIZATION_LOADING',
  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  FETCH_OWN_ORGANIZATION: 'FETCH_OWN_ORGANIZATION',
  SELECT_ORGANIZATION: 'SELECT_ORGANIZATION',
  GET_ORGANIZATION_MEMBERS: 'GET_ORGANIZATION_MEMBERS',
  GET_BADGE: 'GET_BADGE',
  EXPORT_CUSTOMERS_REQUEST: 'EXPORT_CUSTOMERS_REQUEST',
  EXPORT_CUSTOMERS_SUCCESS: 'EXPORT_CUSTOMERS_SUCCESS',
  EXPORT_CUSTOMERS_FAILURE: 'EXPORT_CUSTOMERS_FALIURE',
  EXPORT_CUSTOMERS_STOP: 'EXPORT_CUSTOMERS_STOP',
  GET_INTEGRATION_REQUEST: 'GET_INTEGRATION_REQUEST',
  GET_INTEGRATION_SUCCESS: 'GET_INTEGRATION_SUCCESS',
  GET_INTEGRATION_FAILURE: 'GET_INTEGRATION_FAILURE',
  CREATE_INTEGRATION_REQUEST: 'CREATE_INTEGRATION_REQUEST',
  CREATE_INTEGRATION_SUCCESS: 'CREATE_INTEGRATION_SUCCESS',
  CREATE_INTEGRATION_FAILURE: 'CREATE_INTEGRATION_FAILURE',
  DELETE_INTEGRATION_REQUEST: 'DELETE_INTEGRATION_REQUEST',
  DELETE_INTEGRATION_SUCCESS: 'DELETE_INTEGRATION_SUCCESS',
  DELETE_INTEGRATION_FAILURE: 'DELETE_INTEGRATION_FAILURE',
  SET_NOTHING: 'SET_NOTHING',
  SET_FACEBOOK_DATA,
  SET_INSTAGRAM_DATA,
  CLEAR_FACEBOOK_DATA: 'CLEAR_FACEBOOK_DATA',
  SELECT_FAN_PAGE: 'SELECT_FAN_PAGE',
  SELECT_IG_FAN_PAGE: 'SELECT_IG_FAN_PAGE',
  // Conversation
  SET_QUERY: 'SET_QUERY',
  SET_GOTO_KEEP_MSG: 'SET_GOTO_KEEP_MSG',
  FIND_CUSTOMER_REQUEST: 'FIND_CUSTOMER_REQUEST',
  FIND_CUSTOMER_SUCCESS: 'FIND_CUSTOMER_SUCCESS',
  FIND_CUSTOMER_FAILURE: 'FIND_CUSTOMER_FAILURE',
  FETCH_CUSTOMER_SUCCESS: 'FETCH_CUSTOMER_SUCCESS',
  HAS_NEXT_PAGE: 'HAS_NEXT_PAGE',
  FILELIST_HAS_NEXT_PAGE: 'FILELIST_HAS_NEXT_PAGE',
  FIND_CUSTOMER_FROM_CONVERSATION: 'FIND_CUSTOMER_FROM_CONVERSATION',
  FIND_CUSTOMER_FROM_CUSTOMERIDS: 'FIND_CUSTOMER_FROM_CUSTOMERIDS',
  SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',
  ASSIGN_CUSTOMER: 'ASSIGN_CUSTOMER',
  FIND_MESSAGE: 'FIND_MESSAGE',
  ADD_MESSAGE_HISTORY: 'ADD_MESSAGE_HISTORY',
  ADD_MESSAGES_HISTORY: 'ADD_MESSAGES_HISTORY',
  CLEAR_MESSAGE_HISTORY: 'CLEAR_MESSAGE_HISTORY',
  CLEAR_KEPT_MESSAGE: 'CLEAR_KEPT_MESSAGE',
  SET_QUERY_MESSAGE_HISTORY_LOADING: 'SET_QUERY_MESSAGE_HISTORY_LOADING',
  QUERY_MESSAGE_HISTORY: 'QUERY_MESSAGE_HISTORY',
  QUERY_MESSAGE_BY_DATE: 'QUERY_MESSAGE_BY_DATE',
  RELOAD_KEEP_MESSAGE: 'RELOAD_KEEP_MESSAGE',
  GET_KEPT_MESSAGE: 'GET_KEPT_MESSAGE',
  CUSTOMER_ACCOUNTING: 'CUSTOMER_ACCOUNTING',
  SHOW_TYPING_SIGNAL: 'SHOW_TYPING_SIGNAL',
  HIDE_TYPING_SIGNAL: 'HIDE_TYPING_SIGNAL',
  SET_DELETE_MESSAGE: 'SET_DELETE_MESSAGE',
  GET_CUSTOMER_ACCOUNTING_REQUEST: 'GET_CUSTOMER_ACCOUNTING_REQUEST',
  GET_CUSTOMER_ACCOUNTING_SUCCESS: 'GET_CUSTOMER_ACCOUNTING_SUCCESS',
  GET_CUSTOMER_ACCOUNTING_FAILURE: 'GET_CUSTOMER_ACCOUNTING_FAILURE',
  CUSTOMER_ACCOUNTING_LIST_REQUEST: 'CUSTOMER_ACCOUNTING_LIST_REQUEST',
  CUSTOMER_ACCOUNTING_LIST_SUCCESS: 'CUSTOMER_ACCOUNTING_LIST_SUCCESS',
  CUSTOMER_ACCOUNTING_LIST_FAILURE: 'CUSTOMER_ACCOUNTING_LIST_FAILURE',
  CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_REQUEST:
    'CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_REQUEST',
  CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_SUCCESS:
    'CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_SUCCESS',
  CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_FAILURE:
    'CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_FAILURE',
  CUSTOMER_RESET: 'CUSTOMER_RESET',
  GET_CUSTOMER_DETAIL_DATA_REQUEST: 'GET_CUSTOMER_DETAIL_DATA_REQUEST',
  GET_CUSTOMER_DETAIL_DATA_SUCCESS: 'GET_CUSTOMER_DETAIL_DATA_SUCCESS',
  GET_CUSTOMER_DETAIL_DATA_FAILURE: 'GET_CUSTOMER_DETAIL_DATA_FAILURE',
  GET_LASTREAD: 'GET_LASTREAD',
  SET_ADVANCE_SEARCH_OPEN: 'SET_ADVANCE_SEARCH_OPEN',
  SET_NEW_FILE: 'SET_NEW_FILE',
  // Tags
  REMOVE_TAG: 'REMOVE_TAG',
  ADD_TAG_REQUEST: 'ADD_TAG_REQUEST',
  ADD_TAG_SUCCESS: 'ADD_TAG_SUCCESS',
  ADD_TAG_FAILURE: 'ADD_TAG_FAILURE',
  // Preference
  GET_PREFERENCE: 'GET_PREFERENCE',
  TOGGLE_MUTE: 'TOGGLE_MUTE',
  // OS_Notification
  OPEN_OS_NOTIFICATION: 'OPEN_OS_NOTIFICATION',
  CLOSE_OS_NOTIFICATION: 'CLOSE__OS_NOTIFICATION',
  // Template
  GET_TEMPLATE_DATA: 'GET_TEMPLATE_DATA',
  GET_TEMPLATE: 'GET_TEMPLATE',
  TEMPLATE_DATA_VALIDATION: 'TEMPLATE_DATA_VALIDATION',
  PUBLISH_TEMPLATE: 'PUBLISH_TEMPLATE',
  PUBLISH_TEMPLATE_FAILURE: 'PUBLISH_TEMPLATE_FAILURE',
  PUBLISH_TEMPLATE_VALIDATE_FAILURE: 'PUBLISH_TEMPLATE_VALIDATE_FAILURE',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  // CRAWL
  GET_CRAWL: 'GET_CRAWL',
  // broadcast
  GET_BROADCAST_LIST_REQUEST: 'GET_BROADCAST_LIST_REQUEST',
  GET_BROADCAST_LIST_SUCCESS: 'GET_BROADCAST_LIST_SUCCESS',
  UPDATE_BROADCAST_LIST: 'UPDATE_BROADCAST_LIST',
  GET_BROADCAST_LIST_CONTINUE_SUCCESS: 'GET_BROADCAST_LIST_CONTINUE_SUCCESS',
  GET_BROADCAST_LIST_FAILURE: 'GET_BROADCAST_LIST_FAILURE',
  GET_BROADCAST_COUNT: 'GET_BROADCAST_COUNT',
  BROADCAST_LIST_REQUEST_RESET: 'BROADCAST_LIST_REQUEST_RESET',
  SET_BROADCAST_CREATE_CONDITION: 'SET_BROADCAST_CREATE_CONDITION',
  SET_BROADCAST_CREATE_TIMINGTYPE: 'SET_BROADCAST_CREATE_TIMINGTYPE',
  SET_BROADCAST_CREATE_TIMING: 'SET_BROADCAST_CREATE_TIMING',
  SET_BROADCAST_CREATE_MESSAGE: 'SET_BROADCAST_CREATE_MESSAGE',
  SET_BROADCAST_CREATE_NAME: 'SET_BROADCAST_CREATE_NAME',
  SET_BROADCAST_CUSTOMER_NUM: 'SET_BROADCAST_CUSTOMER_NUM',
  SEND_BROADCAST_REQUEST: 'SEND_BROADCAST_REQUEST',
  SEND_BROADCAST_SUCCESS: 'SEND_BROADCAST_SUCCESS',
  SEND_BROADCAST_FAILURE: 'SEND_BROADCAST_FAILURE',
  COPY_BROADCAST,
  // account-setting
  GET_PAYMENTS_REQUEST: 'GET_PAYMENTS_REQUEST',
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAILURE: 'GET_PAYMENTS_FAILURE',
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
  GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',
  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',
  CANCEL_NEXT_ORDER_REQUEST: 'CANCEL_NEXT_ORDER_REQUEST',
  CANCEL_NEXT_ORDER_SUCCESS: 'CANCEL_NEXT_ORDER_SUCCESS',
  CANCEL_NEXT_ORDER_FAILURE: 'CANCEL_NEXT_ORDER_FAILURE',
  GET_PLANS_REQUEST: 'GET_PLANS_REQUEST',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  GET_PLANS_FAILURE: 'GET_PLANS_FAILURE',
  GET_ADDONS_REQUEST: 'GET_ADDONS_REQUEST',
  GET_ADDONS_SUCCESS: 'GET_ADDONS_SUCCESS',
  GET_ADDONS_FAILURE: 'GET_ADDONS_FAILURE',
  GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
  UPDATE_USER_INFO_REQUEST: 'UPDATE_USER_INFO_REQUEST',
  UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_FAILURE: 'UPDATE_USER_INFO_FAILURE',
  GET_PAYMENT_METHODS_REQUEST: 'GET_PAYMENT_METHODS_REQUEST',
  GET_PAYMENT_METHODS_SUCCESS: 'GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_FAILURE: 'GET_PAYMENT_METHODS_FAILURE',
  CREATE_PAYMENT_METHOD_REQUEST: 'CREATE_PAYMENT_METHOD_REQUEST',
  CREATE_PAYMENT_METHOD_SUCCESS: 'CREATE_PAYMENT_METHOD_SUCCESS',
  CREATE_PAYMENT_METHOD_FAILURE: 'CREATE_PAYMENT_METHOD_FAILURE',
  GET_PAYMENT_METHOD_REQUEST: 'GET_PAYMENT_METHOD_REQUEST',
  GET_PAYMENT_METHOD_SUCCESS: 'GET_PAYMENT_METHOD_SUCCESS',
  GET_PAYMENT_METHOD_FAILURE: 'GET_PAYMENT_METHOD_FAILURE',
  BIND_CREDIT_CARD_REQUEST: 'BIND_CREDIT_CARD_REQUEST',
  BIND_CREDIT_CARD_SUCCESS: 'BIND_CREDIT_CARD_SUCCESS',
  BIND_CREDIT_CARD_FAILURE: 'BIND_CREDIT_CARD_FAILURE',
  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',
  GET_PAYMENT_REQUEST: 'GET_PAYMENT_REQUEST',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAILURE: 'GET_PAYMENT_FAILURE',
  GET_PAY_INFO_REQUEST: 'GET_PAY_INFO_REQUEST',
  GET_PAY_INFO_SUCCESS: 'GET_PAY_INFO_SUCCESS',
  GET_PAY_INFO_FAILURE: 'GET_PAY_INFO_FAILURE',
  GET_RECEIPTS_REQUEST: 'GET_RECEIPTS_REQUEST',
  GET_RECEIPTS_SUCCESS: 'GET_RECEIPTS_SUCCESS',
  GET_RECEIPTS_FAILURE: 'GET_RECEIPTS_FAILURE',
  GET_RECEIPT_REQUEST: 'GET_RECEIPT_REQUEST',
  GET_RECEIPT_SUCCESS: 'GET_RECEIPT_SUCCESS',
  GET_RECEIPT_FAILURE: 'GET_RECEIPT_FAILURE',
  GET_RESOURCE_REQUEST: 'GET_RESOURCE_REQUEST',
  GET_RESOURCE_SUCCESS: 'GET_RESOURCE_SUCCESS',
  GET_RESOURCE_FAILURE: 'GET_RESOURCE_FAILURE',
  // GrowthTool
  GET_CAMPAIGNS: 'GET_CAMPAIGNS',
  GET_CAMPAIGN_BY_ID: 'GET_CAMPAIGN_BY_ID',
  GET_CAMPAIGN_ANALYZE: 'GET_CAMPAIGN_ANALYZE',
  GET_SHARER_DATA: 'GET_SHARER_DATA',
  GET_CAMPAIGN_CHART: 'GET_CAMPAIGN_CHART',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_FAILED: 'CREATE_CAMPAIGN_FAILED',
  TOGGLE_FAN: 'TOGGLE_FAN',
  TOGGLE_CAMPAIGN: 'TOGGLE_CAMPAIGN',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  EMPTY_CREATE_RESULT: 'EMPTY_CREATE_RESULT',
  CREATE_CHANNEL_INFO: 'CREATE_CHANNEL_INFO',
  GET_CHANNEL_INFO: 'GET_CHANNEL_INFO',
  UPDATE_CHANNEL_INFO: 'UPDATE_CHANNEL_INFO',
  RESET_CAMPAIGN_SELECTED: 'RESET_CAMPAIGN_SELECTED',
  SET_DISPLAY_NAME_CAMPAIGN: 'SET_DISPLAY_NAME_CAMPAIGN',
  // SUPER8API
  GET_SUPER8API_PERMISSIONS_REQUEST: 'GET_SUPER8API_PERMISSIONS_REQUEST',
  GET_SUPER8API_PERMISSIONS_SUCCESS: 'GET_SUPER8API_PERMISSIONS_SUCCESS',
  GET_SUPER8API_PERMISSIONS_FAILURE: 'GET_SUPER8API_PERMISSIONS_FAILURE',
  GET_SUPER8API_KEY_REQUEST: 'GET_SUPER8API_KEY_REQUEST',
  GET_SUPER8API_KEY_SUCCESS: 'GET_SUPER8API_KEY_SUCCESS',
  GET_SUPER8API_KEY_FAILURE: 'GET_SUPER8API_KEY_FAILURE',
  // Modal
  OPEN_MODAL,
  CLOSE_MODAL,
  UPDATE_MODAL_PROPS,

  // Super Link
  SET_SUPERLINK_CAMPAIGN_LIST: 'SET_SUPERLINK_CAMPAIGN_LIST',
  SET_SUPERLINK_CAMPAIGN_INFO: 'SET_SUPERLINK_CAMPAIGN_INFO',
  SET_SUPERLINK_CAMPAIGN_CHANNELS: 'SET_SUPERLINK_CAMPAIGN_CHANNELS',
  SET_SUPERLINK_CAMPAIGN_INFO_TAG: 'SET_SUPERLINK_CAMPAIGN_INFO_TAG',
  SET_SUPERLINK_CAMPAIGN_CHANNEL_LIST: 'SET_SUPERLINK_CAMPAIGN_CHANNEL_LIST',
  SET_SUPERLINK_CAMPAIGN_SAVE_RESULT: 'SET_SUPERLINK_CAMPAIGN_SAVE_RESULT',
  RESET_CAMPAIGN_ANALYZE: 'RESET_CAMPAIGN_ANALYZE',
  RESET_CAMPAIGN_CREATE_DATA: 'RESET_CAMPAIGN_CREATE_DATA',
  SET_CAMPAIGN_CREATE_NAME: 'SET_CAMPAIGN_CREATE_NAME',
  SET_CAMPAIGN_CREATE_CHANNELS: 'SET_CAMPAIGN_CREATE_CHANNELS',
  CREATE_SUPERLINK_CAMPAIGN: 'CREATE_SUPERLINK_CAMPAIGN',
  UPDATE_SUPERLINK_CAMPAIGN: 'UPDATE_SUPERLINK_CAMPAIGN',
  UPDATE_SUPERLINK_CAMPAIGN_CHANNELS: 'UPDATE_SUPERLINK_CAMPAIGN_CHANNELS',
  RESET_SUPERLINK_SAVE_RESULT: 'RESET_SUPERLINK_SAVE_RESULT',
  SET_LINE_CHANNEL_INFO: 'SET_LINE_CHANNEL_INFO',
  SET_SUPERLINK_FETCH_DATA_FETCHING: 'SET_SUPERLINK_FETCH_DATA_FETCHING',
  SET_SUPERLINK_FETCH_DATA_SUCCESS: 'SET_SUPERLINK_FETCH_DATA_SUCCESS',
  SET_SUPERLINK_CAMPAIGN_PAGE: 'SET_SUPERLINK_CAMPAIGN_PAGE',
  SET_SUPERLINK_CHANNEL_PAGE: 'SET_SUPERLINK_CHANNEL_PAGE',
  RESET_SUPERLINK_CAMPAIGN_QUERY: 'RESET_SUPERLINK_CAMPAIGN_QUERY',
  RESET_SUPERLINK_CHANNEL_QUERY: 'RESET_SUPERLINK_CHANNEL_QUERY',
  RESET_SUPERLINK_CAMPAIGN_EDIT_DATA: 'RESET_SUPERLINK_CAMPAIGN_EDIT_DATA',
  SET_SUPERLINK_CHANNEL_SEARCH_NAME: 'SET_SUPERLINK_CHANNEL_SEARCH_NAME',

  // SUPER MARKET
  SET_SUPERMARKET_TYPE: 'SET_SUPERMARKET_TYPE',
  SET_SUPERMARKET_TABINDEX: 'SET_SUPERMARKET_TABINDEX',
  RESET_SUPERMARKET: 'RESET_SUPERMARKET',
};
