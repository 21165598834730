import {
  NOTIFICATION_CENTER_SET_ANNOUNCEMENT,
  NOTIFICATION_CENTER_SET_ANNOUNCEMENT_FAILURE,
  NOTIFICATION_CENTER_SET_SHOW_BADGE_ICON,
  NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_CONTINUE_SUCCESS,
  NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS,
  NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_FAILURE,
  NOTIFICATION_CENTER_SET_ISLOADING,
  NOTIFICATION_CENTER_SET_MODAL_STATUS,
} from '../actions/type';

const INITIAL_STATE = {
  announcement: {},
  showNotificationCenterBadgeIcon: false,
  systemMsgs: [],
  isLoading: false,
  isModalOpen: false,
};

export default function notificationCenterReducer(
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
    case NOTIFICATION_CENTER_SET_MODAL_STATUS:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
      };
    case NOTIFICATION_CENTER_SET_ANNOUNCEMENT:
      return {
        ...state,
        announcement: action.announcement,
      };
    case NOTIFICATION_CENTER_SET_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        announcement: {},
      };
    case NOTIFICATION_CENTER_SET_SHOW_BADGE_ICON:
      return {
        ...state,
        showNotificationCenterBadgeIcon: action.showNotificationCenterBadgeIcon,
      };
    case NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_CONTINUE_SUCCESS:
      return {
        ...state,
        systemMsgs: state.systemMsgs.concat(action.systemMsgs),
      };
    case NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS:
      return {
        ...state,
        systemMsgs: action.systemMsgs,
      };
    case NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_FAILURE:
      return {
        ...state,
        systemMsgs: [],
        isLoading: false,
      };
    case NOTIFICATION_CENTER_SET_ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}
