import actionType from '../actions/type';

const INITIAL_STATE = {
  orgOwnData: {},
  isLoading: false,
  userData: {},
  isSend: false,
  passwordLevel: '',
  permission: null,
  billing: {
    plans: [],
    addons: [],
    userInfo: {
      order: {},
    },
    nextOrder: {},
    orders: [],
    receipts: [],
    receipt: null,
    receiptPaginate: null,
    paymentMethods: [],
    paymentMethod: {},
    payments: [],
    payment: null,
    payInfo: null,
    newOrderResult: null,
    resource: {},
  },
  feature: null,
};

let preState = null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_AUTH_ORG_OWN_DATA:
      return { ...state, orgOwnData: action.payload };
    case actionType.SET_AUTH_ISLOADING:
      return { ...state, isLoading: action.payload };
    case actionType.FETCH_USER:
      return { ...state, userData: action.payload };
    case actionType.NORMAL_SIGNUP:
      return { ...state, userData: action.payload };
    case actionType.SOCIAL_SIGNUP:
      return { ...state, userData: action.payload };
    case actionType.NORMAL_LOGIN:
      return { ...state, userData: action.payload };
    case actionType.SOCIAL_LOGIN:
      return { ...state, userData: action.payload };
    case actionType.USER_LOGOUT:
      return INITIAL_STATE;
    case actionType.SEND_PASSWORD:
      return { ...state, isSend: action.payload };
    case actionType.CLOSE_PASSWORD_TAG:
      return { ...state, isSend: action.payload };
    case actionType.SET_PASSWORD_LEVEL:
      return { ...state, passwordLevel: action.payload };
    case actionType.GET_PERMISSION:
      return { ...state, permission: action.payload };
    case actionType.GET_ORDERS_REQUEST:
    case actionType.GET_ORDERS_FAILURE:
      return { ...state, billing: { ...state.billing, orders: [] } };
    case actionType.GET_ORDERS_SUCCESS:
      return { ...state, billing: { ...state.billing, orders: action.data } };
    case actionType.GET_PAYMENTS_REQUEST:
    case actionType.GET_PAYMENTS_FAILURE:
      return { ...state, billing: { ...state.billing, payments: [] } };
    case actionType.GET_PAYMENTS_SUCCESS:
      return { ...state, billing: { ...state.billing, payments: action.data } };
    case actionType.GET_PLANS_REQUEST:
    case actionType.GET_PLANS_FAILURE:
      return { ...state, billing: { ...state.billing, plans: [] } };
    case actionType.GET_PLANS_SUCCESS:
      return { ...state, billing: { ...state.billing, plans: action.data } };
    case actionType.GET_ADDONS_REQUEST:
    case actionType.GET_ADDONS_FAILURE:
      return { ...state, billing: { ...state.billing, addons: [] } };
    case actionType.GET_ADDONS_SUCCESS:
      return { ...state, billing: { ...state.billing, addons: action.data } };
    case actionType.GET_USER_INFO_REQUEST:
    case actionType.GET_USER_INFO_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
          },
        },
      };
    case actionType.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
            ...action.data,
          },
        },
      };
    case actionType.UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
          },
        },
      };
    case actionType.UPDATE_USER_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
          },
        },
      };
    case actionType.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
            ...action.data,
          },
        },
      };
    case actionType.GET_NEXT_ORDER_REQUEST:
    case actionType.GET_NEXT_ORDER_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
            nextOrder: {},
          },
        },
      };
    case actionType.GET_NEXT_ORDER_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
            nextOrder: action.data,
          },
        },
      };
    case actionType.GET_ORDER_REQUEST:
    case actionType.UPDATE_ORDER_REQUEST:
    case actionType.CANCEL_NEXT_ORDER_REQUEST:
      preState = state;
      if (action.isGetNextOrder) {
        return {
          ...state,
          isLoading: true,
          billing: { ...state.billing, nextOrder: {} },
        };
      }
      return {
        ...state,
        isLoading: true,
      };
    case actionType.GET_ORDER_SUCCESS:
    case actionType.UPDATE_ORDER_SUCCESS:
    case actionType.CANCEL_NEXT_ORDER_SUCCESS:
      if (action.isGetNextOrder) {
        return {
          ...state,
          isLoading: false,
          billing: {
            ...state.billing,
            nextOrder: {
              ...action.data,
              plan_id: action?.data?.plan || action?.data?.plan_id,
            },
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        billing: {
          ...state.billing,
          userInfo: {
            ...state.billing.userInfo,
            order: {
              ...action.data,
              plan_id: action?.data?.plan || action?.data?.plan_id,
            },
          },
        },
      };
    case actionType.GET_ORDER_FAILURE:
    case actionType.UPDATE_ORDER_FAILURE:
    case actionType.CANCEL_NEXT_ORDER_FAILURE:
      return preState;
    case actionType.CREATE_ORDER_REQUEST:
      if (action.dryRun) {
        return {
          ...state,
          isLoading: true,
          billing: {
            ...state.billing,
            newOrderResult: null,
          },
        };
      }
      return {
        ...state,
        isLoading: true,
        billing: {
          ...state.billing,
        },
      };
    case actionType.CREATE_ORDER_FAILURE:
      if (action.dryRun) {
        return {
          ...state,
          isLoading: false,
          billing: {
            ...state.billing,
            newOrderResult: null,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        billing: {
          ...state.billing,
        },
      };
    case actionType.CREATE_ORDER_SUCCESS:
      if (action.dryRun) {
        return {
          ...state,
          isLoading: false,
          billing: {
            ...state.billing,
            newOrderResult: action.data,
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        billing: {
          ...state.billing,
        },
      };
    case actionType.CREATE_PAYMENT_METHOD_REQUEST:
    case actionType.CREATE_PAYMENT_METHOD_FAILURE:
    case actionType.GET_PAYMENT_METHOD_REQUEST:
    case actionType.GET_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          paymentMethod: {},
        },
      };
    case actionType.CREATE_PAYMENT_METHOD_SUCCESS:
    case actionType.GET_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          paymentMethod: action.data,
        },
      };
    case actionType.GET_PAYMENT_METHODS_REQUEST:
    case actionType.GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          paymentMethods: [],
        },
      };
    case actionType.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          paymentMethods: action.data,
        },
      };
    case actionType.CREATE_PAYMENT_REQUEST:
    case actionType.CREATE_PAYMENT_SUCCESS:
    case actionType.CREATE_PAYMENT_FAILURE:
    case actionType.GET_PAY_INFO_REQUEST:
    case actionType.GET_PAY_INFO_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          payInfo: null,
        },
      };
    case actionType.GET_PAY_INFO_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          payInfo: action.data,
        },
      };
    case actionType.GET_PAYMENT_REQUEST:
    case actionType.GET_PAYMENT_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          payment: null,
        },
      };
    case actionType.GET_PAYMENT_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          payment: action.data,
        },
      };
    case actionType.GET_RECEIPTS_REQUEST:
    case actionType.GET_RECEIPTS_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          receipts: [],
          receiptPaginate: null,
        },
      };
    case actionType.GET_RECEIPTS_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          receipts: action.receipts,
          receiptPaginate: action.receiptPaginate,
        },
      };
    case actionType.GET_RECEIPT_REQUEST:
    case actionType.GET_RECEIPT_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          receipt: null,
        },
      };
    case actionType.GET_RECEIPT_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          receipt: action.data,
        },
      };
    case actionType.GET_RESOURCE_REQUEST:
    case actionType.GET_RESOURCE_FAILURE:
      return {
        ...state,
        billing: {
          ...state.billing,
          resource: {},
        },
      };
    case actionType.GET_RESOURCE_SUCCESS:
      return {
        ...state,
        billing: {
          ...state.billing,
          resource: action.data,
        },
      };
    case actionType.SET_USER_ORG_FEATURE:
      return { ...state, feature: action.payload };
    default:
      return state;
  }
};
