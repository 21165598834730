import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconAssignBack = ({ onClick, iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <g fill={isHover ? hexRgba(iconColor, 40) : iconColor} fillRule="evenodd">
        <path
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3 10.6a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6zm0-1.6a2.2 2.2 0 1 1 0-4.4 2.2 2.2 0 0 1 0 4.4zm1.828 4.6H11.3a.8.8 0 0 1 0-1.6h4.828a4.8 4.8 0 0 1 4.8 4.8v3a.8.8 0 0 1-1.6 0v-3a3.2 3.2 0 0 0-3.2-3.2zM5.566 10.434a.8.8 0 0 0-1.132 0l-3 3a.8.8 0 0 0 1.132 1.132L4.2 12.932v3.443c0 .973.483 1.859 1.258 2.48.77.619 1.785.945 2.815.945H11a.8.8 0 0 0 0-1.6H8.273c-.706 0-1.357-.226-1.814-.593-.452-.363-.659-.812-.659-1.232v-3.443l1.634 1.634a.8.8 0 0 0 1.132-1.132l-3-3z"
        />
      </g>
    </svg>
  );
};

IconAssignBack.defaultProps = {
  iconColor: color.$12,
};

export default IconAssignBack;
