import actionType from '../actions/type';

const INITIAL_STATE = {
  isLoading: false,
  track: {
    domain: '',
    provider: 'other',
  },
  analytics: {},
  data: {},
  list: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_RETARGETING:
      return {
        ...state,
        ...action.payload,
      };
    case actionType.SET_RETARGETING_FAIL:
      return INITIAL_STATE;
    default:
      return state;
  }
};
