import i18n from 'i18next';
import { SnackBarType } from '../../types';
import { enqueueSnackbar } from '../../actions/snackbarAction';
import { queryTag } from '../../actions/tagManagementAction';
import { fetchDataInit } from '../../actions/allCustomersAction';
import {
  getCustomerGroupListInit,
  getCustomers,
  getCustomerGroup,
} from '../../actions/customerGroupsAction';
import { transformTagsToString, TWD } from '../../common/utils';
import { getRichMenuGroups } from '../../actions/richMenuSettingAction';

import store from '../../store';

const { dispatch, getState } = store;
const isMatchTagmanagement = () =>
  /^\/customer-management\/[0-9a-zA-Z]{10}\/tag-management$/g.test(
    window.location.pathname,
  );
const isMatchAllCustomers = () =>
  /^\/customer-management\/[0-9a-zA-Z]{10}\/all-customers$/g.test(
    window.location.pathname,
  );
const isMatchCustomerGroupsDetail = () =>
  /^\/customer-management\/[0-9a-zA-Z]{10}\/customer-groups\/[0-9a-zA-Z]{10}$/g.test(
    window.location.pathname,
  );
const isMatchCustomerGroups = () =>
  /^\/customer-management\/[0-9a-zA-Z]{10}\/customer-groups$/g.test(
    window.location.pathname,
  );
const isMatchRichMenuSetting = () =>
  /^\/organization-setting\/[0-9a-zA-Z]{10}\/rich-menu-setting$/g.test(
    window.location.pathname,
  );
const isMatchChatPayProductsList = () =>
  /^\/chatPay\/[0-9a-zA-Z]{10}\/productsList$/g.test(window.location.pathname);

const refreshCustomerGroupCustomer = () => {
  const groupId = getState().customerGroups.data.objectId;
  dispatch(getCustomerGroup(groupId));
  dispatch(getCustomers(groupId, true));
};

export default {
  BROADCAST_SUCCESS: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`system-task.assign-task.${data.type}-success`, {
          name: data.options.name,
        }),
      }),
    );
  },
  EXPORT_BROADCAST: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`broadcast:export-success`),
      }),
    );
  },
  BATCH_MANAGEMENT_ADD_TAGS: (data, orgId) => {
    if (isMatchTagmanagement()) {
      dispatch(queryTag(orgId, undefined, true));
    }

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(
          `system-task.assign-task.add-tags-success${
            isMatchAllCustomers() || isMatchCustomerGroupsDetail()
              ? ''
              : '-short'
          }`,
          {
            count: TWD(data.total, false, true),
            tags: transformTagsToString(data.options.tags),
          },
        ),
        onRefresh: isMatchAllCustomers()
          ? () => dispatch(fetchDataInit(orgId, true))
          : isMatchCustomerGroupsDetail()
          ? () => refreshCustomerGroupCustomer()
          : undefined,
      }),
    );
  },
  BATCH_CUSTOMER_GROUP_DELETE: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(
          'system-task.assign-task.delete-customer-gruop-success',
          {
            name: data.options.name,
          },
        ),
      }),
    );
  },
  BATCH_MANAGEMENT_DELETE_TAGS: (data, orgId) => {
    if (isMatchTagmanagement()) {
      dispatch(
        queryTag(orgId, undefined, true, undefined, true, {
          useStoreTagName: true,
        }),
      );
    }

    if (data.options.forOrganization) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('system-task.assign-task.delete-org-tags-success', {
            tags: transformTagsToString(data.options.tags),
            count: TWD(data.options.tags.length, false),
          }),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t(
            `system-task.assign-task.delete-tags-success${
              isMatchAllCustomers() ? '' : '-short'
            }`,
            {
              count: TWD(data.total, false, true),
              tags: transformTagsToString(data.options.tags),
            },
          ),
          onRefresh: isMatchAllCustomers()
            ? () => dispatch(fetchDataInit(orgId, true))
            : undefined,
        }),
      );
    }
  },
  BATCH_MANAGEMENT_CUSTOMER_GROUP: (data, orgId) => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`system-task.assign-task.${data.type}-success`, {
          name: data.options.name,
        }),
      }),
    );

    if (isMatchCustomerGroups()) {
      dispatch(getCustomerGroupListInit(orgId));
    }
  },
  BATCH_MANAGEMENT_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.export-done'),
      }),
    );
  },
  RICHMEMU_GROUP_PUBLISH: (data, orgId) => {
    if (isMatchRichMenuSetting()) {
      dispatch(getRichMenuGroups(orgId));
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.publish-rich-menu-group', {
          name: data.options.richMenuGroup.title,
        }),
      }),
    );
  },
  RICHMEMU_GROUP_UNPUBLISH: (data, orgId) => {
    if (isMatchRichMenuSetting()) {
      dispatch(getRichMenuGroups(orgId));
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.unpublish-rich-menu-group', {
          name: data.options.richMenuGroup.title,
        }),
      }),
    );
  },
  RICHMEMU_GROUP_DELETE: (data, orgId) => {
    if (isMatchRichMenuSetting()) {
      dispatch(getRichMenuGroups(orgId));
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.delete-rich-menu-group', {
          name: data.options.richMenuGroup.title,
        }),
      }),
    );
  },
  RICHMEMU_GROUP_SCHEDULED: (data, orgId) => {
    if (isMatchRichMenuSetting()) {
      dispatch(getRichMenuGroups(orgId));
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.schedule-rich-menu-group', {
          name: data.options.richMenuGroup.title,
        }),
      }),
    );
  },
  TASK_ERROR: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('system-task.assign-task.export-error'),
      }),
    );
  },
  SUPERLINK_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.superlink-export-done'),
      }),
    );
  },

  SHARE_RANK_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('system-task.assign-task.shareRank-export-done'),
      }),
    );
  },

  DATA_CENTER_MESSAGE_FROM_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('overview:message-analysis.export-success'),
      }),
    );
  },

  DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('overview:template-account.export-done'),
      }),
    );
  },
  DATA_CENTER_TAG_STATISTIC_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('overview:tag.export-success'),
      }),
    );
  },
  DATA_CENTER_PLATFORM_STATISTIC_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('overview:customer-detail.export-success'),
      }),
    );
  },
  INFOBIP_CONSOLE_SEND: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('pnp:send-success', {
          name: data?.options?.name || '',
        }),
      }),
    );
  },
  EXPORT_GAME_MODULE: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`roulette:task-message`, {
          name: data?.options?.campaignName,
        }),
      }),
    );
  },
  EXPORT_COUPON_MODULE: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`coupon:task-message`, {
          name: data?.options?.couponName,
        }),
      }),
    );
  },
  EINVOIVE_EXPORT: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`eInvoice:task-message`, {
          name: data?.options?.campaignName,
        }),
      }),
    );
  },
  RETARGETING_EXPORT: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`retargeting:task-message`, {
          name: data?.options?.campaignName,
        }),
      }),
    );
  },
  RECURRING_EXPORT: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`recurringMessage:task-message`, {
          name: data?.options?.name,
        }),
      }),
    );
  },
  CUSTOMER_GROUP_IMPORT_CSV: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(
          `system-task.assign-task.create-customer-group-success`,
          {
            name: data.options.title,
          },
        ),
      }),
    );
    if (isMatchCustomerGroups()) {
      dispatch(getCustomerGroupListInit(data.options.organizationId));
    }
  },
  CS_DATA_EXPORT: data => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`customerServiceData:export.success`, {
          name: `${i18n.t(
            `customerServiceData:export.${data.options.blockType}`,
          )}-${i18n.t(`customerServiceData:${data.options.query.caseType}`)}`,
        }),
      }),
    );
  },
  SHOP8_CSV: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`chatPay:uploadProductSuccessMsg`),
      }),
    );
    if (isMatchChatPayProductsList()) {
      window.location.reload();
    }
  },
  POINT_HISTORY_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`aiBot:pointHistoryModal.exportSuccess`),
      }),
    );
  },
  EINOVICE_ITEM_EXPORT: () => {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t(`eInvoice:itemExportSuccess`),
      }),
    );
  },
};
