import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack';

const useStyles = makeStyles(
  () => ({
    root: {
      zIndex: 10000,
    },
    containerAnchorOriginTopLeft: {
      left: 24,
    },
  }),
  {
    name: 'SnackbarProvider',
  },
);

function SnackbarProvider({ children }) {
  const classes = useStyles();

  return (
    <NotiSnackbarProvider
      classes={{
        containerRoot: classes.root,
        containerAnchorOriginTopLeft: classes.containerAnchorOriginTopLeft,
      }}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </NotiSnackbarProvider>
  );
}
export default SnackbarProvider;
