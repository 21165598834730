import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconCalendar = ({ open, onClick }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      {open ? (
        <svg
          onClick={onClick}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <defs>
            <path
              id="calendarPath"
              d="M17.91 4.636h-1.637v-.818c0-.49-.328-.818-.818-.818-.491 0-.819.327-.819.818v.818H9.727v-.818C9.727 3.328 9.4 3 8.91 3c-.49 0-.818.327-.818.818v.818H6.455C5.064 4.636 4 5.7 4 7.091v11.454C4 19.936 5.064 21 6.455 21h11.454c1.391 0 2.455-1.064 2.455-2.455V7.091c0-1.391-1.064-2.455-2.455-2.455zM6.454 6.273H8.09v.818c0 .49.327.818.818.818.491 0 .818-.327.818-.818v-.818h4.91v.818c0 .49.327.818.818.818.49 0 .818-.327.818-.818v-.818h1.636c.491 0 .818.327.818.818v2.454H5.637V7.091c0-.491.327-.818.818-.818zm11.454 13.09H6.455c-.491 0-.819-.327-.819-.818v-7.363h13.091v7.363c0 .491-.327.819-.818.819z"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <mask id="calendar" fill="#fff">
              <use xlinkHref="#calendarPath" />
            </mask>
            <g fill={hexRgba(color.$12, 40)} mask="url(#calendar)">
              <path d="M0 0h24v24H0z" />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          onClick={onClick}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <defs>
            <path
              id="calendarPath"
              d="M17.91 4.636h-1.637v-.818c0-.49-.328-.818-.818-.818-.491 0-.819.327-.819.818v.818H9.727v-.818C9.727 3.328 9.4 3 8.91 3c-.49 0-.818.327-.818.818v.818H6.455C5.064 4.636 4 5.7 4 7.091v11.454C4 19.936 5.064 21 6.455 21h11.454c1.391 0 2.455-1.064 2.455-2.455V7.091c0-1.391-1.064-2.455-2.455-2.455zM6.454 6.273H8.09v.818c0 .49.327.818.818.818.491 0 .818-.327.818-.818v-.818h4.91v.818c0 .49.327.818.818.818.49 0 .818-.327.818-.818v-.818h1.636c.491 0 .818.327.818.818v2.454H5.637V7.091c0-.491.327-.818.818-.818zm11.454 13.09H6.455c-.491 0-.819-.327-.819-.818v-7.363h13.091v7.363c0 .491-.327.819-.818.819z"
            />
          </defs>
          <g fill="none" fillRule="evenodd">
            <mask id="calendar" fill="#fff">
              <use xlinkHref="#calendarPath" />
            </mask>
            <g
              fill={isHover ? hexRgba(color.$12, 40) : color.$12}
              mask="url(#calendar)"
            >
              <path d="M0 0h24v24H0z" />
            </g>
          </g>
        </svg>
      )}
    </>
  );
};

export default IconCalendar;
