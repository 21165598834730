import { useState } from 'react';
import PropTypes from 'prop-types';
import { color } from '../../../themes';

const IconDelete20 = ({ onClick, className, id }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="delete"
          d="M11.4 2.45a2.4 2.4 0 0 1 2.394 2.236l.006.164-.001.6H16.2a.8.8 0 0 1 .794.7l.006.1a.8.8 0 0 1-.7.794l-.1.006h-.9v8.1a2.4 2.4 0 0 1-2.236 2.394l-.164.006H7.1a2.4 2.4 0 0 1-2.394-2.236L4.7 15.15v-8.1h-.9a.8.8 0 0 1-.794-.7L3 6.25a.8.8 0 0 1 .7-.794l.1-.006h2.399l.001-.6a2.4 2.4 0 0 1 2.236-2.394L8.6 2.45h2.8zm2.3 4.6H6.3v8.1a.8.8 0 0 0 .7.794l.1.006h5.8a.8.8 0 0 0 .794-.7l.006-.1v-8.1zm-5.2 1.9a.8.8 0 0 1 .794.7l.006.1v3.5a.8.8 0 0 1-1.594.1l-.006-.1v-3.5a.8.8 0 0 1 .8-.8zm3 0a.8.8 0 0 1 .8.8v3.5a.8.8 0 1 1-1.6 0v-3.5a.8.8 0 0 1 .8-.8zm-.1-4.9H8.6a.8.8 0 0 0-.794.7l-.006.1-.001.6h4.4l.001-.6a.8.8 0 0 0-.7-.794l-.1-.006z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`deleteMask${id}`} fill="#fff">
          <use xlinkHref="#delete" />
        </mask>
        <g fill={isHover ? color.$1 : color.$10} mask={`url(#deleteMask${id})`}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

IconDelete20.defaultProps = {
  id: '',
};

IconDelete20.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconDelete20;
