import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  clearECSrc,
  cyberbizSendEmail,
  shoplineConnectWithApp,
} from '../../actions/userAction';
import ECService from '../../services/ECService';
import { color } from '../../themes';
import Button from './Button';
import ImageDisplay from './ImageDisplay';

const useStyles = makeStyles(
  {
    root: {
      zIndex: '6000 !important',
    },
    paper: {
      width: 960,
      maxWidth: 'unset',
      borderRadius: 12,
      boxShadow:
        '0 30px 66px 0 rgba(0, 0, 0, 0.09), 0 8px 58px 0 rgba(0, 0, 0, 0.05)',
      overflow: 'unset',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color.$7,
      fontSize: 20,
      lineHeight: 1,
      borderRadius: 12,
      padding: '24px 24px 24px',
    },
    content: {
      padding: '24px 24px 12px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.4',
      letterSpacing: 'normal',
      textAlign: 'left',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '12px 24px 24px',
      backgroundColor: 'rgba(249, 249, 249, 1)',
      borderBottomRightRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
    'button--margin-right': {
      marginRight: 8,
    },
    option: {
      width: '100%',
      padding: '0px 12px',
      marginRight: '0',
    },
    hr: {
      width: '100%',
      height: '1px',
      borderTopColor: color.$9,
      margin: '12px 0',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '15px',
      color: color.$5,
    },
    picture: {
      borderRadius: '50%',
      marginRight: 8,
    },
    isAlreadyUsed: {
      color: color.$10,
    },
    isUsed: {
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '13px',
      color: color.$18,
      backgroundColor: '#f1c03633',
      padding: '6px 8px',
      borderRadius: 12,
      minWidth: 58,
      marginLeft: 8,
    },
    name: {
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: 'break-all',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '1',
    },
  },
  {
    name: 'ECEnableModal',
  },
);

function ECEnableModal({ isOpen, onCancel, user, provider }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orgs, setOrgs] = useState([]);
  const [selectOrg, setSelectOrg] = useState(null);

  async function handleOnClick() {
    switch (provider) {
      case 'cyberbiz':
        await dispatch(
          cyberbizSendEmail(selectOrg?.id, true, user?.sessionToken),
        );
        break;
      case 'shopline':
        await dispatch(
          shoplineConnectWithApp(selectOrg?.id, user?.sessionToken),
        );
        break;
      default:
        clearECSrc();
        navigate('/');
        break;
    }

    onCancel();
  }

  useEffect(() => {
    const init = async () => {
      const res = await ECService.getOwnerOrAdminOrg(provider);
      setOrgs(res);
      setSelectOrg(res?.filter(c => c?.enableSelect)?.[0] || null);
    };

    init();
  }, [user]);

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={isOpen}
    >
      <div className={classes.title}>{t('superMarket:cyberbizSelectOrg')}</div>
      <div className={classes.content}>
        <div style={{ marginBottom: 16 }}>
          {t(`superMarket:${provider}SelectOrgSubtitle`)}
        </div>
        <RadioGroup
          aria-label="default-display"
          name="default-display"
          value={selectOrg?.id || ''}
          onChange={e => {
            const id = e.target.value;
            setSelectOrg(orgs.find(c => c?.id?.toString() === id.toString()));
          }}
        >
          {orgs.map(c => {
            return (
              <div key={`FormControlLabel-${c?.id}`}>
                <FormControlLabel
                  disabled={!c?.enableSelect}
                  className={classes.option}
                  value={c?.id}
                  control={<Radio color="primary" />}
                  label={
                    <div
                      className={classnames(classes.label, {
                        [classes.isAlreadyUsed]: !c?.enableSelect,
                      })}
                    >
                      <ImageDisplay
                        className={classes.picture}
                        src={c?.icon}
                        width={36}
                        height={36}
                        alt={c?.displayName}
                      />
                      <div className={classes.name}>{c?.displayName}</div>
                      {!c?.enableSelect ? (
                        <div className={classes.isUsed}>
                          {t('superMarket:cyberbizAlreadyUsed')}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  }
                />
                <hr className={classes.hr} />
              </div>
            );
          })}
        </RadioGroup>
      </div>
      <div className={classes.actions}>
        <Button
          topic="primaryBlue"
          className={classes['button--margin-right']}
          onClick={() => {
            onCancel();
            clearECSrc();
            navigate('/');
          }}
        >
          {t('coupon:upload-modal.cancelText')}
        </Button>
        <Button
          topic="primaryBlueHighLight"
          onClick={handleOnClick}
          isDisable={
            !selectOrg || !orgs?.length || !orgs?.find(org => org?.enableSelect)
          }
        >
          {t('superMarket:cyberbizBind')}
        </Button>
      </div>
    </Dialog>
  );
}

export default ECEnableModal;
