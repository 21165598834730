import _uniqBy from 'lodash/uniqBy';
import { StatusType } from '../types';

import {
  TAG_MANAGEMENT_QUERY_TAG_REQUEST,
  TAG_MANAGEMENT_QUERY_TAG_SUCCESS,
  TAG_MANAGEMENT_QUERY_TAG_FAILURE,
  TAG_MANAGEMENT_RESET,
  TAG_MANAGEMENT_COUNT_QUERY_TAG_SUCCESS,
  TAG_MANAGEMENT_COUNT_QUERY_TAG_REQUEST,
  TAG_MANAGEMENT_COUNT_QUERY_TAG_FAILURE,
  TAG_MANAGEMENT_SET_DELETE_TAG_LIST,
  TAG_MANAGEMENT_SET_RESOURCE,
} from '../actions/type';

const initialState = {
  listStatus: StatusType.INITIAL,
  tagName: '',
  data: [],
  options: {},
  count: 0,
  deleteTagList: [],
  resource: {},
};

export default function tagManagementReducer(state = initialState, action) {
  switch (action.type) {
    case TAG_MANAGEMENT_RESET:
      return initialState;
    case TAG_MANAGEMENT_SET_RESOURCE:
      return {
        ...state,
        resource: action.payload,
      };
    case TAG_MANAGEMENT_QUERY_TAG_REQUEST:
      return {
        ...state,
        tagName: action.tagName,
        listStatus: action.listStatus,
        data: action.isInitial ? [] : state.data,
        options: action.options,
      };
    case TAG_MANAGEMENT_QUERY_TAG_SUCCESS:
      if (action.tagName === state.tagName) {
        return {
          ...state,
          listStatus: action.listStatus,
          data: _uniqBy(state.data.concat(action.data), 'id'),
          deleteTagList: action.deleteTagList,
        };
      }
      return {
        ...state,
      };
    case TAG_MANAGEMENT_QUERY_TAG_FAILURE:
      return {
        ...state,
        listStatus: action.listStatus,
        deleteTagList: [],
      };
    case TAG_MANAGEMENT_COUNT_QUERY_TAG_REQUEST:
    case TAG_MANAGEMENT_COUNT_QUERY_TAG_FAILURE:
      return {
        ...state,
        count: 0,
      };
    case TAG_MANAGEMENT_COUNT_QUERY_TAG_SUCCESS:
      return {
        ...state,
        count: action.count,
      };
    case TAG_MANAGEMENT_SET_DELETE_TAG_LIST:
      return {
        ...state,
        deleteTagList: action.deleteTagList,
      };
    default:
      return state;
  }
}
