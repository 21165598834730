import { nextAPI } from '../common/axios';

export default class AnnouncementService {
  static async getList({ orgId, limit, skip = 0 }) {
    let param = `?orgId=${orgId}&limit=${limit}`;
    if (skip > 0) {
      param += `&skip=${skip}`;
    }

    const response = await nextAPI.get(`/announcements/list${param}`);

    return response;
  }

  static async create({ orgId, name, content, keep }) {
    const response = await nextAPI.post(`/announcements/create`, {
      orgId,
      name,
      content,
      nailed: keep,
    });

    return response;
  }

  static async delete(orgId, id) {
    const response = await nextAPI.delete(
      `/announcements/remove?orgId=${orgId}&id=${id}`,
    );

    return response;
  }

  static async update({ orgId, id, nailed }) {
    const response = await nextAPI.put('/announcements/update', {
      orgId,
      id,
      nailed,
    });

    return response;
  }

  static async getActived(orgId) {
    const response = await nextAPI.get(
      `/announcements/get_activated?orgId=${orgId}`,
    );

    return response;
  }

  static async markSeen(orgId, id) {
    const response = await nextAPI.put('/announcements/mark_seen', {
      orgId,
      id,
    });

    return response;
  }
}
