import { nextAPI } from '../common/axios';

export default class LastReadService {
  static async getLastRead({ orgId, conversationId, sender, senderType }) {
    const response = await nextAPI.post('/last_read/conversation/reset', {
      orgId,
      conversationId,
      sender,
      senderType,
    });

    // it is possible that the lastRead.at is undefined for a new conversation
    return response?.lastRead;
  }
}
