import { useEffect } from 'react';
import hexRgba from 'hex-rgba';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import useDebounce from '../../hooks/useDebounce';
import imgSrc from '../../assets/iconSrc';
import { openModal } from '../../actions/modalAction';
import { color } from '../../themes';
import ImageDisplay from '../shared/ImageDisplay';
import {
  ModalType,
  TaskStatusType,
  BatchManagementType,
  TaskEventType,
  SOCKETIO_EVENT_TYPE,
} from '../../types';
import { setShowSystemTaskBadgeIcon } from '../../actions/systemTaskAction';
import eventStore from './taskList';
import IconButton from '../shared/IconButton';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0 14px',
      '&:hover': {
        backgroundColor: hexRgba(color.$7, 10),
      },
    },
    textStyleOpen: {
      color: color.$7,
      marginLeft: 4,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textStyleClose: { display: 'none' },
  }),
  {
    name: 'SystemTaskListItem',
  },
);

function SystemTaskListItem({ open, tabIndex }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const client = useSelector(store => store.socketio.client);
  const clientReady = useSelector(store => store.socketio.ready);

  const { objectId: orgId } = useSelector(
    store => store.organization.selectOrg || {},
  );
  const { showSystemTaskBadgeIcon } = useSelector(store => store.systemTask);

  function handleOpenModal() {
    dispatch(setShowSystemTaskBadgeIcon(false));
    dispatch(openModal(ModalType.SYSTEM_TASK_MODAL, { orgId }));
  }

  const dbsSetShowSystemTaskBadgeIcon = useDebounce(() => {
    dispatch(setShowSystemTaskBadgeIcon(true));
  });

  const taskDone = data => {
    switch (data.type) {
      case BatchManagementType.RICHMENU_SCHEDULED:
        eventStore[TaskEventType.RICHMEMU_GROUP_SCHEDULED](data, orgId);
        break;
      case BatchManagementType.RICHMENU_PUBLISH:
        eventStore[TaskEventType.RICHMEMU_GROUP_PUBLISH](data, orgId);
        break;
      case BatchManagementType.RICHMENU_UNPUBLISH:
        eventStore[TaskEventType.RICHMEMU_GROUP_UNPUBLISH](data, orgId);
        break;
      case BatchManagementType.RICHMENU_DELETE:
        eventStore[TaskEventType.RICHMEMU_GROUP_DELETE](data, orgId);
        break;
      case BatchManagementType.BROADCAST:
        eventStore[TaskEventType.BROADCAST_SUCCESS](data);
        break;
      case BatchManagementType.EXPORT_BROADCAST:
        eventStore[TaskEventType.EXPORT_BROADCAST](data);
        break;
      case BatchManagementType.ADD_TAGS:
        eventStore[TaskEventType.BATCH_MANAGEMENT_ADD_TAGS](data, orgId);
        break;
      case BatchManagementType.DELETE_TAGS:
        eventStore[TaskEventType.BATCH_MANAGEMENT_DELETE_TAGS](data, orgId);
        break;
      case BatchManagementType.DELETE_CUSTOMER_GROUP:
        eventStore[TaskEventType.BATCH_CUSTOMER_GROUP_DELETE](data, orgId);
        break;
      case BatchManagementType.CREATE_CUSTOMER_GROUP:
      case BatchManagementType.UPDATE_CUSTOMER_GROUP:
      case BatchManagementType.REFRESH_CUSTOMER_GROUP:
        eventStore[TaskEventType.BATCH_MANAGEMENT_CUSTOMER_GROUP](data, orgId);
        break;
      case BatchManagementType.EXPORT:
        eventStore[TaskEventType.BATCH_MANAGEMENT_EXPORT]();
        break;
      case BatchManagementType.SUPERLINK_EXPORT:
        eventStore[TaskEventType.SUPERLINK_EXPORT]();
        break;
      case BatchManagementType.SHARE_RANK_EXPORT:
        eventStore[TaskEventType.SHARE_RANK_EXPORT]();
        break;
      case BatchManagementType.DATA_CENTER_MESSAGE_FROM_EXPORT:
        eventStore[TaskEventType.DATA_CENTER_MESSAGE_FROM_EXPORT]();
        break;
      case BatchManagementType.DATA_CENTER_TAG_STATISTIC_EXPORT:
        eventStore[TaskEventType.DATA_CENTER_TAG_STATISTIC_EXPORT]();
        break;
      case BatchManagementType.DATA_CENTER_PLATFORM_STATISTIC_EXPORT:
        eventStore[TaskEventType.DATA_CENTER_PLATFORM_STATISTIC_EXPORT]();
        break;
      case BatchManagementType.DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT:
        eventStore[TaskEventType.DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT]();
        break;
      case BatchManagementType.COUPON_EXPORT:
        eventStore[TaskEventType.EXPORT_COUPON_MODULE](data);
        break;
      case BatchManagementType.GAME_EXPORT:
        eventStore[TaskEventType.EXPORT_GAME_MODULE](data);
        break;
      case BatchManagementType.EINVOIVE_EXPORT:
        eventStore[TaskEventType.EINVOIVE_EXPORT](data);
        break;
      case BatchManagementType.RETARGETING_EXPORT:
        eventStore[TaskEventType.RETARGETING_EXPORT](data);
        break;
      case BatchManagementType.CUSTOMER_GROUP_IMPORT_CSV:
        eventStore[TaskEventType.CUSTOMER_GROUP_IMPORT_CSV](data);
        break;
      case BatchManagementType.RECURRING_EXPORT:
        eventStore[TaskEventType.RECURRING_EXPORT](data);
        break;
      case BatchManagementType.CS_DATA_EXPORT:
        eventStore[TaskEventType.CS_DATA_EXPORT](data);
        break;
      case BatchManagementType.SHOP8_CSV:
        eventStore[TaskEventType.SHOP8_CSV](data);
        break;
      case BatchManagementType.POINT_HISTORY_EXPORT:
        eventStore[TaskEventType.POINT_HISTORY_EXPORT](data);
        break;
      case BatchManagementType.EINOVICE_ITEM_EXPORT:
        eventStore[TaskEventType.EINOVICE_ITEM_EXPORT](data);
        break;
      default:
    }
  };

  const eventListener = event => {
    if (event.type === SOCKETIO_EVENT_TYPE.TASK) {
      const { data } = event;
      dbsSetShowSystemTaskBadgeIcon();
      if (data.status === TaskStatusType.DONE) {
        taskDone(data);
      }
      if (
        data.type === BatchManagementType.EXPORT &&
        data.status === TaskStatusType.ERROR
      ) {
        eventStore[TaskEventType.TASK_ERROR]();
      }
    }
  };

  useEffect(() => {
    if (client && clientReady && orgId && dbsSetShowSystemTaskBadgeIcon) {
      client.on('event', eventListener);
    }
    return () => {
      if (client && clientReady && orgId && dbsSetShowSystemTaskBadgeIcon) {
        client.off('event', eventListener);
      }
    };
  }, [client, clientReady, orgId, dbsSetShowSystemTaskBadgeIcon]);

  return (
    <ListItem
      button
      onClick={handleOpenModal}
      className={classes.root}
      tabIndex={tabIndex}
    >
      <IconButton color="primary" component="span">
        <ImageDisplay
          src={imgSrc.icNavSystemTask}
          width={24}
          height={24}
          badgeColor="primary"
          badgeShow={showSystemTaskBadgeIcon}
        />
      </IconButton>
      <Typography
        variant="subtitle1"
        className={open ? classes.textStyleOpen : classes.textStyleClose}
      >
        {t('navigation.system-task')}
      </Typography>
    </ListItem>
  );
}

export default SystemTaskListItem;
