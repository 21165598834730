import { color } from '../../../themes';

const IconChevronLeft = ({ onClick, className, width, height, iconColor }) => {
  const random = Math.random();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <defs>
        <path
          id="ic-chevron-left"
          d="M8.2625,12.6 L13.5125,17.7428571 C13.6875,17.9142857 13.8625,18 14.125,18 C14.3875,18 14.5625,17.9142857 14.7375,17.7428571 C15.0875,17.4 15.0875,16.8857143 14.7375,16.5428571 L10.1,12 L14.7375,7.45714286 C15.0875,7.11428571 15.0875,6.6 14.7375,6.25714286 C14.3875,5.91428571 13.8625,5.91428571 13.5125,6.25714286 L8.2625,11.4 C7.9125,11.7428571 7.9125,12.2571429 8.2625,12.6 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="24" />
        <mask id={`ic-chevron-left-${random}`} fill="#fff">
          <use xlinkHref="#ic-chevron-left" />
        </mask>
        <g fill={iconColor} mask={`url(#ic-chevron-left-${random})`}>
          <rect width="24" height="24" />
        </g>
      </g>
    </svg>
  );
};

IconChevronLeft.defaultProps = {
  width: 24,
  height: 24,
  iconColor: color.$6,
};

export default IconChevronLeft;
