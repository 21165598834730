import axios from 'axios';
import actionType from './type';
import config from '../config';

const crawl = url => async dispatch => {
  const response = await axios.get(config.META_TAG_CRAWLER_URL, {
    params: {
      url,
    },
  });
  dispatch({
    type: actionType.GET_CRAWL,
    payload: response,
  });
};

export default crawl;
