const INBOXTYPE = {
  UNASSIGNED: 'unassigned',
  ALL: 'all',
  SELF: 'self',
  PRIVATE: 'private',
  DONE: 'done',
  SPAM: 'spam',
  BOT: 'bot',
  UNFOLLOW: 'unfollow',
  HISTORY: 'history',
  DEPARTMENT: 'department',
  BIND: 'bind',
  SUBBIND: 'subBind',
};

export default INBOXTYPE;
