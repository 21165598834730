import _uniqBy from 'lodash/uniqBy';
import {
  CUSTOMER_GROUPS_UPDATE_GROUP,
  CUSTOMER_GROUPS_GET_LIST_REQUEST,
  CUSTOMER_GROUPS_GET_LIST_SUCCESS,
  CUSTOMER_GROUPS_GET_LIST_CONTINUE_SUCCESS,
  CUSTOMER_GROUPS_GET_LIST_FAILURE,
  RESET_CUSTOMER_GROUPS,
  CUSTOMER_GROUPS_UPDATE_GROUP_NAME_SUCCESS,
  UPDATE_CUSTOMER_GROUPS_STATE,
  CUSTOMER_GROUPS_GET_GROUP_DATA_SUCCESS,
  CUSTOMER_GROUPS_GET_CUTOMERS_REQUEST,
  CUSTOMER_GROUPS_GET_CUTOMERS_SUCCESS,
  CUSTOMER_GROUPS_GET_CUTOMERS_FAILURE,
} from '../actions/type';
import { FetchStatus, StatusType } from '../types';

const INITIAL_STATE = {
  status: FetchStatus.INITIAL,
  list: [],
  count: 0,
  options: {},
  data: {
    name: '',
    query: {
      originalDisplayName: '',
      displayName: '',
      tagDensity: [],
      platforms: [],
      beforeConversationDate: '',
      afterConversationDate: '',
      beforeJoinDate: '',
      afterJoinDate: '',
      gender: [],
    },
  },
  customers: [],
  resource: null,
};

export default function customerGroupsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CUSTOMER_GROUPS_UPDATE_GROUP:
      return state;
    case CUSTOMER_GROUPS_GET_LIST_REQUEST:
      return {
        ...state,
        status: action.status,
      };
    case CUSTOMER_GROUPS_GET_LIST_SUCCESS:
      return {
        ...state,
        status: action.status,
        list: action.list,
      };
    case CUSTOMER_GROUPS_GET_LIST_CONTINUE_SUCCESS:
      return {
        ...state,
        status: action.status,
        list: _uniqBy(state.list.concat(action.list), 'objectId'),
      };
    case CUSTOMER_GROUPS_GET_LIST_FAILURE:
      return {
        ...state,
        status: StatusType.INITIAL_QUERY_FAILURED,
      };
    case UPDATE_CUSTOMER_GROUPS_STATE:
      return {
        ...state,
        ...action.updateState,
      };
    case RESET_CUSTOMER_GROUPS:
      return INITIAL_STATE;
    case CUSTOMER_GROUPS_UPDATE_GROUP_NAME_SUCCESS:
      const newList = state.list.map(data => {
        if (data.objectId === action.customerGroupId) {
          return { ...data, name: action.name };
        }
        return data;
      });
      return {
        ...state,
        list: newList,
      };
    case CUSTOMER_GROUPS_GET_GROUP_DATA_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
        },
      };
    case CUSTOMER_GROUPS_GET_CUTOMERS_REQUEST:
      return {
        ...state,
        status: FetchStatus.FETCHING,
      };
    case CUSTOMER_GROUPS_GET_CUTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.isContinue
          ? _uniqBy([...state.customers, ...action.customers], 'objectId')
          : action.customers,
      };
    case CUSTOMER_GROUPS_GET_CUTOMERS_FAILURE:
      return {
        ...state,
        status: FetchStatus.FAILURE,
      };
    default:
      return state;
  }
}
