import { useEffect, useState, useCallback } from 'react';
import _debounce from 'lodash/debounce';
import config from '../config';

export default function useDebounce(event, delay = config.DEBOUNCE_DELAY) {
  const [debouncedEvent, setDebouncedEvent] = useState();
  useEffect(() => {
    setDebouncedEvent(() => _debounce(event, delay));
  }, []);

  return useCallback(debouncedEvent, [debouncedEvent]);
}
