import { PlatformType } from '../types';
import Logo from './images/svg/logo-super-8.svg';
import Ripple from './images/svg/bg-nav-ripple.svg';
import Menu from './images/svg/menu.svg';
import overview from './images/svg/dashboard.svg';
import messagecenter from './images/svg/inbox.svg';
import messagebot from './images/svg/chatbot.svg';
import webviewEditor from './images/svg/questionnaire.svg';
import broadcastBlock from './images/svg/broadcast-block.svg';
import broadcast from './images/svg/broadcast.svg';
import customermanage from './images/svg/users.svg';
import chatPay from './images/svg/ic-nav-questionnaire.svg';
import analysis from './images/svg/bar-chart.svg';
import marketingAutomation from './images/svg/ic-nav-dashboard.svg';
import organizationSetting from './images/svg/ic-nav-setting.svg';
import helpCenter from './images/svg/help-center.svg';
import bell from './images/svg/bell.svg';
import defaultUser from './images/svg/default-customer-light.svg';
import chevronUp from './images/svg/chevron-up.svg';
import chevronDown from './images/svg/chevron-down.svg';
import addOrg from './images/svg/add-org.svg';
import addCard from './images/svg/add-card.svg';
import tagDelete from './images/svg/ic-x-circle-20.svg';
import tagDeleteBlack from './images/svg/ic-x-circle-black-20.svg';
import tagAdd from './images/svg/ic-add-circle-20.svg';
import icZap from './images/svg/ic-zap.svg';
import icSun from './images/svg/ic-sun.svg';
import icMap from './images/svg/ic-map-pin-20.svg';
import appleStoreIcon from './images/svg/link-app-store-g.svg';
import googleStoreIcon from './images/svg/link-google-play.svg';
import LogoColor from './images/svg/logo-super-8-color.svg';
import facebookIcon from './images/svg/logo-login-facebook.svg';
import googleIcon from './images/svg/logo-login-google.svg';
import icCheckBlack from './images/svg/ic-check-black.svg';
import icCheck from './images/svg/ic-check-circle.svg';
import icCheckV from './images/svg/ic-check.svg';
import UpperLeftRipple from './images/png/img-signin-01.png';
import BottomLeftRipple from './images/png/img-signin-02.png';
import chevronDownIcon from './images/svg/ic-chevron-down.svg';
import chevronUpIcon from './images/svg/ic-chevron-up.svg';
import chevronRightIcon from './images/svg/ic-chevron-right.svg';
import icX from './images/svg/ic-x.svg';
import icXBlock from './images/svg/ic-x-block.svg';
import defaultOrg from './images/png/default-org-dark.png';
import defaultLightOrg from './images/svg/default-org-light.svg';
import imgAudio from './images/svg/img-file-audio.svg';
import imgVideo from './images/svg/img-file-video.svg';
import imgDoc from './images/svg/img-file-doc.svg';
import icSearch from './images/svg/ic-search.svg';
import icClear from './images/svg/ic-x-circle.svg';
import icEdit from './images/svg/ic-edit.svg';
import icFileUpload from './images/svg/img-photo-upload.svg';
import icImage from './images/svg/ic-image.svg';
import icImageHover from './images/svg/ic-image-hover.svg';
import icBrowse from './images/svg/ic-browse.svg';
import icFilterApply from './images/svg/ic-filter-apply-20.svg';
import icFilter from './images/svg/ic-filter-20.svg';
import icRight from './images/svg/ic-chevron-right-13.svg';
import icTagBlock from './images/svg/ic-tag-block.svg';
import icTag from './images/svg/ic-tag.svg';
import arrowDown from './images/svg/ic-arrow-down-20.svg';
import arrowUp from './images/svg/ic-arrow-up-20.svg';
import icInboxAll from './images/svg/ic-inbox-all.svg';
import icInboxUnassign from './images/svg/ic-inbox-unassign.svg';
import icInboxDone from './images/svg/ic-inbox-done.svg';
import icInboxAllAssign from './images/svg/ic-inbox-all-assigned.svg';
import icInboxBot from './images/svg/ic-inbox-chatbot.svg';
import icInboxSpam from './images/svg/ic-inbox-spam.svg';
import icInboxUnfollow from './images/svg/ic-inbox-block.svg';
import icTag20 from './images/svg/ic-tag-20.svg';
import icFans from './images/svg/ic-fan-16.svg';
import icBroadcast from './images/svg/ic-broadcast-20.svg';
import emptyCustomerManagement from './images/svg/img-empty-customer-management.svg';
import emptyCustomerManagementSearch from './images/svg/img-empty-customer-management-search.svg';
import icHeartLevel0 from './images/svg/ic-heart-level-0.svg';
import icHeartLevel1 from './images/svg/ic-heart-level-1.svg';
import icHeartLevel2 from './images/svg/ic-heart-level-2.svg';
import icHeartLevel3 from './images/svg/ic-heart-level-3.svg';
import icHeartLevel4 from './images/svg/ic-heart-level-4.svg';
import icSort from './images/svg/ic-sort-16.svg';
import icSortDown from './images/svg/ic-sort-down-16.svg';
import icSortUp from './images/svg/ic-sort-up-16.svg';
import emptyLabelManagement from './images/svg/img-empty-label-management.svg';
import emptyLabelSearch from './images/svg/img-empty-label-search.svg';
import icFbMessenger02 from './images/svg/ic-fbmessenger-02.svg';
import icLine from './images/svg/ic-line.svg';
import icHelpCircle from './images/svg/ic-help-circle.svg';
import icErrorCircle from './images/svg/ic-error-circle.svg';
import icSuccessCircle from './images/svg/ic-success-circle.svg';
import icAlertTriangle from './images/svg/ic-alert-triangle.svg';
import avatarBot from './images/png/avatar-chatbot@3x.png';
import icRefresh16 from './images/svg/ic-refresh-16.svg';
import icResend from './images/svg/ic-resend.svg';
import icCardConfirm from './images/svg/ic-card-confirm.svg';
import icCardList from './images/svg/ic-card-list.svg';
import icCardItem from './images/svg/ic-card-item.svg';
import icCardItemHover from './images/svg/ic-card-item-hover.svg';
import icCardTemlateAll from './images/svg/ic-cardtemplate-all.svg';
import icText from './images/svg/ic-text.svg';
import icTextHover from './images/svg/ic-text-hover.svg';
import imgDefaultCard from './images/svg/img_default_card.svg';
import icRightAngle from './images/svg/ic-right-angle.svg';
import icPlus from './images/svg/ic-plus.svg';
import icMinusCircle from './images/svg/ic-minus-circle.svg';
import icMinusCircle20 from './images/svg/ic-minus-circle-20.svg';
import icChevronLeft from './images/svg/ic-chevron-left.svg';
import icDelete20 from './images/svg/ic-delete-20.svg';
import icLink from './images/svg/ic-link.svg';
import icPhone from './images/svg/ic-phone.svg';
import btnMore from './images/svg/btn-more.svg';
import emptyBroadcast from './images/svg/img-empty-broadcast.svg';
import emptyNoproduct from './images/svg/img-empty-noproduct.svg';
import emptyMarketingAutomation from './images/svg/img_empty_marketing_automation.svg';
import emptyRichMenu from './images/svg/img_emptyRichMenu.svg';
import icAddCircle from './images/svg/ic-add-circle.svg';
import icAddCircle20Hover from './images/svg/ic-add-circle-20-hover.svg';
import imgLineCampaign from './images/png/line-data@3x.png';
import imgMessengerCampaign from './images/png/messenger-data@3x.jpg';
import growthTool from './images/svg/ic-nav-growth-tool.svg';
import super8api from './images/svg/ic-nav-super-8-api.svg';
import lineOfficialNotification from './images/svg/ic-nav-line-official-notification.svg';
import icMoreHorizontal from './images/svg/ic-more-horizontal.svg';
import icLinePoint from './images/svg/ic-line-points.svg';
import icLinePointHover from './images/svg/ic-line-points-hover.svg';
import imgEmptyCustomerManagementSearch from './images/png/img-empty-customer-management-search@3x.png';
import superlink from './images/svg/ic-nav-superlink.svg';
import icNavSystemTask from './images/svg/ic-nav-system-task.svg';
import icSetting from './images/svg/setting.svg';
import icInLineSvg from './images/svg/ic-in-line.svg';
import icInProgressSvg from './images/svg/ic-in-progress.svg';
import icNavBellSvg from './images/svg/ic-nav-bell.svg';
import webclip from './images/png/webclip.png';
import userDefaultImage from './images/svg/user_default_image.svg';
import emptyNotification from './images/svg/img-empty-notification.svg';
import ovChevronLeft from './images/svg/ov-chevron-left.svg';
import ovChevronRight from './images/svg/ov-chevron-right.svg';
import fb from './images/svg/fb.svg';
import line from './images/svg/line.svg';
import registerSuccess from './images/svg/img-register-success.svg';
import icEyeBlack from './images/svg/ic-eye-black-20.svg';
import covid from './images/messagebot/bot-covid.png';
import igPrivateReply from './images/messagebot/bot17_comment@2x.png';
import igStoryMention from './images/messagebot/bot_ig_mention@2x.png';
import greetingIcon from './images/messagebot/greetingbot.png';
import privateIcon from './images/messagebot/privatebot.png';
import leavingIcon from './images/messagebot/leavingbot.png';
import hotelBotIcon from './images/messagebot/hotelbot.png';
import carIcon from './images/messagebot/bot-car.png';
import botImageRenderIcon from './images/messagebot/bot-images-combination@3x.png';
import birthdayIcon from './images/messagebot/bot-birthday.png';
import estateIcon from './images/messagebot/bot-estate.png';
import bookrestaurantIcon from './images/messagebot/bot-bookrestaurant.png';
import apartmentIcon from './images/messagebot/bot-apartment.png';
import newsIcon from './images/messagebot/bot-news.png';
import insuranceIcon from './images/messagebot/bot-insurance.png';
import restaurantReviewIcon from './images/messagebot/bot-restaurant-review.png';
import marketingIcon from './images/messagebot/bot-marketing.png';
import weddingIcon from './images/messagebot/bot-wedding.png';
import travelIcon from './images/messagebot/bot-travel.png';
import hairSalonIcon from './images/messagebot/bot-book-hair-salon.png';
import beautySalonIcon from './images/messagebot/bot-beauty-salon.png';
import restaurantCouponIcon from './images/messagebot/bot-restaurant-coupon.png';
import shoppingIcon from './images/messagebot/shoppingbot.png';
import customizeIcon from './images/messagebot/customizebot.png';
import brandintroIcon from './images/messagebot/brandintrobot.png';
import rankingIcon from './images/messagebot/rankingbot.png';
import shortversionIcon from './images/messagebot/shortversionbot.png';
import discountIcon from './images/messagebot/discountbot.png';
import tftestIcon from './images/messagebot/tftestbot.png';
import optiontestIcon from './images/messagebot/optiontestbot.png';
import customerfeedbackIcon from './images/messagebot/customerfeedbackbot.png';
import keywordIcon from './images/messagebot/keywordbot.png';
import botLineConnect from './images/messagebot/bot-lin-econnect.png';
import botSearchorder from './images/messagebot/bot-searchorder.png';
import bot91App from './images/messagebot/bot-91-ap-ptopsale.png';
import emptyInboxChatBot from './images/svg/img-empty-inbox-chatbot.svg';
import emptyInbox from './images/svg/img-empty-inbox.svg';
import emptySeachCustomer from './images/svg/img-empty-search-customer.svg';
import emptySeachMsg from './images/svg/img-empty-search-msg.svg';
import btnDownload from './images/svg/btn-download.svg';
import icChatBotHandel from './images/svg/ic-chatbot-handel.svg';
import icAutoReply from './images/svg/ic-auto-reply.svg';
import icSettingGary from './images/svg/ic-setting.svg';
import icBlock10 from './images/png/ic-block-10.png';
import icBroadcastBoth from './images/svg/ic-broadcast-both-platform.svg';
import icBroadcastWhatsapp from './images/svg/ic-broadcast-whatsapp.svg';
import icBroadcastLivechat from './images/svg/ic-broadcast-livechat.svg';
import icBroadcastLine from './images/svg/ic-broadcast-line.svg';
import icBroadcastMessenger from './images/svg/ic-broadcast-messenger.svg';
import icCalendar from './images/svg/ic-calendar.svg';
import icCalendarGray from './images/svg/ic-calendar-gray.svg';
import icPnPCoast from './images/svg/img-pnp-coast.svg';
import icPnPText from './images/png/img_pnp_text.png';
import icPnPJoin from './images/svg/img-pnp-join.svg';
import icPnPSendamount from './images/svg/img-pnp-sendamount.svg';
import icPnPSendsuccess from './images/svg/img-pnp-sendsuccess.svg';
import icMarketingAutomation from './images/png/img_marketing_automation.png';
import icChatpayUnavailable from './images/png/img_chatpay_unavailable.png';
import emptyQuestionnaire from './images/svg/img_empty_questionnaire.svg';
import emptyEInvoice from './images/svg/img_empty_eInvoice.svg';
import emptyRecurring from './images/svg/img_empty_recurring.svg';
import emptySearchResult from './images/svg/img_empty_search_result.svg';
import emptySurveyCake from './images/svg/img_empty_surveycake.svg';
import emptyMemberPerformance from './images/svg/img_empty_memberPerformance.svg';
import emptyRetargeting from './images/svg/img_empty_shoppingcart.svg';
import emptyRoulette from './images/svg/img-empty-roulette.svg';
import emptyScratch from './images/svg/img-empty-scratch.svg';
import emptySlots from './images/svg/img_empty_slots.svg';
import emptyPrize from './images/svg/img-empty-prize.svg';
import icFileUnknown from './images/svg/img_file_unknown.svg';
import imgBroadcastInstagram from './images/png/ic-broadcast-ig@2x.png';
import imgInstagram16 from './images/png/ic-ig-16.png';
import imgInstagram20 from './images/png/ic-instagram@2x.png';
import icBtnCustomerChat from './images/svg/btn-customer-chat.svg';
import emptyCoupon from './images/svg/img-empty-couponset.svg';
import icCoupon from './images/svg/ic-coupon.svg';
import icCouponHover from './images/svg/ic-coupon-hover.svg';
import icFilmPlay from './images/svg/ic_film_play.svg';
import icFilmLink from './images/svg/ic_film_link.svg';
import icMail from './images/svg/ic_mail_24.svg';
import icMobile from './images/svg/ic_mobile_24.svg';
import icInfo from './images/svg/ic_info.svg';
import icOrderForm from './images/svg/ic_order_form.svg';
import icDeliveryStatus from './images/svg/ic_delivery_status.svg';
import icAddImg from './images/png/ic-addImg.png';
import icECPay from './images/svg/ic-ECPay.svg';
import icO from './images/svg/ic-o.svg';
import icImport from './images/svg/ic-import.svg';
import icMessage from './images/svg/ic_message_20.svg';

import icInboxDept from './images/svg/ic_inbox_dept.svg';
import icInboxOnce from './images/svg/ic_inbox_once.svg';
import icInboxMine from './images/svg/ic-inbox-mine.svg';

import icEmptyMarketingAutomation from './images/svg/-g-img_empty_marketing_automation.svg';

import superMarket from './images/png/ic_supermarket@2x.png';

import imgLivechat from './images/png/ic_webchat@2x.png';
import iconInstagram from './images/png/ic-instagram-32@2x.png';
import imgWhatsapp24 from './images/svg/ic_WhatsApp_24.svg';

import lonMsg1 from './images/png/lon_msg1.png';
import lonMsg2 from './images/png/lon_msg2.png';
import lonMsg3 from './images/png/lon_msg3.png';
import lonMsg4 from './images/png/lon_msg4.png';
import lonMsg5 from './images/png/lon_msg5.png';
import lonMsg6 from './images/png/lon_msg6.png';
import lonMsg7 from './images/png/lon_msg7.png';
import lonMsg8 from './images/png/lon_msg8.png';
import lonMsg9 from './images/png/lon_msg9.png';
import lonMsg10 from './images/png/lon_msg10.png';
import lonMsg11 from './images/png/lon_msg11.png';
import lonLine1 from './images/png/lon_line1.png';
import lonLine2 from './images/png/lon_line2.png';
import lonLine3 from './images/png/lon_line3.png';
import lonLine4 from './images/png/lon_line4.png';
import lonLine5 from './images/png/lon_line5.png';
import lonLine6 from './images/png/lon_line6.png';
import lonLine7 from './images/png/lon_line7.png';
import lonLine8 from './images/png/lon_line8.png';
import lonLine9 from './images/png/lon_line9.png';
import lonLine10 from './images/png/lon_line10.png';
import lonLine11 from './images/png/lon_line11.png';
import lonSms1 from './images/png/lon_sms1.png';
import lonSms2 from './images/png/lon_sms2.png';
import lonSms3 from './images/png/lon_sms3.png';
import lonSms4 from './images/png/lon_sms4.png';
import lonSms5 from './images/png/lon_sms5.png';
import lonSms6 from './images/png/lon_sms6.png';
import lonSms7 from './images/png/lon_sms7.png';
import lonSms8 from './images/png/lon_sms8.png';
import lonSms9 from './images/png/lon_sms9.png';
import lonSms10 from './images/png/lon_sms10.png';
import lonSms11 from './images/png/lon_sms11.png';

export const icInLine = icInLineSvg;
export const icInProgress = icInProgressSvg;

const imgSrc = {
  icMessage,
  icImport,
  icSuccessCircle,
  icErrorCircle,
  icO,
  icECPay,
  icOrderForm,
  icDeliveryStatus,
  emptyNoproduct,
  icAddImg,
  chatPay,
  icBroadcastLivechat,
  emptyMemberPerformance,
  imgWhatsapp24,
  icInfo,
  emptySearchResult,
  emptySurveyCake,
  emptyRecurring,
  icMail,
  icMobile,
  emptyRetargeting,
  emptyEInvoice,
  icFilmLink,
  icFilmPlay,
  icMarketingAutomation,
  icChatpayUnavailable,
  icEmptyMarketingAutomation,
  icCoupon,
  icCouponHover,
  botLineConnect,
  botSearchorder,
  bot91App,
  greetingIcon,
  privateIcon,
  leavingIcon,
  hotelBotIcon,
  carIcon,
  botImageRenderIcon,
  birthdayIcon,
  estateIcon,
  bookrestaurantIcon,
  apartmentIcon,
  newsIcon,
  insuranceIcon,
  restaurantReviewIcon,
  marketingIcon,
  weddingIcon,
  travelIcon,
  hairSalonIcon,
  beautySalonIcon,
  restaurantCouponIcon,
  shoppingIcon,
  customizeIcon,
  brandintroIcon,
  rankingIcon,
  shortversionIcon,
  discountIcon,
  tftestIcon,
  optiontestIcon,
  customerfeedbackIcon,
  keywordIcon,
  emptyCoupon,
  emptyPrize,
  emptyRoulette,
  emptyScratch,
  emptySlots,
  emptyQuestionnaire,
  icBlock10,
  icBroadcastBoth,
  icBroadcastLine,
  icBroadcastMessenger,
  imgBroadcastInstagram,
  icBroadcastWhatsapp,
  icPnPText,
  icSettingGary,
  icCalendarGray,
  icCheckBlack,
  icCheckV,
  icXBlock,
  icPnPCoast,
  icPnPJoin,
  icPnPSendamount,
  icPnPSendsuccess,
  icCalendar,
  icEyeBlack,
  broadcastBlock,
  icTagBlock,
  fb,
  line,
  ovChevronLeft,
  ovChevronRight,
  registerSuccess,
  emptyNotification,
  webclip,
  userDefaultImage,
  icNavBellSvg,
  Logo,
  Ripple,
  Menu,
  overview,
  'marketing-automation': marketingAutomation,
  'message-center': messagecenter,
  'message-bot': messagebot,
  'webview-editor': webviewEditor,
  broadcast,
  superMarket,
  'customer-management': customermanage,
  'data-analysis': analysis,
  'organization-setting': organizationSetting,
  helpCenter,
  bell,
  defaultUser,
  chevronUp,
  chevronDown,
  addOrg,
  addCard,
  tagDelete,
  tagDeleteBlack,
  tagAdd,
  icZap,
  icSun,
  icMap,
  appleStoreIcon,
  googleStoreIcon,
  LogoColor,
  facebookIcon,
  googleIcon,
  icCheck,
  UpperLeftRipple,
  BottomLeftRipple,
  chevronDownIcon,
  chevronUpIcon,
  defaultOrg,
  defaultLightOrg,
  icSearch,
  icClear,
  icEdit,
  icFileUpload,
  imgAudio,
  imgVideo,
  imgDoc,
  icFilterApply,
  icFilter,
  icRight,
  icTag,
  arrowDown,
  arrowUp,
  icInboxAll,
  icInboxUnassign,
  icInboxDone,
  icInboxAllAssign,
  icInboxBot,
  icInboxSpam,
  icInboxUnfollow,
  icTag20,
  icBroadcast,
  emptyCustomerManagement,
  emptyCustomerManagementSearch,
  icHeartLevel0,
  icHeartLevel1,
  icHeartLevel2,
  icHeartLevel3,
  icHeartLevel4,
  icSort,
  icSortDown,
  icSortUp,
  emptyLabelManagement,
  emptyLabelSearch,
  icFbMessenger02,
  icLine,
  icHelpCircle,
  icAlertTriangle,
  avatarBot,
  icRefresh16,
  icResend,
  icCardConfirm,
  icCardList,
  icCardItem,
  icCardItemHover,
  icCardTemlateAll,
  icText,
  icTextHover,
  imgDefaultCard,
  chevronRightIcon,
  icX,
  icRightAngle,
  icPlus,
  icMinusCircle20,
  icMinusCircle,
  icChevronLeft,
  icImage,
  icImageHover,
  icBrowse,
  icDelete20,
  icLink,
  icPhone,
  btnMore,
  emptyBroadcast,
  emptyMarketingAutomation,
  icAddCircle20Hover,
  icAddCircle,
  imgLineCampaign,
  imgMessengerCampaign,
  icFans,
  'growth-tool': growthTool,
  super8api,
  lineOfficialNotification,
  icMoreHorizontal,
  icLinePoint,
  icLinePointHover,
  imgEmptyCustomerManagementSearch,
  'super-link': superlink,
  icNavSystemTask,
  icSetting,
  emptyInboxChatBot,
  emptyInbox,
  emptySeachCustomer,
  emptySeachMsg,
  btnDownload,
  icChatBotHandel,
  icAutoReply,
  icFileUnknown,
  imgInstagram16,
  imgInstagram20,
  icBtnCustomerChat,
  covid,
  igPrivateReply,
  igStoryMention,
  icInboxDept,
  icInboxOnce,
  icInboxMine,
  emptyRichMenu,
  lonMsg1,
  lonMsg2,
  lonMsg3,
  lonMsg4,
  lonMsg5,
  lonMsg6,
  lonMsg7,
  lonMsg8,
  lonMsg9,
  lonMsg10,
  lonMsg11,
  lonLine1,
  lonLine2,
  lonLine3,
  lonLine4,
  lonLine5,
  lonLine6,
  lonLine7,
  lonLine8,
  lonLine9,
  lonLine10,
  lonLine11,
  lonSms1,
  lonSms2,
  lonSms3,
  lonSms4,
  lonSms5,
  lonSms6,
  lonSms7,
  lonSms8,
  lonSms9,
  lonSms10,
  lonSms11,
};

export const platformIconsSrc = {
  [PlatformType.FACEBOOK]: imgSrc.fb,
  [PlatformType.LINE]: imgSrc.line,
  [PlatformType.INSTAGRAM]: iconInstagram,
  [PlatformType.LIVECHAT]: imgLivechat,
  [PlatformType.WHATSAPP]: imgWhatsapp24,
};

export default imgSrc;
