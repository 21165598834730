import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OnboardingModal from './OnboardingModal';

function Onboarding({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const { userData } = useSelector(store => store.auth);

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (userData) {
      const { toturialDisabled } = userData;
      if (!toturialDisabled && toturialDisabled !== undefined) {
        setIsOpen(true);
      }
    }
  }, [userData]);

  return (
    <>
      <OnboardingModal isOpen={isOpen} onCancel={handleCancel} />
      {children}
    </>
  );
}

export default Onboarding;
