import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { color } from '../../themes';

const useStyles = makeStyles(
  () => ({
    white: {
      '& svg circle': {
        color: `${color.$7}`,
      },
    },
    grey: {
      '& svg circle': {
        color: `${color.$10}`,
      },
    },
    blue: {
      '& svg circle': {
        color: `${color.$3}`,
      },
    },
  }),
  {
    name: 'Circular',
  },
);

const Circular = ({ topic, ...spreadProps }) => {
  const classes = useStyles();

  return (
    <CircularProgress
      classes={{ root: classes[`${topic}`] }}
      {...spreadProps}
    />
  );
};

Circular.defaultProps = {
  topic: 'white',
};

Circular.propTypes = {
  topic: PropTypes.string,
};

export default Circular;
