import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import MomentUtils from '@date-io/moment';

import 'moment/locale/zh-tw';
import 'moment/locale/ja';

moment.suppressDeprecationWarnings = true;

function DateProvider({ children }) {
  const { i18n } = useTranslation();

  const getMapDatePickerLanguage = () => {
    let res = 'zh-TW';
    switch (i18n.language) {
      case 'ja-JP':
        res = 'ja';
        break;
      default:
        break;
    }

    return res;
  };

  const [browserLaguage, setBrowserLaguage] = useState(
    getMapDatePickerLanguage(),
  );
  useEffect(() => {
    setBrowserLaguage(getMapDatePickerLanguage());
    moment.locale(i18n.language, {
      weekdaysShort: i18n.services.resourceStore.data[
        i18n.language
      ].momentFormat.weekdaysShort.split('_'),
      weekdays: i18n.services.resourceStore.data[
        i18n.language
      ].momentFormat.weekdays.split('_'),
      relativeTime:
        i18n.services.resourceStore.data[i18n.language].momentFormat
          .relativeTimeFormat,
    });
  }, [i18n.language]);

  return (
    <MuiPickersUtilsProvider locale={browserLaguage} utils={MomentUtils}>
      {children}
    </MuiPickersUtilsProvider>
  );
}

export default DateProvider;
