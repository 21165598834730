import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import ReactSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { color } from '../../themes';

const useStyles = makeStyles(
  {
    root: {
      marginBottom: 100,
      '& *': {
        outline: 'none !important',
      },
      '& .slick-dots li button:before': {
        opacity: 1,
        color: color.$8,
      },
      '& .slick-dots li.slick-active button:before': {
        color: color.$1,
        opacity: 1,
      },
      '& .slick-dots li': {
        margin: 0,
      },
      '& .slick-dots': {
        bottom: -57,
      },
    },
  },
  {
    name: 'Slider',
  },
);

/**
 * https://react-slick.neostack.com/docs/api
 */

function Slider({ sliderRef, className, settings, children }) {
  const classes = useStyles();

  return (
    <ReactSlider
      ref={sliderRef}
      className={classnames(classes.root, className)}
      {...settings}
    >
      {children}
    </ReactSlider>
  );
}

export default Slider;
