import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TWD } from '../../common/utils';
import { color } from '../../themes';
import {
  BatchManagementType,
  TaskStatusType,
  CustomerGroupStatusType,
} from '../../types';
import SystemTaskStatusIcon from './SystemTaskStatusIcon';
import { getCurrentUserByLocalStorage } from '../../actions/userAction';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '12px 24px',
      '&:hover': {
        backgroundColor: 'rgba(224, 224, 224, 0.2)',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
    },
    'header-title': {
      width: '100%',
      fontSize: 13,
      lineHeight: '18px',
      color: color.$13,
      margin: '0 16px 0 0',
      '& > span.description': {
        overflowWrap: 'anywhere',
        margin: 0,
      },
      '& a': {
        cursor: 'pointer',
        color: color.$2,
        textDecoration: 'underline',
        '&:hover': {
          color: 'rgba(156, 193, 0, 0.5)',
        },
      },
    },
    'start-date': {
      fontSize: 11,
      lineHeight: 1,
      color: color.$11,
      marginTop: 6,
    },
    'task-retry': {
      cursor: 'pointer',
      color: color.$2,
      textDecoration: 'underline',
      '&:hover': {
        color: 'rgba(156, 193, 0, 0.5)',
      },
    },
  }),
  { name: 'Task' },
);

function transformTagsToString(tags) {
  return tags.reduce((pre, tag, index) => {
    if (index === 0) {
      return `${pre}「${tag}」`;
    }
    return `${pre}、「${tag}」`;
  }, '');
}

function Task({ data, onRetry }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { objectId, type, status, total, createdAt, options, url } = data;
  const isDisable = type === CustomerGroupStatusType.CUSTOM_IMPORT;

  const openUrl = downloadUrl => {
    if (window.dataLayer) {
      const userData = getCurrentUserByLocalStorage();
      if (userData) {
        window.dataLayer.push({
          event: 'S8_exportFile',
          data: JSON.stringify({
            userObjectId: userData.objectId,
            cAt: new Date(),
            taskId: objectId,
          }),
        });
      }
    }
    window.location.href = downloadUrl;
  };

  let descriptionComponent = null;
  switch (type) {
    case BatchManagementType.ADD_TAGS:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t('system-task.type.add-tags', {
            count: TWD(total, false).format(),
            tags: transformTagsToString(options.tags),
          })}
        </span>
      );
      break;
    case BatchManagementType.DELETE_TAGS:
      if (options.forOrganization) {
        descriptionComponent = (
          <span className="description" title={`ID: ${objectId}`}>
            {t('system-task.type.delete-org-tags', {
              count: TWD(options.tags.length, false).format(),
              tags: transformTagsToString(options.tags),
            })}
          </span>
        );
      } else {
        descriptionComponent = (
          <span className="description" title={`ID: ${objectId}`}>
            {t('system-task.type.delete-tags', {
              count: TWD(total, false).format(),
              tags: transformTagsToString(options.tags),
            })}
          </span>
        );
      }
      break;
    case BatchManagementType.BROADCAST:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t('system-task.type.send-broadcast', {
            name: options?.name ?? '',
          })}
        </span>
      );
      break;
    case BatchManagementType.EXPORT_BROADCAST:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`broadcast:task-export`)}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.EXPORT_SHOP8_ORDER:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`chatPay:orderListContainer.export`)}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {options && options.group && options.group.name
            ? `${t('system-task.type.export-by-customer-group', {
                groupName: options.group.name,
                count: TWD(total, false).format(),
              })} `
            : `${t('system-task.type.export', {
                count: TWD(total, false).format(),
              })} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.CREATE_CUSTOMER_GROUP:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.create-customer-group', {
            count: TWD(total, false).format(),
            name: options.name,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.UPDATE_CUSTOMER_GROUP:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.update-customer-group', {
            name: options.name,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.REFRESH_CUSTOMER_GROUP:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.update-customer-group', {
            name: options.name,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.RICHMENU_PUBLISH:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.publish-rich-menu-group', {
            name: options.richMenuGroup.title,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.RICHMENU_UNPUBLISH:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.unpublish-rich-menu-group', {
            name: options.richMenuGroup.title,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.RICHMENU_DELETE:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.delete-rich-menu-group', {
            name: options.richMenuGroup.title,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.DELETE_CUSTOMER_GROUP:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.delete-customer-group', {
            name: options.name,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.RICHMENU_SCHEDULED:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.schedule-rich-menu-group', {
            name: options.richMenuGroup.title,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.SUPERLINK_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.superlink-export', {
            campaignName: options.campaignName,
          })} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.SHARE_RANK_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.shareRank-export', {
            campaignName: options.campaignName,
          })} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.DATA_CENTER_MESSAGE_FROM_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('overview:message-analysis.task')} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.DATA_CENTER_TAG_STATISTIC_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('overview:tag.task')} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('overview:template-account.task')} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.DATA_CENTER_PLATFORM_STATISTIC_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('overview:customer-detail.task')} `}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;

    case BatchManagementType.COUPON_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`coupon:task-export`, { name: options?.couponName })}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.GAME_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`roulette:task-export`, { name: options?.campaignName })}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.EINVOIVE_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`eInvoice:task-export`, { name: options?.campaignName })}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.RETARGETING_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`retargeting:task-export`, { name: options?.campaignName })}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.RECURRING_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`recurringMessage:task-export`, { name: options?.name })}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.CUSTOMER_GROUP_IMPORT_CSV:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {`${t('system-task.type.create-customer-group', {
            count: TWD(options.count, false).format(),
            name: options.title,
          })} `}
        </span>
      );
      break;
    case BatchManagementType.CS_DATA_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t('customerServiceData:export.task', {
            name: `${t(
              `customerServiceData:export.${data.options.blockType}`,
            )}-${t(`customerServiceData:${data.options.query.caseType}`)}`,
          })}

          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.SHOP8_CSV:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t('chatPay:uploadTask', {
            total,
          })}
        </span>
      );
      break;
    case BatchManagementType.POINT_HISTORY_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`aiBot:pointHistoryModal.exported`)}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    case BatchManagementType.EINOVICE_ITEM_EXPORT:
      descriptionComponent = (
        <span className="description" title={`ID: ${objectId}`}>
          {t(`eInvoice:itemExport`)}
          {status === TaskStatusType.DONE && (
            <a onClick={() => openUrl(url)}>{t('download')}</a>
          )}
        </span>
      );
      break;
    default:
  }

  function handleOnClickRetry() {
    onRetry(options.query, options.options);
  }

  return isDisable ? null : (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes['header-title']}>
          {descriptionComponent}
          {type === BatchManagementType.EXPORT &&
            status === TaskStatusType.ERROR && (
              <span
                className={classes['task-retry']}
                onClick={handleOnClickRetry}
              >
                {t('system-task.assign-task.retry')}
              </span>
            )}
          <div className={classes['start-date']}>
            {moment(createdAt).format('YYYY-MM-DD HH:mm')}
          </div>
        </div>
      </div>
      <SystemTaskStatusIcon objectId={objectId} taskStatus={status} />
    </div>
  );
}

Task.defaultProps = {
  data: {
    description: '',
    success: 0,
    fail: 0,
    total: 1,
    url: '',
  },
  onRetry: () => {},
};

Task.propTypes = {
  data: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    description: PropTypes.string,
    status: PropTypes.oneOf(Object.values(TaskStatusType)).isRequired,
    scheduleAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
    success: PropTypes.number,
    fail: PropTypes.number,
    total: PropTypes.number,
    url: PropTypes.string,
    createdAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.instanceOf(moment),
    ]),
    options: PropTypes.object,
  }),
  onRetry: PropTypes.func,
};

export default Task;
