import imgSrc from '../../assets/iconSrc';

const GROWTHTOOL_TYPE = {
  text: imgSrc.icText,
  image: imgSrc.icImage,
  card: imgSrc.icCardItem,
  textHover: imgSrc.icTextHover,
  imageHover: imgSrc.icImageHover,
  cardHover: imgSrc.icCardItemHover,
  linePoint: imgSrc.icLinePoint,
  linePointHover: imgSrc.icLinePointHover,
  coupon: imgSrc.icCoupon,
  couponHover: imgSrc.icCouponHover,
};

export default GROWTHTOOL_TYPE;
