import { SET_NEXT_RESOURCE } from '../actions/type';

const INITIAL_STATE = {
  nextResource: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NEXT_RESOURCE:
      return {
        ...state,
        ...action.setting,
      };
    default:
      return state;
  }
};
