import { v4 as uuidv4 } from 'uuid';
import coreAPI, { nextAPI } from '../common/axios';
import FileService from './FileService';

export default class OrganizationService {
  static async createOrganization(iconFile, orgName, propertyType, locale) {
    if (!orgName) throw new Error('createOrganization: orgName is empty');
    if (!propertyType)
      throw new Error('createOrganization: propertyType is empty');
    if (!locale) throw new Error('createOrganization: locale is empty');
    let iconUrl;

    if (iconFile) {
      const { url } = await FileService.upload(iconFile);
      iconUrl = url;
    }

    const org = await coreAPI.post('/classes/Organization', {
      name: uuidv4(),
      icon: iconUrl,
      displayName: orgName,
      industry: propertyType,
      locale,
    });
    return {
      displayName: orgName,
      icon: iconUrl,
      ...org,
    };
  }

  static async evictOrganization(orgId, userIds, emails) {
    if (!orgId) throw new Error('evictOrganization: orgId is empty');
    if (userIds.length === 0 && emails.length === 0) {
      if (!userIds.length || !userIds) {
        throw new Error('evictOrganization: userIds is empty');
      } else {
        throw new Error('evictOrganization: emails is empty');
      }
    }
    const response = await coreAPI.post(`/functions/evict`, {
      className: 'Organization',
      objectId: orgId,
      userIds: userIds.length === 0 ? undefined : userIds,
      emails: emails.length === 0 ? undefined : emails,
    });
    return response;
  }

  static async findOrgMembers(orgId, options, userIds) {
    if (!orgId) throw new Error('findOrgMembers: orgId is empty');
    return nextAPI.post('/functions/findOrgMembers', {
      where: {
        organization: {
          objectId: orgId,
        },
        userIds,
      },
      options: {
        includeOwner: true,
      },
      ...options,
    });
  }

  static async invite({ orgId, emails, authorityName, orgRoleId }) {
    if (!orgId) throw new Error('invite: orgId is empty');
    if (!emails || emails.length <= 0)
      throw new Error('invite: emails is empty');

    return coreAPI.post('/functions/invite', {
      className: 'Organization',
      objectId: orgId,
      emails,
      authority: authorityName,
      orgRoleId,
    });
  }

  static async updateMemberAuthority({
    orgId,
    userId,
    authorityName,
    orgRoleId,
  }) {
    if (!orgId) throw new Error('updateMemberAuthority: orgId is empty');
    if (!userId) throw new Error('updateMemberAuthority: userId is empty');

    return nextAPI.post('/functions/updateMemberAuthority', {
      where: {
        organization: {
          objectId: orgId,
        },
        user: {
          objectId: userId,
        },
      },
      authority: authorityName,
      orgRoleId,
    });
  }

  static async updateOrganizationInfo(
    orgId,
    needUpdateIcon,
    iconFile,
    orgName,
    propertyType,
    locale,
  ) {
    if (!orgName) throw new Error('updateOrganizationInfo: orgName is empty');
    if (!propertyType)
      throw new Error('updateOrganizationInfo: propertyType is empty');
    let iconUrl = iconFile || '';
    if (needUpdateIcon && iconFile) {
      const { url } = await FileService.upload(iconFile);
      iconUrl = url;
    }

    await coreAPI.post(`/classes/Organization/${orgId}`, {
      icon: iconUrl,
      displayName: orgName,
      industry: propertyType,
      locale,
      _method: 'PUT',
    });

    return {
      displayName: orgName,
      icon: iconUrl,
      industry: propertyType,
    };
  }

  static async deleteOrganization(orgId) {
    if (!orgId) throw new Error('deleteOrganization: orgId is empty');

    const response = await coreAPI.post(`/classes/Organization/${orgId}`, {
      _method: 'DELETE',
    });

    return response;
  }

  static async saveDefaultTemplate(
    orgId,
    templateId,
    templateType,
    name,
    elements,
    expiredAt,
    note,
  ) {
    if (!orgId) throw new Error('saveDefaultTemplate: orgId is Empty');
    if (!templateType)
      throw new Error('saveDefaultTemplate: templateType is Empty');
    if (!name) throw new Error('saveDefaultTemplate: name is Empty');
    if (elements.length <= 0)
      throw new Error('saveDefaultTemplate: elements is Empty');

    let content;
    if (templateType === 'text') {
      content = elements;
      // eslint-disable-next-line no-param-reassign
      elements = undefined;
    } else {
      // eslint-disable-next-line no-param-reassign
      elements = elements.map(element => {
        const temp = { ...element };
        delete temp.id;
        temp.buttons = temp.buttons.map(button => {
          const tempButton = { ...button };
          delete tempButton.id;
          return tempButton;
        });
        return temp;
      });
    }

    const payload = {
      template: {
        className: 'Template',
        templateType,
        name,
        note,
        expiredAt,
        data: {
          elements,
          content,
          templateType,
        },
        organization: {
          __type: 'Pointer',
          className: 'Organization',
          objectId: orgId,
        },
        hidden: false,
        templateId,
        version: 2,
      },
    };

    return coreAPI.post('/functions/publishTemplate', payload);
  }

  static async getDefaultTemplates(orgId, templateType, whereOptions, options) {
    return coreAPI.post('/functions/queryTemplate', {
      where: {
        organization: {
          __type: 'Pointer',
          className: 'Organization',
          objectId: orgId,
        },
        templateType,
        vendor: { $ne: true },
        ...whereOptions,
      },
      skip: 0,
      limit: 20,
      sort: {
        createdAt: -1,
      },
      group: true,
      ...options,
    });
  }

  static async deleteDefaultTemplate(orgId, templateId) {
    return coreAPI.post('/functions/removeTemplate', {
      templateId,
      organization: {
        __type: 'Pointer',
        className: 'Organization',
        objectId: orgId,
      },
    });
  }

  static async updateOrganizationWhitelist(orgId, trustRouteList) {
    if (!orgId) throw new Error('updateOrganizationWhitelist: orgId is empty');
    if (!trustRouteList)
      throw new Error('updateOrganizationWhitelist: trustRouteList is empty');

    await coreAPI.post(`/classes/Organization/${orgId}`, {
      redirectOptions: {
        whitelist: trustRouteList,
      },
      _method: 'PUT',
    });
  }

  static async getOrganization(orgId, userId) {
    if (!orgId) throw new Error('getOrganization: orgId is empty');
    if (!userId) throw new Error('getOrganization: userId is empty');

    const query = {
      where: {
        objectId: orgId,
        members: {
          $inQuery: {
            where: {
              users: {
                __type: 'Pointer',
                className: '_User',
                objectId: userId,
              },
            },
            className: 'Membership',
          },
        },
      },
      include:
        'conversation.lastMessage,conversation,members,owner,perm,accounting,owner',
      keys:
        'locale,features,redirectOptions,accounting,industry,displayName,icon,members,owner,conversation,owner.picture,owner.firstName,owner.lastName,accounting.message,accounting.customer,perm,status,_acl,_wperm,_rperm',
      order: 'displayName',
      _method: 'GET',
    };

    const { results } = await coreAPI.post('/classes/Organization', query);
    return (results || [])[0] || {};
  }

  static async getOneOrganization(orgId) {
    if (!orgId) throw new Error('getOrganization: orgId is empty');

    const query = {
      where: {
        objectId: orgId,
      },
      include:
        'conversation.lastMessage,conversation,members,owner,perm,accounting,owner',
      keys:
        'locale,features,redirectOptions,accounting,industry,displayName,icon,members,owner,conversation,owner.picture,owner.firstName,owner.lastName,accounting.message,accounting.customer,perm,status,_acl,_wperm,_rperm',
      order: 'displayName',
      _method: 'GET',
    };

    const { results } = await coreAPI.post('/classes/Organization', query);
    return (results || [])[0];
  }

  static async updateUserSetting({ orgId, settings }) {
    const response = await nextAPI.put('/user_settings/upsert', {
      orgId,
      settings,
    });

    return response;
  }

  static async unsetUserSetting({ orgId, settings }) {
    const response = await nextAPI.put('/user_settings/unset', {
      orgId,
      settings,
    });

    return response;
  }

  static async getUserSetting({ orgId }) {
    const response = await nextAPI.get(`/user_settings/query?orgId=${orgId}`);

    return response;
  }

  static async IntegrationLineLogin({ channelId, channelSecret, orgId }) {
    const response = await nextAPI.post(`/integration/line/login`, {
      channelId,
      channelSecret,
      orgId,
    });
    return response;
  }
}
