import { useState } from 'react';
import classNames from 'classnames';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import { color } from '../../themes';

const useStyles = makeStyles(
  theme => ({
    body2: {
      ...theme.typography.body2,
      marginRight: '8px',
      color: color.$10,
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '22px',
      alignItems: 'center',
      ...theme.input.default.wrapper,
    },
    root: {
      overflowX: 'auto',
      overflowY: 'hidden',
      display: 'flex',
      alignItems: 'center',
      height: '42px',
      borderRadius: '22px',
    },
    multiline: {
      overflowX: 'hidden',
      overflowY: 'hidden',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '22px',
      padding: '0px',
    },
    input: {
      color: color.$3,
      WebkitTextFillColor: color.$3,
      padding: '12px 24px',
      '&::placeholder': {
        color: color.$10,
        WebkitTextFillColor: color.$10,
        lineHeight: '25px',
        fontWeight: 'normal',
        opacity: 1,
      },
    },
    urlInput: {
      ...theme.input.default.input,
      padding: '12px 0px 12px 16px',
      lineHeight: '21px',
      '&::placeholder': {
        transform: 'translateY(1%)',
      },
    },
    pathInput: {
      ...theme.input.default.input,
      padding: '12px 16px',
      lineHeight: '21px',
      '&::placeholder': {
        transform: 'translateY(1%)',
      },
    },
    cardInput: {
      ...theme.input.default.input,
      padding: '12px 16px 11px',
      lineHeight: '21px',
      '&::placeholder': {
        transform: 'translateY(1%)',
      },
    },
    defaultRoot: {
      ...theme.input.default.root,
    },
    defaultInput: {
      ...theme.input.default.input,
    },
    disableInput: {
      ...theme.input.default.input,
      color: '#2a394d',
      WebkitTextFillColor: '#2a394d',
    },
    textFieldCardInput: {
      ...theme.input.default.input,
      padding: '12px 16px',
    },
    error: {
      border: `1px solid ${color.$17}`,
    },
    disabledInputWrapper: {
      border: 'solid 1px #e0e0e0',
    },
  }),
  {
    name: 'Input',
  },
);

const Input = ({
  className,
  style,
  InputProps,
  topic,
  onChange,
  inputRef,
  multiline,
  disabled,
  enableUnderline,
  valid,
  placeholder,
  select,
  min,
  MenuItems,
  selectprops,
  isError,
  wordCount,
  limit,
  inputProps,
  error,
  ...props
}) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);
  const [inputValid, setInputValid] = useState(undefined);
  const root = !multiline ? classes.root : classes.multiline;

  return (
    <MUITextField
      className={classNames(
        [
          className,
          classes.inputWrapper,
          ...(inputValid !== undefined ? [valid ? 'valid' : 'invalid'] : []),
          ...(error !== undefined
            ? [
                error
                  ? 'invalid'
                  : inputValid !== undefined
                  ? 'valid'
                  : undefined,
              ]
            : []),
        ],
        {
          focus: isFocus,
          [classes.disabledInputWrapper]: disabled,
          [classes.error]: isError,
        },
      )}
      style={style}
      disabled={disabled}
      multiline={multiline}
      select={select}
      fullWidth
      inputRef={inputRef}
      selectprops={selectprops}
      InputProps={{
        disableUnderline: !enableUnderline,
        placeholder,
        classes: {
          root: classNames(root, classes[`${topic}Root`]),
          input: classNames([
            classes.input,
            classes[`${topic}Input`],
            disabled && classes.disableInput,
          ]),
        },
        onChange,
        inputProps: {
          min,
          onFocus: () => {
            if (InputProps) {
              if (InputProps.onFocus) {
                InputProps.onFocus();
              }
            }

            setIsFocus(true);
            if (valid !== undefined) {
              setInputValid(valid ? 'valid' : 'invalid');
            } else {
              setIsFocus(false);
            }
          },
          onBlur: e => {
            if (InputProps) {
              if (InputProps.onBlur) {
                InputProps.onBlur(e);
              }
            }
            setIsFocus(false);
            setInputValid(undefined);
          },
          ...inputProps,
        },
        endAdornment: limit && (
          <InputAdornment className={classes.body2} position="end">
            <span>{`${wordCount}/${limit}`}</span>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...props}
    >
      {MenuItems &&
        MenuItems.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          );
        })}
    </MUITextField>
  );
};

Input.defaultProps = {
  enableUnderline: false,
  wordCount: 0,
};

export default Input;
