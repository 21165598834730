import moment from 'moment';
import axios from 'axios';
import {
  UPDATE_CUSTOMER_GROUPS_STATE,
  UPDATE_CUSTOMER_GROUPS_REQUEST,
  UPDATE_CUSTOMER_GROUPS_SUCCESS,
  CUSTOMER_GROUPS_GET_LIST_REQUEST,
  CUSTOMER_GROUPS_GET_LIST_SUCCESS,
  CUSTOMER_GROUPS_GET_LIST_CONTINUE_SUCCESS,
  CUSTOMER_GROUPS_GET_LIST_FAILURE,
  CREATE_CUSTOMER_GROUPS_REQUEST,
  CREATE_CUSTOMER_GROUPS_SUCCESS,
  CREATE_CUSTOMER_GROUPS_FAILURE,
  RESET_CUSTOMER_GROUPS,
  CUSTOMER_GROUPS_UPDATE_GROUP_NAME_REQUEST,
  CUSTOMER_GROUPS_UPDATE_GROUP_NAME_SUCCESS,
  DELETE_CUSTOMER_GROUPS_REQUEST,
  DELETE_CUSTOMER_GROUPS_SUCCESS,
  CUSTOMER_GROUPS_GET_GROUP_DATA_SUCCESS,
  CUSTOMER_GROUPS_GET_CUTOMERS_REQUEST,
  CUSTOMER_GROUPS_GET_CUTOMERS_FAILURE,
  CUSTOMER_GROUPS_GET_CUTOMERS_SUCCESS,
} from './type';
import CustomerGroupService from '../services/CustomerGroupService';
import SystemTaskService from '../services/SystemTaskService';
import InspectAddOnService from '../services/InspectAddOnService';
import { enqueueSnackbar } from './snackbarAction';
import { StatusType, SnackBarType, FetchStatus, VendorStore } from '../types';
import exportData from '../common/exportData';

const { CancelToken } = axios;
let source = null;
/**
 * @param {string} customerGroupId - required
 * @param {string} name - required
 */
export const updateCustomerGroupName = (
  customerGroupId,
  name,
) => async dispatch => {
  dispatch({
    type: CUSTOMER_GROUPS_UPDATE_GROUP_NAME_REQUEST,
  });

  try {
    await CustomerGroupService.updateCustomerGroup(customerGroupId, { name });
    dispatch({
      type: CUSTOMER_GROUPS_UPDATE_GROUP_NAME_SUCCESS,
      customerGroupId,
      name,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const updateCustomerGroupState = state => {
  return {
    type: UPDATE_CUSTOMER_GROUPS_STATE,
    updateState: { ...state },
  };
};
/**
 * @param {string} orgId
 * @param {string} name
 */
export const createCustomerGroup = (orgId, name) => async (
  dispatch,
  getState,
) => {
  const query = { ...getState().allCustomers.query };
  const options = { ...getState().allCustomers.options };
  query.orgId = orgId;

  dispatch({ type: CREATE_CUSTOMER_GROUPS_REQUEST });

  try {
    await CustomerGroupService.createCustomerGroup(name, query, options);
    dispatch({ type: CREATE_CUSTOMER_GROUPS_SUCCESS });
  } catch (error) {
    dispatch({ type: CREATE_CUSTOMER_GROUPS_FAILURE });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

let haveNextCustomerGroup = false;

export const getCustomerGroupListInit = (orgId, options) => async (
  dispatch,
  getState,
) => {
  const { options: preOptions } = getState().customerGroups;

  const newOption = {
    ...preOptions,
    ...options,
  };

  dispatch({
    type: UPDATE_CUSTOMER_GROUPS_STATE,
    updateState: {
      options: newOption,
    },
  });

  try {
    dispatch({
      type: CUSTOMER_GROUPS_GET_LIST_REQUEST,
      status: StatusType.INITIAL_QUERYING,
    });

    if (source) {
      source.cancel('getCustomerGroupsList canceled');
    }
    source = CancelToken.source();

    const result = await CustomerGroupService.getList(
      orgId,
      {
        ...newOption,
        count: true,
      },
      null,
      {
        cancelToken: source.token,
      },
    );

    haveNextCustomerGroup =
      (result?.results || []).length === (newOption?.limit || 40);

    dispatch({
      type: UPDATE_CUSTOMER_GROUPS_STATE,
      updateState: {
        count: result.count,
      },
    });

    dispatch({
      type: CUSTOMER_GROUPS_GET_LIST_SUCCESS,
      status: StatusType.INITIAL_QUERY_FINISHED,
      list: result.results.map(data => ({
        ...data,
        updatedAt: moment(data.updatedAt),
      })),
    });
  } catch (error) {
    if (error.message !== 'getCustomerGroupsList canceled') {
      dispatch({ type: CUSTOMER_GROUPS_GET_LIST_FAILURE });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error.message,
        }),
      );
    }
  }
};

export const getCustomerGroupListContinue = (orgId, options) => async (
  dispatch,
  getState,
) => {
  const { options: preOptions, list: preList } = getState().customerGroups;

  const newOption = {
    ...preOptions,
    ...options,
  };

  dispatch({
    type: UPDATE_CUSTOMER_GROUPS_STATE,
    updateState: {
      options: newOption,
    },
  });

  try {
    if (haveNextCustomerGroup) {
      const nowListLength = preList.length;

      dispatch({
        type: CUSTOMER_GROUPS_GET_LIST_REQUEST,
        status: StatusType.QUERYING,
      });

      const result = await CustomerGroupService.getList(orgId, {
        ...newOption,
        skip: nowListLength,
        limit: 20,
      });

      haveNextCustomerGroup = (result?.results || []).length === 20;

      dispatch({
        type: CUSTOMER_GROUPS_GET_LIST_CONTINUE_SUCCESS,
        status: StatusType.QUERY_FINISHED,
        list: result.results.map(data => ({
          ...data,
          updatedAt: moment(data.updatedAt),
        })),
      });
    }
  } catch (error) {
    dispatch({ type: CUSTOMER_GROUPS_GET_LIST_FAILURE });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const getCustomerGroup = (groupId, options) => async dispatch => {
  try {
    const data = await CustomerGroupService.getCustomerGroup(groupId, options);

    dispatch({
      type: UPDATE_CUSTOMER_GROUPS_STATE,
      updateState: {
        count: data?.count || 0,
      },
    });

    dispatch({ type: CUSTOMER_GROUPS_GET_GROUP_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const deleteCustomerGroup = groupId => async dispatch => {
  dispatch({ type: DELETE_CUSTOMER_GROUPS_REQUEST });
  try {
    await CustomerGroupService.updateCustomerGroup(groupId, { expired: true });
    dispatch({ type: DELETE_CUSTOMER_GROUPS_SUCCESS });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export function resetCustomerGroup() {
  return {
    type: RESET_CUSTOMER_GROUPS,
  };
}

export const exportCustomerGroup = (orgId, groupId) => async (
  dispatch,
  getState,
) => {
  try {
    const { displayName } = getState()?.organization?.selectOrg;
    const i18nData = exportData.customerGroup(displayName);

    await SystemTaskService.exportCustomerGroupById(orgId, groupId, i18nData);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const updateCustomerGroup = (orgId, groupId) => async dispatch => {
  dispatch({ type: UPDATE_CUSTOMER_GROUPS_REQUEST });
  try {
    await CustomerGroupService.requeryCustomerGroup(orgId, groupId);
    dispatch({ type: UPDATE_CUSTOMER_GROUPS_SUCCESS });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.message,
      }),
    );
  }
};

export const setCustomerGroup = (groupId, setData) => (dispatch, getState) => {
  const newList = getState().customerGroups.list.map(data => {
    if (data.objectId === groupId) {
      return { ...data, ...setData };
    }
    return data;
  });

  dispatch({
    type: UPDATE_CUSTOMER_GROUPS_STATE,
    updateState: {
      list: newList,
    },
  });
};

export const getCustomers = (groupId, isInit) => async (dispatch, getState) => {
  const { customers, count } = getState().customerGroups;
  try {
    if (isInit || customers.length < count) {
      dispatch({ type: CUSTOMER_GROUPS_GET_CUTOMERS_REQUEST });

      const response = await CustomerGroupService.getCustomers(
        groupId,
        isInit ? 0 : customers.length,
        isInit ? 40 : 20,
      );

      const resultCustomers = response.result.customers.map(customer => {
        return {
          objectId: customer?.objectId,
          displayName: customer?.displayName,
          originalDisplayName:
            customer?.originalDisplayName || customer?.displayName,
          picture: customer?.picture,
          platform: customer?.platform,
          gender: customer?.gender,
          tagDensity: customer?.tagDensity,
          email: customer?.email,
          joinedAt: customer?.joinedAt,
          cellPhone: customer?.cellPhone,
          conversationId: customer?.conversation?.objectId,
          lastMessageAt: customer?.lastMessageAt,
          friendship: customer?.friendship,
        };
      });
      dispatch({
        type: CUSTOMER_GROUPS_GET_CUTOMERS_SUCCESS,
        isContinue: !isInit,
        customers: resultCustomers,
      });
      setTimeout(() => {
        dispatch({
          type: UPDATE_CUSTOMER_GROUPS_STATE,
          updateState: {
            status: FetchStatus.DONE,
          },
        });
      }, 200);
    }
  } catch (error) {
    dispatch({ type: CUSTOMER_GROUPS_GET_CUTOMERS_FAILURE });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message:
          error.response && error.response.data
            ? error.response.data.error
            : error.message,
      }),
    );
  }
};

export const getInspectAddonResource = orgId => async dispatch => {
  try {
    const response = await InspectAddOnService.getResource(
      orgId,
      VendorStore.SUPER8,
    );

    dispatch({
      type: UPDATE_CUSTOMER_GROUPS_STATE,
      updateState: {
        resource: response || {},
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.response?.data?.error || error.message);

    dispatch({
      type: UPDATE_CUSTOMER_GROUPS_STATE,
      updateState: {
        resource: {},
      },
    });
  }
};

export const clearInspectAddonResource = () => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CUSTOMER_GROUPS_STATE,
      updateState: {
        resource: null,
      },
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message,
      }),
    );
  }
};
