import { useState } from 'react';
import hexRgba from 'hex-rgba';
import { color } from '../../../themes';

const IconSetting = ({ iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill={isHover ? hexRgba(iconColor, 40) : iconColor} fillRule="evenodd">
        <path
          id="a"
          d="M12 9c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm0 4.5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm6.225 1.05c.075-.15.225-.3.525-.3 1.275 0 2.25-.975 2.25-2.25s-.975-2.25-2.25-2.25h-.15c-.15 0-.3-.075-.375-.225 0-.075 0-.075-.075-.15-.075-.15-.075-.375.15-.6.9-.9.9-2.325 0-3.15-.45-.45-.975-.675-1.575-.675s-1.2.225-1.65.675a.462.462 0 01-.525.075c-.15 0-.3-.225-.3-.45C14.25 3.975 13.275 3 12 3s-2.25.975-2.25 2.25v.15c0 .15-.075.3-.225.375-.075 0-.075 0-.15.075-.15.075-.375 0-.6-.15-.9-.9-2.325-.9-3.15 0-.9.9-.9 2.325.075 3.225.15.15.15.375.075.6-.075.15-.3.3-.525.3-1.275 0-2.25.975-2.25 2.25s.975 2.25 2.25 2.25h.15c.225 0 .375.15.45.3.075.15.075.375-.15.6-.45.45-.675.975-.675 1.575s.225 1.125.675 1.575c.9.9 2.325.9 3.225-.075.15-.15.375-.15.6-.075.225.075.3.225.3.525 0 1.275.975 2.25 2.25 2.25s2.25-.975 2.25-2.25v-.15c0-.225.15-.375.3-.45.15-.075.375-.075.6.15.9.9 2.325.9 3.15 0 .9-.9.9-2.325-.075-3.225-.075-.15-.15-.375-.075-.525zm-1.35-.6c-.3.75-.15 1.575.45 2.25.15.15.225.3.225.525a.68.68 0 01-.225.525.68.68 0 01-.525.225c-.225 0-.375-.075-.6-.3-.6-.6-1.425-.75-2.175-.375-.75.3-1.2 1.05-1.2 1.8v.15c0 .45-.3.75-.75.75s-.75-.3-.75-.75v-.075c0-.825-.525-1.5-1.275-1.8-.225-.15-.525-.15-.825-.15-.525 0-1.05.225-1.425.6-.3.3-.75.3-1.05 0a.68.68 0 01-.225-.525c0-.225.075-.375.3-.6.6-.6.75-1.425.375-2.175-.3-.75-1.05-1.2-1.8-1.2h-.15c-.45 0-.75-.3-.75-.75s.3-.75.75-.75h.075c.825 0 1.5-.525 1.8-1.275.3-.75.15-1.575-.45-2.25-.3-.3-.3-.75 0-1.05.3-.3.75-.3 1.125.075.525.525 1.35.675 2.025.45.075 0 .15 0 .225-.075.75-.3 1.2-1.05 1.2-1.8v-.15c0-.45.3-.75.75-.75s.75.3.75.825c0 .825.45 1.5 1.2 1.8.75.3 1.575.15 2.25-.45a.68.68 0 01.525-.225.68.68 0 01.525.225c.3.3.3.75-.075 1.125-.525.525-.675 1.35-.45 2.025 0 .075 0 .15.075.225.3.75 1.05 1.2 1.8 1.2h.15c.45 0 .75.3.75.75s-.3.75-.825.75c-.75 0-1.5.45-1.8 1.2z"
        />
      </g>
    </svg>
  );
};

IconSetting.defaultProps = {
  iconColor: color.$12,
};

export default IconSetting;
