import { useState } from 'react';
import { color as themeColor } from '../../../themes';

const IconCopy20 = ({
  onClick,
  className,
  id,
  hoverColor = themeColor.$1,
  color = themeColor.$10,
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="copy"
          d="M15.034 7.637a2.466 2.466 0 0 1 2.466 2.466v4.931a2.466 2.466 0 0 1-2.466 2.466h-4.931a2.466 2.466 0 0 1-2.466-2.466v-4.931a2.466 2.466 0 0 1 2.466-2.466h4.931zm0 1.644h-4.931a.822.822 0 0 0-.822.822v4.931c0 .454.368.822.822.822h4.931a.822.822 0 0 0 .822-.822v-4.931a.822.822 0 0 0-.822-.822zM10.08 2.5a2.283 2.283 0 0 1 2.283 2.283v.842a.822.822 0 1 1-1.644 0v-.842a.64.64 0 0 0-.64-.64H4.784a.64.64 0 0 0-.64.64v5.297c0 .353.287.64.64.64h.811a.822.822 0 1 1 0 1.643h-.81A2.283 2.283 0 0 1 2.5 10.08V4.783A2.283 2.283 0 0 1 4.783 2.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <mask id={`copyMask${id}`} fill="#fff">
          <use xlinkHref="#copy" />
        </mask>
        <g fill={isHover ? hoverColor : color} mask={`url(#copyMask${id})`}>
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  );
};

export default IconCopy20;
