import { useState } from 'react';
import { color } from '../../../themes';

const IconEdit20 = ({ onClick, className, id }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="edit"
          d="M16.544 16.43a.785.785 0 1 1 0 1.57H3.785a.785.785 0 0 1 0-1.57zm-4.14-13.2l2.777 2.777a.785.785 0 0 1 0 1.11l-7.569 7.569a.785.785 0 0 1-.537.23l-2.843.067a.785.785 0 0 1-.804-.804l.067-2.844a.785.785 0 0 1 .23-.536l7.569-7.569a.785.785 0 0 1 1.11 0zm-.555 1.666l-6.791 6.79-.04 1.707 1.706-.04 6.79-6.791-1.665-1.666z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-193 -8)">
        <rect
          width="221"
          height="36"
          fill="transparent"
          fillOpacity=".2"
          rx="4"
        />
        <g transform="translate(193 8)">
          <mask id={`editMask${id}`} fill="#fff">
            <use xlinkHref="#edit" />
          </mask>
          <g fill={isHover ? color.$1 : color.$10} mask={`url(#editMask${id})`}>
            <path d="M0 0h20v20H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconEdit20;
