import hexRgba from 'hex-rgba';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router';
import ImageDisplay from '../shared/ImageDisplay';
import imgSrc from '../../assets/iconSrc';
import { color } from '../../themes';
import { validateAddOrganization } from '../../actions/organizationAction';
import { openModal } from '../../actions/modalAction';
import { ModalType } from '../../types';
import config from '../../config';

const useStyles = makeStyles(
  () => ({
    listitem: {
      padding: '0',
      height: '56px',
      cursor: 'pointer',
      opacity: 0.6,
      '&:hover': {
        backgroundColor: hexRgba(color.$7, 10),
        opacity: 1,
      },
    },
    'listitem--hidden': {
      display: 'none',
    },
    'org-icon': { borderRadius: '50%', marginLeft: '22px' },
    'modal-content': {
      margin: 0,
    },
  }),
  {
    name: 'AddOrganizationListItem',
  },
);

function AddOrganizationListItem({ show, displayMenu, textStyle }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function handleClick() {
    if (await dispatch(validateAddOrganization())) {
      navigate('/add-organization');
    } else {
      dispatch(
        openModal(ModalType.DIALOG_TEXT, {
          title: t('navigation.modal.out-of-quota.title'),
          content: t('navigation.modal.out-of-quota.content'),
          onSubmit: () => {
            window.open(
              `mailto:${config.ADDON_UNAVAIABLE_MAIL}?subject=${t(
                'superMarket:emailSubject',
                {
                  featureName: t('navigation.modal.out-of-quota.head'),
                },
              )}&body=${encodeURIComponent(
                t('superMarket:emailBody', {
                  featureName: t('navigation.modal.out-of-quota.head'),
                  orgId: '',
                  displayName: '',
                }),
              )}`,
            );
          },
          cancelText: t('navigation.modal.out-of-quota.cancelText'),
          submitText: t('navigation.modal.out-of-quota.submitText'),
        }),
      );
    }
  }

  return (
    <ListItem
      className={classnames(classes.listitem, {
        [classes['listitem--hidden']]: !(show ? displayMenu : true),
      })}
      onClick={handleClick}
    >
      <ImageDisplay
        className={classes['org-icon']}
        src={imgSrc.addOrg}
        width={36}
        height={36}
      />
      <Typography variant="subtitle1" className={textStyle}>
        {t('navigation.createOrganization')}
      </Typography>
    </ListItem>
  );
}

AddOrganizationListItem.propTypes = {
  show: PropTypes.bool.isRequired,
  displayMenu: PropTypes.bool.isRequired,
  textStyle: PropTypes.string.isRequired,
};

export default AddOrganizationListItem;
