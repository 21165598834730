import coreAPI, { nextAPI } from '../common/axios';
import { CustomerGroupStatusType } from '../types';
import { transformCustomerGroupQuery } from './ServiceUtility';

export default class CustomerGroupService {
  static async getList(orgId, options = {}, where = {}, axiosOption) {
    if (!orgId) {
      throw new Error('orgId is empty');
    }

    const payload = {
      ...options,
      _method: 'GET',
      where: {
        ...options.where,
        organization: {
          className: 'Organization',
          __type: 'Pointer',
          objectId: orgId,
        },
        expired: { $ne: true },
        status: { $ne: CustomerGroupStatusType.CREATING },
        ...where,
      },
      limit: options?.limit || 40,
      skip: options?.skip || 0,
      order: '-updatedAt',
    };

    const response = await coreAPI.post(
      `/classes/CustomerGroup`,
      payload,
      axiosOption,
    );
    return response;
  }

  static async createCustomerGroup(name, query, options) {
    if (!name) {
      throw new Error('name is empty');
    }

    if (!query) {
      throw new Error('query is empty');
    }

    if (!options) {
      throw new Error('options is empty');
    }

    const response = await coreAPI.post(`/classes/CustomerGroup`, {
      _method: 'POST',
      name,
      query: transformCustomerGroupQuery(query),
      options,
    });

    return response.result;
  }

  static async deleteCustomerGroup(groudId) {
    if (!groudId) {
      throw new Error('groupId');
    }
    const response = await coreAPI.post(`/classes/CustomerGroup/${groudId}`, {
      _method: 'DELETE',
    });

    return response.result;
  }

  /**
   * @param {string} orgId - required
   * @param {{name: string}} options - required
   */
  static async updateCustomerGroup(groupId, options) {
    if (!groupId) {
      throw new Error('groupId is empty');
    }

    if (!options) {
      throw new Error('options is empty');
    }

    if (!options.name && !options.expired) {
      if (!options.name) throw new Error('options.name is empty');
      throw new Error('options.expired is empty');
    }

    const response = await coreAPI.post(`/classes/CustomerGroup/${groupId}`, {
      _method: 'PUT',
      ...options,
    });

    return response.result;
  }

  static async requeryCustomerGroup(orgId, groupId) {
    if (!orgId) {
      throw new Error('groupId is empty');
    }

    if (!groupId) {
      throw new Error('groupId is empty');
    }

    const response = await nextAPI.put('/customer_group/refresh', {
      orgId,
      groupId,
    });

    return response.result;
  }

  static async getCustomerGroup(groupId, options = {}) {
    const response = await coreAPI.post(`/classes/CustomerGroup/${groupId}`, {
      _method: 'GET',
      ...options,
    });
    return response;
  }

  static async getCustomers(
    groupId,
    skip = 0,
    limit = 40,
    returnCount = false,
  ) {
    const response = await coreAPI.post(
      `/functions/getCustomersByCustomerGroup`,
      {
        where: {
          customerGroupId: groupId,
        },
        skip,
        limit,
        returnCount,
        select: ['lastMessageAt', 'friendship', 'conversation'],
        sliceTagsCount: 12,
      },
    );
    return response;
  }

  static async importCustomerGroup({ title, type, fileUrl, orgId }) {
    const response = await nextAPI.post('/customer_group/import/csv', {
      title,
      type,
      fileUrl,
      orgId,
    });

    return response;
  }
}
