import axios from 'axios';
import JwtDecode from 'jwt-decode';
import { ORGANIZATION_RESET } from '../actions/type';
import config from '../config';
import reduxStore from '../store';

const axiosInstance = axios.create({
  baseURL: config.PARSE_SERVER_URL,
  headers: {
    'Content-Type': 'text/plain',
  },
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

axiosInstance.interceptors.response.use(
  response => response.data,
  error => {
    if (error?.response?.data?.error === 'invalid session token') {
      localStorage.removeItem(config.LOCAL_STORAGE);
      reduxStore.dispatch({ type: ORGANIZATION_RESET });
      window.location.replace('/login');
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(
  request => {
    if (request.headers['Content-Type'] === 'text/plain') {
      const currentUser = localStorage.getItem(config.LOCAL_STORAGE);
      const token = currentUser
        ? { _SessionToken: JSON.parse(currentUser).sessionToken }
        : null;
      request.data = {
        ...request.data,
        _ApplicationId: config.PARSE_APP_ID,
        _JavaScriptKey: config.PARSE_JAVASCRIPT_KEY,
        ...token,
        sessionToken:
          request?.hasSessionToken && currentUser
            ? JSON.parse(currentUser).sessionToken
            : undefined,
      };
    }
    return request;
  },
  error => Promise.reject(error),
);

export const pureAxios = axios.create({
  baseURL: config.PARSE_SERVER_URL,
  headers: {
    'Content-Type': 'text/plain',
  },
});

pureAxios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.data?.error === 'invalid session token') {
      localStorage.removeItem(config.LOCAL_STORAGE);
      reduxStore.dispatch({ type: ORGANIZATION_RESET });
      window.location.replace('/login');
    }
    return Promise.reject(error);
  },
);

pureAxios.interceptors.request.use(
  request => {
    if (request.headers['Content-Type'] === 'text/plain') {
      const currentUser = localStorage.getItem(config.LOCAL_STORAGE);
      const token = currentUser
        ? { _SessionToken: JSON.parse(currentUser).sessionToken }
        : null;
      request.data = {
        ...request.data,
        _ApplicationId: config.PARSE_APP_ID,
        _JavaScriptKey: config.PARSE_JAVASCRIPT_KEY,
        ...token,
      };
    }
    return request;
  },
  error => Promise.reject(error),
);

export const serverApiAxios = axios.create({
  baseURL: config.PARSE_SERVER_URL,
  headers: {
    'X-Parse-Application-Id': config.PARSE_APP_ID,
    'X-Parse-JavaScript-Key': config.PARSE_JAVASCRIPT_KEY,
  },
});

serverApiAxios.interceptors.request.use(axiosConfig => {
  const cfg = axiosConfig;
  const { pathname } = window.location;
  const pathRoutes = pathname.split('/');
  const orgId =
    axiosConfig?.orgId ||
    (pathRoutes.length >= 4 && pathRoutes[2]) ||
    localStorage.getItem(config.SELECTED_ORG_ID);

  if (orgId) cfg.headers['x-super8-organization'] = axiosConfig.orgId || orgId;

  cfg.headers['X-Parse-Session-Token'] = JSON.parse(
    localStorage.getItem(config.LOCAL_STORAGE),
  ).sessionToken;
  cfg.headers['x-custom-lang'] = JSON.parse(
    localStorage.getItem(config.LOCAL_STORAGE),
  ).language;
  return cfg;
});

serverApiAxios.interceptors.response.use(
  response => response.data,
  error => {
    const errorMessage = error.response
      ? error.response.data.error
        ? error.response.data.error.message
        : error.response.data.message
      : error.message;
    if (errorMessage === 'error/resourceInsufficient') {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(errorMessage);
  },
);

export const couponApi = axios.create({
  baseURL: config.PARSE_SERVER_URL,
  headers: {
    'X-Parse-Application-Id': config.PARSE_APP_ID,
    'X-Parse-JavaScript-Key': config.PARSE_JAVASCRIPT_KEY,
  },
});

couponApi.interceptors.request.use(axiosConfig => {
  const cfg = axiosConfig;
  const { pathname } = window.location;
  const pathRoutes = pathname.split('/');
  const orgId =
    axiosConfig?.orgId ||
    (pathRoutes.length >= 4 && pathRoutes[2]) ||
    localStorage.getItem(config.SELECTED_ORG_ID);

  if (orgId) cfg.headers['x-super8-organization'] = axiosConfig.orgId || orgId;

  cfg.headers['X-Parse-Session-Token'] = JSON.parse(
    localStorage.getItem(config.LOCAL_STORAGE),
  ).sessionToken;
  cfg.headers['x-custom-lang'] = JSON.parse(
    localStorage.getItem(config.LOCAL_STORAGE),
  ).language;
  return cfg;
});

couponApi.interceptors.response.use(
  response => {
    const { success } = response.data;
    if (success) {
      return response.data;
    }

    return Promise.reject(response.data);
  },
  error => {
    const errorMessage = error.response
      ? error.response.data.error
        ? error.response.data.error.message
        : error.response.data.message
      : error.message;
    return Promise.reject(errorMessage);
  },
);

export const elandAPI = axios.create({
  baseURL: `${config.PARSE_SERVER_URL}/report/v1`,
  headers: {
    'X-Parse-Application-Id': config.PARSE_APP_ID,
    'X-Parse-JavaScript-Key': config.PARSE_JAVASCRIPT_KEY,
  },
});

elandAPI.interceptors.request.use(axiosConfig => {
  const tempConfig = { ...axiosConfig };
  const { pathname } = window.location;
  const pathRoutes = pathname.split('/');
  const orgId =
    axiosConfig?.orgId ||
    (pathRoutes.length >= 4 && pathRoutes[2]) ||
    localStorage.getItem(config.SELECTED_ORG_ID);

  if (orgId)
    tempConfig.headers['x-super8-organization'] = axiosConfig.orgId || orgId;

  const currentUser = localStorage.getItem(config.LOCAL_STORAGE);

  tempConfig.headers['X-Parse-Session-Token'] = JSON.parse(
    currentUser,
  ).sessionToken;

  return tempConfig;
});

elandAPI.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);

export const richMenuAPI = axios.create({
  baseURL: config.RICH_MENU_API_URL,
});

function configFilter(cfg) {
  const tempCfg = { ...cfg };
  if (
    cfg.method === 'put' &&
    (/^\/api\/v1\/rich-menu-group\/?.*/.test(cfg.url) ||
      /^\/api\/v1\/liff-setting\/?.*/.test(cfg.url))
  ) {
    delete tempCfg.data.orgId;
  }

  return tempCfg;
}

richMenuAPI.interceptors.request.use(async axiosConfig => {
  const cfg = axiosConfig;
  const currentUser = JSON.parse(
    localStorage.getItem(config.LOCAL_STORAGE) || '{}',
  );

  if (!axiosConfig.data.orgId) {
    throw new Error('Need orgId');
  }

  cfg.headers = {
    ...cfg.headers,
    _SessionToken: currentUser.sessionToken,
    orgId: axiosConfig.data.orgId,
  };

  return configFilter(cfg);
});

richMenuAPI.interceptors.response.use(
  response => response.data,
  error => {
    const errorMessage = error.response
      ? error.response.data.error
        ? error.response.data.error.message
        : error.response.data.message
      : error.message;
    return Promise.reject(errorMessage);
  },
);

export default axiosInstance;

export const partnerTagAPI = axios.create({
  baseURL: config.PARTNER_TAG_SERVER_URL,
});

partnerTagAPI.interceptors.response.use(
  response => response.data,
  error => {
    if (error?.response?.data?.error === 'invalid session token') {
      localStorage.removeItem(config.LOCAL_STORAGE);
      reduxStore.dispatch({ type: ORGANIZATION_RESET });
      window.location.replace('/login');
    }
    return Promise.reject(error);
  },
);

export const gameAPI = axios.create({
  baseURL: config.GAME_API_URL,
});

gameAPI.interceptors.request.use(async axiosConfig => {
  const cfg = { ...axiosConfig };

  delete cfg.orgId;

  let accessToken = localStorage.getItem('gameAccessToken');

  if (!axiosConfig.orgId) {
    throw new Error('Need orgId');
  }

  if (accessToken) {
    const decoded = JwtDecode(accessToken);
    if (
      new Date().getTime() < decoded.exp * 1000 &&
      decoded.id === axiosConfig.orgId
    ) {
      cfg.headers.authorization = `Bearer ${accessToken}`;

      return cfg;
    }
  }

  try {
    const response = await axios.get(
      `${config.GAME_API_URL}/user/accessToken/${axiosConfig.orgId}`,
    );

    accessToken = response.data.accessToken;
    cfg.headers.authorization = `Bearer ${accessToken}`;
    localStorage.setItem('gameAccessToken', accessToken);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return cfg;
});

gameAPI.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);

export const fileUploadInstance = axios.create();

fileUploadInstance.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);

export const maAPI = axios.create({
  baseURL: config.MA_SERVER_URL,
});

maAPI.CancelToken = axios.CancelToken;
maAPI.isCancel = axios.isCancel;

maAPI.interceptors.response.use(
  response => response.data,
  error => {
    if (error?.response?.data?.error === 'invalid session token') {
      localStorage.removeItem(config.LOCAL_STORAGE);
      reduxStore.dispatch({ type: ORGANIZATION_RESET });
      window.location.replace('/login');
    }
    return Promise.reject(error);
  },
);

maAPI.interceptors.request.use(
  request => {
    const currentUser = localStorage.getItem(config.LOCAL_STORAGE);
    const token = currentUser
      ? { _SessionToken: JSON.parse(currentUser).sessionToken }
      : null;

    request.headers = { ...request.headers, ...token };
    return request;
  },
  error => Promise.reject(error),
);

export const nextAPI = axios.create({
  baseURL: config.NEXT_API_URL,
});

nextAPI.CancelToken = axios.CancelToken;
nextAPI.isCancel = axios.isCancel;

nextAPI.interceptors.request.use(axiosConfig => {
  const currentUserObjString = localStorage.getItem(config.LOCAL_STORAGE);
  const currentUser = JSON.parse(currentUserObjString);
  const sessionToken = currentUser?.sessionToken;

  // eslint-disable-next-line no-param-reassign
  axiosConfig.headers = {
    _SessionToken: sessionToken,
    ...axiosConfig.headers,
  };

  return axiosConfig;
});

nextAPI.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);

export const hubSpotAPI = axios.create({
  baseURL: config.HUB_SPOT_URL,
});
