import PropTypes from 'prop-types';
import classnames from 'classnames';
import hexRgba from 'hex-rgba';
import { makeStyles } from '@material-ui/core/styles';
import MUIModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { color } from '../../themes';

const useStyles = makeStyles(
  theme => ({
    modalStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '9999 !important',
      overflowY: 'auto',
    },
    paper: {
      position: 'absolute',
      width: 734,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '12px',
      outline: 'none',
    },
    title: {
      ...theme.typography.display2,
      height: 68,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionTitle: {
      ...theme.typography.display3,
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      padding: '24px 24px 0px',
      backgroundColor: color.$19,
    },
    content: {
      ...theme.typography.subheading,
      backgroundColor: hexRgba(color.$9, 20),
      padding: '24px 24px 12px',
      margin: 0,
      maxHeight: 500,
      overflow: 'auto',
    },
    actionContent: {
      ...theme.typography.subheading,
      opacity: 0.6,
      padding: '16px 24px 24px',
      backgroundColor: color.$19,
    },
    footer: {
      padding: '12px 24px 24px',
      backgroundColor: hexRgba(color.$9, 20),
      textAlign: 'right',
    },
    actionFooter: {
      padding: '0px 24px 24px',
      textAlign: 'right',
      backgroundColor: color.$19,
    },
  }),
  {
    name: 'Modal',
  },
);

const Modal = props => {
  const { paperClass, open, action, contentRef } = props;
  const classes = useStyles();
  return (
    <MUIModal
      className={classes.modalStyle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          className={classnames(classes.paper, paperClass)}
          style={props.style}
        >
          <div className={action ? classes.actionTitle : classes.title}>
            <span>{props.title}</span>
          </div>
          <div
            className={action ? classes.actionContent : classes.content}
            ref={contentRef}
          >
            {props.content}
          </div>
          <div className={action ? classes.actionFooter : classes.footer}>
            {props.actions}
          </div>
        </div>
      </Fade>
    </MUIModal>
  );
};

Modal.defaultProps = {
  paperClass: null,
  contentRef: () => {},
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  paperClass: PropTypes.string,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default Modal;
